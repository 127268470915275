import React from "react";
import { BsCheck2 } from "react-icons/bs";
import { TabSelector } from "../TabSelector";

interface ITab {
  completed: boolean;
  active: boolean;
  showOnDesktop?: boolean;
  position: number;
  label: string;
  subText: string;
  tab?: string;
  onClick?: () => void;
}

const TransferFYCTabs = ({
  completed,
  active,
  tab,
  showOnDesktop,
  position,
  label,
  subText,
  onClick,
}: ITab) => {
  return (
    <TabSelector
      isActive={active}
      onClick={onClick}
      className={`p-2 px-2 rounded-full md:rounded-md items-center  transition-all duration-500 gap-3 md:gap-5  flex-row  cursor-pointer shrink-0 ${
        showOnDesktop ? " flex md:flex-col   " : "flex md:flex-col "
      } ${active ? "bg-[#0EB683] md:bg-transparent" : "bg-transparent"}`}
    >
      <div
        className={`w-7 h-7 rounded-full flex  justify-center shrink-0 transition-all duration-500 md:border md:mb-1 md:border-[#0EB683] items-center md:z-10 ${
          completed ? "bg-[#0eb683]" : "bg-[#F6FCFA] md:bg-white"
        }`}
      >
        {completed && <BsCheck2 color="#fff" />}
        {!completed && (
          <span
            className={`${
              completed
                ? "text-white transition-all duration-500"
                : "text-[#0EB683]"
            } md:hidden`}
          >
            {position + 1} 
          </span>
        )}
        {!completed && active ? (
          <div className="hidden md:block dot rounded-full h-2 w-2 bg-[#0EB683]"></div>
        ) : null}
      </div>
      <div className="details flex flex-col items-center text-left">
        <span
          className={`${
            active ? "text-white md:text-[#0EB683] text-sm" : "text-xs"
          } md:text-lg md:font-semibold text-[#0EB683] transition-all duration-500`}
        >
          {label}
        </span>
        <span className="text-xs text-gray-500 hidden md:inline ">
          {subText}
        </span>
      </div>
    </TabSelector>
  );
};

export default TransferFYCTabs;
