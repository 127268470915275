import React from "react";
import brandLogo from "../../assets/misc/design-top.svg";
import brandLogo2 from "../../assets/misc/design-bottom.svg";
import logo from "../../assets/misc/logo-on-dark.svg";
import messageIcon from "../../assets/misc/messageIcon.svg";
import { useNavigate } from "react-router-dom";

const PasswordEmailScreen = () => {
  const navigate = useNavigate();

  const reRoute = () => {
    navigate("/");
  };

  return (
    <div>
      <img src={brandLogo} alt="" className="absolute w-28  md:w-44 " />
      <img
        src={brandLogo2}
        alt=""
        className="absolute w-28 md:w-44 right-0 bottom-0"
      />
      <div className=" flex items-center flex-col gap-5 bg-[#0C0D1F] h-screen justify-center ">
        <img src={logo} alt="" className=" w-60 inline pb-5" />
        <img src={messageIcon} alt="" className="pt-5" />

        <h1 className="text-white font-bold text-3xl pt-5">Check your email</h1>
        <small className="text-center text-white ">
          We’ve sent you an email,
          <br />
          it contains a link to reset your password
        </small>

        <button
          onClick={reRoute}
          className="bg-[#0eb683] px-28 md:px-40 py-3 rounded-md text-white"
        >
          Okay!
        </button>
        <small className="text-center">
          Didn’t receive the email? Check your spam folder or
          <br />
          <button
            onClick={() => navigate("/forgotPassword")}
            className="text-[#0eb683]"
          >
            resend email
          </button>
        </small>
      </div>
    </div>
  );
};

export default PasswordEmailScreen;
