import React from "react";
interface IMainProps {
  currentStep: number;
  formSteps: (step: number) => JSX.Element | undefined;
  err: string;
}
const StepperMain = ({ currentStep, formSteps, err }: IMainProps) => {
  return (
    <div className="flex-auto h-full bg-[#F6FCFA] overflow-y-auto py-3 md:px-[10%] px-5">
      <h1 className="hidden md:block text-[32px] leading-[48px] font-bold pt-8 py-4 px-8 md:px-0 border-b border-[#CBE0D6] md:ml-1">
        Onboarding Refresh
      </h1>
      {err ? (
        <div className="error text-[#e01a00] text-sm text-center bg-rose-200 border-dashed border-2 border-[#a10] rounded-md py-2 mt-3">
          {err}
        </div>
      ) : (
        ""
      )}

      <div>{formSteps(currentStep)}</div>
    </div>
  );
};

export default StepperMain;
