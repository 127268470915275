import React from "react";
import { FC } from 'react';
import SvgIconConstituentValues from './types';

export interface SvgIcon extends FC<SvgIconConstituentValues>{}

const LogoutIcon = ({
    height ="24px",
    width ="24px",
    stroke ="#5c6f7f",
    ...props
}:React.SVGProps<SVGAElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width= {width}
      height= {height}
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M3.33325 10.5H12.4999"
        stroke= {stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66667 6.3335L2.5 10.5002L6.66667 14.6668"
        stroke= {stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 3V18"
        stroke= {stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogoutIcon;


