import React, { createContext, useContext, ReactNode } from "react";

export type UserType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  firstName: string;
  lastName: string;
  email: string;
  userBankId: string;
  userBankCode: string | null;
  userBankName: string | null;
  isCorePromoter: boolean;
  userAccountNumber: string;
  ngnLBPAccountName: string;
  ngnLBPBankCode: string;
  ngnLBPBankName: string;
  ngnLBPBankId: string;
  ngnLBPSubAccountNumber: string;
  ngnLBPVirtualAccountId: string;
  ngnLBPSubAccountStatus: string;
  profileStatus: string | null;
  bankStatus: boolean;
  kycStatus: string;
  verificationCode: string;
  phoneNumber: string;
  gender: string;
  invitationCode: string;
  refereeCode: string;
  accountType: string;
  dob: string;
  bvn: string;
  address1: string;
  address2: string;
  country: string;
  state: string;
  city: string | null;
  postCode: string | null;
  lbpCustomerId: string;
  lbpCustomerStatus: string;
  lbpVerificationStatus: string;
  idType: string;
  selfie: string;
  idDocPhoto: string;
  ngnLBPSubAccountId: string;
  ngnBalance: string;
  usdBalance: string;
  eurBalance: string;
  gbpBalance: string;
  usdtBalance: string;
  usdcBalance: string;
  businessName: string | null;
  businessIncNumber: string | null;
  businessType: string | null;
  businessPhoneNumber: string | null;
  businessId: string | null;
  brassSubAccountId: string | null;
  role: {
    id: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    name: string;
    code: string;
    description: string;
  };
  bankName: string;
};


interface UserContextType {
  user: UserType | null;
  setUser: (user: UserType | null) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export function useUserContext() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
}
interface UserProviderProps {
  children: ReactNode;
}

export function UserProvider({ children }: UserProviderProps) {
  const [user, setUser] = React.useState<UserType | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}