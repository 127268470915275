import React from 'react'
import { FC } from "react"
import SvgIconConstituentValues from './types'


export interface SvgIcon extends FC<SvgIconConstituentValues>{}
const TransactionsIcon = ({
    height ="24px",
    width ="24px",
    stroke = "stroke",

    ...props
}:React.SVGProps<SVGAElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width= {width} height= {height} viewBox="0 0 24 24" fill= "none">
<path d="M22 2L11.5833 12.4167M22 2L2 8.66667L11.5833 12.4167L22 2ZM22 2L15.3333 22L11.5833 12.4167L22 2Z" stroke= {stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default TransactionsIcon