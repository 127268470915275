import React, { useState } from "react";
import OTPInput from "../widgets/OTPInput";
import { motion, AnimatePresence } from "framer-motion";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";
import { FaCheckSquare } from "react-icons/fa";
import CopyIcon from "../../assets/iconsComponents/CopyIcon";

const UsdcSummary = ({currency, prev}:{currency:string, prev:()=> void}) => {
  const [verificationModal, setVerificationModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [copyStatus, setCopyStatus] = useState("");
  const [checkBox, setCheckBox] =useState("check")



  const copyToClipBoard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyStatus("Copied Successfully");
    let timeout = setTimeout(() => {
      setCopyStatus("");
      clearTimeout(timeout);
    }, 1500);
  };
  return (
    <div className="bg-[#f7fbfb] h-full w-full  ">
      <div className="flex items-center md:hidden justify-between">
        <p className="text-[#5C6F7F] text-xs">Reference Code</p>
        <h1 className="text-[#0eb683] font-medium">ABC123</h1>
      </div>
      <p className="text-[#FA0204] text-sm font-medium pt-5 md:hidden">
        Always input this reference code during transactions so your wallet is
        credited correctly
      </p>
      <div className="md:flex md:items-center md:gap-12">

      
      <div className="md:bg-white md:p-5">

      
      <h1 className="text-[#071827] font-bold text-2xl pt-5 ">Summary</h1>
      <div className="flex items-center justify-between md:pb-3">
        <p className="text-[#071827] text-sm">You are sending:</p>
       
        <h1 className="text-[#0eb683] font-bold text-2xl">0.00{currency}</h1>
      </div>
      <div className="flex flex-col gap-7 bg-[#E2F0EA]  p-5 ">
        <div className="flex items-center gap-36 md:justify-between ">
          <p className="text-[ #5C6F7F] text-sm">To:</p>
          <div className="flex gap-1">

          <div
          onClick={()=> copyToClipBoard("0x772e43feE24E36A7Db42c41BffEA73685B62af13")}
           className="text-[#0eb683] bg-[#fff] border border-[#0eb683] transition-all active:scale-90 rounded-md flex items-center gap-1 px-2 cursor-pointer">
            <CopyIcon /> 
            <p>
            Copy
              </p> 
            </div>
            
          <h1 className="break-all text-[#071827] font-medium self-end">
            0x772e43feE24E36A7Db42c41BffEA73685B62af13{" "}
          </h1>
          
          </div>
        </div>
        <div className="flex gap-11 md:justify-between">
          <p className="text-[ #5C6F7F] text-sm">Beneficiary Name:</p>
          <h1 className="text-[#071827] font-medium text-right break-all">Abubakar Chinedu</h1>
        </div>
        <div className="flex gap-32 md:justify-between">
          <p className="text-[ #5C6F7F] text-sm">Alias:</p>
          <h1 className="text-[#071827] font-medium">Abuchi</h1>
        </div>
        <div className="flex gap-14  md:justify-between">
          <p className="md:hidden text-[ #5C6F7F] font-medium text-sm">
            Save Beneficiary <br /> Details:
          </p>
          <p className="hidden md:inline text-[ #5C6F7F] font-medium text-sm">
            Save Beneficiary Details:
          </p>
          <div className="border w-4 h-4 bg-white border-slate-300 relative">
            <div className="text-[#0eb683] absolute left-[-2px] top-[-2px]">

            <FaCheckSquare size={20}/>
            </div>
          </div>
        </div>
      </div>
      <p className="text-[#FA0204]  font-bold pt-5">
        Please verify wallet address as transactions are non-refundable
      </p>
      </div>

      <div className=" flex flex-col  py-5 md:py-10 md:bg-white  md:px-5 md:py- md:my-5 md:max-w-lg  md:mr-16  ">
        <h1 className="text-[#071827] text-2xl font-bold pb-5 md:pb-3">Confirmation</h1>
        <p className="text-[#0eb683] text-sm pb-9">
          Transfers typically depend on the blockchain network
        </p>
        <p className="text-[#0B0C0E] text-sm pb-3 md:mb-">I confirm that:</p>
        <div className="flex flex-col gap-5">
          <div className=" flex items-center gap-5">
            <input
              type="checkbox"
              id="checkbox"
              className=" border-4 border-slate-300 p-2     "
            />
            <p className="text-[#5C6F7F] text-sm">
              The information I have provided is correct
            </p>
          </div>
          <div className=" flex items-center gap-5">
            <input
              type="checkbox"
              id="checkbox"
              className=" border border-slate-300 p-2 bg-[#0eb683]   "
            />
            <p className="text-[#5C6F7F] text-sm">
              I agree my {currency} balance be debited along with all associated charges
            </p>
          </div>
          <div className=" flex items-center gap-5">
            <input
              type="checkbox"
              id="checkbox"
              className=" border border-slate-300 p-2 mb- "
            />
            <p className="text-[#5C6F7F] text-sm">
              I agree to be bound by Trusted Transfers'{" "}
              <button className="text-[#0EB683] transition-all active:scale-90 underline">
                Terms of Service
              </button>
            </p>
          </div>
          <div className=" flex items-center gap-5">
            <input
              type="checkbox"
              id="checkbox"
              className=" border border-slate-300 p-2 mb-6"
            />
            <p className="text-[#5C6F7F] text-sm">
              By clicking the button below, Trusted Transfers is authorized to
              transfer the funds to the beneficiary wallet{" "}
            </p>
          </div>
        </div>
      </div>
      </div>
      <div className="flex items-center justify-between md:bg-[#f7fbfb] md:pb-6">
        <button
          onClick={prev}
          className="text-[#0eb683] bg-white border border-[#0eb683] p-3 px-8 mt-10     md:max-w-lg rounded-md transition-all active:scale-90"
        >
          Previous
        </button>
        <button
          onClick={() => {
            setVerificationModal(true);
          }}
          className="bg-[#0eb683] text-white px-8 p-3 mt-10     md:max-w-lg rounded-md transition-all active:scale-90 md:mr-36 "
        >
          Initiate Transfer
        </button>
      </div>
      {verificationModal && (
        <div>
          <div>
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="confirmation_modal backdrop-blur-sm bg-[#13111137] fixed w-full h-full top-0 left-0 justify-center md:justify-end items-center z-10"
            >
              <motion.div
                initial={{
                  x: 600,
                  scale: 0,
                }}
                animate={{
                  x: 0,
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 600,
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                className="flex h-screen items-end md:items-center  md:justify-center "
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="card flex flex-col bg-white h-[350px] w-full max-w-md md:max-w-md md:max-h-full rounded-t-3xl md:rounded-3xl p-10 "
                >
                  <div className="flex flex-col gap-3">
                    <div className=" ">
                      <h1 className="text-3xl text-[#0eb683] font-extrabold self-center text-center mb-8">
                        Verification
                      </h1>
                    </div>
                    <div className=" mt-1 md:mt-0">
                      <p className="text-[#5C6F7F] text-sm ">
                        Enter the 5-digit code sent to your email
                      </p>
                      <OTPInput
                        autoFocus
                        length={5}
                        className="otpContainer flex justify-between  w-full py-3 mt-4 md:mt-0 "
                        inputClassName="otpInput w-12 h-12 md:w-[50px] md:h-[50px] bg-white border border-[#0EB683] outline-[#0EB683] text-[#0EB683] text-2xl text-center font-bold rounded-lg"
                        autoCapitalize={true}
                        onChangeOTP={() => {}}
                        onPaste={() => {}}
                      />
                    </div>
                    <div className="flex items-center justify-center">
                      <button
                        onClick={() => {
                          setVerificationModal(false);
                          setSuccess(true);
                        }}
                        className="bg-[#0eb683] w-full self-center justify-center  text-white p-3 rounded-md transition-all active:scale-90 mt-4 md:mt-0 "
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      )}
      {success && (
        <div>
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                // delay: 0.3,
                duration: 0.1,
              },
            }}
            className="confirmation_modal bg-[#13111173] backdrop-blur-sm fixed w-full top-0 left-0 justify-center md:justify-end h-full items-center z-50"
          >
            <motion.div
              initial={{
                x: 600,
                scale: 0,
              }}
              animate={{
                x: 0,
                scale: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                x: 600,
                scale: 0,
                transition: {
                  delay: 0.3,
                },
              }}
              className="flex h-screen items-center justify-center md:justify-end"
            >
              <motion.div
                initial={{
                  x: 600,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className="desktop-card md:flex hidden justify-center flex-col gap-6 bg-white  w-full max-w-md h-[450px] md:h-full rounded-l-3xl p-5 "
              >
                <div className="icon self-center">
                  <SuccessMobile width={"200px"} height={"200px"} />
                </div>
                <h1 className="text-2xl font-bold text-[#071827] self-center">
                  Transfer initiated
                </h1>

                <p className="text-sm text-[#5C6F7F] self-center text-center">
                  Your recepient will receive value shortly
                </p>

                <button
                  onClick={() => {
                    setSuccess(false);
                  }}
                  className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                >
                  Done
                </button>
              </motion.div>
              {/* Mobile */}

              <motion.div
                initial={{
                  x: 600,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className="mobile-card flex md:hidden justify-center flex-col gap-8 bg-white  w-full max-w-sm h-[450px] rounded-3xl p-5 "
              >
                <div className="icon self-center">
                  <SuccessMobile width={"100px"} height={"100px"} />
                </div>
                <h1 className="text-2xl font-bold text-[#071827] self-center">
                  Transfer initiated
                </h1>

                <p className="text-sm text-[#5C6F7F] self-center text-center">
                Your recepient will receive value shortly
                </p>

                <button
                  onClick={() => {
                    setSuccess(false);
                  }}
                  className="bg-[#0eb683] text-white rounded-md px-16 py-3 self-center transition-all active:scale-90"
                >
                  Done
                </button>
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default UsdcSummary;
