import { useMemo } from "react";
import { useLocation } from "react-router-dom";

type UseQueryParamPropType = string | string[];

/**
 * Get Query Strings of the browser URL
 * @param props query string(s) key to filter
 * @returns Object containing the props search
 */
const useQueryParams = (props: UseQueryParamPropType) => {
  const { search } = useLocation();
  return useMemo(() => {
    const searchKeys = props instanceof Array ? props : [props];
    return searchKeys.reduce((accum, key) => {
      accum[key] = new URLSearchParams(search).get(key);
      return accum;
    }, {} as Record<string, any>);
  }, [search, props]);
};

export default useQueryParams;