import React from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/misc/logo-on-light.svg";

const TermsOfUse = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen w-screen  bg-[#fff] overflow-y-scroll">
      <div className=" xxs:p-5 md:px-14">
        <div className="flex  justify-between items-center mb-9">
          <div className="flex items-center ">
            <button
              onClick={() => {
                navigate("/login", { replace: true });
              }}
              className=" md:bg-slate-400 rounded-full md:p-2 xxs:mr-2 flex transition-all active:scale-90 hover:scale-110"
            >
              <BsChevronLeft size={23} />
            </button>
            <h1 className="xxs:text-sm md:text-md    text-black font-sans font-extrabold ">
              GENERAL TERMS OF USE{" "}
            </h1>
          </div>

          <div className="xxs:w-20 md:w-32 flex  ">
            <img src={logo} alt="" />
          </div>
        </div>

        <ol className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            These general Terms and Conditions ("Terms of Use") shall apply to
            the terms and conditions around your use of the website,{" "}
            <a
              className="hover:underline text-[#0eb683]"
              href="https://www.ttfcx.com/ "
            >
              https://www.ttfcx.com/{" "}
            </a>{" "}
            (the “website”), the web platform, mobile application (“Platform”)
            and related materials and documentation made available by TTFCX
            (including all updates and other modifications thereto) through any
            of its Platforms.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Access to our Platform is by invitation. Before registering,
            creating your account (“Account”), logging into and using our
            Platforms, please read and understand the Terms of Use and the Terms
            of Service. By using the Platform, you are bound by it together with
            any other terms and conditions that may be applicable for any type
            of service or product we make or develops in future.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            If you do not agree to our Terms of Use, you may not access the
            Platform or tools. If you use any of the services offered on the
            Platform on behalf of a business, you represent and warrant that you
            can enter into this Agreement with TTFCX on behalf of that business,
            that you accept the terms and conditions contained herein on behalf
            of that business, and that you have received a copy of the
            Agreement.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            All the terms and conditions listed on this page shall together be
            referred to as the “Terms of Use". "User/Client/Customer", "You" and
            “Your" refers to you, the person/business/corporate entity accessing
            the service and accepting TTFCX's terms and conditions. Whenever you
            use the services on the Platforms, you agree to be bound by the
            Terms of Service, including any amendment, which may periodically
            take place.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            The Terms of Use contained herein represent a complete, and
            exclusive Agreement between you and TTFCX (“TTFCX Enterprises”,
            “we”, “us, “our” or “The Company”) and should be read alongside any
            other and all prior Agreements, representations, and understandings
            (“Related Agreements”). If any conflict exists between the Terms of
            Use and any Related Agreements, the specific terms of the Related
            Agreements shall apply with respect to the particular service being
            accessed by you to the extent of the conflict. Our services are not
            for Users below 18 years of age.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            All Services may be date and time-stamped and we may issue unique
            transaction identification (“ID”) for proper authentication,
            documentation, and accounting of the relevant service period.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX cannot be held liable in any case for any damage or loss
            caused directly or indirectly by the intentional or gross negligence
            of User or by the contents provided by a third-party or by factors
            outside our control.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX shall not be liable if you are unable to use our services or
            access the Platform due to congestion, network failure/downtime or
            malfunction of electronic communication media used for such
            purposes; or third-party actions of which we have no control.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX is an independent contractor for all purposes, providing the
            Platforms and our services on an independent service provider basis.
            We do not have control or assume the liability for the purposes for
            which our service is used.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Any other legal relationship between the User and TTFCX, for every
            circumstance not regulated by the Terms of Use, shall be subject to
            accepted standards of practice in accordance with the laws and
            regulations of the jurisdiction of the User.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          AMENDMENTS TO THE TERMS OF USE
        </h1>
        <ol className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX can change the Terms of Use at any time at its own discretion.
            All changes shall become effective on their publication on our
            website, and you shall be notified by email. We will post a
            notification on our Platforms of any amendments or revisions to
            these Terms of Use and your use of the Platforms and if the changes
            reduce your rights or increase your responsibilities, we will
            provide you with advance notice of 7 (seven) days from the date of
            the publication.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            If you consider any amendment carried out to be intolerable, you may
            cease using our services.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            The continued use of our services after the date when the Terms of
            Use are meant to come into effect shall correspond to a complete
            acceptance of the revision carried out.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          MAINTAINING YOUR ACCOUNT{" "}
        </h1>
        <ol className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            An individual or business who wants to have access to our services
            shall receive an invitation to create an Account on the Platform. By
            creating an Account and using our services, you warrant that you are
            legally eligible to conduct transactions or authorized to do so on
            behalf of your business. If it comes to our notice that you are
            ineligible or lack such authorization, you shall be removed from the
            Platform and may be investigated for signs of illegal activity.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            When a new account is created, you are responsible for the accuracy
            and validity of all the personal and business information required.
            These include your contact details, location, identity documents and
            the particulars of the service required. TTFCX may close or suspend
            your Account if the information you provide is false, inaccurate, or
            incomplete and may report you to the appropriate authorities.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            All personal information is stored in TTFCX’s secure and encrypted
            database.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            After creating an Account, you must keep your login, personal
            information, and transaction details confidential and secure. All
            services where your data has been entered correctly shall be
            regarded as valid. All Account registrations are subject to rigorous
            KYC processes through our third-party partners engaged for such
            services. We shall not be liable for any breaches or claims if it is
            established that you compromised or exposed your login details
            and/or personal data through no fault of ours.
          </li>

          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            You may close your Account/opt out whenever you wish by closing and
            deactivating it. The effective closure of the Account shall
            correspond to the termination of the Terms of Use and Terms of
            Service. This will not absolve you from fulfilling any obligations
            owed to us.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            In accordance with the legal period of warranty requested by law,
            all the personal data saved in TTFCX’s database shall be deleted at
            the User’s express request after the expiration of the respite
            period provided by the data protection regulations or laws in your
            jurisdiction.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            A closed Account may be reopened when you reactivate the Account. In
            such circumstances, you shall be subject to the Terms of Use and
            Terms of Service that are in force at the date of the re-opening.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            We retain the right to exclude you from our services at our
            discretion. If an Account was closed because of an infringement or
            violation pursuant to the Terms of Use and/or Terms of Service, or
            because a User performed a prohibited action leading to fraud or
            illegal activity, that User may be reported to the appropriate
            authorities in their jurisdiction.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            If a User does not access their Account by "logging in" with their
            Account name and security details for a period of twelve calendar
            months, that account shall be deemed,” Inactive" and may be
            deactivated.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          USER CONDUCT ON THE PLATFORM:
        </h1>
        <ol className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            It is your responsibility as a User not to use our services for any
            illegal or fraudulent transactions. It is also your responsibility
            to verify the source and legality of your incoming and outgoing
            transactions.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Our fees for providing the Services are non-refundable. Where we are
            unable to process/complete our service, you shall be refunded in
            full.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Rates displayed on the Platform are subject to market fluctuations.
            Sometimes we give Users a limited window (available at our
            discretion) to use the rates for our services. If a rate changes
            after you lock in and make an outgoing transaction, you will be
            refunded if you decide not to continue your transaction with the new
            rates.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            You are solely responsible for the transaction information you
            provide to TTFCX and must promptly inform TTFCX of any changes
            thereto. Changes should be communicated to TTFCX by entering the
            updated information through your Account on the Platform or
            contacting TTFCX customer support.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          SUSPENSION OF USE OF PLATFORM:
        </h1>
        <ol className="flex flex-col gap-3 mb-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            We may suspend your access to the Platform or immediately terminate
            this Terms of Use if you:
          </li>
        </ol>
        <ul className="flex flex-col gap-3 mb-3 md:pl-10">
          <li className="font-sans font-medium text-xs">
            <span className="font-bold">(a)</span> Breach the Terms of Use,
          </li>
          <li className="font-sans font-medium text-xs">
            <span className="font-bold">(b)</span> Deliberately provide false
            registration information{" "}
          </li>
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(c)</span> Consistently fail of our
            KYC/Due Diligence processes{" "}
          </li>
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(d)</span> Repeatedly provide misleading
            information or withhold information required for us to operate on
            the platform or provide its services{" "}
          </li>
          <li className="font-sans font-medium text-xs">
            <span className="font-bold">(e)</span> Commit any criminal activity
            including perpetration or intention to commit fraud on the Platform
            or directly or indirectly through the Platform
          </li>
          <li className="font-sans font-medium text-xs">
            <span className="font-bold">(f)</span> Fail to comply with TTFCX's
            best practices requirements for security management or to respond to
            an inquiry from TTFCX, concerning the accuracy or completeness of
            the information you are required to provide pursuant to this Terms
            of Use
          </li>
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(g) </span>Are linked to terrorist
            related activities
          </li>
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(h)</span> Treat our services, tools, or
            team members unprofessionally.
          </li>
        </ul>
        <ol start={2} className="flex flex-col gap-3 mb-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Furthermore, we may suspend you or terminate your Account:
          </li>
        </ol>
        <ul className="flex flex-col gap-3 mb-3 md:pl-10">
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(i)</span> If we reasonably suspect
            criminal and/or fraudulent activity on your Account or transaction
            activities;
          </li>
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(j)</span> If any amendable breach is
            not cured within the period of notice from us;
          </li>
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(k)</span> In the event that certain
            third-party licenses or access to third-party components of the
            Platform are terminated;
          </li>
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(l)</span> If we reasonably believe your
            breach compromises the integrity and/or security of the Platform in
            any material fashion and/or the security of other users;
          </li>
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(m)</span> Fraudulent transactions are
            being run with your Account
          </li>
        </ul>
        <ol start={3} className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Transactions once initiated are irreversible. In some circumstances
            TTFCX may be able to suspend a transaction before a given period
            elapses. This however is not guaranteed.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            {" "}
            The User is obliged to treat confidentially the terms of their
            obligation with TTFCX, as well as all other information and data
            they acquire in connection with the Platform and not use it for
            purposes outside the scope of this Terms of Use or pass it on to
            third parties.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          LEGAL USE OF THE PLATFORM AND OF ITS COMPONENTS
        </h1>

        <ol className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Under no circumstances shall the Platform be used for any purpose
            different from the services for which it was developed. We shall not
            be liable for any unauthorized access to a User’s Account or
            attempts to use, and/or the results of using the Platform or our
            services for unauthorized purposes and in a manner not intended for
            by TTFCX.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            All Intellectual Property including Trademarks, Domains, Terms of
            Use, Logos, Images and any other material developed by TTFCX
            (graphics, pictures, text etc.), as well as any other algorithm,
            software, client list or information, content or underlying idea
            developed by TTFCX for the Platform, are owned by us and protected
            by copyright in their entirety and may not be modified, reproduced
            and redistributed, in whole or in part, without our express previous
            written permission. All necessary legal actions shall be undertaken
            to ensure compliance to this policy.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Any action aimed to interfere with the normal functioning of the
            Platform including, but not limited to, bugging, registration of
            false User details, duplicate registrations, manipulation of
            Platform’s system, hacking, releasing or propagating viruses, worms
            or logic bombs or similar fraudulent activity is strictly prohibited
            and shall be pursued to the fullest extent permitted by law.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            We reserve the right to restrict access to certain parts of the
            Platform and to certain services offered, including, but not limited
            to Live Chat and Internal Messaging system.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            We may periodically and without previous notice, remove or amend the
            design, interface, look and feel of the Platform or any of the
            services offered on the platform, as well as alter available service
            fees where such alterations do not affect services already in
            progress.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Under some circumstances, we may provide our Users with software
            designed internally or by third parties in order to optimize the
            functioning of the Platform or to benefit from additional services.
            In such circumstances, you may be asked to accept further Terms and
            Conditions related to the utilization of the new component. Any
            attempt to interfere with, modify or reverse engineer any software
            provided is strictly prohibited.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            We are not liable for any network failures caused by the electronic
            equipment used by a User while accessing the Platform or for faults
            due to their Mobile Network Operator or Internet Service Provider
            while accessing the Platform or any failures caused by third
            parties’ actions that we have no control of.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            The section headings appearing in this Terms of Use are inserted
            only as a matter of convenience and in no way define, limit,
            construe or describe the scope or extent of such section or in any
            way affect such section.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            We are always working to improve the Platform so there may be some
            downtime, technical issues, or interruptions on some features in; or
            aspects of the Platform. Contact customer care with the information
            provided on both the Platform and the website if you are affected
            and exercise some patience while you are instructed on the next
            steps to take.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            {" "}
            We shall not be deemed in default hereunder, nor shall be
            responsible for, any cessation, interruption or delay in the
            performance of our obligations hereunder, due to earthquake, flood,
            fire, storm, natural disaster, pandemic, act of God, war, terrorism,
            armed conflict, national or international lockdown, labor strike,
            lockout, or boycott, that may affect the financial services industry
            provided that the User relying upon this clause shall receive
            written notice thereof promptly and, in any event, within seven (7)
            days of discovery thereof, and shall take all steps reasonably
            necessary under the circumstances to mitigate the effects of the
            force majeure event upon which such notice is based; provided,
            however, that in the event a force majeure event described in this
            Section extends for a period in excess of thirty (30) days in the
            aggregate, TTFCX or the User may decide whether to terminate the
            contract.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          INVITATION OF NEW USERS
        </h1>
        <ol className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Users may be given the privilege of inviting an individual or
            business to the Platform for in-app bonus points or for a commission
            at the sole discretion of TTFCX, provided they make confidentiality
            and discretion a priority.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Where our Platforms contain links to other websites and resources
            provided by our partners or other third parties, these links are
            provided for information only. We do not make any representations
            regarding the accuracy and reliability of contents available on such
            third-party websites and accept no responsibility for them or for
            any loss or damage that may arise from their utilization.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          PROMOTIONS & REWARDS
        </h1>
        <ol className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Your eligibility for any offer or promotion and/or participation in
            a rewards program shall be subject to the terms and conditions for
            the respective bonus offer, promotion, or rewards program also
            available on the website, by email and TTFCX’s social media pages
            always.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX reserves the right to deny admission or to terminate admission
            to any of the bonuses, promotions and/or special offerings, as well
            as to modify, suspend or discontinue their validity at its sole
            discretion and without informing the User.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          COMPLAINTS AND SUGGESTIONS
        </h1>
        <ol className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            If the User wishes to make a complaint or a suggestion regarding
            TTFCX, the Platform or the Services offered, contact our Customer
            Centre section through the messaging function in your Account on the
            Platform or through the provided phone numbers or send us an email.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Complaints, claims, or suggestions regarding the acceptance,
            settlement, or resettlement of a TTFCX service must be made within
            48 hours from the expiry of such service.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          WAIVER
        </h1>
        <ol className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            In no event shall any delay, failure or omission (in whole or in
            part) in enforcing, exercising or pursuing any right, power,
            privilege, claim or remedy conferred by or arising under these Terms
            of Use or by law, be deemed to be or construed as a waiver of that
            or any other right, power, privilege, claim or remedy in respect of
            the circumstances in question, or operate so as to bar the
            enforcement of that, or any other right, power, privilege, claim or
            remedy, in any other instance at any time or times subsequently.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          SEVERABILITY
        </h1>
        <ol className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            48. If any provision of these Terms of Use is found by any court or
            administrative or regulatory body of competent jurisdiction to be
            invalid or unenforceable, such invalidity or unenforceability shall
            not affect the other provisions of these Terms of Use, which shall
            remain in full force and effect.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            In such instances, the part declared invalid or unenforceable shall
            be amended in a manner consistent with the applicable law to
            reflect, as closely as possible, TTFCX's original intent.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          INDEMNIFICATION{" "}
        </h1>
        <ol className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            You agree that TTFCX shall have no liability whatsoever for any use
            you make of the Platform. You agree to indemnify and hold harmless
            TTFCX from any and all claims, damages, liabilities, costs, and fees
            (including reasonable attorneys’ fees) arising from negligent use of
            the Platform or your breach of this Agreement.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          NO WARRANTY/LIMITATION OF LIABILITY
        </h1>
        <ol className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            The Platform and all content, software, materials, and other
            information provided by TTFCX or otherwise accessible to you in
            connection with this agreement or TTFCX's services, are provided "as
            is" and TTFCX, and their respective affiliates make no warranty of
            any kind (and disclaim all warranties of any kind) with respect to
            the foregoing, whether express or implied, in each case to the
            maximum extent permitted under applicable law without limiting the
            foregoing, TTFCX makes no warranty but shall do its reasonable best
            to ensure that the Platform meets your requirements and is available
            on an uninterrupted, secure, or error-free basis.
          </li>

          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX makes no warranty but shall do its best to provide reasonable
            standard quality in the Services on the Platform. TTFCX or its
            partners shall not be liable to you or to any third-party under any
            tort, contract, negligence, strict liability, or other legal or
            equitable theory for:
            <ul className="">
              <li className="  md:px-6 font-sans font-medium text-xs mt-2">
                (a) any lost profits, interruption of business, or other
                special, indirect, incidental, or consequential damages of any
                kind arising out of the use or inability to use the Platform
                even if TTFCX has been advised of the possibility of such loss
                or damages and whether or not such loss or damages are
                foreseeable, or
              </li>

              <li className="  md:px-6 font-sans font-medium text-xs mt-2">
                (b) any damages relating to the website, tools, or TTFCX
                services, or your use thereof.
              </li>
            </ul>
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          APPLICABLE LAW AND PLACE OF JURISDICTION
        </h1>
        <ol className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            The applicable provisions of the Laws of Nigeria shall govern any of
            TTFCX’s Services used by the User and any Regulations and Rules made
            in terms thereof, as amended from time to time. It shall be the
            responsibility of the User to ensure that they are aware of these
            provisions. The language used in any transaction related to this
            Terms of Use shall be English.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            You commit to us that by opening an Account on our Platform and
            using of our Services, you do not violate any laws applicable to
            you. You take responsibility for any consequences of your breach of
            this section.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          TERMINATION{" "}
        </h1>
        <ol className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX reserves the right to suspend or to permanently terminate, any
            of its Services to a User without providing any prior reason.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Any funds belonging to the User shall be promptly paid into the
            User’s wallet provided in the Platform from which it was paid, less
            any outstanding fees or charges owed to TTFCX.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            This Terms of Use are valid immediately Parties sign up to create an
            Account on the Platform and remain valid indefinitely, until User
            closes their account or opt out and delete the Platform or is
            terminated by TTFCX.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Typos, mistakes, and transmission errors are excluded from this, as
            long as they are not caused with intent or by gross negligence.
          </li>
        </ol>
      </div>
      <p className="font-sans font-medium text-xs px-5 mb-11">
        TTFCX is currently available on the web; and at a date which will be
        communicated to you, the mobile application will be available for
        download on Google Play store for Android device users and the App Store
        for Apple iOS device users.
      </p>
    </div>
  );
};

export default TermsOfUse;
