export const onboardingSteps = [
  {
    name: "choose",
    label: "Account Type",
    description: "Select your account type",
    completed: false,
    link: "/onboarding",
    slug: "choose",
  },
  {
    name: "business-representative",
    label: "Business Representative",
    description: "Complete basic details about the account manager",
    completed: false,
    link: "/onboarding/business-details",
    slug: "business-representative",
  },
   {
    name: "individual",
    label: "User Details",
    description: "Complete basic details about yourself",
    completed: false,
    link: "/onboarding/individual-details",
    slug: "individual",
  },
  {
    name: "bank",
    label: "Bank Details",
    description: "Complete basic details about your bank information",
    completed: false,
    link: "/onboarding/bank-details",
    slug: "bank",
  },
  {
    name: "password",
    label: "Create Password",
    description: "Secure your account from unauthorized access and fraud",
    completed: false,
    link: "/onboarding/create-password",
    slug: "password",
  },
  {
    name: "business",
    label: "Business Details",
    description: "Complete basic details about your business",
    completed: false,
    link: "/onboarding/business-details",
    slug: "business",
  },
  {
    name: "business-owner",
    label: "Ultimate Business Owners",
    description: "Complete basic details about business owners",
    completed: false,
    link: "/onboarding/business-details",
    slug: "business-owner",
  },


];
