import React, { useEffect, useState } from "react";
import { AiOutlineMinus } from "react-icons/ai";
import { FiSend } from "react-icons/fi";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { numberWithCommas } from "../../pages/dashboard/Withdrawals";
import { useTransactionReference } from "../../sevices/hooks/wallets";
import { ITransaction } from "../../store/useTransactions";
import { useTabs } from "../widgets/TabWidget";

function chunkArray<T>(array: T[], size: number) {
  let result = [];
  for (let i = 0; i < array?.length; i += size) {
    let chunk = array?.slice(i, i + size);
    result.push(chunk);
  }
  return result;
}

const Activity = ({
  tabs,
  itemsPerPage,
  currentPage,
  data,
}: {
  tabs: string[];
  itemsPerPage: number;
  currentPage: number;
  data: ITransaction[];
}) => {
  const [selectedTab, setSelectedTab] = useTabs(tabs);
  const [currentPageIndex, setCurrentPageIndex] = useState(currentPage);
  const [tableHeaders] = useState([
    "SN",
    "TYPE",
    "TIMESTAMP (GMT+1)",
    "TRANSACTION REF",
    "CURRENCY",
    "AMOUNT",
    "NARRATION",
    "STATUS",
  ]);
  const [tableData, setTableData] = useState(data);
  const [chosenTab, setChosenTab] = useState("All");

  useEffect(() => setTableData(data), [data]);
  // const transactionRef = useTransactionReference();


  const createStatusBadge = (status: string) => {
    const colorMap: any = {
      initiated: { bg: "bg-[#e5e5e5]", color: "text-[#5C6F7F]" },
      "in-progress": { bg: "bg-[#fff2eb]", color: "text-[#FF7F36]" },
      successful: { bg: "bg-[#e2fef0]", color: "text-[#0EB683]" },
    };

    const { bg, color } = colorMap[status?.toLowerCase()] || {};

    return (
      <span
        className={`inline-block p-1 px-3 rounded-full whitespace-nowrap text-sm ${color} ${bg}`}
      >
        {status}
      </span>
    );
  };
  const moment = require("moment");
  const navigate = useNavigate();



  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;

    if (!searchTerm.trim()) {
      if (chosenTab.toLowerCase() === "all") return setTableData(data);
      setTableData(
        data.filter((d) => d.currency.toLowerCase() === chosenTab.toLowerCase())
      );
      return;
    }

    const filterTransaction = (item: ITransaction, searchTerm: string) => {
      const isAmount =
        typeof item.amount === "string"
         //@ts-ignore
          ? item.amount.indexOf(searchTerm) !== -1
          : item.amount.raw.indexOf(searchTerm) !== -1;

      const exist =
        isAmount ||
        (item.currency &&
          item.currency.toLowerCase().indexOf(searchTerm) !== -1) ||
        (item.narration &&
          item.narration.toLowerCase().indexOf(searchTerm) !== -1) ||
        item.status?.toLowerCase().indexOf(searchTerm) !== -1;

      return exist;
    };

    const newList = (
      chosenTab.toLowerCase() === "all" ? data : tableData
    ).filter((item) => filterTransaction(item, searchTerm.toLowerCase()));

    setTableData(newList);
  };

  function truncateString(str: string, num: number) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  useEffect(() => {
    if (chosenTab === "All") {
      setTableData(data);
    } else {
      setTableData(
        data.filter((d) => d.currency.toLowerCase() === chosenTab.toLowerCase())
      );
    }
  }, [chosenTab]);

  return (
    <div>
      <div className="overflow-x-auto md:mt-0 xxs:mt-5">
        <div className="w-full rounded-2xl flex items-center justify-center overflow-hidden">
          <div className="w-full ">
            <div className="bg-white shadow-md rounded hidden md:block">
              <header className="flex justify-between p-4 border-b border-slate-50  ">
                <h1 className="text-[#071827] text-xl font-semibold">
                  Recent Activity
                </h1>
                <p
                  onClick={() => {
                    navigate("/dashboard/transactions", { replace: true });
                  }}
                  className="text-[#0eb683] font-medium hover:underline cursor-pointer transition-all active:scale-90 pr-8 text-lg"
                >
                  View all
                </p>
              </header>
              <table className="min-w-max w-full table-auto">



                {data.length ? (
                  chunkArray(tableData, itemsPerPage)[
                    currentPageIndex - 1
                  ]?.map((transaction, index) => {
                    const {
                      date,
                      reference,
                      createdAt,
                      id,
                      unit,
                      gateway_reference,
                      narration,
                      status,
                      currency,
                      type,
                      amount,
                      memo,
                      is_committed,
                    } = transaction;
                    





                    const statusBadge = createStatusBadge(
                      status || "In-Progress"
                    );

                    const transactionType =
                    type === "debit" ? (
                      <AiOutlineMinus size={15} className="font-extrabold" />
                    ) : type === "credit" ? (
                      <MdAdd size={15} className="font-extrabold" />
                    ) : (
                      <FiSend size={15} className="font-extrabold" />
                    );

                    const ref = truncateString(
                      reference,
                      17
                    ).toUpperCase()

                    return (
                      <>
                        <tbody className="text-gray-600 text-sm font-light">
                          <tr className="border-b border-slate-50 hover:bg-gray-100">
                            <div className="flex items-center gap-5 pl-3">
                            <td className="py-3 px-6 text-left whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="mr-2 font-bold">{index + 1}</div>
                            </div>
                          </td>
                              <figure className="py-3 pr-6 text-left whitespace-nowrap">
                                <div
                                  className={`  w-8 h-8 rounded-full ${
                                    type === "debit"
                                      ? "bg-[#FEECE2] text-[#FA0204]"
                                      : type === "credit"
                                      ? "bg-[#E2FEF0] text-[#0eb683]"
                                      : "bg-[#E2FEF0] text-[#0eb683]"
                                  }  flex items-center justify-center font-bold`}
                                >
                                  {transactionType}
                                </div>
                              </figure>

                              <div>
                                <p className="">
                                  {type
                                    ? type.charAt(0).toUpperCase() +
                                      type.slice(1)
                                    : ""}
                                </p>
                                <div className="text-[#071827]">{amount}</div>
                              </div>
                            </div>
                            <td className="py-3 px-6 ">
                              <div
                                // onClick={getDetails}
                                className=" whitespace-nowrap hover:underline text-[#0eb683] cursor-pointer text-md"
                              >
                                {ref}
                              </div>
                            </td>
                            <td className="py-3 px-6 text-[#071827] text-md">
                              <div className="">
                              {currency.toUpperCase() === "USDC" ? "USD" : currency.toUpperCase() }

                              </div>
                            </td>
                            <td className="py-3 px-6 ">
                              <h1 className=" whitespace-nowrap text-md ">
                                {moment(date).format("D/M/YYYY  h:mm a")}
                              </h1>
                            </td>

                            <td className="py-3 px- text-[#0eb683] text-md cursor-pointer hover:underline transition-all active:scale-90">
                              View receipt
                            </td>
                          </tr>


                        </tbody>
                      </>
                    );
                  })
                ) : (
                  <div className="p-3 text-[#071827]">Please Wait ...</div>
                )}
              </table>
            </div>

            {/* Mobile view */}

            <>
              <div className="bg-white shadow-md rounded  md:hidden block">
                <header className="flex justify-between p-4 border-b border-slate-50  ">
                  <h1 className="text-[#071827] text-xl font-semibold">
                    Recent Activity
                  </h1>
                  <p
                    onClick={() => {
                      navigate("/dashboard/transactions", { replace: true });
                    }}
                    className="text-[#0eb683] font-medium hover:underline cursor-pointer transition-all active:scale-90"
                  >
                    View all
                  </p>
                </header>
                <table className="min-w-max w-full table-auto">

                  {data.length ? (
                    chunkArray(tableData, itemsPerPage)[
                      currentPageIndex - 1
                    ]?.map((transaction, index) => {
                      const {
                        date,
                        reference,
                        createdAt,
                        id,
                        unit,
                        gateway_reference,
                        narration,
                        status,
                        currency,
                        type,
                        amount,
                        memo,
                        is_committed,
                      } = transaction;

                      // const date = moment(created_at ?? createdAt).format(
                      //   "D/M/YYYY  h:mm a"
                      // );
                      // moment(created_at ?? createdAt).format("l");

                      // const statusBadge = createStatusBadge(
                      //   status.toUpperCase() || x.toUpperCase()
                      // );
                      const transactionType =
                      type === "debit" ? (
                        <AiOutlineMinus size={15} className="font-extrabold" />
                      ) : type === "credit" ? (
                        <MdAdd size={15} className="font-extrabold" />
                      ) : (
                        <FiSend size={15} className="font-extrabold" />
                      );


                      const ref = truncateString(
                        reference,
                        17
                      ).toUpperCase()




                      const statusBadge = createStatusBadge(
                        status || "in-progress"
                      );

                      return (
                        <>
                          <tbody className="text-gray-600 text-sm font-light">


                            {/* Mobile view */}
                            <div
                              className=" md:hidden   border-b border-slate-50 "
                              key={index}
                            >


                              <div className="flex items-center justify-between   ">
                                <div className="">
                                  <div className="flex items-center">
                                    <figure className="p-3">
                                      <div
                                        className={`w-12 h-12 rounded-full ${
                                          type === "debit"
                                            ? "bg-[#FEECE2] text-[#FA0204]"
                                            : type === "credit"
                                            ? "bg-[#E2FEF0] text-[#0eb683]"
                                            : "bg-[#E2FEF0] text-[#0eb683]"
                                        }  flex items-center justify-center font-bold`}
                                      >
                                        {transactionType}
                                      </div>
                                    </figure>

                                    <div className="flex flex-col gap-3">
                                      <p className="font-bold whitespace-nowrap text-base text-[#5C6F7F]">
                                        {/* {currency === "usdc" ? "USD" : currency}{" "} */}
                                        {amount}
                                      </p>
                                      <p className=" text-[#071827] text-md">
                                        {moment(date).format("D/M/YYYY  h:mm a")}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="flex flex-col gap-3 pr-3">
                                  <p className="text-[#071827] self-end text-right ">
                                  {currency}
                                  </p>

                                  <p className="text-md text-[#0eb683]  hover:underline cursor-pointer transition-all active:scale-90 text-right">
                                    view
                                  </p>
                                </div>
                              </div>
                            </div>
                          </tbody>
                        </>
                      );
                    })
                  ) : (
                    <div>Please Wait ...</div>
                  )}
                </table>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activity;
