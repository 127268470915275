import _ from "lodash";

import React, { useEffect, useState } from "react";
import {
  Control,
  Controller,
  useForm,
  UseFormGetValues,
  //@ts-ignore
} from "react-hook-form";
import Select from "react-select";
import { IDOptionType } from "../../pages/onboarding/IndividualUser";

type CustomSelectProps = {
  selectedOption: IDOptionType | null;
  setSelectOption: (slug: React.SetStateAction<any>) => void;
  options: any;
  isDisabled?: boolean;
  name?: string;
  control?: Control<any, any>;
  getValues?: UseFormGetValues<any>;
  placeholder?: string;
  errors?: any;
  defaultValue?: IDOptionType;
};

const CustomSelect = (props: CustomSelectProps) => {
  const {
    selectedOption,
    setSelectOption,
    options,
    isDisabled,
    name,
    getValues,
    control,
    placeholder,
    errors,
  } = props;

  const [isMobile, setIsMobile] = useState<boolean>(false);

  const handleChange = (option: any) => {
    setSelectOption(option);
  };

  var alteredOptions = options.map((option: any) => ({
    label: option.label ? option.label : option.name,
    slug: option.slug ? option.slug : option.name,
    // description: option.description ? option.description : "",
  }));

  var actualOption = alteredOptions.find(
    (option: IDOptionType) =>
      option?.slug === selectedOption?.slug ||
      (option?.slug === selectedOption?.slug && selectedOption?.slug)
  );

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      //@ts-ignore
      borderColor: errors && errors?.[name] && "#E01A00",
      paddingTop: "5px",
      paddingBottom: "5px",
      paddingLeft: "6px",
      backgroundColor: isMobile ? "white" : "#F2FAF7", // Change background color based on state.isMobile
      fontSize: "13px",
    }),

    indicatorsContainer: (provided: any) => ({
      ...provided,
      display: "hidden",
    }),

    singleValue: (provided: any) => ({
      ...provided,
      textTransform: "capitalize",
    }),

    option: (provided: any) => ({
      ...provided,
      textTransform: "capitalize",
    }),
  };


  useEffect(() => {
    // Function to handle window resize and update mobile state
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="w-full">
      {control ? (
        <Controller
          control={control}
          name={_.camelCase(name)}
          defaultValue={
            getValues &&
            alteredOptions.find(
              (option: IDOptionType) =>
                option?.label === getValues(_.camelCase(name))
            )
          }
          //@ts-ignore
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Select
              styles={customStyles}
              value={actualOption}
              defaultValue={
                getValues &&
                alteredOptions.find(
                  (option: IDOptionType) =>
                    option?.label === getValues(_.camelCase(name))
                )
              }
              options={alteredOptions}
              placeholder={placeholder}
              isClearable={false}
              onChange={(option) => onChange(option.label)}
              isDisabled={isDisabled}
            />
          )}
        />
      ) : (
        <Select
          styles={customStyles}
          value={actualOption}
          options={alteredOptions}
          isClearable={false}
          placeholder={placeholder}
          onChange={handleChange}
          isDisabled={isDisabled}
        />
      )}
    </div>
  );
};

export default CustomSelect;
