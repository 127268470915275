export const transferSteps = [
  {
    id: 0,
    name: "Beneficiary",
    label: "Beneficiary Details",
    description: "Provide information about your beneficiary",
    completed: false,
  },
  {
    id: 1,
    name: "Document",
    label: "Payment Instructions",
    description: "Provide transfer instructions",
    completed: false,
  },
  {
    id: 2,
    name: "Review",
    label: "Review",
    description: "View payment summary",
    completed: false,
  },
];
