import UsdWallet from "./UsdWallet";
import NairaWallet from "./NairaWallet";
import EurWallet from "./EurWallet";
import GbpWallet from "./GbpWallet";
import { useParams } from "react-router-dom";
import { useWalletAccount } from "../../../sevices/hooks/wallets";
import UsdcWallet from "./UsdcWallet";
import UsdtWallet from "./UsdtWallet";

const WalletView = () => {
  const { currency } = useParams();

  return (
    <>
      {currency === "usd" && <UsdWallet currency={currency} />}
      {currency === "ngn" && <NairaWallet currency={currency} />}
      {currency === "eur" && <EurWallet currency={currency} />}
      {currency === "gbp" && <GbpWallet currency={currency} />}
      {currency === "usdc" && <UsdcWallet currency={currency} />}
      {currency === "usdt" && <UsdtWallet currency={currency} />}
    </>
  );
};

export default WalletView;
