import React, { InputHTMLAttributes } from "react";

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  label: string;
  name: string;
  type: string;
  required?: boolean;
  disabled?: boolean;
  value?: string;
  errorMessages?: any;
  maxChar?: number;
  onChange: (newValue: string) => void;
}

const Input: React.FC<InputProps> = ({
  label,
  name,
  type,
  required,
  disabled,
  errorMessages,
  value,
  maxChar, // Destructure the new prop
  onChange,
  ...rest // Rest of the props
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (maxChar && e.target.value.length > maxChar) {
      e.target.value = e.target.value.slice(0, maxChar);
    }
    onChange(e.target.value);
  };

  return (
    <div className="relative z-0 w-full ">
      <input
        // Spread the rest of the props
        {...rest}
        id={name}
        type={type}
        disabled={disabled}
        placeholder=" "
        value={value}
        onChange={handleChange} // Add onChange event handler
        required={required}
        className={`pt-3.5 pb-2.5 block w-full px-2.5 mt-0 bg-white md:bg-[#F2FAF7] border  rounded-[4px] ${
          errorMessages?.[name]
            ? "border-[#E01A00]"
            : "appearance-none focus:outline-none outline-[#0eb683] focus:ring-0 focus:border-[#0eb683] border-gray-200"
        }`}
      />
      <label
        htmlFor={name}
        className="absolute duration-300 top-3 -z-1 origin-0 text-[#6F798B] text-[14px] leading-[21px] font-normal pl-3"
      >
        {required && <span className="text-[#E01A00]">*</span>}
        {label}
      </label>
    </div>
  );
};

export default Input;



