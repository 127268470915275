import React, { SetStateAction, useState } from "react";
import ReactLoading from "react-loading";
import {
  useBusinessAcc,
  useBusinessCorporate,
  useBusinessIndividual,
  useBusinessUserKyc,
  useCompleteOnboarding,
  useRefresh,
  useUpdateUser,
  useUpdateUserOnboardingInfo,
  useUser,
  useVerifyAccount,
} from "../../../sevices/hooks/users";
import { IFormData, useForm } from "../../../context/OnboardingFormContext";
import useQueryParams from "../../../hooks/useQueryParams";
import { useLocation, useNavigate } from "react-router-dom";

export interface IController {
  handleClick: (direction: any) => void;
  currentStep: number;
  nextDisabled?: boolean;
  color?: string;
  setErrMsg: React.Dispatch<SetStateAction<string>>;
  steps: {
    id: number;
    description: string;
    step: string;
  }[];
  handleFormSubmit?: () => void;
}
const Controller = ({
  handleClick,
  currentStep,
  steps,
  color,
  handleFormSubmit,
  setErrMsg,
  nextDisabled,
}: IController) => {
  //@ts-ignore
  const userData = JSON.parse(localStorage.getItem("user")) || {};
  const kyc = useRefresh();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const at = queryParams.get("at");
  const code = queryParams.get("code");
  const user = queryParams.get("user");
  const createUserAccount = useUpdateUser(user as string);
  const [loading, setLoading] = useState(false);
  // console.log(user);
  const { state, dispatch } = useForm();
  const businessKyc = useBusinessUserKyc();
  const createBusinessAccount = useUser();
  const createComplete = useCompleteOnboarding();
  const createBusinessIndividual = useBusinessIndividual();
  const createBusinessCorporate = useBusinessCorporate();
  const newBusiness = useBusinessAcc();
  const [formData, setFormData] = useState({
    idDocUrl: "",
    id_type: "",
    selfieUrl: "",
  });

  const updateInfo = useUpdateUserOnboardingInfo(userData.id);
  // console.log(state, "state");
  const handleInputChange = (name: string, value: string) => {
    // Dispatch an action to update the field in the context state
    dispatch({ type: "UPDATE_FIELD", field: name as keyof IFormData, value });

    // Update the local state (formData) if needed
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitKyc = async () => {
    setLoading(true);

    try {
      const { data } = await kyc.mutateAsync({
        idNumber: state.idNumber,
        idType: state.IDType as string,
        middleName: state.middleName,
        refereeCode: code as string,
        accountType: at as string,
        individual: {
          firstName: state.firstName,
          lastName: state.lastName,
          email: state.email,
          phoneNumber: state.phoneNumber,
          country: state.country as string,
          city: state.city,
          dateOfBirth: state.dob,
          bvn: state.bvn,
          addressLine_1: state.address1,
          addressLine_2: state.address2,
          state: state.state as string,
          postalCode: state.postCode,
          gender: (state.gender as string) === "M" ? "Male" : "Female",
          type: "IndividualCustomer",
        },
      });
      handleClick("next");
      setLoading(false);
      // console.log(data, "submitKycs");
    } catch (error: any) {
      setLoading(false);
      setErrMsg(error?.response?.data);
    }
  };

  const createBusiAccount = async () => {
    try {
      const accountResponse = await createBusinessAccount.mutateAsync({
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        refereeCode: code as string,
        accountType: at as string,
        phoneNumber: formatPhoneNumber(state.phoneNumber as string),
        userBankCode: state.bankCode,
        dob: state.dob,
        bvn: state.bvn,
        address1: state.address1,
        address2: state.address2,
        country: state.country as string,
        state: state.state as string,
        postCode: state.postCode,
        gender: state.gender === "M" ? "Male" : "Female",
        city: state.city,
        middleName: state.middleName,
        idNumber: state.idNumber,
        idType: state.id_type,
        password:state.password,
        business: {
          BasicCountry: state.BasicCountry,
          businessName: state.businessName,
          bvn: state.bvn,
          dateOfRegistration: state.dateOfRegistration,
          description: state.description,
          ContactEmail: state.businessEmail,
          ContactCountry: state.BasicCountry,
          ContactState: state.ContactRegisteredState,
          country: state.country as string,
          state: state.state as string,
          industry: state.industry,
          addressLine_1: state.addressLine_1,
          city: state.city,
          registration:
            state.businessType === "co"
              ? state.companyType
              : state.businessType,
          postalCode: state.postCode,
          ContactRegisteredCountry: state.BasicCountry,
          ContactRegisteredState: state.ContactRegisteredState,
          phoneNumber: formatPhoneNumber(state.businessphoneNumber),
          type: "BusinessCustomer",
        },
      });

      if (accountResponse) {
        const userId = accountResponse.data.data.id;

        //  setUserId(userId);

        createFormDataBusiness(userId);
      }
    } catch (err: any) {
      setErrMsg(err.response.data);
    } finally {
      setLoading(false);
    }
  };
  // console.log(currentStep);
  // console.log(steps.length);
  const createFormDataBusiness = async (id: string) => {
    //  setLoadingMsg("Confirming Account status...");

    const formData = new FormData();
    if (id) {
      //  const { countryOfRegistration } = userDetail;
      formData.append("businessEmail", state.ContactEmail);
      formData.append("businessName", state.businessName);
      formData.append("businessNumber", state.idNumber);
      formData.append("businessNature", state.description);
      formData.append("businessAddress", state.addressLine_1);
      formData.append("countryOfRegistration", state.BasicCountry);
      formData.append("businessPhone", state.businessphoneNumber);
      formData.append("website", state.website);
      formData.append("userId", id);
      formData.append("docType", state.docType);
      if (state.file1) {
        formData.append("registrationDoc", state.file1);
      }
      if (state.file2) {
        formData.append("registrationDoc", state.file2);
      }

      try {
        const res = await newBusiness.mutateAsync(formData);

        if (res.data.id) {
          const businessId = res.data.id;
          dispatch({
            type: "UPDATE_FIELD",
            field: "businessId",
            value: businessId,
          });
          setLoading(false);

          handleClick("next");
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const submitBusinessKyc = async () => {
    // console.log("clicked");
    setLoading(true);
    try {
      const { data } = await businessKyc.mutateAsync({
        id_number: state.businessIncorNum,
        country: state.BasicCountry,
        business_type: state.businessType,
        email: state.email,
      });
      if (data) {
        createBusiAccount();
      }
    } catch (err: any) {
      setLoading(false);
    }
  };

  const handleCorporateSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("businessId", state.businessId);
      if (state.businessIDType) {
        formData.append("idType", state.businessIDType);
      }
      if (state.businessOwnerGender) {
        formData.append("gender", state.businessOwnerGender);
      }
      formData.append("fullName", state.fullName);
      formData.append("addressLine1", state.businessOwnerAdrress1);
      formData.append("addressLine2", state.businessOwnerAdrress2);
      formData.append("email", state.businessOwnerEmail);
      formData.append("postCode", state.businessOwnerPostCode);
      formData.append("shareholding", `${state.businessOwnerPercentage}%`);
      formData.append("phoneNumber", state.businessOwnerPhoneNumber);
      if (state.businessOwnerIdFile) {
        formData.append("idDoc", state.businessOwnerIdFile);
      }
      if (state.businessOwnerCountry) {
        formData.append("country", state.businessOwnerCountry);
      }
      if (state.businessOwnerState) {
        formData.append("state", state.businessOwnerState);
      }

      const res = await createBusinessIndividual.mutateAsync(formData);

      setLoading(false);

      navigate("/success");
    } catch (err) {
      setLoading(false);
    }
  };

  const handleOwnerSubmit = async () => {
    try {
      const formData = new FormData();

      formData.append("businessId", state.businessId);

      formData.append("businessName", state.corporateBusinessName);
      formData.append("registrationNumber", state.corporateIncorNum);
      formData.append("businessAddress", state.corporateBusinessAddress);
      formData.append("businessEmail", state.corporateBusinessEmail);
      formData.append("businessPhoneNumber", state.corporateBusinessPhone);
      formData.append("shareholding", `${state.corporateBusinessPercentage}%`);

      if (state.corporateIdFile) {
        formData.append("registrationDocument", state.corporateIdFile);
      }
      if (state.corporateCountry) {
        formData.append("countryOfRegistration", state.corporateCountry);
      }
      const res = await createBusinessCorporate.mutateAsync(formData);

      setLoading(false);

      navigate("/refresh-success");
    } catch (err) {
      setLoading(false);
    }
  };

  const createAccountCompleted = () => {
    setLoading(true);
    createComplete
      .mutateAsync({ email: state.email })
      .then((res) => {
        setLoading(false);
        navigate("/refresh-success");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleSubmitBank = () => {
    //  setLoadingMsg("Verifying details, Please wait..");
    setLoading(true);
    state.accountName &&
      updateInfo
        .mutateAsync({
          userBankName: state.bank?.bank?.bankName,
          userAccountNumber: state.accountNumber,
          // accountName,
          userBankId: state.bankId,
          userBankCode: state.bankCode,
        })
        .then((res) => {
          handleClick("next");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
  };

  const createPassword = () => {
    setLoading(true);
    updateInfo
      .mutateAsync({ password: state.password })
      .then((res) => {
        handleClick("next");
        setLoading(false);
      })
      .catch((res) => {});
  };

  const handleNext = () => {
    if (currentStep === 1) {
      submitKyc();
    }
    if (currentStep === 2) {
      handleSubmitBank();
    }
    if (currentStep === 3) {
      createPassword();
    }
    if (currentStep === steps.length) {
      createAccountCompleted();
    }
  };

  const handleBusiness = () => {
    // console.log("click");
    if (currentStep === 1) {
      submitKyc();
    }
    if (currentStep === 2) {
      handleSubmitBank();
    }
    if (currentStep === 3) {
      createPassword();
    }
    if (currentStep === 3) {
      // submitBusinessKyc();
      handleClick("next");
    }
    if (currentStep === 5) {
      handleOwnerSubmit();
    }
    // if (currentStep === 6) {
    //   submitBusinessKyc();
    // }
  };

  return (
    <footer
      className={`w-full py-5 flex items-center justify-between  md:bg-[${
        color ? color : "#F7FBFB"
      }] xxs:mb-10`}
    >
      <button
        onClick={() => {
          if (currentStep === 1) {
            navigate("/dashboard");
          } else {
            handleClick("");
          }
        }}
        className="bg-white border border-[#0EB683] text-[#0EB683] p-3 px-7 rounded-md active:scale-90 transition-all duration-500 hover:opacity-80 text-[16px] leading-[24px]"
      >
        {"Back"}
      </button>
      <div>
        {currentStep === 3 && (
          <button
            onClick={() => handleClick("next")}
            className="bg-white border border-[#0EB683] text-[#0EB683] p-3 px-7 rounded-md active:scale-90 transition-all duration-500 hover:opacity-80 text-[16px] leading-[24px] mr-3"
          >
            Skip
          </button>
        )}

        <button
          // disabled={nextDisabled}
          className="bg-[#0EB683] text-white p-3 px-7 rounded-md active:scale-90 transition-all duration-500 hover:opacity-80 disabled:bg-[#8fc0ad] border border-[#0EB683] disabled:cursor-not-allowed text-[16px] leading-[24px]"
          // onClick={() => handleClick("next")}
          onClick={at === "private" ? handleNext : handleBusiness}
        >
          {loading ? (
            <div className="flex items-center justify-end">
              <ReactLoading
                type="spin"
                color="#FFFFFF"
                height={20}
                width={20}
              />
            </div>
          ) : (
            <>
              {currentStep === steps?.length
                ? "Confirm and Submit"
                : "Save and Proceed"}
            </>
          )}
        </button>
      </div>
    </footer>
  );
};

export default Controller;

export function formatPhoneNumber(phoneNumber: string) {
  const cleanedNumber = phoneNumber.replace(/^234/, "");
  const formattedNumber = `0${cleanedNumber}`;
  return formattedNumber;
}
function setLoading(arg0: boolean) {
  throw new Error("Function not implemented.");
}
