import React, { useState } from "react";

import AppSelectInput from "../../components/base-components/AppSelectInput";
import { useTabs } from "../../components/widgets/TabWidget";
import { TabSelector } from "../../components/widgets/TabSelector";
import { TabPanel } from "../../components/widgets/TabWidget";

import { motion, AnimatePresence } from "framer-motion";

import { ABA_COUNTRIES } from "../../data/misc/transfer-countries";
import { useExternalBeneficiary } from "../../sevices/hooks/wallets";
import {
  useAbaCountries,
  useAbaDistricts,
  useIbanCountries,
  useSwiftCountries,
} from "../../sevices/hooks/meta-data";

import FailedIcon from "../../assets/iconsComponents/FailedIcon";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";

import Logo2 from "../../assets/misc/logo-on-light.svg";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../Notifications/LoadingScreen2";
import { HiInformationCircle } from "react-icons/hi";

const ExternalBeneficiaryForm = () => {
  const [selectedTab, setSelectedTab] = useTabs([
    "SWIFT / BIC",
    "IBAN",
    "ABA Number",
  ]);

  const [createdBeneficiary, setCreatedBeneficary] = useState("");
  const [failed, setFailed] = useState(false);
  const navigate = useNavigate();
  const createBeneficiary = useExternalBeneficiary();
  const [loading, setLoading] = useState(false);
  const swiftCountries = useSwiftCountries();
  const ibanCountries = useIbanCountries();
  const abaCountries = useAbaCountries();
  const abaDistricts = useAbaDistricts();
  const [success, setSuccess] = useState(false);
  const swiftOptions =
    swiftCountries?.data?.data?.map(
      ({ name, code }: { name: string; code: string }) => ({
        name: code,
        label: name,
      })
    ) || [];

  const ibanOptions =
    ibanCountries?.data?.data?.map(
      ({ name, code }: { name: string; code: string }) => ({
        name: code,
        label: name,
      })
    ) || [];

  const abaOptions =
    abaCountries?.data?.data?.map(
      ({ name, code }: { name: string; code: string }) => ({
        name: code,
        label: name,
      })
    ) || [];

  const districtOptions =
    abaDistricts?.data?.data?.map(
      ({ name, code }: { name: string; code: string }) => ({
        name: code,
        label: name,
      })
    ) || [];

  //   saveBeneficiaryDetails(createdBeneficiary)

  const [swiftFields, setSwiftFields] = useState({
    beneficiaryName: "",
    billingDetails: {
      line1: "",
      line2: "",
      district: "",
      city: "",
      country: "",
      postalCode: "",
      name: "",
    },
    bankAddress: {
      bankName: "",
      city: "",
      country: "",
    },
    destinationType: "noneIban",
    accountNumber: "",
    routingNumber: "",
    // iban: "",
  });

  const SwiftBtn =
    swiftFields?.beneficiaryName.trim() !== "" &&
    swiftFields?.billingDetails?.line1.trim() !== "" &&
    // swiftFields?.billingDetails?.line2.trim() !== "" &&
    swiftFields?.billingDetails?.district.trim() !== "" &&
    swiftFields?.billingDetails?.city.trim() !== "" &&
    swiftFields?.billingDetails?.country.trim() !== "" &&
    swiftFields?.billingDetails?.postalCode.trim() !== "" &&
    swiftFields?.billingDetails?.name.trim() !== "" &&
    swiftFields?.bankAddress?.city.trim() !== "" &&
    swiftFields?.bankAddress?.country.trim() !== "" &&
    swiftFields?.accountNumber.trim() !== "" &&
    swiftFields?.routingNumber.trim() !== "";

  const [ibanFields, setIbanFields] = useState({
    beneficiaryName: "",
    billingDetails: {
      line1: "",
      line2: "",
      district: "",
      city: "",
      country: "",
      postalCode: "",
      name: "",
    },
    bankAddress: {
      bankName: "",
      city: "",
      country: "",
    },
    destinationType: "iban",
    accountNumber: "",
    routingNumber: "",
    iban: "",
  });

  const IbanBtn =
    ibanFields?.billingDetails?.line1.trim() !== "" &&
    ibanFields?.billingDetails?.district.trim() !== "" &&
    ibanFields?.billingDetails?.city.trim() !== "" &&
    ibanFields?.billingDetails?.country.trim() !== "" &&
    ibanFields?.billingDetails?.postalCode.trim() !== "" &&
    ibanFields?.billingDetails?.name.trim() !== "" &&
    ibanFields?.bankAddress?.city.trim() !== "" &&
    ibanFields?.bankAddress?.country.trim() !== "" &&
    ibanFields?.iban.trim() !== "";

  const [abaFields, setAbaFields] = useState({
    beneficiaryName: "",
    billingDetails: {
      line1: "",
      line2: "",
      district: "",
      city: "",
      country: "",
      postalCode: "",
      name: "",
    },
    bankAddress: {
      bankName: "",
      city: "",
      country: "",
    },
    destinationType: "usa",
    accountNumber: "",
    routingNumber: "",
    iban: "",
  });

  const AbaBtn =
    abaFields?.beneficiaryName.trim() !== "" &&
    abaFields?.billingDetails?.line1.trim() !== "" &&
    abaFields?.billingDetails?.line2.trim() !== "" &&
    abaFields?.billingDetails?.district.trim() !== "" &&
    abaFields?.billingDetails?.city.trim() !== "" &&
    abaFields?.billingDetails?.country.trim() !== "" &&
    abaFields?.billingDetails?.postalCode.trim() !== "" &&
    abaFields?.billingDetails?.name.trim() !== "" &&
    abaFields?.bankAddress?.city.trim() !== "" &&
    abaFields?.bankAddress?.country.trim() !== "" &&
    abaFields?.accountNumber.trim() !== "" &&
    abaFields?.routingNumber.trim() !== "";
  // abaFields?.iban.trim() !== "";

  //swiftData
  const onSwiftFieldChange = ({
    name,
    value,
  }: {
    value: string;
    name: string;
  }) => {
    switch (name) {
      case "bankName":
      case "bankCity":
      case "bankCountry":
        swiftFields.bankAddress[
          name === "bankCity"
            ? "city"
            : name === "bankName"
            ? "bankName"
            : "country"
        ] = value;
        setSwiftFields({ ...swiftFields });

        break;
      case "line1":
      case "line2":
      case "district":
      case "city":
      case "country":
      case "postalCode":
        swiftFields.billingDetails[name] = value;
        setSwiftFields({ ...swiftFields });
        break;

      case "name":
        swiftFields.billingDetails.name = value;
        swiftFields.beneficiaryName = value;
        setSwiftFields({ ...swiftFields });
        break;
      default:
        setSwiftFields({ ...swiftFields, [name]: value });
    }
  };
  //IbanData
  const onIbanFieldChange = ({
    name,
    value,
  }: {
    value: string;
    name: string;
  }) => {
    switch (name) {
      case "bankCity":
      case "bankCountry":
        ibanFields.bankAddress[name === "bankCity" ? "city" : "country"] =
          value;
        setIbanFields({ ...ibanFields });

        break;
      case "line1":
      case "line2":
      case "district":
      case "city":
      case "country":
      case "postalCode":
        ibanFields.billingDetails[name] = value;
        setIbanFields({ ...ibanFields });
        break;

      case "name":
        ibanFields.billingDetails.name = value;
        ibanFields.beneficiaryName = value;
        setIbanFields({ ...ibanFields });
        break;

      default:
        setIbanFields({ ...ibanFields, [name]: value });
    }
  };

  //abaData
  const onAbaFieldChange = ({
    name,
    value,
  }: {
    value: string;
    name: string;
  }) => {
    switch (name) {
      case "bankCity":
      case "bankCountry":
        abaFields.bankAddress[name === "bankCity" ? "city" : "country"] = value;
        setAbaFields({ ...abaFields });

        break;
      case "line1":
      case "line2":
      case "district":
      case "city":
      case "country":
      case "postalCode":
        abaFields.billingDetails[name] = value;
        setAbaFields({ ...abaFields });
        break;
      case "name":
        abaFields.billingDetails.name = value;
        abaFields.beneficiaryName = value;
        setAbaFields({ ...abaFields });
        break;
      default:
        setAbaFields({ ...abaFields, [name]: value });
    }
  };

  const createSwiftBeneficiary = (e: any) => {
    e.preventDefault();
    setLoading(true);
    createBeneficiary
      // @ts-ignore
      .mutateAsync(swiftFields)

      .then((res) => {
        setLoading(false);
        setSuccess(true);
        setCreatedBeneficary(res?.data?.beneficiaryId);
      })
      .catch((e) => {
        setLoading(false);
        setFailed(true);
      });
  };

  const createIbanBeneficiary = (e: any) => {
    e.preventDefault();
    setLoading(true);
    createBeneficiary
      // @ts-ignore
      .mutateAsync(ibanFields)

      .then((res) => {
        setLoading(false);
        setSuccess(true);
        setCreatedBeneficary(res?.data?.beneficiaryId);
      })
      .catch((e) => {
        setLoading(false);
        setFailed(true);
      });
  };

  const createAbaBeneficiary = (e: any) => {
    e.preventDefault();
    setLoading(true);
    createBeneficiary
      // @ts-ignore
      .mutateAsync(abaFields)

      .then((res) => {
        setLoading(false);
        setSuccess(true);
        setCreatedBeneficary(res?.data?.beneficiaryId);
      })
      .catch((e) => {
        setLoading(false);
        setFailed(true);
      });
  };

  return (
    <>
      <LoadingScreen showLoading={loading} msg="Processing..." bg="#fffc">
        <div className="bg-[#F7FBFB] ">
          <div className="w-44 h-14  block p-5 xxs:m-auto ">
            <img src={Logo2} alt="" />
          </div>

          <div className="flex flex-row min-h-full justify-center    xxs:mb-5 ">
            <div className="">
              <main className="panel">
                <div className="text-center py-10">
                  <h1 className="font-bold text-[#071827]  text-3xl xxs:text-xl">
                    Add 3rd Party Beneficiary
                  </h1>
                  <p className="text-[#071827] ">
                    You have been requested to submit your beneficiary details
                  </p>
                </div>
                <nav className=" flex items-center px-5 md:justify-start xxs:justify-between md:gap-24  ">
                  <TabSelector
                    className={`cursor-pointer relative bg-transparent  ${
                      selectedTab === "SWIFT / BIC"
                        ? "before:block before:w-5  before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                        : ""
                    }`}
                    isActive={selectedTab === "SWIFT / BIC"}
                    onClick={() => setSelectedTab("SWIFT / BIC")}
                  >
                    <div className="flex items-center gap-2">
                      <div className=" bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>
                      <h1 className="text-[#5C6F7F] xxs:text-sm md:text-base ">
                        SWIFT / BIC
                      </h1>
                    </div>
                  </TabSelector>

                  <TabSelector
                    className={`cursor-pointer relative bg-transparent  ${
                      selectedTab === "IBAN"
                        ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                        : ""
                    }`}
                    isActive={selectedTab === "IBAN"}
                    onClick={() => setSelectedTab("IBAN")}
                  >
                    <div className="flex items-center gap-2">
                      <div className=" bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>
                      <h1 className="text-[#5C6F7F] xxs:text-sm md:text-base ">
                        IBAN{" "}
                      </h1>
                    </div>
                  </TabSelector>

                  <TabSelector
                    className={`cursor-pointer relative bg-transparent ${
                      selectedTab === "ABA Number"
                        ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                        : ""
                    }`}
                    isActive={selectedTab === "ABA Number"}
                    onClick={() => setSelectedTab("ABA Number")}
                  >
                    <div className="flex items-center gap-2">
                      <div className=" bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>
                      <h1 className="text-[#5C6F7F] xxs:text-sm md:text-base ">
                        ABA Number
                      </h1>
                    </div>
                  </TabSelector>
                </nav>
                <TabPanel
                  hidden={selectedTab !== "SWIFT / BIC"}
                  className="mt-8 xxs:px-5"
                >
                  <div className="md:bg-white md:max-w-lg md:flex  md:flex-col md:pl-8 md:pb-8 md:mt-12 md:pt-8 rounded-xl">
                    <div className="card ">
                      <header className="flex items-center justify-between mb-6 ">
                        <h1 className="text-[#071827] font-bold xxs:text-base md:text-lg ">
                          Beneficiary Information
                        </h1>
                      </header>
                      <div className="flex flex-col  gap-5">
                        <div className="mid relative  ">
                          <input
                            type="text"
                            name="routingNumber"
                            className={`py-3 px-4 w-full md:max-w-md border border-[#E2E9F0] rounded-md md:bg-[#F2FAF7]  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                            placeholder="Swift Code / Routing Number"
                            required
                            onChange={(e) => {
                              onSwiftFieldChange({
                                name: "routingNumber",
                                value: e.target.value,
                              });
                            }}
                          />
                          <div className="absolute left-0 bottom-[-17px] flex gap-2 items-center">
                            <HiInformationCircle fill="#0eb683" />
                            <small className="hidden md:block  text-[#313131d7] text-[10px] font-semibold">
                              {" "}
                              Swift Code eg (BOFAUS3N) or Routing Number
                              (011000015) is required
                            </small>
                            <small className=" md:hidden  text-[#313131d7] text-xs font-semibold">
                              {" "}
                              Swift Code{" "}
                              <span className="text-[#0eb683] font-bold ">
                                OR
                              </span>{" "}
                              Routing Number is required
                            </small>
                          </div>
                          <span className="text-red-500 absolute left-2 bottom-4">
                            *
                          </span>
                        </div>

                        <div className="mid relative">
                          <input
                            type="text"
                            name="beneficiaryName"
                            className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                            placeholder="Beneficiary Name"
                            required
                            onChange={(e) => {
                              onSwiftFieldChange({
                                name: "name",
                                value: e.target.value,
                              });
                            }}
                          />
                          <span className="text-red-500 absolute left-2 bottom-4">
                            *
                          </span>
                        </div>
                        <div className="mid relative">
                          <input
                            type="number"
                            name="accountNumber"
                            className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                            placeholder="Beneficiary Account Number"
                            required
                            onChange={(e) => {
                              onSwiftFieldChange({
                                name: "accountNumber",
                                value: e.target.value,
                              });
                            }}
                          />
                          <span className="text-red-500 absolute left-2 bottom-4">
                            *
                          </span>
                        </div>
                        <div className="mid relative">
                          <input
                            type="number"
                            name="accountNumber"
                            className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                            placeholder="Confirm Beneficiary Account Number"
                            required
                            onChange={(e) => {
                              onSwiftFieldChange({
                                name: "accountNumber",
                                value: e.target.value,
                              });
                            }}
                          />
                          <span className="text-red-500 absolute left-2 bottom-4">
                            *
                          </span>
                        </div>
                        <div className="mid relative">
                          <input
                            type="text"
                            name="line1"
                            className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                            placeholder="Address Line 1"
                            required
                            onChange={(e) => {
                              onSwiftFieldChange({
                                name: "line1",
                                value: e.target.value,
                              });
                            }}
                          />
                          <span className="text-red-500 absolute left-2 bottom-4">
                            *
                          </span>
                        </div>
                        <div className="mid relative">
                          <input
                            type="text"
                            name="line2"
                            className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                            placeholder="Address Line 2"
                            required
                            onChange={(e) => {
                              onSwiftFieldChange({
                                name: "line2",
                                value: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="mid relative">
                          <input
                            type="text"
                            name="postalCode"
                            className={`py-3 px-4 w-full md:max-w-md border border-[#E2E9F0] md:bg-[#F2FAF7] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                            placeholder="Beneficiary Postal Code"
                            required
                            onChange={(e) => {
                              onSwiftFieldChange({
                                name: "postalCode",
                                value: e.target.value,
                              });
                            }}
                          />
                          <span className="text-red-500 absolute left-2 bottom-4">
                            *
                          </span>
                        </div>
                        <div className="mid max-w-md relative">
                          <input
                            type="text"
                            name="district"
                            className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683]  placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                            placeholder="Beneficiary District Code"
                            required
                            maxLength={2}
                            onChange={(e) => {
                              onSwiftFieldChange({
                                name: "district",
                                value: e.target.value.toUpperCase(),
                              });
                            }}
                          />
                          <div className="absolute left-0 bottom-[-17px] flex gap-2">
                            <HiInformationCircle fill="#0eb683" />
                            <small className=" hidden md:block text-[#313131d7] text-[10px] font-semibold">
                              {" "}
                              Enter 2 Character Beneficiary District Code e.g.
                              'NY' for New York
                            </small>
                            <small className=" md:hidden text-[#313131d7] text-xs font-semibold">
                              {" "}
                              Beneficiary District Code e.g. 'NY' for New York
                            </small>
                          </div>

                          <span className="text-red-500 absolute left-2 bottom-4">
                            *
                          </span>
                        </div>

                        <div className="mid relative">
                          <input
                            type="text"
                            name="city"
                            className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683]  placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                            placeholder="Beneficiary City"
                            required
                            onChange={(e) => {
                              onSwiftFieldChange({
                                name: "city",
                                value: e.target.value,
                              });
                            }}
                          />
                          <span className="text-red-500 absolute left-2 bottom-4">
                            *
                          </span>
                        </div>

                        <div className="mid max-w-md relative">
                          <AppSelectInput
                            options={swiftOptions || []}
                            label=""
                            placeholder="Beneficiary Country"
                            handleChange={(inputValue) => {
                              inputValue &&
                                onSwiftFieldChange({
                                  name: "country",
                                  value: inputValue.name,
                                });
                            }}
                          />
                          <span className="text-red-500 absolute left-2 bottom-4">
                            *
                          </span>
                        </div>

                        <div className="mid relative">
                          <input
                            type="text"
                            name="bankName"
                            className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683]  placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                            placeholder="Bank Name"
                            required
                            onChange={(e) => {
                              onSwiftFieldChange({
                                name: "bankName",
                                value: e.target.value,
                              });
                            }}
                          />
                          <span className="text-red-500 absolute left-2 bottom-4">
                            *
                          </span>
                        </div>
                        <div className="mid relative">
                          <input
                            type="text"
                            name="bankCity"
                            className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683]  placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                            placeholder="Bank City"
                            required
                            onChange={(e) => {
                              onSwiftFieldChange({
                                name: "bankCity",
                                value: e.target.value,
                              });
                            }}
                          />
                          <span className="text-red-500 absolute left-2 bottom-4">
                            *
                          </span>
                        </div>

                        <div className="mid max-w-md relative">
                          <AppSelectInput
                            options={swiftOptions || []}
                            label=""
                            className="placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base"
                            placeholder="Bank Country"
                            handleChange={(inputValue) => {
                              inputValue &&
                                onSwiftFieldChange({
                                  name: "bankCountry",
                                  value: inputValue.name,
                                });
                            }}
                          />
                          <span className="text-red-500 absolute left-2 bottom-4">
                            *
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    disabled={SwiftBtn !== true}
                    onClick={createSwiftBeneficiary}
                    className="bg-[#0eb683] text-white p-3 mt-10 w-full md:max-w-lg rounded-md transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
                  >
                    Save Beneficiary
                  </button>
                </TabPanel>

                <TabPanel
                  hidden={selectedTab !== "IBAN"}
                  className="mt-8 xxs:px-5"
                >
                  <div className="md:bg-white md:max-w-lg md:flex  md:flex-col md:pl-8 md:pb-8 md:mt-12 md:pt-8 rounded-xl">
                    <header className="flex items-center justify-between mb-6 ">
                      <h1 className="text-[#071827] font-bold xxs:text-base md:text-lg ">
                        Beneficiary Information
                      </h1>
                    </header>
                    <div className="flex flex-col gap-5">
                      <div className="mid relative ">
                        <input
                          type="text"
                          name="routingNumber"
                          className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683]  placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                          placeholder="IBAN Number"
                          required
                          onChange={(e) => {
                            onIbanFieldChange({
                              name: "iban",
                              value: e.target.value,
                            });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                        <div className="absolute left-0 bottom-[-17px] flex gap-2 items-center">
                          <HiInformationCircle fill="#0eb683" />
                          <small className="  text-[#313131d7] text-[10px] font-semibold">
                            {" "}
                            Eg: AL472121100900000002356987411
                          </small>
                        </div>
                      </div>
                      <div className="mid relative">
                        <input
                          type="text"
                          name="beneficiaryName"
                          className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                          placeholder="Beneficiary Name"
                          required
                          onChange={(e) => {
                            onIbanFieldChange({
                              name: "name",
                              value: e.target.value,
                            });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>
                      <div className="mid relative">
                        <input
                          type="text"
                          name="line1"
                          className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                          placeholder="Address Line 1"
                          required
                          onChange={(e) => {
                            onIbanFieldChange({
                              name: "line1",
                              value: e.target.value,
                            });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>
                      <div className="mid relative">
                        <input
                          type="text"
                          name="postalCode"
                          className={`py-3 px-4 md:bg-[#F2FAF7] w-full md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                          placeholder="Beneficiary Postal Code"
                          required
                          onChange={(e) => {
                            onIbanFieldChange({
                              name: "postalCode",
                              value: e.target.value,
                            });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>

                      <div className="mid max-w-md relative">
                        <input
                          type="text"
                          name="district"
                          className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                          placeholder="Beneficiary District "
                          required
                          onChange={(e) => {
                            onIbanFieldChange({
                              name: "district",
                              value: e.target.value,
                            });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>
                      <div className="mid relative">
                        <input
                          type="text"
                          name="city"
                          className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                          placeholder="Beneficiary City"
                          required
                          onChange={(e) => {
                            onIbanFieldChange({
                              name: "city",
                              value: e.target.value,
                            });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>

                      <div className="mid max-w-md relative">
                        <AppSelectInput
                          options={ibanOptions || []}
                          label=""
                          placeholder="Beneficiary Country"
                          handleChange={(inputValue) => {
                            inputValue &&
                              onIbanFieldChange({
                                name: "country",
                                value: inputValue.name,
                              });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>

                      <div className="mid relative">
                        <input
                          type="text"
                          name="swiftBankcity"
                          className={`py-3 px-4 w-full md:md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c]placeholder:xxs:text-sm placeholder:md:text-base`}
                          placeholder="Bank City"
                          onChange={(e) => {
                            onIbanFieldChange({
                              name: "bankCity",
                              value: e.target.value,
                            });
                          }}
                          required
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>

                      <div className="mid max-w-md relative">
                        <AppSelectInput
                          options={ibanOptions || []}
                          label=""
                          placeholder="Bank Country"
                          handleChange={(inputValue) => {
                            inputValue &&
                              onIbanFieldChange({
                                name: "bankCountry",
                                value: inputValue.name,
                              });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    disabled={IbanBtn !== true}
                    onClick={createIbanBeneficiary}
                    className="bg-[#0eb683] text-white p-3 mt-10 w-full md:max-w-lg rounded-md transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
                  >
                    Save Beneficiary
                  </button>
                </TabPanel>
                <TabPanel
                  hidden={selectedTab !== "ABA Number"}
                  className="xxs:px-5"
                >
                  <div className="card mt-8 md:bg-white md:max-w-lg md:flex  md:flex-col md:pl-8 md:pb-8 md:mt-12 md:pt-8 rounded-xl">
                    <header className="flex items-center justify-between mb-6 ">
                      <h1 className="text-[#071827] font-bold xxs:text-base md:text-lg ">
                        Beneficiary Information
                      </h1>
                    </header>
                    <div className="flex flex-col  gap-5">
                      <div className="mid relative ">
                        <input
                          type="text"
                          name=""
                          className={`py-3 px-4 w-full md:bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                          placeholder="ABA / Routing Number"
                          required
                          onChange={(e) => {
                            onAbaFieldChange({
                              name: "routingNumber",
                              value: e.target.value,
                            });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                        <div className="absolute left-0 bottom-[-17px] flex gap-2">
                          <HiInformationCircle fill="#0eb683" />
                          <small className="  text-[#313131d7] text-[10px] font-semibold">
                            {" "}
                            Eg: 020190231
                          </small>
                        </div>
                      </div>
                      <div className="mid relative">
                        <input
                          type="text"
                          name="beneficiaryName"
                          className={`py-3 px-4 w-full md:bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                          placeholder="Beneficiary Name"
                          required
                          onChange={(e) => {
                            onAbaFieldChange({
                              name: "name",
                              value: e.target.value,
                            });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>
                      <div className="mid relative">
                        <input
                          type="number"
                          name=""
                          className={`py-3 px-4 w-full md:bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                          placeholder="Beneficiary's Account Number"
                          required
                          onChange={(e) => {
                            onAbaFieldChange({
                              name: "accountNumber",
                              value: e.target.value,
                            });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>
                      <div className="mid relative">
                        <input
                          type="number"
                          name=""
                          className={`py-3 px-4 w-full md:bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                          placeholder="Confirm Beneficiary Account Number"
                          required
                          onChange={(e) => {
                            onAbaFieldChange({
                              name: "accountNumber",
                              value: e.target.value,
                            });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>
                      <div className="mid relative">
                        <input
                          type="text"
                          name="line1"
                          className={`py-3 px-4 w-full md:bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                          placeholder="Address Line 1"
                          required
                          onChange={(e) => {
                            onAbaFieldChange({
                              name: "line1",
                              value: e.target.value,
                            });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>
                      <div className="mid relative">
                        <input
                          type="text"
                          name="line2"
                          className={`py-3 px-4 w-full md:bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                          placeholder="Address Line 2"
                          required
                          onChange={(e) => {
                            onAbaFieldChange({
                              name: "line2",
                              value: e.target.value,
                            });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>
                      <div className="mid relative">
                        <input
                          type="text"
                          name="postalCode"
                          className={`py-3 px-4 w-full md:bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                          placeholder="Beneficiary Postal Code"
                          required
                          onChange={(e) => {
                            onAbaFieldChange({
                              name: "postalCode",
                              value: e.target.value,
                            });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>

                      <div className="mid max-w-md relative">
                        <AppSelectInput
                          options={districtOptions || []}
                          label=""
                          placeholder="Beneficiary District"
                          handleChange={(inputValue) => {
                            inputValue &&
                              onAbaFieldChange({
                                name: "district",
                                value: inputValue.name,
                              });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>

                      <div className="mid relative">
                        <input
                          type="text"
                          name="city"
                          className={`py-3 px-4 w-full md:bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                          placeholder="Beneficiary City"
                          required
                          onChange={(e) => {
                            onAbaFieldChange({
                              name: "city",
                              value: e.target.value,
                            });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>
                      <div className="mid max-w-md relative">
                        <AppSelectInput
                          options={abaOptions || []}
                          label=""
                          placeholder="Beneficiary Country"
                          handleChange={(inputValue) => {
                            inputValue &&
                              onAbaFieldChange({
                                name: "country",
                                value: inputValue.name,
                              });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>

                      <div className="mid relative">
                        <input
                          type="text"
                          name="city"
                          className={`py-3 px-4 w-full md:bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                          placeholder="Bank City"
                          required
                          onChange={(e) => {
                            onAbaFieldChange({
                              name: "bankCity",
                              value: e.target.value,
                            });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>

                      <div className="mid max-w-md relative">
                        <AppSelectInput
                          options={abaOptions || []}
                          label=""
                          placeholder="Bank Country"
                          handleChange={(inputValue) => {
                            inputValue &&
                              onAbaFieldChange({
                                name: "bankCountry",
                                value: inputValue.name,
                              });
                          }}
                        />
                        <span className="text-red-500 absolute left-2 bottom-4">
                          *
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    disabled={AbaBtn !== true}
                    onClick={createAbaBeneficiary}
                    className="bg-[#0eb683] text-white p-3 mt-10 w-full md:max-w-lg rounded-md transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
                  >
                    Save Beneficiary
                  </button>
                </TabPanel>
              </main>

              <AnimatePresence>
                {success && createdBeneficiary && (
                  <div>
                    <motion.div
                      initial={{
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                        transition: {
                          duration: 0.3,
                        },
                      }}
                      exit={{
                        opacity: 0,
                        transition: {
                          // delay: 0.3,
                          duration: 0.1,
                        },
                      }}
                      className="confirmation_modal backdrop-blur-sm
           bg-[#13111137] fixed w-full top-0 left-0 justify-center h-full items-center z-50"
                    >
                      <motion.div
                        initial={{
                          x: 600,
                          scale: 0,
                        }}
                        animate={{
                          x: 0,
                          scale: 1,
                          transition: {
                            duration: 0.3,
                          },
                        }}
                        exit={{
                          x: 600,
                          scale: 0,
                          transition: {
                            delay: 0.3,
                          },
                        }}
                        className="flex h-screen items-center justify-center md:justify-end "
                      >
                        <motion.div
                          initial={{
                            x: 600,
                            opacity: 0,
                          }}
                          animate={{
                            x: 0,
                            opacity: 1,
                            transition: {
                              delay: 0.3,
                              duration: 0.3,
                            },
                          }}
                          exit={{
                            x: 100,
                            opacity: 1,
                            transition: {
                              duration: 0.3,
                            },
                          }}
                          className="flex  justify-center flex-col gap-8 bg-white md:h-full w-full h-[450px] md:w-[448px] rounded-3xl md:rounded-l-3xl p-5 "
                        >
                          <div className="icon self-center">
                            <SuccessMobile width={"100px"} height={"100px"} />
                          </div>
                          <h1 className="text-2xl font-bold text-[#071827] self-center">
                            Beneficiary added
                          </h1>

                          <p className="  text-sm text-[#5C6F7F] self-center text-center">
                            Your beneficiary was successfully added
                          </p>
                          <button
                            onClick={() => navigate("/", { replace: true })}
                            className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90 "
                          >
                            Continue
                          </button>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </div>
                )}
              </AnimatePresence>

              <AnimatePresence>
                {failed && (
                  <div>
                    <motion.div
                      initial={{
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                        transition: {
                          duration: 0.3,
                        },
                      }}
                      exit={{
                        opacity: 0,
                        transition: {
                          // delay: 0.3,
                          duration: 0.1,
                        },
                      }}
                      className="confirmation_modal backdrop-blur-sm
           bg-[#13111137] fixed w-full top-0 left-0 justify-center h-full items-center z-50"
                    >
                      <motion.div
                        initial={{
                          x: 600,
                          scale: 0,
                        }}
                        animate={{
                          x: 0,
                          scale: 1,
                          transition: {
                            duration: 0.3,
                          },
                        }}
                        exit={{
                          x: 600,
                          scale: 0,
                          transition: {
                            delay: 0.3,
                          },
                        }}
                        className="flex h-screen items-center justify-center md:justify-end "
                      >
                        <motion.div
                          initial={{
                            x: 600,
                            opacity: 0,
                          }}
                          animate={{
                            x: 0,
                            opacity: 1,
                            transition: {
                              delay: 0.3,
                              duration: 0.3,
                            },
                          }}
                          exit={{
                            x: 100,
                            opacity: 1,
                            transition: {
                              duration: 0.3,
                            },
                          }}
                          className="flex  justify-center flex-col gap-8 bg-white md:h-full w-full h-[450px] md:w-[448px] rounded-3xl md:rounded-l-3xl p-5 "
                        >
                          <div className="icon self-center">
                            <FailedIcon width={"100px"} height={"100px"} />
                          </div>
                          <h1 className="text-2xl font-bold text-[#071827] self-center">
                            Unsuccessful
                          </h1>

                          <p className="  text-sm text-[#5C6F7F] self-center text-center">
                            We couldn't add your beneficiary, please retry
                          </p>

                          <button
                            onClick={() => {
                              setFailed(false);
                            }}
                            className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                          >
                            Retry
                          </button>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </LoadingScreen>
    </>
  );
};

export default ExternalBeneficiaryForm;
