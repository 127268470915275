import React, { useState, useEffect } from "react";
import { FaCheckSquare } from "react-icons/fa";


const AppCheckBox = (props: any) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    props.getChecked(isChecked);
  }, [isChecked, props]);
  return (
    <div
      className="app-checkbox"
      style={{ borderColor: isChecked ? "#0eb683" : "#9a9c9c" }}
      onClick={() => setIsChecked(!isChecked)}
    >
      <div
        className="check-icon fill-[#0eb683]"
        style={{ display: isChecked ? "inline" : "none" }}
      >
        <FaCheckSquare size={22} fill={"#0eb683"}/>
      </div>
    </div>
  );
};

export default AppCheckBox;
