import React, { useState, FocusEventHandler, InputHTMLAttributes } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  required?: boolean;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // Define the onChange prop
}

const PasswordInput: React.FC<InputProps> = ({
  label,
  name,
  required,
  onChange, // Receive the onChange prop
  value,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleFocus: FocusEventHandler<HTMLInputElement> = () => {
    setIsInputFocused(true);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = () => {
    setIsInputFocused(!!value || false);
  };

  return (
    <div className={`flex relative z-0 w-ful`}>
      <input
        type={passwordVisible ? "text" : "password"}
        className="pt-3.5 pb-2.5 block w-full px-2.5 mt-0 bg-white md:bg-[#F2FAF7] border appearance-none focus:outline-none outline-[#0eb683] focus:ring-0 focus:border-[#0eb683] border-gray-200 rounded-[4px]"
        required={required}
        value={value}
        id={name}
        placeholder=" "
        onChange={onChange} // Use the onChange prop here
      />
      <label
        htmlFor={name}
        className="absolute duration-300 top-3 -z-1 origin-0 text-[#6F798B] text-[14px] leading-[21px] font-normal pl-3"
      >
        {required && <span className="text-[#E01A00]">*</span>}
        {label}
      </label>
      <button
        className="inline-flex absolute top-[50%] right-5 -translate-y-1/2 text-[#5C6F7F]"
        onClick={() => setPasswordVisible(!passwordVisible)}
      >
        {passwordVisible ? <FiEye /> : <FiEyeOff />}
      </button>
    </div>
  );
};

export default PasswordInput;
