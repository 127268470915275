import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()
  return (
    <div className='bg-[#0c0d1f] flex flex-col items-center justify-center h-screen xxs:p-5 gap-4 '>
      
      <h1 className='text-9xl font-extrabold text-[#0eb683]'>404</h1>  
      <div className='self-center'>
        <h2 className='text-center text-[#fff] text-2xl font-extrabold mb-2'>
          There's nothing here
        </h2>
        <p className='text-[#fff] text-lg mb-2 xxs:text-center'>Looks like you are lost in the <span onClick={()=> navigate("/", {replace: true})} className='text-xl font-semibold hover:text-[#0eb683] hover:underline cursor-pointer'>TTFCX</span>  wild orbit. Unfortunately, there's only so much you can do from here</p>
      </div>

      <button
      onClick={()=>{
        navigate(-1)
      }}
       className='bg-[#0eb683] text-[#fff] p-3 rounded-lg px-10 transition-all active:scale-90' >
        Please, Take me back!
      </button>
        </div>
  )
}

export default NotFound