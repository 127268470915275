import React, { useState } from "react";
import { transferSteps } from "../../../types/transfer-data";
import { TabSelector } from "../../../components/widgets/TabSelector";
import { TabPanel, useTabs } from "../../../components/widgets/TabWidget";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import TransferFYCTabs from "../../../components/widgets/OTPInput/TransferFYCTabs";
import BeneficiaryLayout from "../../../components/beneficiary-component/BeneficiaryLayout";
import UsdcBeneficiary from "../../../components/wallet-components/UsdcBeneficiary";
import UsdcTransactionDetails from "../../../components/wallet-components/UsdcTransactionDetails";
import UsdcSummary from "../../../components/wallet-components/UsdcSummary";
import StableCoinBeneficiaryLayout from "../../../components/beneficiary-component/StableCoinBeneficiaryLayout";



const TransferUsdc = () => {
    const [currentTab, setCurrentTab] = useState(0)
  const navigate = useNavigate();
  const [steps, setSteps] = useState(transferSteps);
  const [selectedTab, setSelectedTab] = useTabs(
    transferSteps.map((step) => step.name)
  );
  const { currency } = useParams();
  const [currentFlow, setcurrentFlow] = useState("Beneficiary");

  const stepUpdate = (currentStep: string, value: boolean) => {
    const updatedSteps = steps.map((step) =>
      step.name === currentStep ? { ...step, completed: value } : step
    );
    setSteps(updatedSteps);
  };

  const nextStep = () => {
    if (currentTab < formSteps.length ) {
      setCurrentTab(currentTab + 1);
    }
  };
  const nextSteps = () => {
    if (currentTab < formSteps.length ) {
      setCurrentTab(currentTab + 1);
    }
  };
  const previous = () => {
    if(currentTab > 0) {
      setCurrentTab(currentTab - 1)
    }
  }

  const transferFlow = (currentFlow: any) => {
    return currentFlow === "Beneficiary"
      ? steps
      : steps.filter((step) => step.name !== "Beneficiary");
  };
  const formSteps = [
    {
     
      content: <StableCoinBeneficiaryLayout onClick={nextStep} />,
    },
    { 
     
      content:   <UsdcBeneficiary onClick={nextSteps} placeholder={"Beneficiary USDC Address"}/>,
    },
    {
      // name: 'Form 3  displaying from Step',
      content: <UsdcTransactionDetails onClick={nextSteps} currency={"USDC"}  prev ={previous}/>,
    },
    {
      // name: 'Form 3  displaying from Step',
      content: <UsdcSummary currency="USDC"  prev ={previous} />,
    },
  ]

  return (
    <div className="md:bg-[#f7fbfb]">
      <div className="h-full w-full px-5 pt-5">
        <header className="flex items-center  md:justify-between gap-24 md:gap-4 md:ml-5">
          <div className="flex items-center gap-20 md:gap-4">
            <button
              onClick={() => {
                navigate(`/dashboard/wallet/usdc`, { replace: true });
              }}
              className=" bg-[#ECF6F3] rounded-full p-2 flex transition-all active:scale-90   "
            >
              <BsChevronLeft />
            </button>
            <p className="font-semibold text-lg ">Transfer USDC</p>
          </div>
          <div className="md:flex flex-col hidden mr-5">
            <p className="self-end text-[#5C6F7F] text-sm">Reference Code</p>
            <h1 className="self-end text-[#0eb683] font-bold text-xl">ABC123</h1>
            <small className="self-end text-[#FA0204] font-bold">
              Always input this reference code during transactions so your
              wallet is credited correctly
            </small>
          </div>
        </header>
        <nav
          className={`tabs overflow-auto  border-t-2 pt-3 mt-6 border-[#f7fbfb] md:border-0 relative  ${
            selectedTab === "Business"
              ? "hidden "
              : "flex md:flex-row md:items-center  md:justify-center md:gap-48 md:before:block md:before:bg-slate-200 md:before:absolute md:before:w-[760px] md:before:h-[2px] md:before:transition-all md:before:top-[33px] before:right-[4] before:ml-7"
          }`}
        >
          {transferFlow(currentFlow).map((step, index) => (
            <TransferFYCTabs
              key={index}
              completed={step.completed}
              active={step.name === selectedTab}
              position={index}
              label={step.label}
              subText={step.description}
              showOnDesktop={index === 1}
            />
          ))}
        </nav>
      </div>
      <main className="md:ml-5 md:mt-9">{formSteps[currentTab].content}</main>
    </div>
  );
};

export default TransferUsdc;
