import React from 'react'
import { FC } from 'react';
import SvgIconConstituentValues from './types';
export interface SvgIcon extends FC<SvgIconConstituentValues>{}

const RatesIcon = ({
    height ="24px",
    width ="24px",
    stroke="#5C6F7F",
    ...props
}:React.SVGProps<SVGAElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width= {width} height= {height} viewBox="0 0 20 21" fill="none">
<path d="M2.5 10.5H5L7.5 18L13.3333 3L15 10.5H17.5" stroke= {stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default RatesIcon