import create from "zustand";

interface IRequestAccess {
  showRequestModal: boolean;
  isSuccess: boolean;
  isFailed: boolean;

  setShowRequestModal: (shouldShow: boolean) => void;
  setIsSuccess: (success: boolean) => void;
  setIsFailed: (failed: boolean) => void;
}

export const useRequest = create<IRequestAccess>((set) => ({
  showRequestModal: false,
  isSuccess: false,
  isFailed: false,

  setShowRequestModal: (shouldShow: boolean) =>
    set((state: any) => ({ showRequestModal: shouldShow })),
  setIsSuccess: (success: boolean) =>
    set((state: any) => ({ isSuccess: success })),
  setIsFailed: (failed: boolean) =>
    set((state: any) => ({
      isFailed: failed,
    })),
}));
