import React, { useState } from "react";

import brandLogo from "../assets/misc/design-top.svg";
import brandLogo2 from "../assets/misc/design-bottom.svg";
import logo from "../assets/misc/logo-on-dark.svg";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../context/UserContext";
import LoadingScreen from "../pages/Notifications/LoadingScreen2";

const WelcomeScreen = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { user } = useUserContext();


  const handleRefresh = () => {
    setLoading(true);
    navigate(
      `/refresh?user=${user?.id}&code=${user?.invitationCode}&at=${user?.accountType}`
    );
  };

  const handleLogout = () =>{
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("user");
    navigate("/login")
  }
  return (
    <LoadingScreen showLoading={loading} msg="Initiating refresh...">
      <div className="fixed w-full h-full top-0 left-0">
        <img src={brandLogo} alt="" className="absolute w-28  md:w-44 " />
        <img
          src={brandLogo2}
          alt=""
          className="absolute w-28 md:w-44 right-0 bottom-0"
        />
        <div className=" flex items-center flex-col gap-5 bg-[#0C0D1F] h-screen justify-center ">
          <img src={logo} alt="" className="w-52 inline" />

          <h1 className="text-white font-bold text-4xl py-5 text-center">
            Update Account Information
          </h1>
          <p className="text-center text-white md:text-xl xxs:px-5">
            To ensure compliance with enhanced KYC regulatory requirements,
            <br />
            we need to update your account with additional information.
            <br />
            Your full access will be reinstated promptly after we have received
            and confirmed the required details.
            <br />
            To proceed, kindly click the button below.
          </p>
          <div className="space-x-5">
            <button
              onClick={handleLogout}
              className="bg-[#fff] border border-[#a10] px-8 py-3 rounded-md text-[#a10] hover:text-[#fff] hover:bg-[#a10] transition-all active:scale-90 xxs:px-10 ease-in-out shadow-md"
            >
              Cancel
            </button>
            <button
              onClick={handleRefresh}
              className="bg-[#0eb683] px-32 py-3 rounded-md text-white transition-all active:scale-90 xxs:px-20 shadow-md"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </LoadingScreen>
  );
};

export default WelcomeScreen;
