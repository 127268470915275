import React, { useContext, useMemo, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate, useOutletContext } from "react-router-dom";
import SuccessMobile from "../../../assets/iconsComponents/SuccessMobile";
import Notification from "../../../components/dashboard-components/Notification";
import WalletCompartment from "../../../components/dashboard-components/WalletCompartment";
import usFlag from "../../../assets/flags/usd.png";
import { motion, AnimatePresence } from "framer-motion";
import RefreshIcon from "../../../assets/iconsComponents/RefreshIcon";
import EmptyState from "../../../components/base-components/EmptyState";
import {
  useAccActivity,
  useBuyUsd,
  useWalletAccount,
  useWalletBalance,
} from "../../../sevices/hooks/wallets";
import { useRates } from "../../../sevices/hooks/tags";
import AppNumericInput from "../../../components/widgets/AppNumericInput";
import { numberWithCommas } from "../Withdrawals";

import Activity from "../../../components/wallet-components/Activity";
import FiatWalletCard from "../../../components/dashboard-components/FiatWalletCard";
import SwapModal from "../../../components/dashboard-components/SwapModal";
import { AxiosResponse } from "axios";
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
} from "react-query";
import TabContext from "../../../store/context/TabProvider";

const UsdWallet = ({ currency = "usd" }: { currency?: string }) => {
  const [userDetails] = useOutletContext<any>();
  const navigate = useNavigate();
  const [transferUsd, setTransferUsd] = useState(false);
  // const [showFcyModal, setShowFcyModal] = useState(false);
  const [transferConfirm, setTransferConfirm] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  // const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("buy");
  const [showFcyModal, setShowFcyModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fundedSuccess, setfundedSuccess] = useState(false);
  const [fields, setFields] = useState({
    amount: "",
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const buyUsd = useBuyUsd();

  //rate
  const currentRate = useRates();
  const { refetch } = currentRate;
  //balance
  // const accUsd = useAccountUsd()
  const accUsd = useWalletBalance(currency);

  // const accNgn = useAccountNgn()
  const accNgn = useWalletBalance("ngn");

  //buy usd
  const [usdAmountValue, setUsdAmountValue] = useState<any>();
  const [NGNAmountValue, setNGNAmountValue] = useState<any>();
  const [amountValue, setAmountValue] = useState<any>();
  const [loading, setLoading] = useState(false);
const [fundModal, setFundModal] = useState(false);
  const accountUsd = numberWithCommas(userDetails?.usdBalance);

  const [acc, setAcc] = useState(accountUsd);
  const userActivities = useAccActivity();

  const transactions = useMemo(() => {
    const usd = userActivities?.data?.data?.usd || [];
    // const ngn = userActivities?.data?.data?.ngn || [];

    const ngnStatus = (isCommitted: string) => {
      return isCommitted ? "Successful" : "Pending";
    };
    const getType = (currency: string, type: string) => {
      if (currency.toUpperCase() === "NGN") {
        return type;
      }
      return type === "BUY" ? "credit" : "debit";
    };

    return [...usd]
      .map(
        (t: {
          unit: any;
          createdAt: any;
          originalReference: any;
          created_at: any;
          id: any;
          narration: any;
          gateway_reference: any;
          status: any;
          currency: any;
          type: any;
          memo: any;
          amount: any;
          is_committed: any;
        }) => {
          const {
            unit,
            createdAt,
            originalReference,
            created_at,
            id,
            narration,
            gateway_reference,
            status,
            currency,
            type,
            memo,
            amount,
            is_committed,
          } = t;
          return {
            id,
            date: created_at ?? createdAt,
            status:
              currency?.toUpperCase() === "NGN"
                ? ngnStatus(is_committed)
                : status,
            type: getType(currency, type),
            currency: currency?.toUpperCase(),
            reference:
              currency?.toUpperCase() === "NGN"
                ? gateway_reference
                : originalReference,
            narration: currency?.toUpperCase() === "NGN" ? memo : narration,
            amount:
              currency.toUpperCase() === "NGN"
                ? "₦ " + numberWithCommas(amount?.formatted ?? unit)
                : currency.toUpperCase() === "USD"
                ? "$ " + numberWithCommas(amount?.formatted ?? unit)
                : currency.toUpperCase() === "USDC"
                ? "$ " + numberWithCommas(amount?.formatted ?? unit)
                : numberWithCommas(parseInt(amount?.formatted ?? unit)),
          };
        }
      )
      .sort((a: any, b: any) => {
        const x = new Date(a.date).getTime();
        const y = new Date(b.date).getTime();
        if (x > y) {
          return -1;
        } else if (x < y) {
          return 1;
        } else {
          return 0;
        }
      });
  }, [userActivities?.data?.data]);

  const switchTabs = () => {
    navigate("/dashboard/wallet/transfer/usd", { replace: true });
  };
  const onfieldChange = ({ name, value }: { value: any; name: any }) => {
    setFields({ ...fields, [name]: value });
  };
  const confirmation = () => {
    setShowConfirmationModal(true);
  };


 const fundNgn = () => setFundModal((prevState) => !prevState);

  const closeModal = () => {
    setShowModal(false);
    setShowConfirmationModal(false);
    setShowSuccess(true);
  };
  //@ts-ignore
  const { setTab, tab } = useContext(TabContext);

  const ngnBalance = userDetails?.ngnBalance;
  function commas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const amountErr =
    tab === "buy"
      ? `Minimum amount is NGN ${commas(
          Number(currentRate?.data?.data?.buyRate) * Number(500)
        )} `
      : `Minimum amount is USD ${commas(
          (Number(currentRate?.data?.data?.sellRate) * Number(10)) /
            Number(currentRate?.data?.data?.sellRate)
        )} `;

  return (
    <div className="md:bg-[#f7fbfb] h-full w-full px-5 py-5 md:p-5 md:pl-8">
      <div className="flex items-center gap-24 md:gap-4">
        <button
          className=" rounded-full bg-[#ECF6F3] p-2 flex transition-all active:scale-90 cursor-pointer "
          onClick={() => {
            navigate(-1);
          }}
        >
          <BsChevronLeft />
        </button>
        <p className="font-semibold">{currency.toUpperCase()} Wallet</p>
      </div>
      <div className="box bg-white flex flex-col md:p-5 mt-7 rounded-lg">
        <div className="flex flex-col items-start gap-3 mb-5 md:flex-row md:justify-between">
          <div className="w-full md:w-fit">
            <FiatWalletCard
              text={"USD Balance"}
              currency={"$"}
              // props={accountUsd}
              amount={
                numberWithCommas(accountUsd) === "NaN"
                  ? "0.00"
                  : numberWithCommas(accountUsd)
              }
            />
          </div>
          <div className="flex flex-col md:items-end">
            <div className="button hidden md:flex gap-4 md:gap-3 md:items-end  ">
              <div
                // disabled={true}
                onClick={(e) => {
                  e.preventDefault();
                  setShowFcyModal(true);
                }}
                className="border border-[#0eb683] text-center  text-[#0eb683] w-full p-3 md:w-40 whitespace-nowrap bg-white rounded-md transition-all active:scale-90 cursor-pointer disabled:cursor-not-allowed "
              >
                Buy | Sell USD
              </div>
              <div
                className="bg-[#0eb683] w-full md:w-64 p-3 text-center border border-[#0eb683] text-white rounded-md transition-all active:scale-90 cursor-pointer self-end"
                onClick={switchTabs}
              >
                Transfer FCY
              </div>
            </div>

            {/* Mobile */}

            <div className="flex md:hidden gap-3 w-full md:items-center">
              <div
                // disabled={true}
                onClick={(e) => {
                  e.preventDefault();
                  setShowFcyModal(true);
                }}
                className="border border-[#0eb683] text-center  text-[#0eb683] w-full p-3 md:w-28 whitespace-nowrap bg-white rounded-md transition-all active:scale-90 cursor-pointer "
              >
                Buy | Sell USD
              </div>
              <div
                onClick={switchTabs}
                className="bg-[#0eb683] w-full md:w-40 p-3 text-center border border-[#0eb683] text-white rounded-md transition-all active:scale-90 cursor-pointer self-end"
              >
                Transfer FCY
              </div>
            </div>

            <div>
              {showFcyModal && (
                <SwapModal
                  baseCurrency={"usd"}
                  baseCurrencySymbol={"$ "}
                  currency={"ngn"}
                  currencySymbol={"₦ "}
                  rate={currentRate?.data?.data?.buyRate}
                  confirmation={showConfirmation}
                  setConfirmation={setShowConfirmation}
                  inputValue={
                    tab === "buy"
                      ? NGNAmountValue?.value
                      : usdAmountValue?.value
                  }
                  inputValueFormatted={NGNAmountValue?.formattedValue}
                  inputValueFloat={NGNAmountValue?.floatValue}
                  setInputValue={setNGNAmountValue}
                  openModal={showFcyModal}
                  loading={loading}
                  setLoading={setLoading}
                  setOpenModal={setShowFcyModal}
                  balance={ngnBalance}
                  baseBalance={acc}
                  computedValue={
                    tab === "buy"
                      ? usdAmountValue?.value
                      : NGNAmountValue?.value
                  }
                  computedValueFloat={usdAmountValue?.floatValue}
                  computedValueFormatted={usdAmountValue?.formattedValue}
                  setComputedValue={setUsdAmountValue}
                  errorMessage={amountErr}
                  sellRate={currentRate?.data?.data?.sellRate}
                  success={showSuccessModal}
                  setSuccess={setShowSuccessModal}
                  // initiateBuy={purchaseUsd}
                  setFundModal={fundNgn}
                  refetchRates={refetch}
                />
              )}
            </div>

            <div className=" md:text-right mt-8">
              <p className="text-sm md:hidden text-[#5C6F7F]">
                Securely make transfers to permitted regions with a fee of a
                0.25% + $50 Offshore Charge
                <br />
                Transfers typically typically take 3 business days All funds are
                secured
              </p>

              <p className="text-sm hidden md:flex items-end flex-col  font-medium text-[#5C6F7F] md:text-right">
                Securely make transfers to permitted regions with a fee of a
                0.25% + $50 Offshore Charge
                <br />
                <span className="md:text-right">
                  {" "}
                  Transfers typically typically take 3 business days
                </span>{" "}
                <span className=""> All funds are secured</span>
              </p>
            </div>
          </div>
        </div>
        <AnimatePresence>
          {showModal && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="modal bg-[#a59d9d34] backdrop-blur-sm fixed w-full h-full top-0 left-0 justify-center items-center z-10"
            >
              <motion.div
                initial={{
                  x: 600,
                  scale: 0,
                }}
                animate={{
                  x: 0,
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 600,
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                className="flex h-screen items-center justify-center md:justify-end"
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="card flex flex-col bg-white  w-full max-w-md max-h-full rounded-l-3xl p-6 md:h-full "
                >
                  <button
                    onClick={() => {
                      setShowFcyModal(true);
                      setShowModal(false);
                      setShowConfirmationModal(false);
                    }}
                    className="hover:bg-[#f2faf7] rounded-full p-2 self-end"
                  >
                    <AiOutlineClose size={28} />
                  </button>
                  <div>
                    <h1 className="font-bold text-xl text-[#071827]">
                      Fund your account
                    </h1>
                    <p className="text-[#5C6F7F] text-sm pb-4">
                      Fund your NGN balance via direct bank transfer
                    </p>
                  </div>
                  <input
                    type="number"
                    name="amount"
                    className="w-full  p-3 bg-[#f7fbfb] rounded-md border mb-6 outline-[#0eb683] placeholder:text-sm"
                    placeholder="Enter the amount you wish to transfer "
                    onChange={(e) => {
                      onfieldChange({
                        name: "amount",
                        value: e.target.value,
                      });
                    }}
                  />

                  <Notification
                    info={"To fund your Naira wallet, make a transfer to:"}
                  />

                  <button
                    onClick={confirmation}
                    className="bg-[#0eb683] rounded-md px-10 p-3 transition-all active:scale-90 mt-6 text-white "
                  >
                    Continue
                  </button>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showConfirmationModal && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="confirmation_modal bg-opacity-30 backdrop-blur-sm fixed w-full top-0 left-0 justify-center h-full items-center z-50"
            >
              <motion.div
                initial={{
                  x: 600,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className="flex h-screen items-center justify-center md:justify-end "
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="card flex flex-col bg-white  w-full max-w-md max-h-full rounded-l-3xl p-5 md:h-full "
                >
                  <button
                    onClick={closeModal}
                    className="hover:bg-[#f2faf7] rounded-full p-2  self-end cursor-pointer"
                  >
                    <AiOutlineClose size={28} />
                  </button>
                  <div className="flex gap-3 items-center pb-2">
                    <div
                      onClick={() => setShowConfirmationModal(false)}
                      className="bg-[#f7fbfb] p-2 rounded-full cursor-pointer transition-all active:scale-90"
                    >
                      <BsChevronLeft />
                    </div>

                    <h1 className="font-bold text-xl text-[#071827]">
                      Confirmation
                    </h1>
                  </div>
                  <p className="text-sm text-[#5C6F7F] mb-9">
                    Fund your NGN balance via direct bank transfer
                  </p>
                  <div className="flex items-center justify-between">
                    <h1 className="text-[#071827] font-bold">
                      You are transferring:
                    </h1>
                    <p className="text-[#0EB683] font-bold text-xl">
                      NGN 0.00
                    </p>
                  </div>
                  <div className="py-5">
                    <Notification
                      text={
                        "Once you have transferred the amount, click confirm below"
                      }
                      info={"To:"}
                    />
                  </div>

                  <div
                    onClick={closeModal}
                    className="flex flex-row justify-between md:flex-col mb-4"
                  >
                    <button className="border border-[#0eb683] text-[#0eb683] md:p-3 rounded-md md:mb-4 cursor-pointer transition-all active:scale-90  py-3 px-8 hover:bg-[#0eb683] hover:text-white ">
                      Confirm
                    </button>
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {showSuccess && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                // delay: 0.3,
                duration: 0.1,
              },
            }}
            className="confirmation_modal bg-[#13111137] backdrop-blur-sm fixed w-full top-0 left-0 justify-center h-full items-center z-50"
          >
            <motion.div
              initial={{
                x: 600,
                scale: 0,
              }}
              animate={{
                x: 0,
                scale: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                x: 600,
                scale: 0,
                transition: {
                  delay: 0.3,
                },
              }}
              className="flex h-screen items-center justify-center md:justify-end "
            >
              <motion.div
                initial={{
                  x: 600,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    // scale:1,
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 1,
                  transition: {
                    // scale:10,
                    duration: 0.3,
                  },
                }}
                className="desktop-card md:flex hidden justify-center flex-col gap-8 bg-white   w-[448px] h-full md:rounded-l-3xl p-5 "
              >
                <div className="icon self-center">
                  <SuccessMobile width={"200px"} height={"200px"} />
                </div>
                <h1 className="text-3xl font-bold text-[#071827] self-center">
                  Wallet Funded
                </h1>

                {/* <CheckIcon/> */}
                <p className="text-sm text-[#5C6F7F] self-center">
                  Your wallet has been successfully funded
                </p>

                <button
                  onClick={() => setShowSuccess(false)}
                  className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90 "
                >
                  Done
                </button>
              </motion.div>

              <motion.div
                initial={{
                  x: 600,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    // scale:1,
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 1,
                  transition: {
                    // scale:10,
                    duration: 0.3,
                  },
                }}
                className="mobile-card flex md:hidden justify-center flex-col gap-8 bg-white  w-full max-w-sm h-[450px] rounded-xl p-5 "
              >
                <div className="icon self-center">
                  <SuccessMobile width={"100px"} height={"100px"} />
                </div>
                <h1 className="text-3xl font-bold text-[#071827] self-center">
                  Wallet Funded
                </h1>

                {/* <CheckIcon/> */}
                <p className="text-sm text-[#5C6F7F] self-center">
                  Your wallet has been successfully funded
                </p>

                <button
                  onClick={() => setShowSuccess(false)}
                  className="bg-[#0eb683] text-white rounded-md px-16 py-3 self-center transition-all active:scale-90 "
                >
                  Done
                </button>
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <>
        {transactions.length > 0 ? (
          // @ts-ignore
          <Activity
            tabs={[]}
            itemsPerPage={5}
            currentPage={1}
            //@ts-ignore
            data={transactions}
          />
        ) : (
          <EmptyState />
        )}
      </>
    </div>
  );
};

export default UsdWallet;
