import React, { useState } from "react";
import AppNumericInput from "../widgets/AppNumericInput";
import { AnimatePresence, motion } from "framer-motion";
import { AiOutlineClose } from "react-icons/ai";
import AppSelectInput from "../base-components/AppSelectInput";
import SendRadioInput from "../base-components/SendRadioInput";
import RefreshIcon from "../../assets/iconsComponents/RefreshIcon";
import { BsChevronLeft } from "react-icons/bs";
import usdc from "../../assets/coins/usdc.png";
import usdt from "../../assets/coins/usdt.png";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";
import { numberWithCommas } from "../../pages/dashboard/Withdrawals";
import { IoMdClose } from "react-icons/io";

const currencyOptions = [
  {
    value: "USD",
  },
  {
    value: "GBP",
  },
  {
    value: "EUR",
  },
  {
    value: "NGN",
  },
].map(({ value }) => ({ value, label: value }));

const ConvertModal = ({
  closeModal,
  currency,
}: {
  closeModal: any;
  currency: string;
}) => {
  const [confirmation, setConfirmation] = useState(false);
  const [swapSuccess, setSwapSuccess] = useState(false);
  const [currencyOpt, setCurrencyOpt] = useState("");
  const [convertAmount, setConvertAmount] = useState<any>();
  const overLayRef = React.useRef(null);
  const close = () => {
    closeModal(false);
  };

  // const handleOverLayClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
  //     if (e.target === overLayRef.current) {
  //       onClose(false);
  //     }
  //   };

  const handleOverLayClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (e.target === overLayRef.current) {
      closeModal(false);
    }
  };

  return (
    <AnimatePresence>
      <div>
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.3,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              // delay: 0.3,
              duration: 0.1,
            },
          }}
          ref={overLayRef}
          className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
          onClick={handleOverLayClick}
        >
          <motion.div
            initial={{
              x: 600,
              scale: 0,
            }}
            animate={{
              x: 0,
              scale: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              x: 600,
              scale: 0,
              transition: {
                delay: 0.3,
              },
            }}
            className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173]"
          >
            <motion.div
              initial={{
                x: 600,
                opacity: 0,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              }}
              exit={{
                x: 100,
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              className="flex flex-col bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative xxs:rounded-md md:rounded-l-md  p-5 md:px-7 overflow-auto"
            >
              <div className="flex items-end justify-end ">
                <div
                  onClick={close}
                  className="close  flex items-center justify-center h-10 w-10  rounded-full
                    hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center "
                >
                  <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                    <IoMdClose size={29} className="" />
                  </span>
                </div>
              </div>

              {!confirmation ? (
                <div>
                  <div className=" flex flex-col gap-3">
                    <h1 className="text-[#071827] text-2xl font-bold self-center ">
                      Convert {currency} to{" "}
                      {currencyOpt === "" ? "USD" : currencyOpt}
                    </h1>
                    <div className="flex flex-col gap-1">
                      <p className="text-[#5C6F7F] text-md  self-center">
                        Current Balance
                      </p>
                      <h2 className="text-[#0EB683] text-3xl font-bold self-center">
                        0.00 {currency}
                      </h2>
                      <p className="text-[#071827] text-sm font-semibold self-center ">
                        1{currency} is currently 0.90{" "}
                        {currencyOpt === "" ? "USD" : currencyOpt}
                      </p>
                    </div>

                    <div className="mid  relative">
                      <AppSelectInput
                        options={currencyOptions || []}
                        label=" "
                        className="bg-#000"
                        placeholder={`Select currency `}
                        handleChange={(inputValue) => {
                          inputValue?.value &&
                            setCurrencyOpt(inputValue?.value);
                        }}
                      />
                      <span className="text-[#e01] absolute left-2 bottom-4">
                        *
                      </span>
                    </div>
                    <div className="mid  relative">
                      <AppNumericInput
                        value={numberWithCommas(convertAmount?.value)}
                        onValueChange={(value: any) => {
                          setConvertAmount(value);
                        }}
                        thousandSeparator={true}
                        prefix="$ "
                        style={{ borderColor: "#0EB683" }}
                        labelStyle={{
                          color: "#000",
                          fontWeight: "bold",
                          // margin: "20px 0px 10px",
                        }}
                        //   label="Amount you wish to buy (USD)"
                        placeholder={`amount you wish to convert (${currency})`}
                        isAllowed={(nValue: any) => nValue.value.length <= 11}
                      />
                      <span className="text-[#e01] absolute left-2 bottom-4">
                        *
                      </span>
                    </div>

                    <div>
                      <p className="text-[#5C6F7F] ">
                        <span className="text-red-500">*</span>
                        Should service fee be debited from total amount?
                      </p>
                      <div className="flex ">
                        <SendRadioInput
                          label=""
                          getValue={(value: boolean) => {
                            // setSendersAmountInclusive(value);
                          }}
                          isRequired={false}
                          defaultValue={true}
                          positiveText="YES"
                          negativeText="NO"
                          revPos={true}
                        />
                      </div>
                      <div>
                        <p className="text-[#071827]">
                          Service Fee @0.25% ({currency})
                        </p>
                        <h1 className="text-[#071827] font-bold text-xl">
                          0.00
                        </h1>
                      </div>
                    </div>

                    <AppNumericInput
                      value={""}
                      onValueChange={(value: any) => {
                        // setNGNAmountValue(value);
                      }}
                      disabled={true}
                      thousandSeparator={true}
                      prefix="₦ "
                      style={{
                        borderColor: "#EAEAEA",
                        backgroundColor: "#EAEAEA",
                      }}
                      labelStyle={{
                        color: "#000",
                        fontWeight: "bold",
                        // margin: "0px 0px",
                      }}
                      label={`Amount you will receive (${
                        currencyOpt === "" ? "USD" : currencyOpt
                      })`}
                      placeholder="Amount will be computed"
                      isAllowed={(nValue: any) => nValue.value.length <= 11}
                      //   info={` Current Balance {currency} `}
                      infoStyle={{
                        textAlign: "end",
                        fontWeight: "bold",
                        padding: 0,
                      }}
                      isInsufficient={""}
                      insufficientLabel="Insufficient balance"
                    />

                    <div className="flex flex-col   ">
                      <button
                        onClick={() => setConfirmation(true)}
                        className="bg-[#0eb683] text-white p-3 rounded-md transition-all active:scale-90  disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90 "
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center gap-4 absolute top-6">
                      <button
                        className=" rounded-full bg-[#ECF6F3] p-2 flex transition-all active:scale-90 cursor-pointer "
                        onClick={() => {
                          setConfirmation(false);
                        }}
                      >
                        <BsChevronLeft />
                      </button>
                      <h1 className="text-lg font-bold text-[#071827] ">
                        Confirmation
                      </h1>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <h1 className="text-[#071827] text-xl font-bold self-center pb-4 ">
                      Conversion Details
                    </h1>

                    <div className="border border-[#0eb683] flex flex-col items-center justify-center w-full h-24 rounded-md">
                      <p className="text-[#5C6F7F] ">Current Balance</p>
                      <div className="flex items-center gap-4">
                        <h1 className="text-3xl text-[#071827] font-bold">
                          600.00 {currency}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className=" flex flex-col gap-4 mt-6">
                    <div className="flex justify-between">
                      <h1 className="text-sm text-[#071827] font-medium">
                        You are converting:
                      </h1>
                      <div className="flex items-center gap-1">
                        <p className="text-sm text-[#0eb683] font-medium">
                          0.00 {currency}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <h1 className="text-sm text-[#071827] font-medium">
                        Charges:
                      </h1>
                      <div className="flex items-center gap-1">
                        <img
                          src={currency === "usdc" ? usdc : usdt}
                          alt="{currency} "
                          className="w-4 "
                        />
                        <p className="text-sm text-[#0eb683] font-medium">
                          {currency} 0.00
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <h1 className="text-sm text-[#071827] font-medium">
                        Total Debited:
                      </h1>
                      <div className="flex items-center gap-1">
                        <p className="text-sm text-[#0eb683] font-medium">
                          0.00 {currency}
                        </p>
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <h1 className="text-sm text-[#071827] font-medium">
                        You would receive:
                      </h1>
                      <div className="flex items-center gap-1">
                        <p className="text-sm text-[#0eb683] font-medium">
                          {currencyOpt} 0.00
                        </p>
                      </div>
                    </div>

                    <p className="text-sm mt-7 text-[#071827] font-semibold text-center">
                      0.00 {currency} converts to USD 0.00
                    </p>

                    <h3 className="xxs:text-sm md:text-base text-center text-[#242628] md:mt-10">
                      By clicking the button below,
                      <br />I agree my {currency} wallet would be debited
                      <br /> and converted to {currencyOpt} immediately
                    </h3>

                    <button
                      onClick={() => {
                        setSwapSuccess(true);
                        // setShowSwapConfirmation(false);
                      }}
                      className="bg-[#0eb683]  text-white p-3 rounded-md transition-all active:scale-90 "
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              )}
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default ConvertModal;
