import React, { useState } from "react";
import RefreshIcon from "../../assets/iconsComponents/RefreshIcon";
import { TabSelector } from "../widgets/TabSelector";
import { useTabs } from "../widgets/TabWidget";
import QrCode from "qrcode.react";
import Toastify from "toastify-js";
import CopyIcon from "../../assets/iconsComponents/CopyIcon";
import InfoIcon from "../../assets/iconsComponents/InfoIcon";
import { AiOutlineClose, AiOutlineCopy } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import { useUsers } from "../../sevices/hooks/users";
import Warning from "./Warning";

const UsdcTransactionDetails = ({
  prev,
  onClick,
  currency,
}: {
  onClick: () => void;
  currency: string;
  prev: () => void;
}) => {
  const [choice, setChoice] = useTabs(["No", "Yes"]);
  const [qrCodeModal, setQrCodeModal] = useState(false);
  const [copyStatus, setCopyStatus] = useState("");
  const getUserDetails = useUsers(
    //@ts-ignore
    localStorage.getItem("user_id"),
    localStorage.getItem("accessToken")
  );
  const copyToClipBoard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyStatus("Wallet address copied to clipboard");
    let timeout = setTimeout(() => {
      setCopyStatus("");
      clearTimeout(timeout);
    }, 1500);
  };

  const showToast = () => {
    setQrCodeModal(false);
    Toastify({
      text: "You have received X USDC - Kindly check your USDC wallet.",
      className: "info",
      style: {
        background: "#D3FDE9",
      },
    }).showToast();
  };

  function truncateString(str: string, num: number) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  

  return (
    <div className="bg-[#f7fbfb] h-full w-full p-5 pt-8 pb-10">
      <div className="flex items-center md:hidden justify-between">
        <p className="text-[#5C6F7F] text-xs">Reference Code</p>
        <h1 className="text-[#0eb683] font-medium">ABC123</h1>
      </div>
      <p className="text-[#FA0204] text-sm font-medium pt-5 md:hidden">
        Always input this reference code during transactions so your wallet is
        credited correctly
      </p>
      <div className="relative">
        <div className="md:bg-white md:p-5 md:max-w-lg ">
          <h1 className="text-[#071827] font-bold text-xl pt-5">
            Transaction Details
          </h1>
          <div className=" pt-8 ">
            <p className="text-[#0B0C0E] text-base">Current Balance</p>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <h1 className="text-[#0eb683] font-bold text-xl">
                  0.00 {currency}
                </h1>
                <button className=" bg-white rounded-full p-1 border border-[#0eb683] transition-all active:scale-90">
                  <RefreshIcon />
                </button>
              </div>
              <button
                onClick={() => {
                  setQrCodeModal(true);
                }}
                className="p-2 px-8 hidden md:block  bg-white text-[#0eb683] border border-[#0eb683]  rounded-md transition-all active:scale-90"
              >
                Receive
              </button>
            </div>
          </div>
          <button
            onClick={() => {
              setQrCodeModal(true);
            }}
            className="p-3 md:hidden bg-white text-[#0eb683] border border-[#0eb683] w-full rounded-md mt-4 transition-all active:scale-90"
          >
            Receive
          </button>
          <AnimatePresence>
            {qrCodeModal && (
              <div>
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    opacity: 0,
                    transition: {
                      // delay: 0.3,
                      duration: 0.1,
                    },
                  }}
                  className="showFcy_modal backdrop-blur-sm bg-[#13111173] fixed w-full h-full top-0 left-0 justify-center items-center z-10"
                >
                  <motion.div
                    initial={{
                      x: 600,
                      scale: 0,
                    }}
                    animate={{
                      x: 0,
                      scale: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 600,
                      scale: 0,
                      transition: {
                        delay: 0.3,
                      },
                    }}
                    className="flex h-screen items-center justify-center md:justify-end"
                  >
                    <motion.div
                      initial={{
                        x: 600,
                        opacity: 0,
                      }}
                      animate={{
                        x: 0,
                        opacity: 1,
                        transition: {
                          delay: 0.3,
                          duration: 0.3,
                        },
                      }}
                      exit={{
                        x: 100,
                        opacity: 1,
                        transition: {
                          duration: 0.3,
                        },
                      }}
                      className="card flex flex-col bg-white shadow-2xl w-full max-w-md md:max-w-md max-h-full  md:h-full rounded-l-3xl xxs:px-5 xxs:py-8 md:pt-5 md:px-10 overflow-y-scroll "
                    >
                      <button
                        onClick={() => {
                          setQrCodeModal(false);
                        }}
                        className="transition-all active:scale-90 hover:bg-[#E5F2EA] text-[#1E3448] rounded-full p-2 self-end mb-3"
                      >
                        <AiOutlineClose size={28} />
                      </button>
                      <div className="mb-5">
                        <h1 className="text-xl text-[#071827] font-bold md:text-center">
                          Receive {currency}
                        </h1>
                      </div>
                      <div className="mx-auto">
                        <div className="flex flex-col   gap-3 relative w-fit h-fit">
                          <div className="qrcode_wrapper flex items-center justify-center w-48 h-48 border-4 border-[#0eb683]  rounded-2xl">
                            {" "}
                            {currency === "USDC" ? (
                              <QrCode
                                value={
                                  getUserDetails?.data?.data?.stableWallet
                                    ?.depositAddresses?.MATIC
                                }
                                size={160}
                              />
                            ) : (
                              <QrCode
                                value={
                                  getUserDetails?.data?.data?.stableWallet
                                    ?.depositAddresses?.ETH
                                }
                                size={160}
                              />
                            )}
                          </div>
                          <div className="blockage  ">
                            <div className="h-3 md:h-2 w-32 bg-white mb-1 absolute -top-1 right-8"></div>
                            <div className="w-3 h-32 md:w-2 bg-white mb-1 absolute top-8 -left-1 "></div>
                            <div className="w-3 h-32 md:w-2 bg-white mb-1 absolute -right-1 md:right-0 top-8 "></div>
                            <div className="h-3 md:h-2 w-32 bg-white mb-1 absolute bottom-1 md:bottom-2 right-8 "></div>
                          </div>
                        </div>
                      </div>
                      <p className="text-[#071827] font-bold text-center xxs:mt-2 ">
                        {" "}
                        Scan code to copy your{" "}
                        <span className="text-[#0eb683] font-bold">
                          {currency}{" "}
                        </span>{" "}
                        wallet address
                      </p>
                      <div className="relative xxs:mt-4 md:mt-4">
                        <div className="w-full h-48 rounded-md bg-[#F7FBFB] px-3 md:px-5 flex flex-col gap-2 md:gap-3 ">
                          <div className="flex flex-col gap-1 mt-3">
                            
                            <p className="text-xs font-extrabold ">
                              Copy:{" "}
                              <span className="text-[#0eb683] font-light">
                                {copyStatus}
                              </span>
                            </p>
                          </div>

                          <div className="mt-1 flex flex-col gap-3 relative">
                            <div
                              onClick={() => {
                                if (currency === "USDT") {
                                  copyToClipBoard(
                                    getUserDetails?.data?.data?.stableWallet
                                      ?.depositAddresses?.ETH
                                  );
                                } else {
                                  copyToClipBoard(
                                    getUserDetails?.data?.data?.stableWallet
                                      ?.depositAddresses?.MATIC
                                  );
                                }
                              }}
                              className="flex items-center  gap-1 border border-[#0eb683] w-20 py-1 px-3 rounded-md bg-white transition-all active:scale-90 cursor-pointer "
                            >
                              <div className="text-[#0eb683]">
                                <AiOutlineCopy />
                              </div>
                              <p className="text-sm text-[#0eb683] font-medium">
                                Copy
                              </p>
                            </div>
                            <p className="xxs:hidden md:block text-[#5C6F7F] text-sm font-semibold">
                              {currency === "USDT"
                                ? truncateString(
                                    getUserDetails?.data?.data?.stableWallet
                                      ?.depositAddresses?.ETH,
                                    35
                                  )
                                : truncateString(
                                    getUserDetails?.data?.data?.stableWallet
                                      ?.depositAddresses?.MATIC,
                                    35
                                  )}
                            </p>
                            <p className="text-[#5C6F7F] text-sm font-semibold md:hidden">
                              {currency === "USDT"
                                ? truncateString(
                                    getUserDetails?.data?.data?.stableWallet
                                      ?.depositAddresses?.ETH,
                                    35
                                  )
                                : truncateString(
                                    getUserDetails?.data?.data?.stableWallet
                                      ?.depositAddresses?.MATIC,
                                    35
                                  )}
                            </p>
                            <div className="flex flex-col bg-[#fff] p-1 rounded-md  w-full">
                                {/* <InfoIcon weight={"20px"} height={"20px"} /> */}
                                <h1 className="font-bold text-[#071827]">Network:</h1>
                                <p className="text-xs text-[#E01A00] font-light "><span className="text-[#E01A00]">Ensure you are transferring over the</span> {currency === "USDT" ? "Ethereum Chain (ERC-20)" : currency ===  "USDC" ? "Polygon (MATIC) Chain" : null}</p>
                              </div>
                          </div>
                        </div>
                        <div className="w-1 h-20 bg-red-600 absolute top-10 rounded-full "></div>
                      </div>
                      <div className="h-40 w-fit my-3">

<Warning currency= {currency === "USDT" ? "usdt" : currency ===  "USDC" ? "usdc" : null} />
</div>
                      <button
                        onClick={() => {
                          showToast();
                        }}
                        className="bg-[#0eb683] text-white w-full p-3 rounded-md mt-6 transition-all active:scale-90"
                      >
                        Finish
                      </button>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </div>
            )}
          </AnimatePresence>
          <div className="mid pt-5 relative ">
            <input
              type="text"
              name=""
              className={`py-3 px-3 min-w-full md:bg-[#F2FAF7] md:max-w-md border border-[#0eb683] rounded-md  outline-[#0eb683] placeholder:text-sm placeholder:px-1 `}
              placeholder={`Amount you want to send in ${currency}`}
              required
            />
            <span className="text-red-500 absolute left-2 bottom-4">*</span>
          </div>
          <p className="text-sm text-slate-500 pt-5">
            {" "}
            <span className="text-[##FA0204]">*</span> Should service fee be
            debited from total amount?
          </p>
          <div className="flex gap-6 pt-2">
            <TabSelector
              className={`cursor-pointer relative bg-transparent mr-4 md:mr-0 ${
                choice === "No"
                  ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                  : ""
              }`}
              isActive={choice === "No"}
              onClick={() => setChoice("No")}
            >
              <div className="flex items-center justify-self-center">
              <div className="flex items-center gap-2">
                <div className=" bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>
                <h1 className="text-[#5C6F7F]">No</h1>
              </div>
              </div>
              
            </TabSelector>

            <TabSelector
              className={`cursor-pointer relative bg-transparent ${
                choice === "Yes"
                  ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                  : ""
              }`}
              isActive={choice === "Yes"}
              onClick={() => setChoice("Yes")}
            >
              <div className="flex items-center gap-2">
                <div className=" bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>
                <h1 className="text-[#5C6F7F]">Yes</h1>
              </div>
            </TabSelector>
          </div>
          <div>
            <div className="pt-5">
              <p className="text-[#0B0C0E] text-sm">
                Service Fee @ 0.1% ({currency})
              </p>
              <h1 className="text-xl font-bold text-[#0b0c0e]">
                0.00 {currency}
              </h1>
            </div>
            <div className="mid pt-5 relative ">
              <input
                type="text"
                name=""
                className={`py-3 px-3 min-w-full md:bg-[#F2FAF7] md:max-w-md border border-[#0eb683] rounded-md  outline-[#0eb683] placeholder:text-sm placeholder:px-1 `}
                placeholder="Enter Reference above"
                required
              />
              <span className="text-red-500 absolute left-2 bottom-4">*</span>
            </div>
            <div className="pt-5">
              <p className="text-[#0B0C0E] text-sm">Blockchain Network</p>
              <h1 className="text-xl font-bold text-[#0b0c0e]">{ currency === "USDC" ? "Polygon (MATIC)": "Ethereum (ERC-20) Network"}</h1>
            </div>
            <div className="pt-5">
              <p className="text-[#0B0C0E] text-sm">
                Total Amount to be debited
              </p>
              <h1 className="text-xl font-bold text-[#0b0c0e]">
                0.00 {currency}
              </h1>
            </div>
            <div className="pt-5">
              <p className="text-[#0B0C0E] text-sm">
                Beneficiary receives ({currency})
              </p>
              <h1 className="text-xl font-bold text-[#0b0c0e]">
                0.00 {currency}
              </h1>
            </div>
          </div>
          <div className="flex items-center justify-between pt-5">
            <button
              onClick={prev}
              className="bg-white text-[#0eb683] p-3 border border-[#0eb683] rounded-md px-6 transition-all active:scale-90"
            >
              Previous
            </button>
            <button
              onClick={onClick}
              className="text-[white] bg-[#0eb683] border border-[#0eb683] p-3 rounded-md px-6 transition-all active:scale-90"
            >
              Continue
            </button>
          </div>
        </div>
        <div className="absolute max-w-md h-40 top-0 right-32 xxs:hidden md:block">
        <Warning currency={currency === "USDT" ? "usdt" : currency ===  "USDC" ? "usdc" : null}/>

        </div>
      </div>
    </div>
  );
};

export default UsdcTransactionDetails;
