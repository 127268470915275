import React, { createContext, useContext, useState } from "react";

export interface IChildren {
  children: React.ReactNode;
}



export type IBusinessUser = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
  refereeCode: string;
  nuban: string;
  bank: string;
  accountType: "private" | "business"; // Assuming 'accountType' can only be "private" or "business"
  businessName: string;
  businessType: string;
  businessIncNumber: string;
  businessPhoneNumber: string;
  dob: string;
  bvn: string;
  address1: string;
  address2: string;
  country: string;
  state: string;
  postCode: string;
  idType: string;
  selfie: string;
  id_doc_photo: string;
  [key: string]: string;
};

interface IContextProps {
  state: IBusinessUser;
  setState: React.Dispatch<React.SetStateAction<IBusinessUser>>;
}

export const AppStateContext = createContext({} as IContextProps);

export function BusinessProvider({ children }: IChildren) {
  const initialState: IBusinessUser = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    refereeCode: "",
    nuban: "",
    bank: "",
    accountType: "business", // Set initial account type
    businessName: "",
    businessType: "",
    businessIncNumber: "",
    businessPhoneNumber: "",
    dob: "",
    bvn: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    postCode: "",
    idType: "",
    selfie: "",
    id_doc_photo: "",
  };

  const [businessUserData, setBusinessUserData] =
    useState<IBusinessUser>(initialState);

  return (
    <AppStateContext.Provider
      value={{ state: businessUserData, setState: setBusinessUserData }}
    >
      {children}
    </AppStateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(AppStateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppProvider");
  }
  return context;
}
