import  React from 'react'
import { FC } from 'react';
import SvgIconConstituentValues from './types';

export interface SvgIcon extends FC<SvgIconConstituentValues>{}

const DashboardIcon = ({
    height = "24px",
    width = "24px",
    stroke = "#5C6F7F",
    ...prop
}: React.SVGProps<SVGAElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width= {width} height= {height} viewBox="0 0 20 20" fill="none">
    <path d="M9.16667 2.5H3.33333C2.8731 2.5 2.5 2.8731 2.5 3.33333V9.16667C2.5 9.6269 2.8731 10 3.33333 10H9.16667C9.6269 10 10 9.6269 10 9.16667V3.33333C10 2.8731 9.6269 2.5 9.16667 2.5Z" stroke= {stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.0834 13.334H13.75C13.5199 13.334 13.3334 13.5205 13.3334 13.7507V17.084C13.3334 17.3141 13.5199 17.5007 13.75 17.5007H17.0834C17.3135 17.5007 17.5 17.3141 17.5 17.084V13.7507C17.5 13.5205 17.3135 13.334 17.0834 13.334Z" stroke= {stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.0834 5.83398H13.75C13.5199 5.83398 13.3334 6.02053 13.3334 6.25065V9.58398C13.3334 9.8141 13.5199 10.0007 13.75 10.0007H17.0834C17.3135 10.0007 17.5 9.8141 17.5 9.58398V6.25065C17.5 6.02053 17.3135 5.83398 17.0834 5.83398Z" stroke= {stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.58337 13.334H6.25004C6.01992 13.334 5.83337 13.5205 5.83337 13.7507V17.084C5.83337 17.3141 6.01992 17.5007 6.25004 17.5007H9.58337C9.81349 17.5007 10 17.3141 10 17.084V13.7507C10 13.5205 9.81349 13.334 9.58337 13.334Z" stroke= {stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default DashboardIcon