import React, { useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ShareIcon from "../../../assets/iconsComponents/ShareIcon";
import AppSelectInput from "../../../components/base-components/AppSelectInput";
import AddFiatComponent from "../../../components/beneficiary-component/AddFiatComponent";
import AddStableCoinComponent from "../../../components/beneficiary-component/AddStableCoinComponent";
import { TabSelector } from "../../../components/widgets/TabSelector";
import { TabPanel, useTabs } from "../../../components/widgets/TabWidget";
import { useBeneficiaries } from "../../../sevices/hooks/wallets";
import { useSidebarState } from "../../../store";


const AddBeneficiary = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("fiat");
  const toggleSideBarOpen = useSidebarState((state) => state.toggleSidebar);

 
  
 
  

  return (
    <div className="bg-[#f7fbfb] h-screen w-full p-5 md:p-0 pt-5 md:pt-5 md:pl-8">
      <div className="div flex items-center  gap-24">
        <div className="flex items-center xxs:gap-16 md:gap-4 md:mb-5">
          <button
            className=" rounded-full mb-4 bg-[#ECF6F3] p-2 flex transition-all active:scale-90 cursor-pointer "
            onClick={() => {
              navigate("/dashboard/beneficiaries", { replace: true });
              // /dashboard/beneficiaries
            }}
          >
            <BsChevronLeft />
          </button>
          <p className="font-semibold md:mr-2 pb-4 md:pb-3  ">Add Beneficiary</p>
        </div>
        <div className="toggle hidden  md:max-w-[240px] w-full h-10 md:h-10 md:flex border border-[#0eb683] mb-6 md:mb-8 self-center justify-between rounded-xl cursor-pointer p-1  bg-white ">
          <div
            className={`px-2 flex-1 whitespace-nowrap flex items-center justify-center transition-all duration-500 rounded-md  ${
              currentTab === "fiat" ? "bg-[#0eb683] text-white" : ""
            }`}
            onClick={() => setCurrentTab("fiat")}
          >
            Fiat
          </div>
          <div
            className={`px-2 flex-1 whitespace-nowrap flex items-center justify-center transition-all duration-500 rounded-md  ${
              currentTab === "stable" ? "bg-[#0eb683] text-white" : ""
            }`}
            onClick={() => setCurrentTab("stable")}
          >
            Stablecoins
          </div>
        </div>
      </div>
      <div className="div bg-[#f7fbfb]  z-10 py-5">
        <div className="toggle md:hidden md:max-w-[320px] w-full h-10 md:h-10 flex border border-[#0eb683] mb-6 md:mb-8 self-center justify-between rounded-xl cursor-pointer p-1 md:mt:10 bg-white">
          <div
            className={`px-2 flex-1 whitespace-nowrap flex items-center justify-center transition-all duration-500 rounded-md  ${
              currentTab === "fiat" ? "bg-[#0eb683] text-white" : ""
            }`}
            onClick={() => setCurrentTab("fiat")}
          >
            Fiat
          </div>
          <div
            className={`px-2 flex-1 whitespace-nowrap flex items-center justify-center transition-all duration-500 rounded-md  ${
              currentTab === "stable" ? "bg-[#0eb683] text-white" : ""
            }`}
            onClick={() => setCurrentTab("stable")}
          >
            Stablecoins
          </div>
        </div>
        {currentTab === "fiat" ? (
          <AddFiatComponent onClick={()=>{}}/>
        ) : (
          <AddStableCoinComponent/>
        )}
      </div>
    </div>
  );
};

export default AddBeneficiary;
