export const purposeOfPayment = [
    {
      value: "Accounting Services",
    },
    {
      value: "Auditing and Bookkeeping Services",
    },
    {
      value: "Advertising Services",
    },
    {
      value: "Advisory and Other Auxiliary Financial Services",
    },
    {
      value: "Aircraft Lease Payment",
    },
    {
      value: "Allowance",
    },
    {
      value: " Architectural Services",
    },
    {
      value: "Asset Management - Cash or Portfolio Savings",
    },
    {
      value: "Automotive Supplier Payments",
    },
    {
      value: "Bandwidth Payment	",
    },
    {
      value: "Building Completion and Finishing Work",
    },
    {
      value: "Building-Cleaning Services",
    },
    {
      value: "Business Travel Allowance",
    },
    {
      value: "Business Travel Allowance",
    },
    {
      value: "Capital Transfer",
    },
    {
      value: "Cargo-Handling Services",
    },
    {
      value: "Charter Fees for Bunkering Vessels",
    },
    {
      value: "Charter Fees for Fishing Vessels",
    },
    {
      value: "Charter Fees for Marine Vessels",
    },
    {
      value: "Circuit-Switched Data Transmission Services",
    },
    {
      value: "Collective-Funds for Community Development Projects",
    },
    {
      value: "Computer and Related Services",
    },
    {
      value: "Conferences, Seminars and Training",
    },
    {
      value: "Construction Work for Buildings",
    },
    {
      value: "Construction Work for Civil Engineering",
    },
    {
      value: "Consultancy Fees",
    },
    {
      value: "Consultancy Services",
    },
    {
      value: "Convention Services",
    },
    {
      value: "Courier Service",
    },
    {
      value: "Course Fees",
    },
    {
      value: "Courses, Membership Subscription and Exam",
    },
    {
      value: "Data Base Services",
    },
    {
      value: "Data Processing Services",
    },
    {
      value: "Demurrage",
    },
    {
      value: "Dividends",
    },
    {
      value: "Donations and Payments to Religious Bodies",
    },
    {
      value: "Donations/Payments to NGOs",
    },
    {
      value: "Electronic Mail",
    },
    {
      value: "Engineering Services",
    },
    {
      value: "Entertainment Services",
    },
    {
      value: "Environmental Services",
    },
    {
      value: "Estacodes",
    },
    {
      value: "Export Proceeds",
    },
    {
      value: "Facsimile Services",
    },
    {
      value: "Financial Leasing",
    },
    {
      value: "Forex Trading",
    },
    {
      value: "Freight Transport Agency Services",
    },
    {
      value: "Freight Transportation",
    },
    {
      value: "FX Trading Income",
    },
    {
      value: "Gifts",
    },
    {
      value: "Guarantees and Commitments",
    },
    {
      value: "Health Related and Social Services",
    },
    {
      value: "Health-Funds Fr Payment of Relatives Hospital Bill",
    },
    {
      value: "Hospital Services",
    },
    {
      value: "Hotels, Restaurants and Catering",
    },
    {
      value: "Installation and Assembly Work",
    },
    {
      value: "Insurance",
    },
    {
      value: "Integrated Engineering Services",
    },
    {
      value: "Interdisciplinary R&D Services ",
    },
    {
      value: "Investigation and Security",
    },
    {
      value: "Judgement Debt",
    },
    {
      value: "Legal Services",
    },
    {
      value: "Life, Accident and Health Insurance Services",
    },
    {
      value: "Loan Repayment",
    },
    {
      value: "Maintenance and Repair of Aircraft",
    },
    {
      value: "Maintenance and Repair of Equipment",
    },
    {
      value: "Maintenance and Repair of Rail Transport",
    },
    {
      value: "Maintenance and Repair of Road Transport",
    },
    {
      value: "Maintenance and Repair of Vessels",
    },
    {
      value: "Maintenance of Fishing/Shipping Vessels",
    },
    {
      value: "Management Consulting Service",
    },
    {
      value: "Management Service Fees",
    },
    {
      value: "Manufacturer Payment",
    },
    {
      value: "Market Research and Public Opinion Polling",
    },
    {
      value: "Medical and Dental Services",
    },
    {
      value: "Medical Treatment",
    },
    {
      value: "Membership Subscriptions",
    },
    {
      value: "Mortgage",
    },
    {
      value: "Mortgage-Funds for Purchase of House",
    },
    {
      value: "Mortgage-Funds for Purchase of Land",
    },
    {
      value: "Motion Picture and Video Tape Production",
    },
    {
      value: "Motion Picture Projection Service",
    },
    {
      value: "News Agency Services",
    },
    {
      value: "Newspaper Delivery Membership",
    },
    {
      value: "Non-Life Insurance Services",
    },
    {
      value: "Others",
    },
    {
      value: "Packet-Switched Data Transmission Services",
    },
    {
      value: "Passenger Transportation",
    },
    {
      value: "Payment for Books, Magazines and Periodicals",
    },
    {
      value: "Payment of Relatives School Fees",
    },
    {
      value: "Pension to Expatriate Staff",
    },
    {
      value: "Personal Home Remittance",
    },
    {
      value: "Personal Travel Allowance",
    },
    {
      value: "Personal Upkeep",
    },
    {
      value: "Photographic Services",
    },
    {
      value: "Pilgrimage",
    },
    {
      value: "Placement and Supply Services of Personnel",
    },
    {
      value: "Postal Services",
    },
    {
      value: "Private Leased Circuit Services",
    },
    {
      value: "Provision and Transfer of Financial Info",
    },
    {
      value: "Pushing and Towing Services",
    },
    {
      value: "R&D Services on Natural Sciences",
    },
    {
      value: "R&D Services on Social Sciences",
    },
    {
      value: "Radio and Television Transmission Services",
    },
    {
      value: "Recreational, Cultural and Sporting Services",
    },
    {
      value: "Refuse Disposal Services",
    },
    {
      value: "Relating to Aircraft",
    },
    {
      value: "Relating to Other Machinery and Equipment",
    },
    {
      value: "Relating to Other Transport Equipment",
    },
    {
      value: "Relating to Ships",
    },
    {
      value: "Religious Offering/Tithe",
    },
    {
      value: "Remittance of Dividend/Profit",
    },
    {
      value: "Remittance of Ticket Sales",
    },
    {
      value: "Rental of Aircraft with Crew",
    },
    {
      value: "Rental of Commercial Vehicles with Operator",
    },
    {
      value: "Rental of Vessels with Crew",
    },
    {
      value: "Rental/Leasing Services Without Operator",
    },
    {
      value: "Repairs and Maintenance of Aircraft",
    },
    {
      value: "Repairs and Maintenance of Marine Vessels",
    },
    {
      value: "Residency Abroad",
    },
    {
      value: "Royalty/Licence/Technical Know-How",
    },
    {
      value: "Salary Payment",
    },
    {
      value: "Sanitation and Similar Services",
    },
    {
      value: "School Fees",
    },
    {
      value: "School Fees, Accommodation and Living Expenses",
    },
    {
      value: "Scientific and Technical Consulting",
    },
    {
      value: "Service Charge for Repair of Machinery",
    },
    {
      value: "Services Auxiliary to All Modes of Transport",
    },
    {
      value: "Services Auxiliary to Insurance",
    },
    {
      value: "Services Incidental to Agriculture, Hunt",
    },
    {
      value: "Services Incidental to Energy Distribution",
    },
    {
      value: "Services Incidental to Fishing",
    },
    {
      value: "Services Incidental to Manufacturing",
    },
    {
      value: "Services Incidental to Mining",
    },
    {
      value: "Services Provided by Medical Professionals",
    },
    {
      value: "Services Provided by Technology Professionals",
    },
    {
      value: "Services Related to Manufacturing Consulting",
    },
    {
      value: "Settlement and Clearing Services for Fintechs",
    },
    {
      value: "Settlement of Bills and Services",
    },
    {
      value: "Settlement of Guarantees",
    },
    {
      value: "Settlement of Performance Bond",
    },
    {
      value: "Social Services",
    },
    {
      value: "Software Implementation Services",
    },
    {
      value: "Software License Payment",
    },
    {
      value: "Sponsorship",
    },
    {
      value: "Sporting and Other Recreational Services",
    },
    {
      value: "Storage and Warehouse Services",
    },
    {
      value: "Subscription for Periodicals or Books",
    },
    {
      value: "Supplier Payments",
    },
    {
      value: "Supporting Services for Air Transport",
    },
    {
      value: "Supporting Services for Maritime Transport",
    },
    {
      value: "Supporting Services for Rail Transport Services",
    },
    {
      value: "Supporting Services for Road Transport Services",
    },
    {
      value: "Surplus on Ticket Sales",
    },
    {
      value: "Taxation Services",
    },
    {
      value: "Technical Services",
    },
    {
      value: "Technical Support Service",
    },
    {
      value: "Technical Testing and Analysis Service",
    },
    {
      value: "Telecommunication Charge",
    },
    {
      value: "Telecommunication Services",
    },
    {
      value: "Telegraph Services",
    },
    {
      value: "Telex Services",
    },
    {
      value: "Tourism and Travel Related Services",
    },
    {
      value: "Tourist Guides Services",
    },
    {
      value: "Trading for Own Account",
    },
    {
      value: "Transfer for Own Use",
    },
    {
      value: "Transfer of Shares",
    },
    {
      value: "Transfer to Related Party",
    },
    {
      value: "Transportation of Fuels",
    },
    {
      value: "Transportation of Other Goods",
    },
    {
      value: "Travel",
    },
    {
      value: "Travel Agencies and Tour Operator Services",
    },
    {
      value: "Tuition",
    },
    {
      value: "Upkeep",
    },
    {
      value: "Upkeep of Child",
    },
    {
      value: "Upkeep of Parent",
    },
    {
      value: "Upkeep of Spouse",
    },
    {
      value: "Urban Planning and Landscape Architecture",
    },
    {
      value: "Utility",
    },
    {
      value: "Voice Telephone Services",
    },
  ].map(({ value }) => ({ value, label: value }));