import React, { useEffect, useMemo, useState } from "react";
import { IOnboardingUser } from "./UserDetails";
import AppSelectInput from "../../../components/base-components/AppSelectInput";
import { useBankList, useResolveBankName, useUpdateUserOnboardingInfo } from "../../../sevices/hooks/users";
import { IBank, TypingDots } from "../ChooseBank";
import InputField from "../comp/InputField";
import Controller from "./Controller";
import { IFormData, useForm } from "../../../context/OnboardingFormContext";
import { useLocation } from "react-router-dom";

const BankDetails = ({
  handleClick,
  currentStep,
  steps,
  setErrMsg,
}: IOnboardingUser) => {
  const getBanks = useBankList();
  //@ts-ignore
  const userData = JSON.parse(localStorage.getItem("user")) || {};
  const resolveName = useResolveBankName();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const user = queryParams.get("user");
  const [loading, setLoading] = useState(false);
  const updateInfo = useUpdateUserOnboardingInfo(user || userData.id);
  const [errorMessage, setErrorMessage] = useState("");

  const { state, dispatch } = useForm();
  // console.log(state, "stte");

  useEffect(() => {
    //  setLoadingMsg(loadingMsg);
    updateInfo
      .mutateAsync({ images: state.selfieData })
      .then((res) => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const [formData, setFormData] = useState({
    bank: {
      label: "",
      value: "",
      bank: {
        anchorId: "",
        bankName: "",
        cbnCode: "",
        createdAt: "",
        deletedAt: "",
        id: "",
        nipCode: "",
        updatedAt: "",
      },
    },
    accountNumber: "",
    accountName: "",
    bankCode: "",
    bankId: "",
  });

  const bankOptions = useMemo(
    () =>
      getBanks?.data?.data?.map((bank: IBank) => ({
        label: bank.bankName,
        value: bank.nipCode,
        bank,
      })),
    [getBanks.data]
  );

  const handleInputChange = (name: string, value: any) => {
    setErrMsg("");
    dispatch({ type: "UPDATE_FIELD", field: name as keyof IFormData, value });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (formData.accountNumber.length === 10 && formData.bank?.bank) {
      const id = formData.bank.bank?.nipCode || "";
      handleInputChange("bankCode", formData.bank.bank?.nipCode);
      handleInputChange("bankId", formData.bank.bank?.anchorId);
      resolveName
        .mutateAsync({
          bankCode: id,
          accountNumber: formData.accountNumber,
        })
        .then((data: any) => {
          handleInputChange(
            "accountName",
            data?.data?.data?.attributes?.accountName
          );
          // setBankCode()
          // setBankId()
          //  setErrorMessage("");
        })
        .catch((error: any) => {
          setErrorMessage("Invalid account details");
        });
    }
  }, [formData.accountNumber, formData.bank]);

  return (
    <div>
      <div className=" pb-4 pt-10">
        <h1 className="text-[24px] leading-[36px] font-bold pb-1 text-[#071827] ">
          Bank Details?
        </h1>
        <p className=" text-[#5C6F7F] text-[16px] font-normal leading-[24px]">
          This is where we would remit commissions earned from your referee’s
          transactions:
        </p>
      </div>
      <div className="md:w-full md:max-w-lg ">
        <form className="bg-none md:bg-white flex flex-col gap-3 p-0 md:p-5 ">
          <div className="relative w-full">
            <AppSelectInput
              options={bankOptions || []}
              label=""
              handleChange={(bank: any) => handleInputChange("bank", bank)}
            />
            {/* <div className="text-red-500 text-xs">{errorMessages.bank}</div> */}
            <span className="text-red-600 absolute left-3 top-3">*</span>
          </div>
          <div className="relative ">
            <InputField
              label="Account Number"
              name="accountNumber"
              type="number"
              required={true}
              maxChar={10}
              value={state.accountNumber}
              onChange={(value: string) =>
                handleInputChange("accountNumber", value)
              }
            />
          </div>
          <div className="text-md font-bold ">
            {resolveName.isLoading ? (
              <TypingDots />
            ) : formData.accountName && formData.accountNumber.length === 10 ? (
              <p className="text-md font-bold text-[#0eb683]">
                {formData.accountName}
              </p>
            ) : (
              errorMessage
            )}
          </div>
        </form>
        <Controller
          nextDisabled={!state.accountName}
          handleClick={handleClick}
          currentStep={currentStep}
          steps={steps}
          setErrMsg={setErrMsg}
        />
      </div>
    </div>
  );
};

export default BankDetails;
