/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext, useRef, SetStateAction } from "react";
import {
  BsCheckCircleFill,
  BsFillCheckCircleFill,
  BsInfoCircle,
} from "react-icons/bs";
import { RiCloseCircleFill } from "react-icons/ri";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OnboardingFooter from "../../components/base-components/OnboardingFooter";
import {
  useIndividualKyc,
  useUpdateUserOnboardingInfo,
  useVerifyAccount,
} from "../../sevices/hooks/users";
import ReactLoading from "react-loading";
import LoadingScreen from "../Notifications/LoadingScreen2";
// import { IUserSignUpData } from "../../types/Users";
import TabContext from "../../store/context/TabProvider";
import Input from "./comp/Input";
import { FiCalendar } from "react-icons/fi";
import DragDropComponent from "../../components/base-components/DragDropComponent";
import AppSelectInput from "../../components/base-components/AppSelectInput";
import CustomSelect from "../../components/base-components/CustomSelect";
import SmartCameraWeb from "../../components/SmartCameraWeb";
import SelfiePopUp from "./comp/SelfiePopUp";
import { useShowSelfie } from "../../store/useShowSelfie";
import { MdOutlineClose } from "react-icons/md";
import { useGetCountry, useGetState } from "../../sevices/hooks/tags";
import { BiUserCheck } from "react-icons/bi";
import useQueryParams from "../../hooks/useQueryParams";
import { FaGenderless } from "react-icons/fa";
import { formatPhoneNumber } from "./refresh-initiative/Controller";

// import LoadingScreen from "../Notifications/LoadingScreen";

export type IDOptionType = {
  id?: number;
  label: string;
  slug: any;
  bank?: any;
} | null;

export const IDTypeOptions = [
  // {
  //   id: 1,
  //   label: "International Passport",
  //   slug: "international-passport",
  // },
  {
    id: 2,
    label: "National Identity Slip",
    slug: "NIN_SLIP",
  },
  {
    id: 3,
    label: "National Identification Card",
    slug: "NIN_V2",
  },
  {
    id: 4,
    label: "Driver's License",
    slug: "DRIVERS_LICENSE",
  },
  {
    id: 5,
    label: "Voter's Card",
    slug: "VOTER_ID",
  },
];

export const GenderData = [
  {
    id: 0,
    label: "Male",
    slug: "M",
  },
  {
    id: 1,
    label: "Female",
    slug: "F",
  },
];

const IndividualUser = ({
  getDetails,
  email,
  backFn,
  nextFn,
  userDetail,
}: {
  getDetails: (data: any, isValid: boolean) => void;
  email: string;
  backFn: () => void;
  nextFn: () => void;
  userDetail: any;
}) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [bvn, setBvn] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [IDType, setIDType] = useState<IDOptionType>(null);
  const [middleName, setMiddleName] = useState("");
  const [country, setCountry] = useState<IDOptionType>(null);
  const [state, setState] = useState<IDOptionType>(null);
  const [postCode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [idFile, setIdFile] = useState<string | null>(null);
  const [selfieData, setSelfieData] = useState([]);
  const [fileExt, setFileExt] = useState("");
  const [userId, setUserId] = useState("");
  const [loadingMsg, setLoadingMsg] = useState("Validating your details...");
  //@ts-ignore
  const { setBusiness, business } = useContext(TabContext);
  const setSelfieModal = useShowSelfie((state) => state.setSelfieModal);
  const showSelfieModal = useShowSelfie((state) => state.showSelfieModal);
  const [gender, setGender] = useState<IDOptionType>(null);
  const [idNumber, setIdNumber] = useState("");
  const { code, token } = useQueryParams(["token", "code"]);
  const [userData, setUserData] = useState(null);

  // const updateInfo = useUpdateUserOnboardingInfo(userId);

  const [errorMessages, setErrorMessages] = useState({
    firstname: "",
    lastname: "",
    middleName: "",
    dob: "",
    phone: "",
    idNumber: "",
    bvn: "",
    // email: "",
    gender: "",
    address1: "",
    // address2: "",
    country: "",
    state: "",
    city: "",
    postCode: "",
    IDType: "",
    // idDocUrl: "",
    selfieData: "",
  });

  const isFormValid = () => {
    setErrorMessage("");
    const errors = {
      firstname: "",
      lastname: "",
      middleName: "",
      dob: "",
      phone: "",
      bvn: "",
      // email: "",
      gender: "",
      address1: "",
      // address2: "",
      country: "",
      state: "",
      city: "",
      postCode: "",
      IDType: "",
      selfieData: "",
      idNumber: "",
    };

    if (!firstname?.trim()) {
      errors.firstname = "First Name is required";
    }
    if (!lastname?.trim()) {
      errors.lastname = "Last Name is required";
    }
    if (!dob?.trim()) {
      errors.dob = "Date of Birth is required";
    }
    if (!middleName?.trim()) {
      errors.middleName = "Middle Name is required";
    }
    if (!phone) {
      errors.phone = "Phone Number is required";
    }
    if (!bvn?.trim()) {
      errors.bvn = "BVN is required";
    }
    // if (!email?.trim()) {
    //   errors.email = "Email is required";
    // }
    if (!gender) {
      errors.gender = "Gender is required";
    }
    if (!address1?.trim()) {
      errors.address1 = "Address Line 1 is required";
    }

    if (!country) {
      errors.country = "Country is required";
    }
    if (!state) {
      errors.state = "State is required";
    }
    if (!city?.trim()) {
      errors.city = "City is required";
    }
    if (!postCode?.trim()) {
      errors.postCode = "Post Code is required";
    }
    if (!IDType) {
      errors.IDType = "ID type is required";
    }

    if (selfieData.length === 0) {
      //@ts-ignore
      errors.selfieData = "Selfie is required";
    }

    if (!idNumber?.trim()) {
      errors.idNumber = "ID Number is required";
    }

    setErrorMessages(errors);

    return Object.values(errors).every((error) => error === "");
  };

  // useEffect(() => {
  //   isFormValid();
  // }, [gender, state, country, selfieData, IDType, phone]);

  const Icountry = useGetCountry();
  const states = useGetState();

  const countryOptions =
    Icountry?.data?.data.data.map(
      (
        {
          countryCode,
          name,
          index,
        }: { countryCode: string; name: string; index: number },
        id: number
      ) => ({
        label: name,
        slug: countryCode,
        id,
      })
    ) || [];

  const stateOptions =
    states?.data?.data.data.map(
      ({ name, index }: { name: string; index: number }, id: number) => ({
        label: name,
        slug: name,
        id,
      })
    ) || [];

  const kyc = useVerifyAccount();
  const formatDate = (dob: string) => {
    const rawDate = new Date(dob);
    return `${rawDate.getDate().toString().padStart(2, "0")}-${(
      rawDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${rawDate.getFullYear()}`;
  };

  const { accountType } = userDetail;

  const handleInputChange = (
    fieldName: string,
    value: SetStateAction<string>
  ) => {
    setErrorMessage("");
    //@ts-ignore
    if (errorMessages[fieldName]) {
      setErrorMessages({ ...errorMessages, [fieldName]: "" });
    }

    switch (fieldName) {
      case "firstname":
        setFirstName(value);
        break;
      case "lastname":
        setLastName(value);
        break;
      case "dob":
        setDob(value);
        break;
      case "middleName":
        setMiddleName(value);
        break;
      // case "phone":
      //   setPhone(value);
      //   break;
      case "bvn":
        setBvn(value);
        break;
      //  case "email":
      //    setDob(value);
      //    break;
      // case "gender":
      //   setDob(value);
      //   break;
      case "address1":
        setAddress1(value);
        break;
      //  case "country":
      //    setDob(value);
      //    break;
      case "city":
        setCity(value);
        break;
      case "postCode":
        setPostcode(value);
        break;
      case "idNumber":
        setIdNumber(value);
        break;
      //  case "idDocUrl":
      //    setDob(value);
      //    break;
      //  case "selfieData":
      //    setDob(value);
      //    break;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function getEXT(inputString: string): string {
    const slashIndex = inputString.indexOf("/");
    if (slashIndex !== -1) {
      // Extract the characters after the slash and prefix with a dot
      return "." + inputString.substring(slashIndex + 1);
    } else {
      // If no slash is found, return an empty string or handle it as needed
      return "";
    }
  }

  const getDocs = (files: any) => {
    if (files) {
      const reader = new FileReader();
      const ext = getEXT(files.type);

      reader.onload = (e) => {
        if (e.target) {
          setFileExt(ext);
          const base64Data = e.target.result as string;
          setIdFile(base64Data);
        }
      };

      reader.readAsDataURL(files);
    }
  };

  const submitKyc = async () => {
    if (userData !== null) {
      getDetails(
        {
          //@ts-ignore
          userID: userData.id,
        },
        true
      );

      nextFn();
    } else {
      if (isFormValid()) {
        setLoading(true);

        try {
          const { data } = await kyc.mutateAsync({
            idNumber: idNumber,
            idType: IDType?.slug,
            middleName: middleName,
            refereeCode: code,
            accountType: accountType,
            individual: {
              firstName: firstname,
              lastName: lastname,
              email: email,
              phoneNumber: formatPhoneNumber(phone),
              country: country?.slug,
              city: city,
              dateOfBirth: dob,
              bvn: bvn,
              addressLine_1: address1,
              addressLine_2: address2,
              state: state?.slug,
              postalCode: postCode,
              gender: gender?.slug === "M" ? "Male" : "Female",
              type: "IndividualCustomer",
            },
          });
          // console.log(data, "this data");
          if (data.user) {
            const { id } = data.user;

            setUserId(id);
            // Pass idDocUrl, id_type, selfieUrl to getDetails function
            getDetails(
              {
                firstname,
                lastname,
                dob,
                phone,
                bvn,
                email,
                gender: gender?.slug,
                address1,
                address2,
                country: country?.slug,
                state: state?.slug,
                city,
                postCode,
                selfieData,
                refereeCode: code,
                id,
                // selfieData,
              },
              isFormValid()
            );
            nextFn();
          } else if (data.data.success.message) {
            setErrorMessage(data.data.success.message);
          }
        } catch (error: any) {
          // console.log(error)
          setLoading(false);
          setErrorMessage(error?.response.data);
        }
      }
    }
  };

  useEffect(() => {
    const data = localStorage.getItem("user_data");
    // console.log(data,"vnn")
    if (data !== null) {
      try {
        const userData = JSON.parse(data);
        setUserData(userData);
        if (userData !== null) {
          getDetails(
            {
              //@ts-ignore
              userID: userData.id,
            },
            true
          );
          nextFn();
        }
      } catch (error) {}
    }
  }, []);

  return (
    <LoadingScreen showLoading={loading} msg={loadingMsg} bg="#f2faf799">
      <div className=" p-5 w-full ">
        <div className="text">
          <h1 className="text-[24px] leading-[36px] font-bold pb-1 text-[#071827] ">
            User Details
          </h1>
          <p className="text-[16px] leading-[24px] font-normal text-[#5C6F7F]">
            Complete basic details about yourself:
          </p>
        </div>

        <div className=" bg-white xxs:p-3 md:p-7 flex flex-col gap-5 rounded-md mt-5 md:max-w-lg">
          {errorMessage && (
            <div className="error text-[#e01a00] text-sm text-center bg-rose-200 border-dashed border-2 border-[#a10] rounded-md py-2">
              {errorMessage}
            </div>
          )}
          <div className="relative ">
            <Input
              label="First Name"
              name="firstname"
              type="text"
              required={true}
              value={firstname}
              errorMessages={errorMessages}
              onChange={(evt) => handleInputChange("firstname", evt)}
            />
            <div className="text-red-500 text-xs">
              {errorMessages.firstname}
            </div>
            <div className="flex items-center gap-2  ">
              <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]  ">
                Input your first name exactly as it appears on your bank account
                details.
              </small>
            </div>
          </div>
          <div className="relative ">
            <Input
              label="Middle Name"
              name="middleName"
              type="text"
              required={true}
              value={middleName}
              errorMessages={errorMessages}
              onChange={(evt) => handleInputChange("middleName", evt)}
            />
            <div className="text-red-500 text-xs">
              {errorMessages.middleName}
            </div>
          </div>
          <div className="relative ">
            <Input
              label="Last Name"
              name="lastname"
              type="text"
              required={true}
              value={lastname}
              errorMessages={errorMessages}
              onChange={(evt) => handleInputChange("lastname", evt)}
            />
            <div className="text-red-500 text-xs">{errorMessages.lastname}</div>
            <div className="flex items-center gap-2  ">
              <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]  ">
                Input your last name exactly as it appears on your bank account
                details.
              </small>
            </div>
          </div>

          <div className="relative w-full">
            <small className="text-[#0B0C0E] text-sm font-normal ">
              <span className="text-[#E01A00]">*</span>
              Gender{" "}
            </small>
            <CustomSelect
              selectedOption={gender?.slug}
              setSelectOption={setGender}
              placeholder={"Choose Gender"}
              options={GenderData || []}
              name={"gender"}
              errors={errorMessages}
            />
            <div className="text-red-500 text-xs">{errorMessages.gender}</div>
          </div>

          <div className="relative mt-2">
            <Input
              label="Date of Birth"
              type="date"
              name="dob"
              required={true}
              value={dob}
              errorMessages={errorMessages}
              onChange={(evt) => handleInputChange("dob", evt)}
            />

            <FiCalendar
              className="placeholder-icon absolute right-2 text-[#0eb683]"
              style={{
                top: "35%",
                // left: "12px",
                transform: "translateY(-50%)",
              }}
              size={22}
            />
            <div className="text-red-500 text-xs">{errorMessages.dob}</div>
            <div className="flex items-center gap-2  ">
              <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]  ">
                Enter your correct{" "}
                <span className="text-[#0eb683]"> Date of Birth</span>
              </small>
            </div>
          </div>
          <div className="relative mt-2">
            <ReactPhoneInput
              containerStyle={{
                border: `0.5px solid ${
                  errorMessages.phone ? "#E01A00" : "#CBE0D6"
                } `,
                padding: "5px",
                backgroundColor: isMobile ? "#fff" : "#F2FAF7",
                borderRadius: "8px",
                outline: "#0eb683",
              }}
              buttonStyle={{
                backgroundColor: "transparent",
                border: "none",
                borderRight: "1px solid #0EB683",
                margin: "10px 0px",
                padding: "0px 11px",
              }}
              inputStyle={{
                width: "100%",
                border: "none",
                padding: "0px 0px 0px 72px",
                backgroundColor: isMobile ? "#fff" : "#F2FAF7",
                height: 40,
              }}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
              }}
              enableAreaCodes={true}
              country="ng"
              placeholder="Enter phone number"
              onChange={(data) => setPhone(data)}
            />
            {phone.trim().length > 8 && (
              <BsCheckCircleFill
                size={20}
                className="text-[#0eb683] absolute top-[35%] -translate-y-5 right-3"
              />
            )}
            <div className="text-red-500 text-xs">{errorMessages.phone}</div>
            <div className="flex items-center gap-2  ">
              <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]  ">
                This should be the number linked to your BVN. You will be
                required to confirm this via a One-Time-Pin (OTP)
              </small>
            </div>
          </div>

          <div className="relative mt-5">
            <Input
              label="Email"
              type="email"
              name="email"
              required={true}
              value={email}
              disabled={true}
              onChange={() => {}}
            />
            {(email?.trim()?.length || 0) > 1 && (
              <BsCheckCircleFill
                size={20}
                className="text-[#0eb683] absolute top-[50%] -translate-y-1/2 right-3"
              />
            )}
          </div>

          <div className="relative">
            <Input
              label="BVN"
              type="number"
              name="bvn"
              required={true}
              maxChar={11}
              value={bvn}
              errorMessages={errorMessages}
              onChange={(evt) => handleInputChange("bvn", evt)}
            />
            {bvn.trim().length === 11 && (
              <BsCheckCircleFill
                size={20}
                className="text-[#0eb683] absolute top-[40%] -translate-y-1/2 right-2"
              />
            )}

            <div className="text-red-500 text-xs">{errorMessages.bvn}</div>
            <div className="flex items-center gap-2  ">
              <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]  ">
                We use your{" "}
                <span className="text-[#0eb683]">
                  {" "}
                  Bank Verification Number
                </span>{" "}
                <span className="font-bold underline">only</span> for identity
                verification
              </small>
            </div>
          </div>
          <div className="relative mt-2">
            <Input
              label="Address Line 1"
              type="text"
              name="address1"
              required={true}
              value={address1}
              errorMessages={errorMessages}
              onChange={(evt) => handleInputChange("address1", evt)}
            />
            <div className="text-red-500 text-xs">{errorMessages.address1}</div>
          </div>
          <div className="relative">
            <Input
              label="Address Line 2"
              type="text"
              name="addressLine2"
              required={false}
              value={address2}
              onChange={(evt) => setAddress2(evt)}
            />
          </div>
          <div className="flex items-center justify-between gap-4">
            <div className="mb-3 w-[50%] relative">
              <small className="text-[#0B0C0E] text-sm font-normal ">
                <span className="text-[#E01A00]">*</span>
                Country{" "}
              </small>
              <CustomSelect
                selectedOption={country?.slug}
                setSelectOption={setCountry}
                placeholder={"Select Country"}
                options={countryOptions || []}
                errors={errorMessages}
                name={"country"}
                defaultValue={country?.slug}
              />
              <div className="text-red-500 text-xs">
                {errorMessages.country}
              </div>
            </div>

            <div className="mb-3 w-[50%] relative">
              <small className="text-[#0B0C0E] text-sm font-normal ">
                <span className="text-[#E01A00]">*</span>
                State{" "}
              </small>

              <CustomSelect
                selectedOption={state?.slug}
                setSelectOption={setState}
                placeholder={"Select City"}
                options={stateOptions || []}
                errors={errorMessages}
                name={"state"}
              />
              <div className="text-red-500 text-xs">{errorMessages.state}</div>
            </div>
          </div>
          <div className="relative w-full">
            <Input
              label="City"
              type="text"
              name="city"
              required={true}
              value={city}
              errorMessages={errorMessages}
              onChange={(evt) => handleInputChange("city", evt)}
            />
            <div className="text-red-500 text-xs">{errorMessages.city}</div>
          </div>
          <div className="relative w-full">
            <Input
              label="Post Code"
              type="text"
              name="postCode"
              required={true}
              errorMessages={errorMessages}
              onChange={(evt) => handleInputChange("postCode", evt)}
            />
            <div className="text-red-500 text-xs">{errorMessages.postCode}</div>
          </div>
          <div className="relative w-full">
            <small className="text-[#0B0C0E] text-sm font-normal ">
              <span className="text-[#E01A00]">*</span>
              Upload means of identification{" "}
            </small>
            <CustomSelect
              selectedOption={IDType?.slug}
              setSelectOption={setIDType}
              placeholder={"ID Type"}
              name={"IDType"}
              errors={errorMessages}
              options={IDTypeOptions || []}
            />
            <span className="text-[#E01A00] absolute left-2 top-9">*</span>
            <div className="text-red-500 text-xs">{errorMessages.IDType}</div>
          </div>

          <div className="relative">
            <Input
              label="Selected ID Number"
              type="text"
              name="idNumber"
              required={true}
              value={idNumber}
              errorMessages={errorMessages}
              onChange={(evt) => handleInputChange("idNumber", evt)}
            />
            <div className="text-red-500 text-xs">{errorMessages.idNumber}</div>
            <div className="flex items-center gap-2  ">
              <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]  ">
                Please enter ID Number{" "}
                <span className="text-[#0eb683]"> Associated</span>{" "}
                <span className="font-bold underline"></span> with the ID type
                you selected
              </small>
            </div>
          </div>
          <div>
            <small className="text-[#0B0C0E] text-sm font-normal ">
              <span className="text-[#E01A00]">*</span>
              Selfie Verification{" "}
            </small>
            <div
              onClick={() => setSelfieModal(true)}
              className="pt-3.5 pb-2.5 block w-full px-2.5 mt-0 bg-[#F2FAF7] border appearance-none hover:outline-none outline-[#0eb683] hover:ring-0 hover:border-[#0eb683] border-gray-200 rounded-[4px] cursor-pointer text-center "
            >
              {selfieData.length !== 0 ? (
                <span className="text-[#0EB683] flex items-center justify-center gap-2 ">
                  <BiUserCheck size={20} /> <span>Selfie Added</span>{" "}
                </span>
              ) : (
                <span className="text-[#0EB683] hover:underline">
                  Tap to begin
                </span>
              )}
            </div>
            <div className="text-red-500 text-xs">
              {errorMessages.selfieData}
            </div>
            <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]  ">
              This is required to verify the ID number provided.
            </small>
          </div>
        </div>
        <SelfiePopUp>
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-base md:text-xl text-[#333333] font-bold ">
              Camera Access Request
            </h1>
            <div
              onClick={() => setSelfieModal(false)}
              className="cursor-pointer  hover:text-[#E01A00] flex items-center "
            >
              <MdOutlineClose size={25} />
            </div>
          </div>

          <div className=" bg-[#F2FAF7] border-2 rounded-md border-[#0eb683] h-full">
            <SmartCameraWeb setSelfieData={setSelfieData}></SmartCameraWeb>
          </div>
        </SelfiePopUp>
        <OnboardingFooter
          backFn={backFn}
          nextFn={submitKyc}
          // nextDisabled={!isValid}
        />
      </div>
    </LoadingScreen>
  );
};

export default IndividualUser;
