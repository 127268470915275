import React, { Dispatch, createContext, useState } from "react";
const TabContext = createContext({});
interface IProps {
  children: React.ReactNode;
}
interface TabState {
  tab: string;
  setTab: React.Dispatch<React.SetStateAction<string>>;
  business: string;
  busPhone: string;
  busType: string;
  busInc: string;
  setBusPhone: React.Dispatch<React.SetStateAction<string>>;
  setBusType: React.Dispatch<React.SetStateAction<string>>;
  setBusInc: React.Dispatch<React.SetStateAction<string>>;
  setBusiness: React.Dispatch<React.SetStateAction<string>>;
}
export const TabProvider = ({ children }: IProps) => {
  const [tab, setTab] = useState("buy");
  const [business, setBusiness] = useState("");
  const [busPhone, setBusPhone] = useState("");
  const [busType, setBusType] = useState("");
  const [busInc, setBusInc] = useState("");



  return (
    <TabContext.Provider value={{ tab, setTab, business, setBusiness, busPhone, setBusPhone, setBusInc, busInc, setBusType, busType }}>
      {children}
    </TabContext.Provider>
  );
};

export default TabContext;
