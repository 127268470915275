/* eslint-disable no-dupe-keys */
import React, { useEffect, useMemo, useState } from "react";
import hamburger from "../../assets/misc/hamburger-button.svg";
import logo from "../../assets/misc/FaviconAsset_ttfcx.svg";
import { useSidebarState } from "../../store";
import { TabPanel, useTabs } from "../../components/widgets/TabWidget";
import { TabSelector } from "../../components/widgets/TabSelector";
import { motion, AnimatePresence } from "framer-motion";
import AppSelectInput from "../../components/base-components/AppSelectInput";
import { AiOutlineClose } from "react-icons/ai";
import PasswordInput from "../../components/base-components/PasswordInput";
import brandLogo from "../../assets/misc/brandLogo.png";
import FailedIcon from "../../assets/iconsComponents/FailedIcon";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";
import {
  useBankList,
  useCheckEmail,
  useInvitees,
  useResetPassword,
  useResolveBankName,
  useUpdateBank,
  useUsers,
  useUsersInvitation,
} from "../../sevices/hooks/users";
import ReactLoading from "react-loading";
import { FaRegCopy, FaSpinner } from "react-icons/fa";
import InviteesCard from "../../components/settings-component/InviteesCard";
import { BiErrorAlt } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { IBank } from "./withdrawals_screen/Withdraw";
import { TypingDots } from "../onboarding/ChooseBank";

export const restrictToTenDigitRegExp = "^[0-9]{10}$";

const Settings = () => {
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  const [invitationSuccess, setInvitationSuccess] = useState(false);
  const [invitationFailed, setInvitationFailed] = useState(false);
  const usersInvitation = useUsersInvitation();

  const [selectedTab, setSelectedTab] = useTabs([
    "profile",
    "bank",
    "security",
    "referrals",
  ]);
  const [disabled, setDisabled] = useState(true);
  const [fields, setFields] = useState({
    email: "",
    lastName: "",
    firstName: "",
  });

  const [editBank, setEditBank] = useState(false);
  const onFieldChange = ({ name, value }: { value: any; name: string }) => {
    setFields({ ...fields, [name]: value });
  };

  //password
  const resetPassword = useResetPassword();
  const [code, setCode] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [reConfirmPassword, setReConfirmPassword] = useState("");
  const [passwordValid, setpasswordValid] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userBank, setUserBank] = useState("");
  const [newPassword, setNewPassword] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [copyStatus, setCopyStatus] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [response, setResponse] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [buffer, setBuffer] = useState(false);
  const invitedUser = useInvitees(
    //@ts-ignore
    localStorage.getItem("user_id")
  );
  // const [errorMessage, setErrorMessage] = useState("");
  const [emailExist, setEmailExist] = useState(false);
  const [msgErr, setMsgErr] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateFailed, setUpdateFailed] = useState(false);
  const [testValue, setTestValue] = useState(false);
  const { data, isLoading } = invitedUser;
  const checkEmail = useCheckEmail();
    const [accountName, setAccountName] = useState("");
//  const [errorMessage, setErrorMessage] = useState("");


  const hasData = data?.data?.data.length;

  const onSubmitPassword = (e: any) => {
    //  e.preventDefault()
    setShowModal(true);
    resetPassword
      .mutateAsync({
        newPassword: newPassword.password,
        email: email,
        verificationCode: code,
      })
      .then((res) => {
        setResponse(true);

        if (res.status >= 200 && res.status <= 299) {
          setSuccess(true);
        } else {
          setSuccess(false);
        }
        setResponseMsg(res.data);
      })
      .catch((e) => {
        setResponse(true);
        setSuccess(false);
        setResponseMsg(e.response.data);
      });
  };

  const copyToClipBoard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyStatus("copied");
    let timeout = setTimeout(() => {
      setCopyStatus("");
      clearTimeout(timeout);
    }, 1500);
  };

  useEffect(() => {
    // Check for password validity
    if (
      newPassword.password === newPassword.confirmPassword &&
      newPassword.password.length > 5
    ) {
      setPasswordMatch(true);
    } else {
      let regularExpression =
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;
      setpasswordValid(regularExpression.test(newPassword.password));

      setPasswordMatch(false);
    }
  }, [newPassword.password, newPassword.confirmPassword]);

  const getUserDetails = useUsers(
    //@ts-ignore
    localStorage.getItem("user_id"),
    localStorage.getItem("accessToken")
  );
  const validateEmail = () => {
    setSpinner(true);
    checkEmail
      .mutateAsync({ email: fields.email })
      .then((res) => {
        if (res.data === true) {
          setSpinner(false);
          setEmailExist(true);
        } else if (res.data === false) {
          inviteUser();
        }
      })
      .catch((e) => {});
  };

  const inviteUser = () => {
    setSpinner(true);
    usersInvitation
      .mutateAsync(fields)
      .then((res: any) => {
        if (res.status === 200) {
          setSpinner(false);
          setInvitationSuccess(true);
        }
      })
      .catch((e: any) => {
        if (e) {
          setSpinner(false);
          setInvitationFailed(true);
        }
      });
  };
  useEffect(() => {
    setCode(getUserDetails?.data?.data?.verificationCode);
    setEmail(getUserDetails?.data?.data?.email);
  }, [getUserDetails]);

  const invitee = data?.data?.data?.length;

  const validateField = () => {
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if (
      regEx.test(fields.email) &&
      fields.firstName !== "" &&
      fields.lastName !== ""
    ) {
      setDisabled(false);
    } else if (
      (!regEx.test(fields.email) && fields.email === "") ||
      (fields.email === "" && fields.firstName === "" && fields.lastName)
    ) {
      setDisabled(true);
    }
  };

  const clearInput = () => {
    setConfirmPassword("");
    setReConfirmPassword("");
    setPassword("");
    setSuccess(false);
  };

  const [bank, setBank] = useState<{
    label?: string;
    value?: string;
    bank?: IBank;
  }>({});

  let bankId = bank?.bank?.id;

  const getBanks = useBankList();
  const resolveName = useResolveBankName();

  const bankOptions = useMemo(
    () =>
      getBanks?.data?.data?.map((bank: IBank) => ({
        label: bank.bankName,
        value: bank.nipCode,
        bank,
      })),
    [getBanks.data]
  );

  useEffect(() => {
    if (accountNumber.length === 10 && bank.bank) {
      const id = bank.bank?.nipCode || "";
      resolveName
        .mutateAsync({
          bankCode: id,
          accountNumber: accountNumber,
        })
        .then((data: any) => {
             setAccountName(data?.data?.data?.attributes?.accountName);
          setDisabled(!disabled);

          setErrorMessage("");
        })
        .catch((error: any) => {
          setErrorMessage(error.response.data);
        });
    }
  }, [accountNumber, bank]);
  //@ts-ignore
  // const accountName = resolveName.result?.account_name;
  // const [accName, setaccName] = useState(accountName);

  const toggleSideBarOpen = useSidebarState((state) => state.toggleSidebar);

  const closeModal = () => {
    setEditBank(false);
  };

  const openModal = () => {
    setEditBank(true);
  };

  const updateBank = useUpdateBank();

  const updateAccDetails = () => {
    setBuffer(true);
    updateBank
      .mutateAsync({
        accountNumber,
        //@ts-ignore
        bankId,
      })
      .then((res) => {
        setEditBank(false);
        setBuffer(false);
        getUserDetails.refetch();
        setUpdateSuccess(true);
      })
      .catch((e) => {
        setEditBank(false);
        setBuffer(false);
        setUpdateFailed(true);
      });
  };

  const close = () => {
    setShowInviteUserModal(false);
    setFields({
      email: "",
      lastName: "",
      firstName: "",
    });
  };

  return (
    <>
      {/* <LoadingScreen showLoading={!!refetchDetails} msg={"Updating Details..."}/>  */}
      <div className="h-full md:h-full w-full bg-[#fff] md:bg-[#f7fbfb]  md:pl-8 p-5 ">
        <div className="pr-5 pt-5 md:pt-0 md:pr-0">
          <div className="w-16 ml-auto block md:hidden ">
            <img src={logo} alt="" />
          </div>
          <div className="menu flex gap-3 pl-5 md:pl-0  flex-col ">
            <button className="md:hidden " onClick={() => toggleSideBarOpen()}>
              <img src={hamburger} alt="" className="w-10" />
            </button>
            <h1 className="font-extrabold text-2xl   md:pl-0 text-[#071827] pb-3">
              Settings
            </h1>
          </div>
        </div>
        <nav className=" border-b-2 border-[#E8E9EB] px-5 md:px-0  pb-4 flex gap-6 justify-between md:justify-start mr-8 ">
          <TabSelector
            className={` cursor-pointer relative bg-transparent text-[#5C6F7F] font-bold text-md ${
              selectedTab === "profile"
                ? "text-[#0eb684] before:block before:w-full before:h-[2px] before:bg-[#0eb683] before:absolute before:bottom-[-18px] before:transition-all "
                : ""
            } `}
            isActive={selectedTab === "profile"}
            onClick={() => setSelectedTab("profile")}
          >
            Profile
          </TabSelector>

          <TabSelector
            className={` cursor-pointer relative bg-transparent text-[#5C6F7F] font-bold text-md  ${
              selectedTab === "bank"
                ? "text-[#0eb684] before:block before:w-full before:h-[2px] before:bg-[#0eb683] before:absolute before:bottom-[-18px] before:transition-all"
                : ""
            } `}
            isActive={selectedTab === "bank"}
            onClick={() => setSelectedTab("bank")}
          >
            Bank
          </TabSelector>
          <TabSelector
            className={` cursor-pointer relative bg-transparent text-[#5C6F7F] font-bold text-md ${
              selectedTab === "security"
                ? "text-[#0eb684] before:block before:w-full before:h-[2px] before:bg-[#0eb683] before:absolute before:bottom-[-18px] before:transition-all"
                : ""
            } `}
            isActive={selectedTab === "security"}
            onClick={() => setSelectedTab("security")}
          >
            Security
          </TabSelector>
          <TabSelector
            className={` cursor-pointer relative bg-transparent text-[#5C6F7F] font-bold text-md flex items-center justify-center gap-1 ${
              selectedTab === "referrals"
                ? "text-[#0eb684] before:block before:w-full before:h-[2px] before:bg-[#0eb683] before:absolute before:bottom-[-18px] before:transition-all"
                : ""
            } `}
            isActive={selectedTab === "referrals"}
            onClick={() => setSelectedTab("referrals")}
          >
            <span>Referrals</span>
            {/* {ivitee < 0 ? (""):(
          <div className="bg-[#FF9636] h-5 px-3  w-fit  text-[#fff] font-bold flex items-center justify-center rounded-md  top-0 right-[-36px]" >{ivitee}</div>)
          } */}
          </TabSelector>
        </nav>
        <div className="panel bg-[#f7fbfb] py-4 px-4 md:px-0">
          <TabPanel
            hidden={selectedTab !== "profile"}
            className="flex flex-col gap-3 mt-5"
          >
            {getUserDetails?.data?.data?.accountType === "private" ? (
              <div className="card bg-white rounded-xl w-full md:max-w-2xl h-fit px-7 py-5 flex flex-col gap-4">
                <div className="flex items-center justify-between mb-2">
                  <h1 className="text-[#071827] font-semibold text-xl">
                    Personal Details
                  </h1>
                </div>
                <div className="name">
                  <h1 className="text-[#5C6F7F] text-base font-semibold">
                    FULL NAME
                  </h1>
                  <p className="text-[#071827]">
                    {getUserDetails?.data?.data?.firstName}{" "}
                    {getUserDetails?.data?.data?.lastName}
                  </p>
                </div>
                <div className="email">
                  <h1 className="text-[#5C6F7F] text-base font-semibold">
                    EMAIL
                  </h1>
                  <p className="text-[#071827]">
                    {getUserDetails?.data?.data?.email}
                  </p>
                </div>
                <div className="flex flex-col md:flex-row justify-between md:items-center">
                  <div className="phone mb-5 md:mb-0 ">
                    <h1 className="text-[#5C6F7F] text-base font-semibold">
                      PHONE NUMBER
                    </h1>
                    <p className="text-[#071827]">
                      {getUserDetails?.data?.data?.phoneNumber}
                    </p>
                  </div>
                  <button className=" text-[#0eb683] self-end border rounded-md border-[#0eb683] text-sm font-semibold w-48 md:w-52 text-center md:px-4 py-2  cursor-pointer transition-all active:scale-90">
                    Contact Support to Edit
                  </button>
                </div>
              </div>
            ) : (
              <div className="card bg-white rounded-xl md: max-w-2xl w-full h-fit px-7 py-5 flex flex-col gap-4">
                <div className="flex items-center justify-between mb-2">
                  <h1 className="text-[#071827] font-semibold">
                    Business Details
                  </h1>
                </div>
                <div className="name">
                  <h1 className="text-[#5C6F7F] text-base font-semibold">
                    BUSINESS NAME
                  </h1>
                  <p className="text-[#071827]">
                    {getUserDetails?.data?.data?.businessName
                      ?.charAt(0)
                      ?.toUpperCase() +
                      getUserDetails?.data?.data?.businessName?.slice(1)}
                  </p>
                </div>
                <div className="email">
                  <h1 className="text-[#5C6F7F] text-base font-semibold">
                    EMAIL
                  </h1>
                  <p className="text-[#071827]">
                    {getUserDetails?.data?.data?.email}
                  </p>
                </div>

                <div className="flex justify-between items-center">
                  <div className="phone">
                    <h1 className="text-[#5C6F7F] text-base font-semibold">
                      {" "}
                      BUSINESS PHONE{" "}
                    </h1>
                    <p className="text-[#071827]">
                      {getUserDetails?.data?.data?.businessPhoneNumber}
                    </p>
                  </div>
                  <p className=" text-[#0eb683] border rounded-md border-[#0eb683] text-sm font-semibold  w-48 md:w-52 text-center md:px-4 py-2 cursor-pointer">
                    Contact Support to Edit
                  </p>
                </div>
              </div>
            )}
          </TabPanel>
          <TabPanel hidden={selectedTab !== "bank"}>
            <div className="card bg-white rounded-xl md:max-w-2xl w-full mt-5 h-fit px-7 py-5 flex flex-col gap-4">
              <div className="flex items-center justify-between mb-2">
                <h1 className="text-[#071827] font-semibold text-xl">Bank</h1>
                <p
                  className=" text-[#0eb683] border rounded-md border-[#0eb683] text-sm font-semibold px-9 py-2 cursor-pointer transition-all active:scale-90"
                  onClick={openModal}
                >
                  Edit
                </p>
              </div>
              <div className="name">
                <h1 className="text-[#5C6F7F] text-base font-semibold">
                  BANK NAME
                </h1>
                <p className="text-[#071827]">
                  {getUserDetails?.data?.data?.bankName}
                </p>
              </div>
              <div className="email">
                <h1 className="text-[#5C6F7F] text-base font-semibold">
                  ACCOUNT NUMBER
                </h1>
                <p className="text-[#071827]">
                  {getUserDetails?.data?.data?.userAccountNumber}
                </p>
              </div>
              <div className="name">
                <h1 className="text-[#5C6F7F] text-base font-semibold">
                  {" "}
                  ACCOUNT NAME
                </h1>
                <p className="text-[#071827]">
                  {getUserDetails?.data?.data?.ngnLBPAccountName}
                </p>
              </div>
            </div>
            {editBank && (
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    // delay: 0.3,
                    duration: 0.1,
                  },
                }}
                className="modal  backdrop-blur-sm bg-[#13111137] fixed w-full h-full top-0 left-0 justify-center items-center z-10"
              >
                <motion.div
                  initial={{
                    x: 600,
                    scale: 0,
                  }}
                  animate={{
                    x: 0,
                    scale: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 600,
                    scale: 0,
                    transition: {
                      delay: 0.3,
                    },
                  }}
                  className="flex h-screen items-center justify-center"
                >
                  <motion.div
                    initial={{
                      x: 600,
                      opacity: 0,
                    }}
                    animate={{
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 100,
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    className="card flex flex-col bg-white  w-full max-w-sm md:max-w-2xl max-h-full rounded-xl p-8  "
                  >
                    <div className="flex justify-between">
                      <h1 className="font-bold text-xl text-[#071827]">
                        Change account details
                      </h1>
                      <button
                        onClick={closeModal}
                        className="hover:bg-[#f2faf7] hover:rounded-full p-3  self-end mb-7"
                      >
                        <AiOutlineClose size={23} />
                      </button>
                    </div>
                    <div className="hidden md:flex items-center justify-between mb-5">
                      <h1 className="font-bold text-xl text-[#071827] md:hidden">
                        Change account details
                      </h1>
                      <div className="flex flex-row gap-3 ml-auto">
                        <button
                          onClick={() => {
                            setEditBank(false);
                          }}
                          className="bg-white rounded-md px-7 p-3 transition-all active:scale-90  text-[#0eb683] border border-[#0eb683] "
                        >
                          Cancel
                        </button>
                        <button
                          onClick={updateAccDetails}
                          disabled={disabled}
                          className="bg-[#0eb683] rounded-md px-7 p-3 transition-all active:scale-90  text-white disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90 "
                        >
                          {buffer ? (
                            <div className="px-3">
                              <ReactLoading
                                type="spinningBubbles"
                                color="#fff"
                                height="27px"
                                width="27px"
                              />
                            </div>
                          ) : (
                            "Update"
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="flex flex-col gap-3">
                      <AppSelectInput
                        options={bankOptions || []}
                        label=""
                        handleChange={(bank: any) => setBank(bank)}
                      />

                      <input
                        type="number"
                        name=""
                        id=""
                        maxLength={10}
                        placeholder="Account Number"
                        className="p-4 rounded-md bg-[#F2FAF7] border outline-[#0eb683] "
                        required
                        value={accountNumber}
                        onChange={(evt) => setAccountNumber(evt.target.value)}
                      />
                    </div>
                    <div className="text-md font-bold ">
            {resolveName.isLoading ? (
              <TypingDots />
            ) : accountName && accountNumber.length === 10 ? (
              <p className="text-md font-bold text-[#0eb683]">{accountName}</p>
            ) : (
              errorMessage
            )}
          </div>
                    <div className="flex justify-between md:hidden mt-40">
                      <button
                        onClick={() => {
                          setEditBank(false);
                        }}
                        className="bg-white rounded-md px-10 p-3 transition-all active:scale-90  text-[#0eb683] border border-[#0eb683] "
                      >
                        Cancel
                      </button>
                      <button
                        onClick={updateAccDetails}
                        disabled={disabled}
                        className="bg-[#0eb683] rounded-md px-10 p-3 transition-all active:scale-90  text-white disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90 "
                      >
                        {buffer ? (
                          <div className="px-3">
                            <ReactLoading
                              type="spinningBubbles"
                              color="#fff"
                              height="27px"
                              width="27px"
                            />
                          </div>
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>
                  </motion.div>
                </motion.div>
              </motion.div>
            )}

            {updateSuccess && (
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    // delay: 0.3,
                    duration: 0.1,
                  },
                }}
                className="modal  backdrop-blur-sm bg-[#13111137] fixed w-full h-full top-0 left-0 justify-center items-center z-10"
              >
                <motion.div
                  initial={{
                    x: 600,
                    scale: 0,
                  }}
                  animate={{
                    x: 0,
                    scale: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 600,
                    scale: 0,
                    transition: {
                      delay: 0.3,
                    },
                  }}
                  className="flex h-screen items-center justify-center"
                >
                  <motion.div
                    initial={{
                      x: 600,
                      opacity: 0,
                    }}
                    animate={{
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 100,
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    className="card flex flex-col bg-white  w-full max-w-sm md:max-w-2xl max-h-full rounded-xl p-8  "
                  >
                    <div className="flex place-items-end justify-end">
                      <button
                        onClick={() => {
                          setUpdateSuccess(false);
                        }}
                        className="hover:bg-[#f2faf7] hover:rounded-full p-3  "
                      >
                        <AiOutlineClose size={23} />
                      </button>
                    </div>

                    <>
                      <div className="flex  justify-center flex-col gap-8 ">
                        <div className="icon self-center">
                          <SuccessMobile width={"100px"} height={"100px"} />
                        </div>
                        <h1 className="text-2xl font-bold text-[#071827] self-center">
                          Success
                        </h1>

                        <p className="  text-md text-slate-500 self-center text-center font-bold">
                          Bank details updated successfully
                        </p>

                        <button
                          onClick={() => {
                            setUpdateSuccess(false);
                          }}
                          className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                        >
                          Done
                        </button>
                      </div>
                    </>
                  </motion.div>
                </motion.div>
              </motion.div>
            )}
          </TabPanel>
          <TabPanel hidden={selectedTab !== "security"}>
            <div className="">
              <div className="input-wrapper flex flex-col  bg-white p-7 rounded-md mt-5 w-full gap-4 md:max-w-2xl">
                <h1 className="text-[#071827] text-xl font-bold ">Password</h1>
                <PasswordInput
                  name="password"
                  // label="Current Password"
                  onChange={(e: any) => {
                    setNewPassword({
                      ...newPassword,
                      currentPassword: e.target.value,
                    });
                  }}
                  value={newPassword.currentPassword}
                  label="Enter current password"
                  // icon={<FiEye />}
                  // notLoginInput
                />

                <PasswordInput
                  name="password"
                  onChange={(e: any) => {
                    setNewPassword({
                      ...newPassword,
                      password: e.target.value,
                    });
                  }}
                  value={newPassword.password}
                  label="Enter new password"
                  // icon={<FiEye /> ? <FiEyeOff /> : ""}
                  // notLoginInput
                />
                {!passwordValid && (
                  <p className="text-xs text-[#0eb683]">
                    Password must be 6 characters or greater, and must contain a
                    special character, a number and an uppercase character
                  </p>
                )}
                <PasswordInput
                  name="password"
                  value={newPassword.confirmPassword}
                  onChange={(e: any) => {
                    setNewPassword({
                      ...newPassword,
                      confirmPassword: e.target.value,
                    });
                    // checkPasswordValidity(e.target.value);
                  }}
                  label="Re-enter new password"
                  // icon={<FiEye />}
                  // notLoginInput
                />
                {passwordValid && !passwordMatch && (
                  <p className="text-xs text-red-500">
                    Passwords do not match!
                  </p>
                )}
                <div className="flex justify-between md:justify-end md:mt-3 gap-3 xxs:mt-10">
                  <button
                    onClick={clearInput}
                    className="bg-white rounded-md px-10 p-3 transition-all active:scale-90  text-[#0eb683] border border-[#0eb683] "
                  >
                    Cancel
                  </button>
                  <button
                    onClick={onSubmitPassword}
                    disabled={!passwordMatch}
                    className="bg-[#0eb683] rounded-md px-10 p-3 transition-all active:scale-90  text-white disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel hidden={selectedTab !== "referrals"}>
            <div className="md:bg-white md:p-3  max-w-2xl rounded-lg mt-5">
              <h1 className="text-[#071827]  mb-3 font-bold text-xl">
                Your Referral Code
              </h1>
              <div className="flex flex-col gap-8 card bg-[#0eb683] p-4 rounded-lg relative">
                <div className="flex items-center justify-between">
                  <div className=" ">
                    <h2 className="text-white font-medium ">
                      {getUserDetails?.data?.data?.invitationCode}
                    </h2>
                    {getUserDetails?.data?.data?.accountType === "private" ? (
                      <p className="text-white font-semibold ">
                        {getUserDetails?.data?.data?.firstName?.charAt(0) +
                          getUserDetails?.data?.data?.firstName?.slice(1)}{" "}
                        {getUserDetails?.data?.data?.lastName?.charAt(0) +
                          getUserDetails?.data?.data?.lastName?.slice(1)}
                      </p>
                    ) : (
                      <p>
                        {getUserDetails?.data?.data?.businessName?.charAt(0) +
                          getUserDetails?.data?.data?.businessName?.slice(1)}
                      </p>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      copyToClipBoard(
                        getUserDetails?.data?.data?.invitationCode
                      );
                    }}
                    className="  hidden md:flex items-center gap-1 text-white border border-white rounded-md py-1 md:py-2 px-3 transition-all active:scale-90 cursor-pointer"
                  >
                    <span className=" font-extrabold text-xl">
                      <FaRegCopy />
                    </span>
                    <p className="text-sm font-medium">Copy</p>
                  </div>
                </div>
                <div className="flex justify-between ">
                  <div className="w-36 h-16 md:mt-3">
                    <div
                      onClick={() => setShowInviteUserModal(true)}
                      className="px-3 py-3 font-bold text-[#0eb683] bg-white whitespace-nowrap text-center cursor-pointer rounded-md transition-all active:scale-90"
                    >
                      Invite new user
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      copyToClipBoard(
                        getUserDetails?.data?.data?.invitationCode
                      );
                    }}
                    className="
                 md:hidden flex items-center gap-1 text-white md:border md:border-white rounded-md py-1 px-3 cursor-pointer transition-all active:scale-90 xxs:absolute xxs:right-0 xxs:top-4 "
                  >
                    <span className=" font-extrabold text-xl">
                      <FaRegCopy />
                    </span>
                    <p className=" hidden md:block text-sm font-medium">Copy</p>
                  </div>
                  <div className="hidden   md:block">
                    <img src={brandLogo} alt="" className="w-18" />
                  </div>
                  <div className="absolute top-0 right-5 text-[#fffc] font-light text-sm">
                    {copyStatus}
                  </div>
                </div>
              </div>
            </div>

            <InviteesCard />
          </TabPanel>
        </div>

        <AnimatePresence>
          {showInviteUserModal && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
            >
              <motion.div
                initial={{
                  scale: 0,
                }}
                animate={{
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173]"
              >
                <motion.div
                  initial={{
                    x: 100,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 0,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="flex flex-col bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative xxs:rounded-md md:rounded-l-md  p-5 md:px-7 "
                >
                  <div className="flex items-end justify-end ">
                    <div
                      onClick={close}
                      className="close  flex items-center justify-center h-10 w-10  rounded-full
                    hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center self-end"
                    >
                      <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                        <IoMdClose size={28} className="" />
                      </span>
                    </div>
                  </div>
                  <h2 className="text-xl font-semibold">Invite new users</h2>
                  <p className=" text-[#5C6F7F] text-sm font-medium whitespace-normal text-justify">
                    Enter your referee’s contact details and an invitation would
                    be sent to them. Any{" "}
                    <span className="text-md font-extrabold underline">
                      BUY
                    </span>{" "}
                    transaction your referee performs earns you up to 
                    <span className="text-[#0eb683] font-bold">
                      NGN1.00/$,£,€ commission
                    </span>
                  </p>
                  <p className="text-center text-black font-medium">
                    Your Referral Code:
                  </p>
                  <p className="ref text-black font-bold text-center text-3xl ">
                    {getUserDetails?.data?.data?.invitationCode}
                  </p>
                  <div className=" flex flex-col items-center w-full gap-3 mt-5">
                    <input
                      type="text"
                      name=""
                      // value={firstname}
                      className="border border-[#E2E9F0] bg-[#F2FAF7] w-full p-3 rounded-md outline-[#0eb683]"
                      placeholder="First Name"
                      onChange={(e) => {
                        onFieldChange({
                          name: "firstName",
                          value: e.target.value,
                        });
                      }}
                    />
                    <input
                      type="text"
                      name=""
                      // value={lastname}
                      className="border bg-[#F2FAF7] border-[#E2E9F0] w-full p-3 rounded-md outline-[#0eb683]"
                      placeholder="Last Name"
                      onChange={(e) => {
                        onFieldChange({
                          name: "lastName",
                          value: e.target.value,
                        });
                      }}
                    />
                    <input
                      type="email"
                      name="email"
                      value={fields.email}
                      className="border border-[#E2E9F0] bg-[#F2FAF7] w-full p-3  outline-[#0eb683] rounded-md"
                      placeholder="Email"
                      onPaste={validateField}
                      onChange={(e) => {
                        onFieldChange({ name: "email", value: e.target.value });
                        validateField();
                      }}
                    />
                    {emailExist ? (
                      <div className="text-[#e01a00] self-start flex text-sm gap-1 items-center">
                        {" "}
                        <BiErrorAlt size={18} className="" />
                        <p className="flex gap-1">
                          {" "}
                          Email{" "}
                          <span className="font-bold">{fields.email}</span>{" "}
                          already exist.
                        </p>
                      </div>
                    ) : (
                      ""
                    )}

                    <button
                      disabled={disabled}
                      onClick={validateEmail}
                      className="text-white w-full bg-[#0eb683] rounded-md px-10 py-3 mt-20 transition-all active:scale-90 cursor-pointer disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
                    >
                      {spinner ? (
                        <div className="ml-32">
                          <ReactLoading
                            type="spinningBubbles"
                            color="#fff"
                            height="27px"
                            width="27px"
                          />
                        </div>
                      ) : (
                        "  Invite new user"
                      )}
                    </button>
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {invitationSuccess && (
            <div>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    // delay: 0.3,
                    duration: 0.1,
                  },
                }}
                className="confirmation_modal backdrop-blur-sm
           bg-[#13111137] fixed w-full top-0 left-0 justify-center h-full items-center z-50"
              >
                <motion.div
                  initial={{
                    x: 600,
                    scale: 0,
                  }}
                  animate={{
                    x: 0,
                    scale: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 600,
                    scale: 0,
                    transition: {
                      delay: 0.3,
                    },
                  }}
                  className="flex h-screen items-center justify-center md:justify-end "
                >
                  <motion.div
                    initial={{
                      x: 600,
                      opacity: 0,
                    }}
                    animate={{
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 100,
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    className="flex  justify-center flex-col gap-8 bg-white md:h-full w-full h-[450px] md:w-[448px] rounded-3xl md:rounded-l-3xl p-5 "
                  >
                    <div className="icon self-center">
                      <SuccessMobile width={"100px"} height={"100px"} />
                    </div>
                    <h1 className="text-2xl font-bold text-[#071827] self-center">
                      Invitation sent
                    </h1>

                    <p className="  text-sm text-[#5C6F7F] self-center text-center">
                      Your invitation was succesfully sent
                    </p>

                    <button
                      onClick={() => {
                        setInvitationSuccess(false);
                        setShowInviteUserModal(false);
                      }}
                      className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                    >
                      Done
                    </button>
                  </motion.div>
                </motion.div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {invitationFailed && (
            <div>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    // delay: 0.3,
                    duration: 0.1,
                  },
                }}
                className="confirmation_modal backdrop-blur-sm
           bg-[#13111137] fixed w-full top-0 left-0 justify-center h-full items-center z-50"
              >
                <motion.div
                  initial={{
                    x: 600,
                    scale: 0,
                  }}
                  animate={{
                    x: 0,
                    scale: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 600,
                    scale: 0,
                    transition: {
                      delay: 0.3,
                    },
                  }}
                  className="flex h-screen items-center justify-center md:justify-end "
                >
                  <motion.div
                    initial={{
                      x: 600,
                      opacity: 0,
                    }}
                    animate={{
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 100,
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    className="flex  justify-center flex-col gap-8 bg-white md:h-full w-full h-[450px] md:w-[448px] rounded-3xl md:rounded-l-3xl p-5 "
                  >
                    <div className="icon self-center">
                      <FailedIcon width={"100px"} height={"100px"} />
                    </div>
                    <h1 className="text-2xl font-bold text-[#071827] self-center">
                      Invitation failed
                    </h1>

                    <p className="  text-sm text-[#5C6F7F] self-center text-center">
                      We couldn't invite your user please retry
                    </p>

                    <button
                      onClick={() => {
                        setInvitationFailed(false);
                        close();
                      }}
                      className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                    >
                      Retry
                    </button>
                  </motion.div>
                </motion.div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {success && (
            <div>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    // delay: 0.3,
                    duration: 0.1,
                  },
                }}
                className="confirmation_modal backdrop-blur-sm
   bg-[#13111137] fixed w-full top-0 left-0 justify-center h-full items-center z-50"
              >
                {response ? (
                  <motion.div
                    initial={{
                      x: 600,
                      scale: 0,
                    }}
                    animate={{
                      x: 0,
                      scale: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 600,
                      scale: 0,
                      transition: {
                        delay: 0.3,
                      },
                    }}
                    className="flex h-screen items-center justify-center md:justify-end "
                  >
                    <motion.div
                      initial={{
                        x: 600,
                        opacity: 0,
                      }}
                      animate={{
                        x: 0,
                        opacity: 1,
                        transition: {
                          delay: 0.3,
                          duration: 0.3,
                        },
                      }}
                      exit={{
                        x: 100,
                        opacity: 1,
                        transition: {
                          duration: 0.3,
                        },
                      }}
                      className="flex  justify-center flex-col gap-8 bg-white md:h-full w-full h-[450px] md:w-[448px] rounded-3xl md:rounded-l-3xl p-5 "
                    >
                      <div className="icon self-center">
                        <SuccessMobile width={"100px"} height={"100px"} />
                      </div>
                      <h1 className="text-2xl font-bold text-[#071827] self-center">
                        Success
                      </h1>

                      <p className="  text-sm text-[#0eb683] self-center text-center">
                        Password Reset Successful
                      </p>

                      <button
                        onClick={() => {
                          setSuccess(false);
                        }}
                        className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                      >
                        Done
                      </button>
                    </motion.div>
                  </motion.div>
                ) : (
                  <FaSpinner
                    size={24}
                    color="#0eb683"
                    className="animate-spin"
                  />
                )}
              </motion.div>
            </div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {failed && (
            <div>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    // delay: 0.3,
                    duration: 0.1,
                  },
                }}
                className="confirmation_modal backdrop-blur-sm
   bg-[#13111137] fixed w-full top-0 left-0 justify-center h-full items-center z-50"
              >
                {response ? (
                  <motion.div
                    initial={{
                      x: 600,
                      scale: 0,
                    }}
                    animate={{
                      x: 0,
                      scale: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 600,
                      scale: 0,
                      transition: {
                        delay: 0.3,
                      },
                    }}
                    className="flex h-screen items-center justify-center md:justify-end "
                  >
                    <motion.div
                      initial={{
                        x: 600,
                        opacity: 0,
                      }}
                      animate={{
                        x: 0,
                        opacity: 1,
                        transition: {
                          delay: 0.3,
                          duration: 0.3,
                        },
                      }}
                      exit={{
                        x: 100,
                        opacity: 1,
                        transition: {
                          duration: 0.3,
                        },
                      }}
                      className="flex  justify-center flex-col gap-8 bg-white md:h-full w-full h-[450px] md:w-[448px] rounded-3xl md:rounded-l-3xl p-5 "
                    >
                      <div className="icon self-center">
                        <FailedIcon width={"100px"} height={"100px"} />
                      </div>
                      <h1 className="text-2xl font-bold text-[#071827] self-center">
                        Failed
                      </h1>

                      <p className="  text-sm text-[#5C6F7F] self-center text-center">
                        Password reset failed please retry
                      </p>

                      <button
                        onClick={() => {
                          setInvitationFailed(false);
                          // setShowInviteUserModal(true);
                        }}
                        className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                      >
                        Retry
                      </button>
                    </motion.div>
                  </motion.div>
                ) : (
                  <FaSpinner
                    size={24}
                    color="#0eb683"
                    className="animate-spin"
                  />
                )}
              </motion.div>
            </div>
          )}
        </AnimatePresence>

        {/* <div className="modal bg-[#fffc] fixed w-full h-full top-0 left-0 flex justify-center items-center">
      {response ?
      <div className="card bg-white shadow-lg shrink-0 w-full max-w-sm h-60 rounded-xl p-8 flex flex-col gap-3">
          <h2 className='text-center text-xl font-semibold'>{ success ? 'Password Reset Successful' : 'Password reset failed' }</h2>
          <div className={`msg p-5 flex items-center gap-3 rounded-md ${success ? 'bg-green-100' : 'bg-red-100'}`}>
            { success ? <FcCheckmark size={24} /> : <FcCancel size={24} /> }
            <p className={success ? 'text-green-500' : 'text-red-500'}>{ responseMsg || 'Some msg here' }</p>
          </div>
          <button className="bg-[#0eb683] px-10 py-3 rounded-md text-white cursor-pointer mt-5 self-center transition-all active:scale-90" onClick={() => {
            success && navigate('/login')
            setShowModal(false)
            setResponse(false)
          }}>
            {success ? 'Proceed to Login' : 'Retry'}
          </button>
      </div> : <FaSpinner size={24} color='#0eb683' className='animate-spin' />}
    </div>

      } */}
      </div>
    </>
  );
};

export default Settings;
