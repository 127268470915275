import { motion, AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useBeneficiaryIdentity,
  useDeleteBeneficiary,
} from "../../sevices/hooks/wallets";
import { useBeneficiariesState } from "../../store/useBeneficiaries";
import binIcon from "../../assets/flags/icons8-trash.gif";
import InfoIcon from "../../assets/iconsComponents/InfoIcon";
import moment from "moment";
import { IoMdClose } from "react-icons/io";
import { Tooltip } from "../base-components/Tooltip";

const BeneficiaryDetailsModal = () => {
  const overLayRef = React.useRef(null);
  const [remove, setRemove] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const showBeneficiaryModal = useBeneficiariesState(
    (state) => state.showBeneficiaryDetails
  );
  const selectedBeneficiaryId = useBeneficiariesState(
    (state) => state.selectedBeneficiaryId
  );
  const setShowBeneficiaryModal = useBeneficiariesState(
    (state) => state.setShowBeneficiaryDetails
  );

  const dBeneficiaryId = useBeneficiariesState((state) => state.dBeneficiaryId);

  const beneficiaryDetails = useBeneficiaryIdentity(selectedBeneficiaryId);

  let character = beneficiaryDetails?.data?.data?.billingDetails?.name
    ?.charAt(0)
    .toUpperCase()
    ?.match(/\b(\w)/g);
  const deleteBeneficiary = useDeleteBeneficiary(dBeneficiaryId);

  const removeBeneficiary = () => {
    deleteBeneficiary
      .mutateAsync({})
      .then((res) => {
        setDeleteSuccess(true);
      })
      .catch((e) => {
        setRemove(false);
        alert("Action cannot be completed at the moment");
      });
  };

  const truncateString = (str: string, num: number) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };
const currency = "usd"
  const date = beneficiaryDetails?.data?.data?.createDate;

  const handleOverLayClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.target === overLayRef.current && setShowBeneficiaryModal(false);
  };
  return (
    <AnimatePresence>
      {showBeneficiaryModal && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.3,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              // delay: 0.3,
              duration: 0.1,
            },
          }}
          onClick={handleOverLayClick}
          className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50 "
        >
          <motion.div
            initial={{
              x: 600,
              scale: 0,
            }}
            animate={{
              x: 0,
              scale: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              x: 600,
              scale: 0,
              transition: {
                delay: 0.3,
              },
            }}
            ref={overLayRef}
            className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173]"
          >
            <motion.div
              initial={{
                x: 600,
                opacity: 0,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              }}
              exit={{
                x: 100,
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              className="flex flex-col bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative xxs:rounded-md md:rounded-l-md  p-5 md:px-7 overflow-auto"
            >
              <button
                // onClick={() => {
                //   setShowBeneficiaryModal(false);
                // }}
                className="hover:bg-[#f2faf7] rounded-full p-2 self-end md:mb-3"
              >
                <Tooltip message="close">
                  <div
                    onClick={() => {
                      setShowBeneficiaryModal(false);
                    }}
                    className="close  flex items-center justify-center h-10 w-10  rounded-full
                    hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center "
                  >
                    <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                      <IoMdClose size={29} className="" />
                    </span>
                  </div>
                </Tooltip>
              </button>

              {!remove && (
                <div className=" ">
                  <h1 className=" absolute top-8 font-bold xxs:text-lg text-xl text-[#071827] pb-3">
                    Beneficiary Details
                  </h1>
                  <div className="flex items-center xxs:pb-3   gap-5 pb-6  ">
                    <div className="bg-[#E5F2EA] flex justify-center align-middle items-center p-3 h-14 w-14  text-center text-[#0eb683] font-extrabold rounded-full self-center  ">
                      <p className=" text-xl">{character} </p>
                    </div>
                    <div>
                      <p className="text-[#071827] text- font-semibold ">
                        {beneficiaryDetails?.data?.data?.billingDetails?.name?.toUpperCase()}
                      </p>
                      <p className="text-[#071827] text-xs font-medium"></p>
                    </div>
                  </div>

                  <div className="flex flex-col xxs:mt-2 mt-5 xxs:gap-3 gap-5">
                    <div className="flex items-center justify-between">
                      <p className="text-[#5C6F7F] text-sm ">Account Number</p>
                      <p className="text-[#071827] text-sm ">
                        {beneficiaryDetails?.data?.data?.accountNumber ||
                          beneficiaryDetails?.data?.data?.iban}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-[#5C6F7F] text-sm">City</p>
                      <p className="text-[#071827] text-sm">
                        {beneficiaryDetails?.data?.data?.billingDetails?.city ||
                          "--"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-[#5C6F7F] text-sm">Country</p>
                      <p className="text-[#071827] text-sm">
                        {beneficiaryDetails?.data?.data?.billingDetails
                          ?.country || "--"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-[#5C6F7F] text-sm">Address Line 1</p>
                      <p className="text-[#071827] text-sm">
                        {beneficiaryDetails?.data?.data?.billingDetails
                          ?.line1 || "--"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-[#5C6F7F] text-sm">Address Line 2</p>
                      <p className="text-[#071827] text-sm">
                        {beneficiaryDetails?.data?.data?.billingDetails
                          ?.line2 || "--"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-[#5C6F7F] text-sm">District</p>
                      <p className="text-[#071827] text-sm">
                        {beneficiaryDetails?.data?.data?.billingDetails
                          ?.district || "--"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-[#5C6F7F] text-sm">Postal Code</p>
                      <p className="text-[#071827] text-sm">
                        {beneficiaryDetails?.data?.data?.billingDetails
                          ?.postalCode || "--"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-[#5C6F7F] text-sm">Bank Name</p>
                      <p className="text-[#071827] text-sm">
                        {beneficiaryDetails?.data?.data?.bankAddress
                          ?.bankName || "--"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-[#5C6F7F] text-sm">Bank Country</p>
                      <p className="text-[#071827] text-sm">
                        {beneficiaryDetails?.data?.data?.bankAddress?.country ||
                          "--"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-[#5C6F7F] text-sm">Bank City</p>
                      <p className="text-[#071827] text-sm">
                        {beneficiaryDetails?.data?.data?.bankAddress?.city ||
                          "--"}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-[#5C6F7F] text-sm">Date Added</p>
                      <p className="text-[#071827] text-sm">
                        {moment(date).utc().startOf("day").fromNow()}
                      </p>
                    </div>
                  </div>
                  <div className="md:mt-8 xxs:mt-4">
                    <Link
                      to={`/dashboard/wallet/transfer/beneficiary/${selectedBeneficiaryId}`}
                      onClick={() => setShowBeneficiaryModal(false)}
                    >
                      <div className="text-white bg-[#0eb683] p-3 border border-[#0eb683] transition-all active:scale-90 text-center rounded-md mb-4 xxs:text-sm cursor-pointer">
                        Transfer to Beneficiary
                      </div>
                    </Link>
                    <div
                      onClick={() => setRemove(true)}
                      className="text-[#0eb683] bg-white p-3 border border-[#0eb683] transition-all active:scale-90 text-center rounded-md xxs:text-sm cursor-pointer hover:bg-[#E01A00] hover:text-[#fff] hover:border-[#E01A00]"
                    >
                      Delete Beneficiary
                    </div>
                  </div>
                </div>
              )}

              {remove && !deleteSuccess && (
                <div>
                  <h1 className="font-bold xxs:text-xl md:text-2xl text-[#071827] pb-3 text-center">
                    Delete beneficiary
                  </h1>
                  <div className="flex items-center justify-center">
                    <img
                      src={binIcon}
                      alt=""
                      className="text-center xxs:w-14 md:w-36"
                      color="#e01a00"
                    />
                  </div>
                  <p className="text-center  text-xl font-bold mt-6">
                    Are you sure you want to{" "}
                    <span className="text-[#e01a00] ">delete</span> <br />{" "}
                    <span className="text-[#0eb683] font-bold md:text-xl xxs:text-base whitespace-nowrap">
                      {truncateString(
                        beneficiaryDetails?.data?.data?.billingDetails?.name?.toUpperCase(),
                        30
                      )}{" "}
                      ?
                    </span>{" "}
                  </p>

                  <div className="bg-[#F2FAF7] mt-12 flex items-center md:p-5 xxs:p-2 gap-2 rounded-md">
                    <div>
                      <InfoIcon weight={"30px"} height={"30px"} />
                    </div>

                    <p className="text-[#5C6F7F] xxs:text-sm">
                      Proceeding to delete beneficiary means the beneficiary
                      will be entirely removed from the system
                    </p>
                  </div>
                  <div className="flex justify-between  mt-12">
                    <button
                      onClick={() => setRemove(false)}
                      className="text-[#0eb683] bg-[#fff] py-3 px-6 border border-[#0eb683] text-base font-medium rounded-md transition-all active:scale-90"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={removeBeneficiary}
                      className="text-[#fff] bg-[#e01a00] py-3 px-6 border border-[#e01a00] text-base font-medium rounded-md transition-all active:scale-90"
                    >
                      Yes, Delete
                    </button>
                  </div>
                </div>
              )}
              {deleteSuccess && (
                <>
                  <h1 className="font-bold xxs:text-xl md:text-2xl text-[#071827] pb-3 text-center">
                    Beneficiary Deleted
                  </h1>

                  <div className="flex items-center justify-center xxs:mt-3 md:mt-20">
                    <img
                      src={binIcon}
                      alt=""
                      className="text-center xxs:w-14 md:w-40"
                      color="#e01a00"
                    />
                  </div>
                  <p className="text-center  text-xl font-bold mt-10">
                    Your beneficiary has been{" "}
                    <span className="text-[#e01a00] ">deleted</span>{" "}
                    successfully{" "}
                  </p>

                  <button
                    onClick={() => {
                      setShowBeneficiaryModal(false);
                      window.location.reload();
                    }}
                    className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90 xxs:mt-5 md:mt-10"
                  >
                    Okay
                  </button>
                </>
              )}
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BeneficiaryDetailsModal;
