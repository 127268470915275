import React, { useMemo } from "react";
import hamburger from "../../assets/misc/hamburger-button.svg";
import { useSidebarState } from "../../store";
import logo from "../../assets/misc/FaviconAsset_ttfcx.svg";
import { useAccActivity, useAccountNgn, useWalletBalance } from "../../sevices/hooks/wallets";
import WalletIcon from "../../assets/iconsComponents/WalletIcon";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import RefreshIcon from "../../assets/iconsComponents/RefreshIcon";
import DashBoardEmptyState from "../../components/dashboard-components/DashBoardEmptyState";
import Activity from "../../components/wallet-components/Activity";
import { FaMoneyBillWave } from "react-icons/fa";

export function numberWithCommas(x: any) {
  return parseFloat(x).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

const Withdrawals = () => {
  const toggleSideBarOpen = useSidebarState((state) => state.toggleSidebar);
const [userDetails] = useOutletContext<any>();
  const accountNgn = useWalletBalance("ngn");

  const navigate = useNavigate();

  const userActivities = useAccActivity();

  const transactions = useMemo(() => {
    // const usd = userActivities?.data?.data?.usd || [];
    const ngn = userActivities?.data?.data?.ngn || [];
    const ngnStatus = (isCommitted: string) => {
      return isCommitted ? "Successful" : "Pending";
    };
    const getType = (currency: string, type: string) => {
      if (currency.toUpperCase() === "NGN") {
        return type;
      }
      return type === "BUY" ? "credit" : "debit";
    };

    return [...ngn]
      .map(
        (t: {
          unit: any;
          createdAt: any;
          originalReference: any;
          created_at: any;
          id: any;
          narration: any;
          gateway_reference: any;
          status: any;
          currency: any;
          type: any;
          memo: any;
          amount: any;
          is_committed: any;
        }) => {
          const {
            unit,
            createdAt,
            originalReference,
            created_at,
            id,
            narration,
            gateway_reference,
            status,
            currency,
            type,
            memo,
            amount,
            is_committed,
          } = t;
          return {
            id,
            date: created_at ?? createdAt,
            status:
              currency?.toUpperCase() === "NGN"
                ? ngnStatus(is_committed)
                : status,
            type: getType(currency, type),
            currency: currency?.toUpperCase(),
            reference:
              currency?.toUpperCase() === "NGN"
                ? gateway_reference
                : originalReference,
            narration: currency?.toUpperCase() === "NGN" ? memo : narration,
            amount:
              currency.toUpperCase() === "NGN"
                ? "₦ " + numberWithCommas(amount?.formatted ?? unit)
                : currency.toUpperCase() === "USD"
                ? "$ " + numberWithCommas(amount?.formatted ?? unit)
                : currency.toUpperCase() === "USDC"
                ? "$ " + numberWithCommas(amount?.formatted ?? unit)
                : numberWithCommas(parseInt(amount?.formatted ?? unit)),
          };
        }
      )
      .sort((a: any, b: any) => {
        const x = new Date(a.date).getTime();
        const y = new Date(b.date).getTime();
        if (x > y) {
          return -1;
        } else if (x < y) {
          return 1;
        } else {
          return 0;
        }
      });
  }, [userActivities?.data?.data]);

  const { refetch, data } = accountNgn;
  return (
    <>
      <div className="h-full bg-[#f7fbfb] w-full p-5  md:pl-8 ">
        <div className="image w-16 ml-auto md:hidden">
          <img src={logo} alt="" className="" />
        </div>
        <button
          className="md:hidden hover:bg-slate-200 rounded-md  transition-all active:scale-90"
          onClick={() => toggleSideBarOpen()}
        >
          <img src={hamburger} alt="menu" className="w-10 " />
        </button>
        <div className=" flex justify-between xxs:items-center md:mr-3">
          <div>
            <h1 className="text-2xl pl-1 font-bold text-[#071827] ">
              Withdrawals
            </h1>
          </div>
          {/* button */}
          <div className="w-62  mt-2">
            <button
              className="hidden md:flex items-center text-white font-medium bg-[#0eb683] p-3 rounded-md gap-2 w-[215px]  transition-all active:scale-90 cursor-pointer justify-center active:bg-#6bf3ca"
              onClick={(e) => {
                e.preventDefault();
                navigate("/dashboard/withdrawals/withdraw", { replace: true });
              }}
            >
              <FaMoneyBillWave size={25} />
              <span className="text-base text-#fff pt-1">Withdraw Funds</span>
            </button>
          </div>
          {/* mobile */}

          <div className="md:hidden  ">
            <button
              className="block md:hidden items-center bg-[#0eb683] p-3 px-10 rounded-md shadow-lg  transition-all active:scale-90 cursor-pointer  text-white  "
              onClick={(e) => {
                e.preventDefault();
                navigate("/dashboard/withdrawals/withdraw", { replace: true });
              }}
            >
              {" "}
              <p className="flex items-center font-extrabold">
                {/* <HiPlus /> */}
                Withdraw
              </p>
            </button>
            {}
          </div>
        </div>

        <div className=" withdrwal flex flex-col md:flex-row gap-6 mt-5">
          {/* card */}
          <div
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/withdrawals/withdraw", { replace: true });
            }}
            className={`wallet_card flex bg-[#fff] md:py-6 md:pr-4 px-4   h-[154px] md:w-[350px] md:h-[154px]  border border-[#0eb683] items-center gap-4 rounded-lg transition-all active:scale-90 cursor-pointer relative  `}
          >
            <div className=" text-[#0B0C0E] bg-[#E7FCF5] p-3 rounded-full ">
              <WalletIcon stroke="#0B0C0E" />
            </div>
            <div className="mt-3">
              <h1 className="text-[#5C6F7F] font-semibold">Naira Balance</h1>
              <p className="font-bold text-lg flex items-center gap-1">
                <span>&#8358;</span>
                {numberWithCommas(userDetails?.ngnBalance) === "NaN"
                  ? "0.00"
                  : `${numberWithCommas(userDetails?.ngnBalance)}`}
              </p>
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                refetch();
              }}
              className="reload_icon border border-[#0eb683] text-[#0eb683] rounded-full p-2 transition-all active:scale-90 ml-6 absolute  top-4 right-4 md:top-auto "
            >
              <RefreshIcon />
            </button>
          </div>
          <Link
            to={""}
            className={`wallet_card flex bg-[#fff] md:py-6 md:pr-4 px-4 w-full h-[154px] md:w-[350px] md:h-[154px]  border border-[#0eb683] items-center gap-4 rounded-lg  cursor-pointer relative  `}
          >
            <div className=" text-[#0B0C0E] bg-[#E7FCF5] p-3 rounded-full ">
              <WalletIcon stroke="#0B0C0E" />
            </div>
            <div className="mt-3">
              <h1 className="text-[#5C6F7F] font-semibold">
                Total Earned Commission
              </h1>
              <p className="font-bold text-lg">
                <span>&#8358;</span>{" "}
                {/* {numberWithCommas(userDetails?.ngnBalance) === "NaN"
                  ? "0.00"
                  : numberWithCommas(userDetails?.ngnBalance)} */}
                  0.00
              </p>
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                refetch();
              }}
              className="reload_icon border border-[#0eb683] text-[#0eb683] rounded-full p-2 transition-all active:scale-90 ml-6 absolute  top-4 right-4 md:top-auto "
            >
              <RefreshIcon />
            </button>
          </Link>
        </div>

        <>
          {transactions.length > 0 ? (
            <div className="md:mt-7 xxs:mb-10">
              <Activity
                tabs={[]}
                itemsPerPage={7}
                currentPage={1}
                //@ts-ignore
                data={transactions}
              />
            </div>
          ) : (
            <>
              <DashBoardEmptyState />
            </>
          )}
        </>
      </div>
    </>
  );
};

export default Withdrawals;
