import create from "zustand";

interface ISelfieAccess {
  showSelfieModal: boolean;

  setSelfieModal: (shouldShow: boolean) => void;
}

export const useShowSelfie = create<ISelfieAccess>((set) => ({
  showSelfieModal: false,

  setSelfieModal: (shouldShow: boolean) =>
    set((state: any) => ({ showSelfieModal: shouldShow })),
}));
