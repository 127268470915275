import DesktopAside from "../dashboard-components/DesktopAside";
import { useSidebarState } from "../../store";
export interface Iprops {
  buyUsd: number;
  buyGbp: number;
  buyEur: number;
}
const Aside = ({ buyUsd, buyGbp, buyEur }: Iprops) => {
  const sideBarOpen = useSidebarState((state) => state.sideBarOpen);
  const toggleSideBarOpen = useSidebarState((state) => state.toggleSidebar);

  return (
    <div className="sidebar">
      <div
        className={`fixed overlay w-full h-screen bg-black opacity-80 top-0 left-0 z-10 md:hidden ${
          !sideBarOpen ? "hidden" : ""
        }`}
        onClick={() => toggleSideBarOpen()}
      ></div>
      <DesktopAside />
    </div>
  );
};

export default Aside;

//Try this remove Desktop aside from aside and put it at a render it in the dashboard screen
