import React, { useState } from "react";
// import { BiRefresh } from "react-icons/bi";
import { BsChevronLeft } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import SuccessMobile from "../../../assets/iconsComponents/SuccessMobile";

import RefreshIcon from "../../../assets/iconsComponents/RefreshIcon";

import Notification from "../../../components/dashboard-components/Notification";
import { AiOutlineClose } from "react-icons/ai";
import ukFlag from "../../../assets/flags/pound.png";
import EmptyState from "../../../components/base-components/EmptyState";
import { useGbpRates } from "../../../sevices/hooks/tags";
import FiatWalletCard from "../../../components/dashboard-components/FiatWalletCard";
import SwapModal from "../../../components/dashboard-components/SwapModal";
import { useAccountNgn, useWalletBalance } from "../../../sevices/hooks/wallets";
import { AxiosResponse } from "axios";
import { RefetchOptions, RefetchQueryFilters, QueryObserverResult } from "react-query";

const GbpWallet = ({ currency = "gbp" }: { currency?: string }) => {
  const navigate = useNavigate();
  const accNgn = useWalletBalance("ngn");
  const [currentTab, setCurrentTab] = useState("buy");
  //hooks for Gbp operations
  const [showBuyGbp, setShowBuyGbp] = useState(false);
  const [confirmBuyGbp, setConfirmBuyGbp] = useState(false);
  const [gbpBuySuccess, setGbpBuySuccess] = useState(false);
  const [confirmSellGbp, setConfirmSellGbp] = useState(false);
  const [gbpSellSuccess, setGbpSellSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gbpAmountValue, setGdpAmountValue] = useState<any>();
  const [NGNGBPAmountValue, setNGNGBPAmountValue] = useState<any>();

  const [fields, setFields] = useState({
    amount: "",
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const onfieldChange = ({ name, value }: { value: any; name: any }) => {
    setFields({ ...fields, [name]: value });
  };
  const confirmation = () => {
    setShowConfirmationModal(true);
  };
  const GBPRate = useGbpRates();
  const gbpRate = GBPRate?.data?.data;

  const ngnBalanace = accNgn?.data?.data?.available_balance?.formatted;
  const openModal = () => {
    setShowModal(true);
    // confirmation()
  };
  const closeModal = () => {
    setShowModal(false);
    setShowConfirmationModal(false);
    setShowSuccess(true);
  };

  function commas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const GbpErr = `Minimum amount is NGN ${commas(
    Number(gbpRate * 100).toFixed()
  )}`;

  return (
    <div className="md:bg-[#f7fbfb] h-full w-full px-5 py-5 md:p-5 md:pl-8 ">
      <div className="flex items-center gap-24 md:gap-4">
        <button
          className=" rounded-full bg-[#ECF6F3] p-2 flex transition-all active:scale-90 cursor-pointer "
          onClick={() => {
            navigate(-1);
          }}
        >
          <BsChevronLeft />
        </button>
        <p className="font-semibold">GBP Wallet</p>
      </div>
      <div className="box bg-white flex flex-col md:p-5 mt-7 rounded-lg">
        <div className="flex flex-col items-start gap-3 mb-5 md:flex-row md:justify-between">
          <div className="w-full md:w-fit">
            <FiatWalletCard
              text={"GBP Balance"}
              // icons={<BiRefresh />}
              amount={"0.00"}
              currency={"£"}
            />
          </div>
          <div className="flex flex-col md:items-end">
            <div className="button flex gap-4 md:items-end ">
              <button
                disabled={true}
                onClick={() => {
                  setShowBuyGbp(true);
                }}
                className="border border-[#0eb683] text-center  text-[#0eb683] w-full p-3 md:w-40 whitespace-nowrap bg-white rounded-md transition-all active:scale-90 cursor-pointer disabled:cursor-not-allowed "
              >
                Buy | Sell GBP
              </button>

              <Link
                to={"/dashboard/wallet/transfer/gbp"}
                className=" bg-[#0eb683] w-full md:w-64 p-3 text-center border border-[#0eb683] text-white rounded-md transition-all active:scale-90 cursor-pointer self-end"
              >
                Transfer FCY
              </Link>
            </div>

            {showBuyGbp && (
              <SwapModal
                baseCurrency={"gbp"}
                baseCurrencySymbol={"£ "}
                currency={"ngn"}
                currencySymbol={"₦ "}
                rate={gbpRate?.data?.data}
                confirmation={confirmSellGbp}
                setConfirmation={setConfirmSellGbp}
                inputValue={NGNGBPAmountValue?.value}
                inputValueFormatted={NGNGBPAmountValue?.formattedValue}
                inputValueFloat={NGNGBPAmountValue?.floatValue}
                setInputValue={setNGNGBPAmountValue}
                openModal={showBuyGbp}
                loading={loading}
                setLoading={setLoading}
                setOpenModal={setShowBuyGbp}
                balance={ngnBalanace}
                baseBalance={"0"}
                computedValue={gbpAmountValue?.value}
                computedValueFloat={gbpAmountValue?.floatValue}
                computedValueFormatted={gbpAmountValue?.formattedValue}
                setComputedValue={setGdpAmountValue}
                errorMessage={GbpErr}
                success={gbpBuySuccess}
                setSuccess={setGbpBuySuccess}
                // initiateBuy={() => {}}
                setFundModal={() => { } } refetchRates={function <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined): Promise<QueryObserverResult<AxiosResponse<any, any>, unknown>> {
                  throw new Error("Function not implemented.");
                } }              />
            )}

            {/* Transfer FCY session */}

            <div className=" md:text-right mt-8">
              <p className="text-sm md:hidden text-[#5C6F7F]">
                Securely make transfers to permitted regions with a fee of a
                0.25% + &#163;50 Offshore Charge
                <br />
                Transfers typically typically take 3 business days All funds are
                secured
              </p>

              <p className="text-sm hidden md:flex items-end flex-col  font-medium text-[#5C6F7F] md:text-right">
                Securely make transfers to permitted regions with a fee of a
                0.25% + &#163;50 Offshore Charge
                <br />
                <span className="md:text-right">
                  {" "}
                  Transfers typically typically take 3 business days
                </span>{" "}
                <span className=""> All funds are secured</span>
              </p>
            </div>
          </div>
        </div>

        <AnimatePresence>
          {showModal && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="modal bg-[#a59d9d34] backdrop-blur-sm fixed w-full h-full top-0 left-0 justify-center items-center z-10"
            >
              <motion.div
                initial={{
                  x: 600,
                  scale: 0,
                }}
                animate={{
                  x: 0,
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 600,
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                className="flex h-screen items-center justify-center md:justify-end"
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="card flex flex-col bg-white  w-full max-w-md max-h-full rounded-l-3xl p-6 md:h-full "
                >
                  <button
                    onClick={() => {
                      setShowBuyGbp(true);
                      setShowModal(false);
                      setShowConfirmationModal(false);
                    }}
                    className="hover:bg-[#f2faf7] rounded-full p-2 self-end"
                  >
                    <AiOutlineClose size={28} />
                  </button>
                  <div>
                    <h1 className="font-bold text-xl text-[#071827]">
                      Fund your account
                    </h1>
                    <p className="text-[#5C6F7F] text-sm pb-4">
                      Fund your NGN balance via direct bank transfer
                    </p>
                  </div>
                  <input
                    type="number"
                    name="amount"
                    className="w-full  p-3 bg-[#f7fbfb] rounded-md border mb-6 outline-[#0eb683] placeholder:text-sm"
                    placeholder="Enter the amount you wish to transfer "
                    onChange={(e) => {
                      onfieldChange({
                        name: "amount",
                        value: e.target.value,
                      });
                    }}
                  />

                  <Notification
                    info={"To fund your Naira wallet, make a transfer to:"}
                  />

                  <button
                    onClick={confirmation}
                    className="bg-[#0eb683] rounded-md px-10 p-3 transition-all active:scale-90 mt-6 text-white "
                  >
                    Continue
                  </button>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showConfirmationModal && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="confirmation_modal bg-opacity-30 backdrop-blur-sm fixed w-full top-0 left-0 justify-center h-full items-center z-50"
            >
              <motion.div
                initial={{
                  x: 600,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className="flex h-screen items-center justify-center md:justify-end "
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="card flex flex-col bg-white  w-full max-w-md max-h-full rounded-l-3xl p-5 md:h-full "
                >
                  <button
                    onClick={closeModal}
                    className="hover:bg-[#f2faf7] rounded-full p-2  self-end cursor-pointer"
                  >
                    <AiOutlineClose size={28} />
                  </button>
                  <div className="flex gap-3 items-center pb-2">
                    <div
                      onClick={() => setShowConfirmationModal(false)}
                      className="bg-[#f7fbfb] p-2 rounded-full cursor-pointer transition-all active:scale-90"
                    >
                      <BsChevronLeft />
                    </div>

                    <h1 className="font-bold text-xl text-[#071827]">
                      Confirmation
                    </h1>
                  </div>
                  <p className="text-sm text-[#5C6F7F] mb-9">
                    Fund your NGN balance via direct bank transfer
                  </p>
                  <div className="flex items-center justify-between">
                    <h1 className="text-[#071827] font-bold">
                      You are transferring:
                    </h1>
                    <p className="text-[#0EB683] font-bold text-xl">NGN 0.00</p>
                  </div>
                  <div className="py-5">
                    <Notification
                      text={
                        "Once you have transferred the amount, click confirm below"
                      }
                      info={"To:"}
                    />
                  </div>

                  <div
                    onClick={closeModal}
                    className="flex flex-row justify-between md:flex-col mb-4"
                  >
                    <button className="border border-[#0eb683] text-[#0eb683] md:p-3 rounded-md md:mb-4 cursor-pointer transition-all active:scale-90  py-3 px-8 hover:bg-[#0eb683] hover:text-white ">
                      Confirm
                    </button>
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {showSuccess && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                // delay: 0.3,
                duration: 0.1,
              },
            }}
            className="confirmation_modal bg-[#13111137] backdrop-blur-sm fixed w-full top-0 left-0 justify-center h-full items-center z-50"
          >
            <motion.div
              initial={{
                x: 600,
                scale: 0,
              }}
              animate={{
                x: 0,
                scale: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                x: 600,
                scale: 0,
                transition: {
                  delay: 0.3,
                },
              }}
              className="flex h-screen items-center justify-center md:justify-end "
            >
              <motion.div
                initial={{
                  x: 600,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    // scale:1,
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 1,
                  transition: {
                    // scale:10,
                    duration: 0.3,
                  },
                }}
                className="desktop-card md:flex hidden justify-center flex-col gap-8 bg-white   w-[448px] h-full md:rounded-l-3xl p-5 "
              >
                <div className="icon self-center">
                  <SuccessMobile width={"200px"} height={"200px"} />
                </div>
                <h1 className="text-3xl font-bold text-[#071827] self-center">
                  Wallet Funded
                </h1>

                {/* <CheckIcon/> */}
                <p className="text-sm text-[#5C6F7F] self-center">
                  Your wallet has been successfully funded
                </p>

                <button
                  onClick={() => setShowSuccess(false)}
                  className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90 "
                >
                  Done
                </button>
              </motion.div>

              <motion.div
                initial={{
                  x: 600,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    // scale:1,
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 1,
                  transition: {
                    // scale:10,
                    duration: 0.3,
                  },
                }}
                className="mobile-card flex md:hidden justify-center flex-col gap-8 bg-white  w-full max-w-sm h-[450px] rounded-xl p-5 "
              >
                <div className="icon self-center">
                  <SuccessMobile width={"100px"} height={"100px"} />
                </div>
                <h1 className="text-3xl font-bold text-[#071827] self-center">
                  Wallet Funded
                </h1>

                {/* <CheckIcon/> */}
                <p className="text-sm text-[#5C6F7F] self-center">
                  Your wallet has been successfully funded
                </p>

                <button
                  onClick={() => setShowSuccess(false)}
                  className="bg-[#0eb683] text-white rounded-md px-16 py-3 self-center transition-all active:scale-90 "
                >
                  Done
                </button>
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <>
        <EmptyState />
      </>
    </div>
  );
};

export default GbpWallet;
