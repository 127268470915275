import React, { useEffect, useState } from "react";
import Select from "react-select";
import "../widgets/styles/standard.css";

const AppSelectInput = ({
  label,
  info,
  handleChange,
  options,
  defaultValue,
  placeholder,
  onChange,
  className,
}: // onChange
{
  label: string;
  info?: string;
  options?: any;
  onChange?: (inputValue: any, actionMeta: any) => void;
  handleChange: (inputValue: any, actionMeta: any) => void;
  defaultValue?: any;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
    error?: any;
    name?: string;
}) => {
  const [selectSettings] = useState({
    isClearable: true,
    isDisabled: false,
    isLoading: false,
    isRtl: false,
    isSearchable: true,
  });

  const { isClearable, isSearchable, isDisabled, isLoading, isRtl } =
    selectSettings;

  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setSelectOptions(options);
    }

    return () => {
      mounted = false;
    };
  }, [options]);

  const customStyles = {
    // Style for the placeholder
    placeholder: (provided: any) => ({
      ...provided,
      color: "#5C6F7F", // Customize the color of the placeholder

      fontSize: "14px",
      lineHeight: "16px"
    }),
  };

  return (
    <div className="app-special-input w-full ">
      <Select
        className="app-select  "
        classNamePrefix="select"
        defaultValue={
          defaultValue?.label
            ? { label: defaultValue?.label, value: defaultValue?.value }
            : selectOptions[0]
        }
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        placeholder={placeholder || "Select a bank"}
        name="color"
        options={selectOptions}
        onChange={handleChange}
        styles={customStyles}
        // required
      />

      {/* <p className="info ">{info}</p> */}
    </div>
  );
};

export default AppSelectInput;
