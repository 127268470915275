import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useTransactionReference } from "../../../sevices/hooks/wallets";
import { useRecieptState } from "../../../store/useReciept";
import { ImDownload } from "react-icons/im";
import TransactionStepper from "./TransactionStepper";
import { usePayouts } from "../../../sevices/hooks/admin";

// const steps = [
//   "Select master blaster campaign settings",
//   "Create an ad group",
//   "Create an ad",
// ];

const TransactionRecieptModal = () => {
  const showBeneficiaryReferenceModal = useRecieptState(
    (state) => state.showTransactionReciept
  );
  const setTransactions = useRecieptState((state) => state.setTransactions);
  const showTransactionReciept = useRecieptState(
    (state) => state.showTransactionReciept
  );
  const setShowBeneficiaryReferenceModal = useRecieptState(
    (state) => state.setShowTransactionReciept
  );
  const [currentTab, setCurrentTab] = useState("status");
  const selectedBeneficiaryRef = useRecieptState(
    (state) => state.beneficiaryReference
  );
  // console.log(selectedBeneficiaryRef, "ref");

  const transactionRef = useTransactionReference(selectedBeneficiaryRef);
 


  return (
    <>
      {showBeneficiaryReferenceModal && (
        <AnimatePresence>
          <motion.div
            // ref = {modalRef}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                // delay: 0.3,
                duration: 0.1,
              },
            }}
            className="modal bg-[#13111173] fixed w-full h-full top-0 left-0 justify-center items-center z-10"
          >
            <motion.div
              initial={{
                x: 600,
                opacity: 0,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              }}
              exit={{
                x: 100,
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              className="flex h-screen items-center justify-center md:justify-end "
            >
              <motion.div
                initial={{
                  x: 600,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className="card flex flex-col bg-white  w-full max-w-sm max-h-full md:rounded-l-3xl px-8 md:h-full md:max-w-md xxs:overflow-y-scroll"
              >
                <button
                  onClick={() => setShowBeneficiaryReferenceModal(false)}
                  className="hover:bg-[#f2faf7] rounded-full p-2 self-end my-5"
                >
                  <AiOutlineClose size={28} />
                </button>
                <div className="toggle w-full h-12 md:h-12 flex border border-[#0eb683] mb-6 md:mb-4 self-center justify-between rounded-lg cursor-pointer p-1">
                  <div
                    className={`px-2 flex-1 whitespace-nowrap flex items-center justify-center transition-all duration-500 rounded-md ${
                      currentTab === "status" ? "bg-[#0eb683] text-white" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentTab("status");
                    }}
                  >
                    Status
                  </div>
                  <div
                    className={`px-2 flex-1 whitespace-nowrap flex items-center justify-center transition-all duration-500 rounded-md ${
                      currentTab === "receipt" ? "bg-[#0eb683] text-white" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentTab("receipt");
                    }}
                  >
                    Receipt
                  </div>
                </div>
                {currentTab === "status" ? (
                  <div>
                    {/* <TransactionStepper /> */}
                  </div>
                ) : (
                  <div>
                    <div className="flex flex-col xxs:mt-2 mt-5 xxs:gap-3 gap-5">
                      <div className="flex items-center justify-between">
                        <p className="text-[#5C6F7F] text-sm ">Status:</p>
                        <p className="text-[#071827] text-sm ">1</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-[#5C6F7F] text-sm">Swift Code:</p>
                        <p className="text-[#071827] text-sm">4</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-[#5C6F7F] text-sm">
                          Beneficiary Name:
                        </p>
                        <p className="text-[#071827] text-sm">5</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-[#5C6F7F] text-sm">
                          Account Number:
                        </p>
                        <p className="text-[#071827] text-sm">5</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-[#5C6F7F] text-sm">City:</p>
                        <p className="text-[#071827] text-sm">5</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-[#5C6F7F] text-sm">Country:</p>
                        <p className="text-[#071827] text-sm"></p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-[#5C6F7F] text-sm">
                          Address Line 1:
                        </p>
                        <p className="text-[#071827] text-sm">6</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-[#5C6F7F] text-sm">
                          Address Line 2:
                        </p>
                        <p className="text-[#071827] text-sm">5</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-[#5C6F7F] text-sm">
                          Beneficiary District:
                        </p>
                        <p className="text-[#071827] text-sm">5</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-[#5C6F7F] text-sm">
                          Beneficiary Postal Code:
                        </p>
                        <p className="text-[#071827] text-sm">4</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-[#5C6F7F] text-sm">Bank Country:</p>
                        <p className="text-[#071827] text-sm">4</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-[#5C6F7F] text-sm">Bank City:</p>
                        <p className="text-[#071827] text-sm">4</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-[#5C6F7F] text-sm">
                          Purpose of Payment:
                        </p>
                        <p className="text-[#071827] text-sm">4</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-[#5C6F7F] text-sm">
                          Payment Invoice:
                        </p>
                        <p className="text-[#071827] text-sm">4</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-[#5C6F7F] text-sm">
                          Total amount debited including charges:
                        </p>
                        <p className="text-[#071827] text-sm">4</p>
                      </div>
                    </div>
                    <button className="flex items-center text-white bg-[#0eb683] w-full justify-center py-4 text-lg rounded-md transition-all active:scale-90 gap-2 mt-8">
                      <ImDownload size={25} /> Download Receipt
                    </button>
                  </div>
                )}
              </motion.div>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      )}
    </>
  );
};

export default TransactionRecieptModal;
