
  import React, { UIEvent, useState } from "react";
  import { Link } from "react-router-dom";

  // const Container = styled.div`
  //   display: flex;
  //   flex: 1;
  //   justify-content: space-between;
  //   flex-direction: column;
  //   gap: 3;
  //   box-shadow: -1px 3px 5px 0px rgba(237, 220, 220, 0.75);
  //   -webkit-box-shadow: -1px 3px 5px 0px rgba(237, 220, 220, 0.75);
  //   -moz-box-shadow: -1px 3px 5px 0px rgba(237, 220, 220, 0.75);
  //   padding: 10px;
  //   height: 150px;
  //   border-radius: 8px;
  // `;

  //@ts-ignore
  interface MouseEvent<T = Element, E = NativeMouseEvent> extends UIEvent<T, E> {
    altKey: boolean;
    button: number;
    buttons: number;
    clientX: number;
    clientY: number;
    ctrlKey: boolean;
    /**
     * See [DOM Level 3 Events spec](https://www.w3.org/TR/uievents-key/#keys-modifier). for a list of valid (case-sensitive) arguments to this method.
     */
    getModifierState(key: string): boolean;
    metaKey: boolean;
    movementX: number;
    movementY: number;
    pageX: number;
    pageY: number;
    relatedTarget: EventTarget | null;
    screenX: number;
    screenY: number;
    shiftKey: boolean;
  }
  export interface Iprops {
    color: string;
    currency: string;
    symbol: string;
    border?: string;
    action: () => void;
    button: string;
    flag: string;
    amount: string;
    buttonColor: string;
    textColor: string;
    navigate: string;
    disable: boolean;
  }

  const WalletWidget = ({
    color,
    currency,
    symbol,
    border,
    action,
    button,
    flag,
    amount,
    buttonColor,
    textColor,
    navigate,
    disable,
  }: Iprops) => {

    const [notify, setNotify] = useState(false)
    const openModal = (e: any) => {
      e.preventDefault();
      action();
    };

      const handleMouseEvent = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        // Do something
      setNotify((prev)=> !prev)
      };

    // const openCard =(e: any) =>{}

    return (
      <Link
        to={navigate}
        className="flex flex-auto justify-between flex-col p-3 xxs:w-[310px] h-52 w-full rounded-lg shadow-sm xxs:flex-shrink-0 md:flex-shrink relative  overflow-hidden  -z-0"
        style={{ backgroundColor: color, border: border }}
        //@ts-ignore
        onMouseEnter={handleMouseEvent}
        //@ts-ignore
        onMouseLeave={handleMouseEvent}
      >
        <div className="flex items-center justify-between">
          <div className="text-[#071827] text-base  font-medium ">
            {currency}
          </div>
          <div className="text-2xl font-bold  ">{symbol}</div>
        </div>
        <div className="text-[#071827] font-bold  text-xl">
          <span>{symbol}</span> <span>{amount}</span>
        </div>
        {notify && (
          <>
            {disable && (
              <div className="absolute right-[-8px] top-[125px]">
                <div className="bg-slate-300 text-[#0eb683] transform rotate-45 origin-bottom-right absolute bottom-0 right-0 py-1 px-2 whitespace-nowrap w-44 text-center font-semibold  shadow-lg ease-in-out transition-all duration-300">
                  Coming Soon
                </div>
              </div>
            )}
          </>
        )}

        <div>
          <div className="flex items-center  justify-between">
            <button
              disabled={disable}
              onClick={openModal}
              className={`bg-[${buttonColor}] text-[${textColor}] font-semibold  py-2 rounded-md w-24  text-center transition-all active:scale-90 disabled:cursor-not-allowed whitespace-nowrap`}
            >
              {button}
            </button>
            <figure className="w-6 ">
              <img src={flag} alt="" />
            </figure>
          </div>
        </div>
      </Link>
    );
  };

  export default WalletWidget;
