import React, { useEffect, useCallback, useRef } from "react";
import "@smile_identity/smart-camera-web";

const SmartCameraWeb = ({ setSelfieData }) => {
  // const handleImagesComputed = useCallback((event) => {
  //   try {
  //     console.log(event.detail.images, "lop");
  //     const selfieData = event.detail.images.slice(0, 2);
  //     setSelfieData(selfieData);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, []);

 const handleImagesComputed = useCallback((event) => {
   try {
     const images = event.detail.images;
     const filteredImages = [];

     // Define the image_id values to search for
     const imageIdsToSearch = [2, 6];

     for (const id of imageIdsToSearch) {
       const matchingImage = images.find((image) => image.image_type_id === id);
       if (matchingImage) {
         filteredImages.push(matchingImage);
       }
     }

     // Log and set the filtered images

     setSelfieData(filteredImages);
   } catch (error) {
     
   }
 }, []);






  const selfieDataRef = useRef(null);

  useEffect(() => {
    const selfieData = selfieDataRef.current;
    if (!selfieData) return;

    selfieData.addEventListener("imagesComputed", handleImagesComputed);

    return () => {
      selfieData.removeEventListener("imagesComputed", handleImagesComputed);
    };
  }, [handleImagesComputed]);

  return (
    <div>
      <smart-camera-web
        capture-id="back"
        ref={selfieDataRef}
      ></smart-camera-web>
    </div>
  );
};

export default SmartCameraWeb;
