import React, { useState, useEffect } from "react";

const SendRadioInput = (props: any) => {
  const [state, setState] = useState(props.defaultValue || false);

  useEffect(() => {
    props.getValue(state);
  }, [state, props]);
  return (
    <div className="send-input">
      <p className={props.isRequired ? "req" : ""}>{props.label}</p>
      <div className="input-container flex gap-28">
        <div
          className={`radio ${state === false ? "active" : ""} radio-1`}
          onClick={() => setState(false)}
        >
          <div className="radio-label" style={{ order: props.revPos ? 2 : 0 }}>
            {props.negativeText || "No"}
          </div>
          <div className="radio-input">
            <div className="radio-indicator"></div>
          </div>
        </div>
        <div
          className={`radio ${state === true ? "active" : ""} radio-2`}
          onClick={() => setState(true)}
        >
          <div className="radio-label" style={{ order: props.revPos ? 2 : 0 }}>
            {props.positiveText || "Yes"}
          </div>
          <div className="radio-input">
            <div className="radio-indicator"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendRadioInput;
