export const industries = [
  {
    label: "Agriculture-Agricultural Cooperatives",
    slug: "Agriculture-AgriculturalCooperatives",
  },
  {
    label: "Agriculture-Agricultural Services",
    slug: "Agriculture-AgriculturalServices",
  },
  {
    label: "Commerce-Automobiles",
    slug: "Commerce-Automobiles",
  },
  {
    label: "Commerce-Digital Goods",
    slug: "Commerce-DigitalGoods",
  },
  {
    label: "Commerce-Physical Goods",
    slug: "Commerce-PhysicalGoods",
  },
  {
    label: "Commerce-Real Estate",
    slug: "Commerce-RealEstate",
  },
  {
    label: "Commerce-Digital Services",
    slug: "Commerce-DigitalServices",
  },
  {
    label: "Commerce-Legal Services",
    slug: "Commerce-LegalServices",
  },
  {
    label: "Commerce-Physical Services",
    slug: "Commerce-PhysicalServices",
  },
  {
    label: "Commerce-Professional Services",
    slug: "Commerce-ProfessionalServices",
  },
  {
    label: "Commerce-Other Professional Services",
    slug: "Commerce-OtherProfessionalServices",
  },
  {
    label: "Education-Nursery Schools",
    slug: "Education-NurserySchools",
  },
  {
    label: "Education-Primary Schools",
    slug: "Education-PrimarySchools",
  },
  {
    label: "Education-Secondary Schools",
    slug: "Education-SecondarySchools",
  },
  {
    label: "Education-Tertiary Institutions",
    slug: "Education-TertiaryInstitutions",
  },
  {
    label: "Education-Vocational Training",
    slug: "Education-VocationalTraining",
  },
  {
    label: "Education-Virtual Learning",
    slug: "Education-VirtualLearning",
  },
  {
    label: "Education-Other Educational Services",
    slug: "Education-OtherEducationalServices",
  },
  {
    label: "Gaming-Betting",
    slug: "Gaming-Betting",
  },
  {
    label: "Gaming-Lotteries",
    slug: "Gaming-Lotteries",
  },
  {
    label: "Gaming-Prediction Services",
    slug: "Gaming-PredictionServices",
  },
  {
    label: "Financial Services-Financial Cooperatives",
    slug: "FinancialServices-FinancialCooperatives",
  },
  {
    label: "Financial Services-Corporate Services",
    slug: "FinancialServices-CorporateServices",
  },
  {
    label: "Financial Services-Payment Solution Service Providers",
    slug: "FinancialServices-PaymentSolutionServiceProviders",
  },
  {
    label: "Financial Services-Insurance",
    slug: "FinancialServices-Insurance",
  },
  {
    label: "Financial Services-Investments",
    slug: "FinancialServices-Investments",
  },
  {
    label: "Financial Services-Agricultural Investments",
    slug: "FinancialServices-AgriculturalInvestments",
  },
  {
    label: "Financial Services-Lending",
    slug: "FinancialServices-Lending",
  },
  {
    label: "Financial Services-Bill Payments",
    slug: "FinancialServices-BillPayments",
  },
  {
    label: "Financial Services-Payroll",
    slug: "FinancialServices-Payroll",
  },
  {
    label: "Financial Services-Remittances",
    slug: "FinancialServices-Remittances",
  },
  {
    label: "Financial Services-Savings",
    slug: "FinancialServices-Savings",
  },
  {
    label: "Financial Services-Mobile Wallets",
    slug: "FinancialServices-MobileWallets",
  },
  {
    label: "Health-Gyms",
    slug: "Health-Gyms",
  },
  {
    label: "Health-Hospitals",
    slug: "Health-Hospitals",
  },
  {
    label: "Health-Pharmacies",
    slug: "Health-Pharmacies",
  },
  {
    label: "Health-Herbal Medicine",
    slug: "Health-HerbalMedicine",
  },
  {
    label: "Health-Telemedicine",
    slug: "Health-Telemedicine",
  },
  {
    label: "Health-Medical Laboratories",
    slug: "Health-MedicalLaboratories",
  },
  {
    label: "Hospitality-Hotels",
    slug: "Hospitality-Hotels",
  },
  {
    label: "Hospitality-Restaurants",
    slug: "Hospitality-Restaurants",
  },
  {
    label: "Nonprofits-Professional Associations",
    slug: "Nonprofits-ProfessionalAssociations",
  },
  {
    label: "Nonprofits-Government Agencies",
    slug: "Nonprofits-GovernmentAgencies",
  },
  {
    label: "Nonprofits-NGOs",
    slug: "Nonprofits-NGOs",
  },
  {
    label: "Nonprofits-Political Parties",
    slug: "Nonprofits-PoliticalParties",
  },
  {
    label: "Nonprofits-Religious Organizations",
    slug: "Nonprofits-ReligiousOrganizations",
  },
  {
    label: "Nonprofits-Leisure And Entertainment",
    slug: "Nonprofits-LeisureAndEntertainment",
  },
  {
    label: "Nonprofits-Cinemas",
    slug: "Nonprofits-Cinemas",
  },
  {
    label: "Nonprofits-Nightclubs",
    slug: "Nonprofits-Nightclubs",
  },
  {
    label: "Nonprofits-Events",
    slug: "Nonprofits-Events",
  },
  {
    label: "Nonprofits-Press And Media",
    slug: "Nonprofits-PressAndMedia",
  },
  {
    label: "Nonprofits-Recreation Centres",
    slug: "Nonprofits-RecreationCentres",
  },
  {
    label: "Nonprofits-Streaming Services",
    slug: "Nonprofits-StreamingServices",
  },
  {
    label: "Logistics-Courier Services",
    slug: "Logistics-CourierServices",
  },
  {
    label: "Logistics-Freight Services",
    slug: "Logistics-FreightServices",
  },
  {
    label: "Travel-Airlines",
    slug: "Travel-Airlines",
  },
  {
    label: "Travel-Ridesharing",
    slug: "Travel-Ridesharing",
  },
  {
    label: "Travel-Tour Services",
    slug: "Travel-TourServices",
  },
  {
    label: "Travel-Transportation",
    slug: "Travel-Transportation",
  },
  {
    label: "Travel-Travel Agencies",
    slug: "Travel-TravelAgencies",
  },
  {
    label: "Utilities-Cable Television",
    slug: "Utilities-CableTelevision",
  },
  {
    label: "Utilities-Electricity",
    slug: "Utilities-Electricity",
  },
  {
    label: "Utilities-Garbage Disposal",
    slug: "Utilities-GarbageDisposal",
  },
  {
    label: "Utilities-Internet",
    slug: "Utilities-Internet",
  },
  {
    label: "Utilities-Telecoms",
    slug: "Utilities-Telecoms",
  },
  {
    label: "Utilities-Water",
    slug: "Utilities-Water",
  },
  {
    label: "Retail",
    slug: "Retail",
  },
  {
    label: "Wholesale",
    slug: "Wholesale",
  },
  {
    label: "Restaurants",
    slug: "Restaurants",
  },
  {
    label: "Construction",
    slug: "Construction",
  },
  {
    label: "Unions",
    slug: "Unions",
  },
  {
    label: "Real Estate",
    slug: "RealEstate",
  },
  {
    label: "Freelance Professional",
    slug: "FreelanceProfessional",
  },
  {
    label: "Other Professional Services",
    slug: "OtherProfessionalServices",
  },
  {
    label: "Online Retailer",
    slug: "OnlineRetailer",
  },
  {
    label: "Other Education Services",
    slug: "OtherEducationServices",
  },
];

export const docType = [
  {
    id: "memart",
    label: "Memart, Constitution or Partnership Agreement",
    value: "memert",
    slug: "memert",
  },
  {
    id: "status_report",
    label: "Status Report from Incorporating Registry",
    value: "statusReport",
    slug: "statusReport",
  },
  {
    id: "bank_statement",
    label: "Bank Statement",
    value: "statusReport",
    slug: "statusReport",
  },
  {
    id: "utility_bill",
    label: "Utility Bill showing company address",
    value: "utilityBill",
    slug: "utilityBill",
  },
];
