import React, { ReactNode, useState } from "react";
import { useShowSelfie } from "../../../store/useShowSelfie";

interface ModalProps {
//   isOpen: boolean;
//   onClose: () => void;
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({  children }) => {
 const setSelfieModal = useShowSelfie((state) => state.setSelfieModal);
 const showSelfieModal = useShowSelfie((state) => state.showSelfieModal);

  const closeModal = () => {
    setSelfieModal(false);
    // onClose();
  };

  React.useEffect(() => {
    setSelfieModal(showSelfieModal);
  }, [showSelfieModal]);

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 transition-opacity ${
        showSelfieModal ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="absolute inset-0 bg-gray-900 opacity-50 cursor-pointer"
        onClick={closeModal}
      ></div>
      <div
        className="bg-white rounded-lg p-6 transform transition-all duration-300 ease-in-out overflow-y-auto overflow-x-hidden"
        style={{  }}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
