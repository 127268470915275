import create from 'zustand'

interface INgnBeneficiaries {
    showBeneficiaryDetails: boolean
    beneficiaries: any[]
    selectedBeneficiaryId: string

    setShowBeneficiaryDetails: (shouldShow: boolean)=> void
    setBeneficiaries: (data: any) => void
    setSelectedBeneficiaryId: (id: string) => void
}

export const useNgnBeneficiaryState = create<INgnBeneficiaries>((set) => ({
    showBeneficiaryDetails: false,
    beneficiaries: [],
    selectedBeneficiaryId: '',

    setShowBeneficiaryDetails: (shouldShow: boolean) => set((state: any) =>
    ({ showBeneficiaryDetails: shouldShow})),
    setBeneficiaries: (data: any) => set((state: any)=>({beneficiaries: data})),
    setSelectedBeneficiaryId:(id: string) => set((state: any)=>({selectedBeneficiaryId: id })),
}))


