import React from "react";
import { FaSpinner } from "react-icons/fa";
import { useInvitees } from "../../sevices/hooks/users";
import moment from "moment";


const InviteesCard = () => {
  const invitedUser = useInvitees(
    //@ts-ignore
    localStorage.getItem("user_id")
  );
  const { data, isLoading} = invitedUser;
  const dataLength = !!data?.data?.data?.length;
  const ivitee = data?.data?.data?.length;


  
  return (
    <>
      {/* {!isLoading && !dataLength && <div>people you've refferred</div>} */}

      {isLoading && !dataLength && (
        <FaSpinner size={24} color="#0eb683" className="animate-spin" />
      )}
      {ivitee === 0 ? (
        ""
      ) : (
        <div 
        key="{index}"
        className="bg-white  p-7 max-w-2xl rounded-lg mt-5">
          <h1 className="text-[#071827] mb-4 font-bold text-xl ">
            Referred Users ({ivitee})
          </h1>

          {data?.data?.data.map(
            (
              invitees: {
                firstName: string;
                lastName: string;
                createdAt: string;
                email:
                  | string
                  | number
                  | boolean
                  | React.ReactElement<
                      any,
                      string | React.JSXElementConstructor<any>
                    >
                  | React.ReactFragment
                  | React.ReactPortal
                  | null
                  | undefined;
                status:
                  | string
                  | number
                  | boolean
                  | React.ReactElement<
                      any,
                      string | React.JSXElementConstructor<any>
                    >
                  | React.ReactFragment
                  | null
                  | undefined;
              },
              index: any
            ) => {
         
              const date = moment(invitees?.createdAt).startOf("day").fromNow();

         
              
              return (
                <div 
               key={index.id}
                className=" border-b border-b-[#E8E9EB]  mt-2 md:flex justify-between items-center">
                  <div className="flex items-center gap-3 w-52">
                    <figure>
                      <div className="w-10 h-10 bg-[#E5F2EA] rounded-full text-[#0eb683] md:mb-3 flex items-center justify-center font-bold">
                        {invitees?.firstName.charAt(0).toUpperCase()}
                        {invitees?.lastName.charAt(0).toUpperCase()}
                      </div>
                    </figure>
                    <div className="flex  md:mb-3">
                      <div className="">
                        <div className="name text-[#071827] font-bold">
                          {invitees?.firstName.charAt(0).toUpperCase() +
                            invitees?.firstName.slice(1)}{" "}
                          {invitees?.lastName.charAt(0).toUpperCase() +
                            invitees?.lastName.slice(1)}
                        </div>
                        <div className="email text-[#5C6F7F] text-sm">
                          {invitees?.email}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="hidden md:flex time text-[#5C6F7F] text-sm">
                    {date}
                  </div>

                  <div className="xxs:flex xxs:items-center xxs:justify-between xxs:my-3">
                    <div className="md:hidden time text-[#5C6F7F] text-sm">
                      {date}
                    </div>
                    <div
                      className={` xxs:inline-block ${
                        invitees?.status === "pending"
                          ? "text-[#f3efed] bg-[#ff8036b2] rounded-full px-4 py-1 "
                          : "text-[#0eb683] bg-[#0eb6845e] rounded-full px-5 py-1 "
                      }`}
                    >
                      {invitees?.status}
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      )}
    </>
  );
};

export default InviteesCard;
