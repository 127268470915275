import create from "zustand";

interface IReciept {
  showTransactionReciept: boolean;
  transactions: any[];
  beneficiaryReference: string;

  setShowTransactionReciept: (shouldShow: boolean) => void;
  setTransactions: (data: any) => void;
  setBeneficiaryReference: (ref: string) => void;
}

export const useRecieptState = create<IReciept>((set) => ({
  showTransactionReciept: false,
  transactions: [],
  beneficiaryReference: "",

  setShowTransactionReciept: (shouldShow: boolean) =>
    set((state: any) => ({ showTransactionReciept: shouldShow })),
  setTransactions: (data: any) => set((state: any) => ({ transactions: data })),
  setBeneficiaryReference: (ref: string) =>
    set((state: any) => ({ beneficiaryReference: ref })),
}));
