import axios from "axios";

export const BASEURL =
  process.env.REACT_APP_BASE_URL || "https://dev-backend.ttfcx.com";

export const api = {
  users: {
    getUser: (userId: string) => `/users/${userId}`,
    verify: "/users/login/verify",
    updateUser: (userId: string) => `/users/${userId}`,
    requestAccess: "/users/request-access",
    invitationVerify: "/users/invitations/verify",
    individualKyc: "/users/individual/kyc",
    users: "/users/part",
    businessKyc: "/users/business/kyc",
    businessAcc: "/users/business",
    userLogin: "/users/login",
    recoverPassword: "/users/recover-password",
    BankList: "/users/lbp/local/banks",
    resolveBankName: "/users/lbp/verifyAccountNumber",
    resetPassword: "/users/reset-password",
    usersInvitations: "/users/invitations",
    generateBeneficiaryForm: "/users/forms/beneficiary/generate",
    invitees: (userId: string) => `/users/${userId}/invitees`,
    otpRequest: (userId: string) => `/users/${userId}/request-otp`,
    signUpVerify: "/users/signup/verify",
    updateBank: "/users/update-bank",
    checkEmail: "/users/check-email",
    userDocs: "/users/documents",
    businessCorporate: "/users/business/corporate",
    businessPersons: "/users/business/contact",
    createSmileAccount: "/users/lbp/customer",
    verifySmile: "/users/individual",
    refreshKYC: "/users/individual/refresh",
    businessUserKYC: "/users/business/KYCP/kyc",
    updateUserInfo: (userId: string) => `/users/onboard/${userId}`,
    onboardingRefresh: "/users/individual/refresh",
    onboardingChecks: "/users/onboarding/continue",
    onboardingCompleted: "/users/onboarding/complete",
    businessAccountKYC: "/users/business-user",
  },
  metadata: {
    banks: "/metadata/banks",
    ratesUsd: "/metadata/rates/usd",
    shutterScoreBanks: (country: string) =>
      `/metadata/shutterscore/banks/${country}`,
    shutterScoreResolve: "/metadata/shutterscore/banks/resolve",
  },
  wallet: {
    AccountNgn: "/wallet/accounts/ngn",
    walletAccount: (currency: string) => `/wallet/accounts/${currency}`,
    AccountUsd: "/wallet/accounts/usd",
    AccActivity: "/wallet/accounts/activity",
    BuyUsd: "/wallet/buy/usd",
    Beneficiaries: "/wallet/beneficiaries",
    ExternalBeneficiaries: "/external/beneficiaries",
    buyCurrency: (currencyCode: string) => `/wallet/buy/${currencyCode}`,
    beneficiaryIdentity: (beneficiaryId: string) =>
      `/wallet/beneficiaries/${beneficiaryId}`,
    transfersUsd: "/wallet/transfer/usd",
    accountWithdrawal: "/wallet/accounts/withdrawal",
    transactionReference: (reference: string) =>
      `/wallet/transactions/${reference}`,
    createNgnBeneficiary: "/wallet/ngn/beneficiaries",
    ngnBeneficiaryIdentity: (beneficiaryId: string) =>
      `/wallet/ngn/beneficiaries/${beneficiaryId}`,
    deleteBeneficiary: (beneficiaryId: string) =>
      `/wallet/beneficiaries/${beneficiaryId}`,
    sellFlow: "/wallet/sell/usd",
    walletBalance: (currency: string) => `/wallet/accounts/balance/${currency}`,
    activityByDate: "/wallet/transactions/dates",
    activityByRef: (reference: string) =>
      `/wallet/transactions/activity/${reference}`,
  },
  flag: {},
  tags: {
    ratesUsd: "/metadata/rates/usd",
    ratesEur: "/metadata/rates/eur",
    gbpRates: "/metadata/rates/gbp",
    usdcRates: "/metadata/rates/usdc",
    usdtRates: "/metadata/rates/usdt",
    swiftCountries: "/metadata/countries/swift",
    ibanCountries: "/metadata/countries/iban",
    abaCountries: "/metadata/countries/aba",
    states: "/metadata/states",
    abaDistricts: "/metadata/districts/aba",
    countries: "/users/lbp/countries",
    newStates: "/users/lbp/ng/states",
  },
  externals: {
    externalBeneficiary: "/external/beneficiaries",
  },
  admin: {
    fees: "/admin/fees",
    adminSettings: "/admin/settings",
    adminPayouts: (id: string) => `/admin/payouts/${id}`,
  },
};

export const makePostRequest = async (
  data: any,
  url: string,
  includeAuthHeader: boolean = true
) => {
  return await axios.post(`${BASEURL}${url}`, data, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken") as string,
    },
  });
};

// export const makePostRequest = async (
//   data: any,
//   url: string,
//   document?: File,
//   includeAuthHeader: boolean = true
// ) => {
//   const formData = new FormData();
//   formData.append('data', JSON.stringify(data));
//   if (document) {
//     formData.append('document', document);
//   }

//   const headers: any = {};
//   if (includeAuthHeader) {
//     headers['x-access-token'] = localStorage.getItem('accessToken') as string;
//   }

//   headers['Content-Type'] = 'application/json';

//   return await axios.post(`${BASEURL}${url}`, formData, {
//     headers: headers,
//   });
// };

export const makeDeleteRequest = async <T = any>(
  url: string,
  includeAuthHeader: boolean = true
) => {
  return await axios.delete<T>(`${BASEURL}${url}`, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken") as string,
    },
  });
};

// export const makePostRequest = async (data: any, url: string) => {
//   const temp = await fetch(`${BASEURL}${url}`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   });
//   if (temp.headers.get("Content-Type")?.indexOf("json") !== -1) {
//     const result = await temp.json();
//     return result;
//   }
//   return Promise.reject( await temp.text());
// };

export const makePostRequestWithCustomHeaders = async (
  data: any,
  url: string,
  headers?: any
) => {
  const temp = await fetch(`${BASEURL}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "x-access-token": localStorage.getItem("accessToken") as string,
      ...headers,
    },
    body: JSON.stringify(data),
  });

  if (!temp.ok) {
    throw new Error(await temp.text());
  }

  const result = await temp.json();
  return result;
};

// export const makeGetRequest = async (url: string, headers?: any) => {
//   const temp = await fetch(`${BASEURL}${url}`, {
//     method: "GET",
//     headers: {
//       ...headers,
//     },
//   });
//   const result = await temp.json();
//   return result;
// };

export const makePatchRequest = async (
  data: any,
  url: string,
  includeAuthHeader: boolean = true
) => {
  return await axios.patch(`${BASEURL}${url}`, data, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken") as string,
    },
  });
};
export const makePutRequest = async (
  data: any,
  url: string,
  includeAuthHeader: boolean = true
) => {
  return await axios.put(`${BASEURL}${url}`, data, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken") as string,
    },
  });
};

export const makeGetRequest = async <T = any>(
  url: string,
  includeAuthHeaders: boolean = true
) => {
  const temp = await axios.get<T>(`${BASEURL}${url}`, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken") as string,
    },
  });
  return temp;
};
