import React, { useEffect } from 'react'

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];

const AutoLogout = ({children}:{children: any}) => {
    let timer: NodeJS.Timeout | undefined;

    useEffect(() => {
     Object.values(events).forEach((item)=>{
        window.addEventListener(item, () =>{
  resetTimer()
  handleLogOut()
        })
     })
    }, [])
    

    const resetTimer = ()=>{
        if(timer) clearTimeout(timer)
    }
        
    const handleLogOut = ()=>{
        timer = setTimeout(() => {
            resetTimer()
            Object.values(events).forEach((item) => {
                window.removeEventListener(item, resetTimer)
            });
            logoutAction()
            
        }, 600000);
    }

    const logoutAction = () =>{
        window.localStorage.removeItem("accessToken")
        window.location.pathname ="/login"
    }
  return children;
}

export default AutoLogout