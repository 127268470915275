import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import {
  useAccActivity,
  useGetTransactionsByDates,
  useGetTransactionsByRef,
} from "../../sevices/hooks/wallets";

import Pagination from "./Pagination";
import GlobalFilter from "./GlobalFilter";
import { useTabs } from "../widgets/TabWidget";
import { TabSelector } from "../widgets/TabSelector";
// import { Transaction, dColumns } from "./column";
import { divide } from "lodash";
import { Column } from "react-table";
import { Tooltip } from "../base-components/Tooltip";
import { AiFillCaretDown, AiOutlineMinus } from "react-icons/ai";
import { MdAdd, MdDownloadDone, MdDownloadForOffline } from "react-icons/md";
import ReactLoading from "react-loading";
import ImageX from "../../assets/misc/TTFCX__LOGO.png";
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
  FiSend,
} from "react-icons/fi";
import jsPDF from "jspdf";
import autoTable, { Color } from "jspdf-autotable";

import Popover from "../Popper";
import { CSVLink } from "react-csv";
//   import jsPDF from 'jspdf';
// import autoTable from 'jspdf-autotable';
import html2canvas from "html2canvas";

const moment = require("moment");

export interface IData {
  data: Transaction[];
}

const Container = ({ data }: IData) => {
  let currentPage = 1;
  const [currentPageIndex, setCurrentPageIndex] = useState(currentPage);
  function chunkArray<T>(array: T[], size: number) {
    let result = [];
    for (let i = 0; i < array?.length; i += size) {
      let chunk = array?.slice(i, i + size);
      result.push(chunk);
    }
    return result;
  }

  return (
    <>
      {data ? (
        chunkArray(data, 10)[currentPageIndex - 1]?.map((trx, index) => {
          const transactionType = (type: string) => {
            let icon;

            switch (type) {
              case "debit":
                icon = <AiOutlineMinus size={15} className="font-extrabold" />;
                break;
              case "credit":
                icon = <MdAdd size={15} className="font-extrabold" />;
                break;
              default:
                icon = <FiSend size={15} className="font-extrabold" />;
                break;
            }

            return icon;
          };
          const icon = transactionType(trx?.type);

          const narration = (nar: string) => {
            return <span className=" truncate w-24 ">{nar}</span>;
          };

          const currencySymbol = (currency: string, amount: string) => {
            let currencySymbol = "";

            switch (currency?.toUpperCase()) {
              case "NGN":
                currencySymbol = "₦ ";
                break;
              case "USD":
                currencySymbol = "$ ";
                break;
              default:
                break;
            }

            return `${currencySymbol}${amount}`;
          };
          const amount = currencySymbol(trx?.currency, trx?.amount);
          const narrations = narration(trx?.narration);

          const myDate = new Date(trx?.createdAt);
          const formatDate = moment(myDate);
          const Cdate = formatDate.format("lll");

          const currency = trx?.currency;

          const mapping = (value: string) => {
            const colorMap: { [key: string]: { bg: string; color: string } } = {
              processing: { bg: "#e5e5e5", color: "#5C6F7F" },
              pending: { bg: "#fff2eb", color: "#FF7F36" },
              successful: { bg: "#e2fef0", color: "#0EB683" },
              cancelled: { bg: "#e2fef0", color: "#f51505" },
              failed: { bg: "#f51505", color: "#e2fef0" },
            };

            const status =
              value?.toLowerCase().includes("success") ||
              value?.toLowerCase().includes("completed")
                ? "successful"
                : value?.toLowerCase();
            const { bg, color } = colorMap[status] || {};

            const stats = (status: string) => {
              return status.replace(/(^|\s|-)(\w)/g, (match, p1, p2) => {
                return p1 + p2.toUpperCase();
              });
            };

            return (
              <span
                className="inline-block px-3 py-1 rounded-full text-sm font-bold mt-2"
                style={{ backgroundColor: bg, color }}
              >
                {stats(status)}
              </span>
            );
          };
          const color = mapping(trx?.status);
          return (
            <div className="  py-4 px-2 bg-[#fff] w-full border-y border-slate-100 ">
              <div className="flex items-center justify-between space-y-3">
                <span className="text-[#5C6F7F]">
                  {" "}
                  {currency} {trx?.type.charAt(0).toUpperCase()}
                  {trx?.type.slice(1)}
                </span>
                <span className="text-[#071827] font-semibold">{amount}</span>
              </div>
              <div className="flex items-center justify-between">
                {narrations}
                <span>{color}</span>
              </div>
              <div className="flex items-center justify-between space-y-3">
                <span>{Cdate}</span>
                <span className="text-sm text-[#0eb683]">View</span>
              </div>
              {/* <footer className=" justify-end p-3">
                <div className="bottom-nav flex gap-2">
                  <button
                    className={
                      (currentPageIndex === 1 ? "no-items" : "") +
                      " flex-1 p-1 px-2 bg-slate-100 rounded-md text-[#5C6F7F]"
                    }
                    onClick={() =>
                      currentPageIndex !== 1
                        ? setCurrentPageIndex(
                            currentPageIndex > 2 ? currentPageIndex - 2 : 1
                          )
                        : null
                    }
                  >
                    <FiChevronsLeft />
                  </button>
                  <button
                    className={
                      (currentPageIndex === 1 ? "no-items" : "") +
                      " flex-1 p-1 px-2 bg-slate-100 rounded-md text-[#5C6F7F]"
                    }
                    onClick={() =>
                      currentPageIndex !== 1
                        ? setCurrentPageIndex(currentPageIndex - 1)
                        : null
                    }
                  >
                    <FiChevronLeft />
                  </button>
                  <div className="pagination flex gap-2 items-center">
                    {chunkArray(tableData, itemsPerPage).map((_, index) => {
                      return (
                        <button
                          key={index}
                          className={
                            currentPageIndex === index + 1
                              ? "active-page-index bg-[#0eb683] text-white p-1 px-3 flex-1 rounded-md"
                              : "flex-1 p-1 px-3 hover:bg-slate-100 rounded-md"
                          }
                          onClick={() => setCurrentPageIndex(index + 1)}
                        >
                          {index + 1}
                        </button>
                      );
                    })}
                  </div>
                  <button
                    className={
                      (currentPageIndex ===
                      chunkArray(tableData, itemsPerPage).length
                        ? "no-items"
                        : "") +
                      " flex-1 p-1 px-2 bg-slate-100 rounded-md text-[#5C6F7F]"
                    }
                    onClick={() =>
                      currentPageIndex !==
                      chunkArray(tableData, itemsPerPage).length
                        ? setCurrentPageIndex(currentPageIndex + 1)
                        : null
                    }
                  >
                    <FiChevronRight />
                  </button>
                  <button
                    className={
                      (currentPageIndex ===
                      chunkArray(tableData, itemsPerPage).length
                        ? "no-items"
                        : "") +
                      " flex-1 p-1 px-2 bg-slate-100 rounded-md text-[#5C6F7F]"
                    }
                    onClick={() =>
                      currentPageIndex !==
                      chunkArray(tableData, itemsPerPage).length
                        ? setCurrentPageIndex(
                            currentPageIndex + 2 >=
                              chunkArray(tableData, itemsPerPage).length
                              ? chunkArray(tableData, itemsPerPage).length - 1
                              : currentPageIndex + 2
                          )
                        : null
                    }
                  >
                    <FiChevronsRight />
                  </button>
                </div>
              </footer> */}
            </div>
          );
        })
      ) : (
        <div>Loading</div>
      )}
    </>
  );
};

export type Transaction = {
  currency: string;
  serialNumber: any;
  createdAt: string;
  transactionRef: string;
  type: string;
  amount: string;
  narration: string;
  status: string;
};

export interface IColumn {
  serialNumber: any;
  createdAt: string;
  transactionRef: string;
  type: string;
  amount: string;
  narration: string;
  status: string;
  currency: string;
}

// export

const TransactionTable = ({
  tableData,
  tabs,
}: {
  tableData: Transaction[];
  tabs: string[];
}) => {
  const [selectedTab, setSelectedTab] = useTabs(tabs);
  const [chosenTab, setChosenTab] = useState("All");
  const [Tdata, setTdata] = useState(tableData);
  const data = useMemo(() => Tdata, [Tdata]);
  const [download, setDownload] = useState(false);
  const [exportType, setExportType] = useState("PDF");
  const [isExport, setIsExport] = useState(true);
  const transactionsByDate = useGetTransactionsByDates();
  const [ref, setRef] = useState("");
  const transactionRef = useGetTransactionsByRef(ref);
  const [isDownLoad, setIsDownload] = useState(false);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const dColumns: readonly Column<Transaction>[] = [
    {
      Header: "SN",
      accessor: "serialNumber",
      //@ts-ignore
      Cell: ({ value, row }) => {
        const index = row?.index;
        return (index !== undefined ? index + 1 : "").toString();
      },
    },
    {
      Header: "TIMESTAMP (GMT+1)",
      accessor: "createdAt",
      Cell: ({ value }) => {
        const myDate = new Date(value);
        const formatDate = moment(myDate);
        const Cdate = formatDate.format("lll");
        return Cdate;
      },
    },
    {
      Header: "TRANSACTION REF",
      accessor: "transactionRef",
      Cell: ({ value, row }: { value: string; row: any }) => {

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip message={value}>
              <span
                onClick={() => setRef(value)}
                className="truncate hover:underline cursor-pointer text-[#0eb683]"
                style={{ marginRight: "0.5rem" }}
              >
                {value.slice(0, 20)}
              </span>
            </Tooltip>
          </div>
        );
      },
    },
    {
      Header: "TYPE",
      accessor: (row) =>
        ` ${
          typeof row?.currency === "string"
            ? row?.currency.toUpperCase()
            : row?.currency
        } ${row?.type.charAt(0).toUpperCase()}${row?.type.slice(1)} `,
      Cell: ({ value }: { value: string }) => (
        <div className="">
          <span className="flex items-center whitespace-nowrap">{value}</span>
        </div>
      ),
    },
    {
      Header: "AMOUNT",
      accessor: (row) => {
        let currencySymbol = "";
        const formatter = new Intl.NumberFormat();
        const amount = formatter.format(Number(row?.amount));
        switch (row?.currency?.toUpperCase()) {
          case "NGN":
            currencySymbol = "₦ ";
            break;
          case "USD":
            currencySymbol = "$ ";
            break;
          default:
            break;
        }

        return (
          <div className="whitespace-nowrap">{`${currencySymbol}${amount}`}</div>
        );
      },
    },

    {
      Header: "NARRATION",
      accessor: "narration",
      Cell: ({ value }) => (
        <div className="flex items-left">
          <Tooltip message={value}>
            <div className="truncate w-40 cursor-pointer">{value}</div>
          </Tooltip>
        </div>
      ),
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ value }) => {
        const colorMap: { [key: string]: { bg: string; color: string } } = {
          processing: { bg: "#e5e5e5", color: "#5C6F7F" },
          pending: { bg: "#fff2eb", color: "#FF7F36" },
          successful: { bg: "#e2fef0", color: "#0EB683" },
          cancelled: { bg: "#e2fef0", color: "#f51505" },
          failed: { bg: "#f51505", color: "#e2fef0" },
        };

        const status =
          value?.toLowerCase().includes("success") ||
          value?.toLowerCase().includes("completed")
            ? "successful"
            : value?.toLowerCase();
        const { bg, color } = colorMap[status] || {};
        const stats = (status: string) => {
          return status.replace(/(^|\s|-)(\w)/g, (match, p1, p2) => {
            return p1 + p2.toUpperCase();
          });
        };

        return (
          <span
            className="inline-block px-3 py-1 rounded-full text-sm font-bold"
            style={{ backgroundColor: bg, color }}
          >
            {stats(status)}
          </span>
        );
      },
    },
  ];
  const columns = useMemo(() => dColumns, []);



  const table = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    state,
    setGlobalFilter,
    prepareRow,
    nextPage,
    gotoPage,
    pageCount,
    setPageSize,
    previousPage,
    pageOptions,
    canNextPage,
    canPreviousPage,
    footerGroups,
  } = table;

  const { globalFilter, pageIndex, pageSize } = state;

  useEffect(() => {
    if (chosenTab === "All") {
      setTdata(tableData);
    } else {
      setTdata(
        tableData.filter(
          (d) => d?.currency?.toLowerCase() === chosenTab.toLowerCase()
        )
      );
    }
  }, [chosenTab]);

  const pdfdownload = () => {
    setDownload(true);
    setTimeout(() => {
      setDownload(false);
    }, 3000);
  };
  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newStartDate = event.target.value;
    const startDateObj = new Date(newStartDate);
    const isoStartDate = startDateObj.toISOString();
    setStartDate(isoStartDate);
  };
  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = event.target.value;
    const endDateObj = new Date(newEndDate);
    const isoEndDate = endDateObj.toISOString();
    setEndDate(isoEndDate);
  };
  const txByDate = () => {
    setLoading(true);
    transactionsByDate
      .mutateAsync({ startDate: startDate, endDate: endDate })
      .then((res) => {
        setLoading(false);
        setIsDownload(true);

        setTdata(res.data.data);
      })
      .catch();
  };

  const filterTX = () => {
    txByDate();
  };

  const convertToColor = (colorString: string): Color | undefined => {
    if (/^#[0-9A-Fa-f]{6}$/.test(colorString)) {
      return colorString;
    }
    return undefined;
  };
  const currentDate = new Date();

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };
  //@ts-ignore
  const dateTimeFormatter = new Intl.DateTimeFormat("en-US", options);
  const formattedDate = dateTimeFormatter.format(currentDate);

  const exportPDF = () => {

    const orientation = "landscape";
    const doc = new jsPDF(orientation);
    doc.setFontSize(12);
    doc.setFont("Helvetica", "bold");
    doc.setTextColor("#333333");
    const headers = [
      [
        "SN",
        "TIMESTAMP",
        "TRANSACTION REF",
        "TYPE",
        "CURRENCY",
        "AMOUNT",
        "NARRATION",
        "STATUS",
      ],
    ];

    const title = "Transaction Report";
    const subtext = `Generated from TTFCX: ${formattedDate}`;

    const titleFontSize = 14;
    const subtextFontSize = 10;
    const subtextFontWeight = "normal";
    const imgWidth = 50;
    const imgHeight = 13;
    const titleWidth = doc.getTextWidth("Transaction");
    const pageWidth = doc.internal.pageSize.getWidth();
    const x = pageWidth - imgWidth - titleWidth - 0;
    const y = 10;

    const Arrdata = [];

    for (let i = 1; i < Tdata.length; i++) {
      const maxTextWidth = 50;
      const truncatedTransactionRef = doc.splitTextToSize(
        Tdata[i].transactionRef,
        maxTextWidth
      );
      const myDate = new Date(Tdata[i]?.createdAt);
      const date = moment(myDate).format("lll");

      const row = [
        i,
        date,
        truncatedTransactionRef,
        Tdata[i - 1].type,
        Tdata[i - 1].currency,
        Tdata[i - 1].amount,
        Tdata[i - 1].narration,
        Tdata[i - 1].status,
      ];

      Arrdata.push(row);
    }

    const convertedColor = convertToColor("#0eb683");

    const totalPages = Math.ceil(Arrdata.length / 20);

    for (let page = 0; page < totalPages; page++) {
      if (page > 0) {
        doc.addPage();
      }
      doc.addImage(ImageX, "PNG", x, y, imgWidth, imgHeight);
      doc.setFontSize(titleFontSize);
      doc.text(title, 15, 15);
      doc.setFontSize(subtextFontSize);
      doc.setFont("Helvetica", subtextFontWeight);
      doc.setTextColor("#797979");
      doc.text(subtext, 15, 22);

      const startIndex = page * 20;
      const endIndex = Math.min((page + 1) * 20, Arrdata.length);
      const pageData = Arrdata.slice(startIndex, endIndex);

      autoTable(doc, {
        head: headers,
        body: pageData,
        startY: 30,

        headStyles: { fillColor: convertedColor },
      });
    }

    doc.save("TTFCX_transaction-report.pdf");
  };

  const headers = [
    { label: "S/N", key: "sn" },
    { label: "TIMESTAMP (GMT +1)", key: "createdAt" },
    { label: "TRANSACTION REF", key: "transactionRef" },
    { label: "TYPE", key: "type" },
    { label: "CURRENCY", key: "currency" },
    { label: "AMOUNT", key: "amount" },
    { label: "NARRATION", key: "narration" },
    { label: "STATUS", key: "status" },
  ];

  const toggleSlide = () => {
    setIsExport((prev) => !prev);
  };

  return (
    <div>
      <div className="mb-4">
        <div className="tabs flex gap-1 pt-5 pb-1 border-b-2 border-slate-300">
          {tabs.map((tab, index) => (
            <TabSelector
              key={index}
              className={`cursor-pointer relative bg-transparent w-16 text-center p-2  text-[#5c6f7f]${
                selectedTab === tab
                  ? "before:block before:w-full  before:bg-[#0eb683] before:absolute before:bottom-[-6px] before:left-0 before:h-1 before:transition-all font-bold text-[#0eb683]"
                  : ""
              }`}
              isActive={selectedTab === tab}
              onClick={() => {
                setSelectedTab(tab);

                setChosenTab(tab);
              }}
            >
              {tab}
            </TabSelector>
          ))}
        </div>
      </div>
      <div className="  flex flex-col bg-white mb-8 overflow-y-auto">
        <div className="overflow-x-auto sm:-mx-0 ">
          <div className="inline-block md:min-w-full xxs:w-full sm:px-6 lg:px-8 ">
            <div className="flex items-center gap-3 ">
              <div className="w-[100%]">
                <GlobalFilter
                  setFilter={setGlobalFilter}
                  filter={globalFilter}
                />
              </div>

              <div className="flex h-full items-center py-[11px] px-4 border border-[#D0D5DD] rounded-md hover:border-[#0eb683]">
                <Popover
                  // title={"OPTIONS"}
                  placementOrder="auto"
                  buttonContent={
                    <>
                      <div className="flex">
                        <span>Export</span>
                        <span className="mt-1">
                          <AiFillCaretDown />
                        </span>
                      </div>
                    </>
                  }
                >
                  <>
                    {
                      <div className="flex flex-col   m-4">
                        {/* <p className="font-medium  text-[#333333]">
                          Export
                        </p> */}

                        <div className="tabs flex relative">
                          <div
                            onClick={toggleSlide}
                            className={`flex items-center cursor-pointer  justify-center text-md md:text-sm font-semibold flex-1 py-2 ${
                              !isExport ? "text-[#aabecf]" : "text-[#0EB683]"
                            }`}
                          >
                            Current Screen
                          </div>
                          <div
                            onClick={toggleSlide}
                            className={`flex items-center cursor-pointer justify-center text-sm  md:text-sm font-semibold flex-1 p-1 md:py-2 ${
                              isExport ? "text-[#aabecf]" : "text-[#0EB683]"
                            }`}
                          >
                            Custom Dates
                          </div>
                          <div
                            className={`indicator h-[2px] w-1/2 bg-[#0EB683] transition-all duration-500 absolute bottom-0 ${
                              isExport ? "left-0" : "left-[50%]"
                            } rounded-xl`}
                          ></div>
                        </div>

                        <div className=" flex  overflow-hidden">
                          <div
                            className={` max-w-md w-full transition-all duration-1000 shrink-0 ${
                              isExport
                                ? "translate-x-0 opacity-100"
                                : "-translate-x-full opacity-0"
                            }`}
                          >
                            {["PDF", "CSV"].map((name, index) => (
                              <div
                                key={index}
                                className="flex items-center  my-1"
                              >
                                <input
                                  id={name}
                                  name={name}
                                  type="checkbox"
                                  defaultChecked={
                                    exportType === name ? true : false
                                  }
                                  checked={exportType === name ? true : false}
                                  onChange={(e) => setExportType(name)}
                                  className="h-4 w-4 text-[#0eb683] accent-[#0eb683] focus:ring-[#fff] border-gray-300 rounded checked:bg-[#fff]"
                                />
                                <label
                                  htmlFor={name}
                                  className="ml-2 mr-8 mt-6 text-sm text-blue"
                                >
                                  {name} Format
                                </label>
                              </div>
                            ))}

                            {exportType === "PDF" && (
                              <button
                                onClick={() => exportPDF()}
                                className="px-10 py-[10px] border-[1px] border-[#0eb683]  rounded-lg bg-[#0eb683] text-white font-semibold transition-all duration-300 hover:shadow-md hover:bg-white hover:text-[#0eb683] flex items-center justify-center disabled:opacity-50 disabled:pointer-events-none mt-14"
                              >
                                Proceed
                              </button>
                            )}
                            {exportType === "CSV" && (
                              <CSVLink
                                filename={"financial-asset-report.csv"}
                                className="px-10 py-[10px] border-[1px] border-[#0eb683]  rounded-lg bg-[#0eb683] text-white font-semibold transition-all duration-300 hover:shadow-md hover:bg-white hover:text-[#0eb683] flex items-center justify-center disabled:opacity-50 disabled:pointer-events-none mt-12"
                                headers={headers}
                                data={
                                  Tdata
                                    ? Tdata.map((div: any, index: number) => {
                                        const myDate = new Date(div?.createdAt);
                                        const date =
                                          moment(myDate).format("lll");
                                        return {
                                          sn: index + 1,
                                          createdAt: date,
                                          transactionRef: div.transactionRef,
                                          type: div.type,
                                          currency: div?.currency,
                                          amount: div.amount,
                                          narration: div.narration,
                                          status: div.status,
                                        };
                                      })
                                    : []
                                }
                              >
                                Proceed
                              </CSVLink>
                            )}
                          </div>
                          <div
                            className={` w-full transition-all duration-1000 shrink-0 ${
                              !isExport
                                ? "-translate-x-full opacity-100"
                                : "translate-x-100 opacity-0"
                            }`}
                          >
                            {["PDF", "CSV"].map((name, index) => (
                              <div
                                key={index}
                                className="flex items-center  my-1"
                              >
                                <input
                                  id={name}
                                  name={name}
                                  type="checkbox"
                                  defaultChecked={
                                    exportType === name ? true : false
                                  }
                                  checked={exportType === name ? true : false}
                                  onChange={(e) => setExportType(name)}
                                  className="h-4 w-4 text-[#0eb683] accent-[#0eb683] focus:ring-[#fff] border-gray-300 rounded checked:bg-[#fff]"
                                />
                                <label
                                  htmlFor={name}
                                  className="ml-2 mr-8 mt-6 text-sm text-blue"
                                >
                                  {name} Format
                                </label>
                              </div>
                            ))}
                            <hr />
                            <div className="grid grid-cols-2 items-center gap-4 my-3 ">
                              <label
                                htmlFor="fromDate"
                                className="text-[14px] leading-normal text-[#383535] font-medium"
                              >
                                From Date
                                <input
                                  type="date"
                                  name="fromDate"
                                  id="fromDate"
                                  className="border border-[#E2E9F0] block p-2 w-full  outline-[#0eb683] rounded-md mt-1"
                                  onChange={handleStartDateChange}
                                />
                              </label>
                              <label
                                htmlFor="toDate"
                                className="text-[14px] leading-normal text-[#383535] font-medium"
                              >
                                To Date
                                <input
                                  type="date"
                                  name="toDate"
                                  id="toDate"
                                  className="border border-[#E2E9F0] block p-2 w-full  outline-[#0eb683] rounded-md mt-1"
                                  onChange={handleEndDateChange}
                                />
                              </label>
                            </div>
                            <div className="grid grid-cols-2 items-center gap-4">
                              <button
                                onClick={filterTX}
                                disabled={startDate === "" && endDate === ""}
                                className="px-12 py-[10px] border-[1px] border-[#0eb683] rounded-lg bg-[#0eb683] text-white font-semibold transition-all duration-300 hover:shadow-md flex items-center justify-center disabled:opacity-50 disabled:pointer-events-none "
                              >
                                {loading ? (
                                  <div className="m-auto">
                                    <ReactLoading
                                      type="spinningBubbles"
                                      color="#fff"
                                      height="27px"
                                      width="27px"
                                    />
                                  </div>
                                ) : (
                                  "Filter"
                                )}
                              </button>
                              {isDownLoad &&
                                startDate !== "" &&
                                endDate !== "" && (
                                  <>
                                    {exportType === "PDF" && (
                                      <button
                                        onClick={() => exportPDF()}
                                        className="px-12 py-[10px] border-[1px] border-[#0eb683]  rounded-lg bg-[#0eb683] text-white font-semibold transition-all duration-300 hover:shadow-md hover:bg-white hover:text-[#0eb683] flex items-center justify-center disabled:opacity-50 disabled:pointer-events-none"
                                      >
                                        Download
                                      </button>
                                    )}
                                    {exportType === "CSV" && (
                                      <CSVLink
                                        filename={"financial-asset-report.csv"}
                                        className="px-10 py-[10px] border-[1px] border-[#0eb683]  rounded-lg bg-[#0eb683] text-white font-semibold transition-all duration-300 hover:shadow-md hover:bg-white hover:text-[#0eb683] flex items-center justify-center disabled:opacity-50 disabled:pointer-events-none"
                                        headers={headers}
                                        data={
                                          Tdata
                                            ? Tdata.map(
                                                (div: any, index: number) => {
                                                  const myDate = new Date(
                                                    div?.createdAt
                                                  );
                                                  const date =
                                                    moment(myDate).format(
                                                      "lll"
                                                    );
                                                  return {
                                                    sn: index + 1,
                                                    createdAt: date,
                                                    transactionRef:
                                                      div.transactionRef,
                                                    type: div.type,
                                                    currency: div?.currency,
                                                    amount: div.amount,
                                                    narration: div.narration,
                                                    status: div.status,
                                                  };
                                                }
                                              )
                                            : []
                                        }
                                      >
                                        Download
                                      </CSVLink>
                                    )}
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </>
                </Popover>
              </div>
            </div>
            <div className="overflow-x-auto xxs:hidden md:block">
              <table
                {...getTableProps()}
                className="appearance-none bg-white min-w-full  mb-6 "
                id="my-table"
              >
                <thead className="bg-[#fff] appearance-none">
                  {headerGroups.map(
                    (headerGroup: {
                      getHeaderGroupProps: () => {
                        [x: string]: any;
                        key: any;
                      };
                      headers: any[];
                    }) => {
                      const { key, ...restHeaderProps } =
                        headerGroup.getHeaderGroupProps();
                      return (
                        <tr key={key} {...restHeaderProps}>
                          {headerGroup.headers.map((column) => (
                            <th
                              className="font-normal text-sm text-primary py-4 text-left whitespace-nowrap px-4 border-r border-slate-50"
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              key={column.id}
                            >
                              <div className="flex items-center">
                                <span className="text-[#5C6F7F] font-bold text-sm">
                                  {column.render("Header")}
                                </span>

                                {column.canSort === true && (
                                  <span className="ml-2">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="10"
                                      height="12"
                                      viewBox="0 0 10 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M5.00016 2.83L8.17016 6L9.58016 4.59L5.00016 0L0.410156 4.59L1.83016 6L5.00016 2.83ZM5.00016 15.17L1.83016 12L0.420156 13.41L5.00016 18L9.59016 13.41L8.17016 12L5.00016 15.17Z"
                                        fill="#323232"
                                      />
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </th>
                          ))}
                        </tr>
                      );
                    }
                  )}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="mt-3 pt-3 w-full space-y-8 border-r border-slate-50"
                >
                  {page.map(
                    (row: {
                      getRowProps: () => JSX.IntrinsicAttributes &
                        React.ClassAttributes<HTMLTableRowElement> &
                        React.HTMLAttributes<HTMLTableRowElement>;
                      cells: any[];
                    }) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className="appearance-none my-4 border border-slate-50 even:bg-white odd:bg-slate-100"
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="font-normal text-sm text-[#202223] py-4 px-4 border-r border-slate-50 text-left"
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>

              <Pagination
                gotoPage={gotoPage}
                length={data.length}
                pageSize={pageSize}
                pageCount={pageCount}
                setPageSize={setPageSize}
              />
            </div>
            <div className="xxs:block md:hidden w-full">
              <Container data={Tdata} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionTable;
