    import React from 'react'
    import { FC } from "react"
    import SvgIconConstituentValues from './types'

    export interface SvgIcon extends FC<SvgIconConstituentValues>{}
    
    const BeneficiaryIcon = ({
        height="28px",
        width ="28px",
        stroke ="#5C6F7F",
        ...props
    }:React.SVGProps<SVGAElement>) => {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} 
         height= {height} viewBox="0 0 24 24" fill="none">
<path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M2 21V17C2 15.8954 2.89543 15 4 15H14C15.1046 15 16 15.8954 16 17V21" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16 3C16.8604 3.2203 17.623 3.7207 18.1676 4.42231C18.7122 5.12392 19.0078 5.98683 19.0078 6.875C19.0078 7.76317 18.7122 8.62608 18.1676 9.32769C17.623 10.0293 16.8604 10.5297 16 10.75" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19 15H20C21.1046 15 22 15.8954 22 17V21" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
      )
    }
    
    export default BeneficiaryIcon