import React, { useMemo } from "react";

import { useNavigate } from "react-router-dom";
import logo from "../../assets/misc/FaviconAsset_ttfcx.svg";
import hamburger from "../../assets/misc/hamburger-button.svg";
import EmptyState from "../../components/base-components/EmptyState";
import AppTable from "../../components/dashboard-components/AppTable";
import { useAccActivity } from "../../sevices/hooks/wallets";
import { useSidebarState } from "../../store";
import { useRecieptState } from "../../store/useReciept";
import { IoIosSend } from "react-icons/io";
import TransactionRecieptModal from "./transaction/TransactionRecieptModal";
import moment from "moment/moment";
import { numberWithCommas } from "./Withdrawals";
import TransactionTable from "../../components/transaction-component/TransactionTable";

export const capitalize = (word: string) => {
  if (!word) return;
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const Transactions = () => {
  // const transactions = useTransactionsState((state) => state.transactions);
  const toggleSideBarOpen = useSidebarState((state) => state.toggleSidebar);

  const showAllTransactionReciept = useRecieptState(
    (state) => state.showTransactionReciept
  );

  const navigate = useNavigate();
  // const setTransactions = useTransactionsState(state => state.setTransactions)
  const userActivities = useAccActivity();

  // const [transactions, setTransactions] = useState<any>([]);



  return (
    <div className="md:bg-[#f6fcf9] xxs:bg-[#fff] h-full w-full p-5  md:pl-8 ">
      <div className="logo w-16 block md:hidden ml-auto">
        <img src={logo} alt="" />
      </div>
      <div className="flex  flex-col justify-start  ">
        <button
          onClick={() => {
            toggleSideBarOpen();
          }}
          className="md:hidden "
        >
          <img src={hamburger} alt="" className="w-10" />
        </button>
        <header className="flex justify-between md:mr-2  ">
          <h1 className=" text-2xl  font-bold text-[#071827]">Transactions</h1>
          <button
            onClick={() => {
              navigate("/dashboard/beneficiaries", { replace: true });
            }}
            className="hidden md:flex items-center text-white font-medium bg-[#0eb683] p-3 rounded-md gap-2 px-8 transition-all active:scale-90 cursor-pointer mt-2 "
          >
            <IoIosSend size={25} />
            <span className="text-base pt-1">Initiate Transfer</span>
          </button>
          <div className="md:hidden ">
            <button
              onClick={() => {
                navigate("/dashboard/beneficiaries", { replace: true });
              }}
              className="  md:hidden items-center bg-[#0eb683] p-3 px-8 shadow-lg transition-all active:scale-90 cursor-pointer rounded-md text-white font-extrabold "
            >
              Transfer
            </button>
          </div>
        </header>
      </div>
      {userActivities?.data?.data.length > 0 ? (
        <>
          
          <div className="">
            <TransactionTable
              tableData={userActivities?.data?.data}
              tabs={["All", "NGN", "USD", "GBP", "EUR", "USDC", "USDT"]}
            />
          </div>
        </>
      ) : (
        <>
          <EmptyState />
        </>
      )}
      {showAllTransactionReciept && <TransactionRecieptModal />}
    </div>
  );
};

export default Transactions;
