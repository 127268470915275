import React, { useEffect, useState } from "react";
import {
  IColumn,
  Transaction,
} from "../transaction-component/TransactionTable";
import { AiOutlineMinus } from "react-icons/ai";
import { MdAdd } from "react-icons/md";
import { FiSend } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
const moment = require("moment");

interface ITrx {
  itemsPerPage: number;
  data: Transaction[];
}
const WalletAccountTable = ({ itemsPerPage, data }: ITrx) => {
  const [tableData, setTableData] = useState(data);
  useEffect(() => setTableData(data), [data]);
  const navigate = useNavigate();

  function chunkArray<T>(array: T[], size: number) {
    let result = [];
    for (let i = 0; i < array?.length; i += size) {
      let chunk = array?.slice(i, i + size);
      result.push(chunk);
    }
    return result;
  }

  return (
    <div className=" flex justify-center items-center">
      <table className="w-full min-w-max bg-white rounded-lg overflow-hidden shadow-md">
        <thead>
          <tr className="bg-[fff] text-gray-600  text-sm leading-normal">
            <th className="py-3 px-6 text-left text-base font-bold">
              Recent Activity
            </th>
            <th className="py-3 px-6 text-left"></th>
            <th className="py-3 px-6 text-left"></th>
            <th className="py-3 px-6 text-right">
              {" "}
              <p
                onClick={() => {
                  navigate("/dashboard/transactions", { replace: true });
                }}
                className="text-[#0eb683] text-xs font-medium hover:underline cursor-pointer transition-all active:scale-90 "
              >
                View all
              </p>
            </th>
          </tr>
        </thead>

        {data ? (
          chunkArray(tableData, itemsPerPage).map((trx, index) => {
            const transactionType = (type: string) => {
              let icon;

              switch (type) {
                case "debit":
                  icon = (
                    <AiOutlineMinus size={15} className="font-extrabold" />
                  );
                  break;
                case "credit":
                  icon = <MdAdd size={15} className="font-extrabold" />;
                  break;
                default:
                  icon = <FiSend size={15} className="font-extrabold" />;
                  break;
              }

              return icon;
            };
            const icon = transactionType(trx?.[0]?.type);

            const transactionRef = (ref: string) => {
              return (
                <span className=" hover:underline cursor-pointer text-[#0eb683]">
                  {ref}
                </span>
              );
            };

            const currencySymbol = (currency: string, amount: string) => {
              let currencySymbol = "";

              switch (currency?.toUpperCase()) {
                case "NGN":
                  currencySymbol = "₦ ";
                  break;
                case "USD":
                  currencySymbol = "$ ";
                  break;
                default:
                  break;
              }

              return `${currencySymbol}${amount}`;
            };
            const amount = currencySymbol(trx?.[0]?.currency, trx?.[0]?.amount);
            const reference = transactionRef(trx?.[0]?.transactionRef);

            const myDate = new Date(trx?.[0]?.createdAt);
            const formatDate = moment(myDate);
            const Cdate = formatDate.format("lll");
            return (
              <tbody key={index + "yuuy"}>
                <tr className="text-gray-700 text-sm">
                  <td className="py-3 px-6 border-b border-gray-200 flex items-center gap-5">
                    <span
                      className={`w-8 h-8 rounded-full ${
                        trx?.[0]?.type === "debit"
                          ? "bg-[#FEECE2] text-[#FA0204]"
                          : trx?.[0]?.type === "credit"
                          ? "bg-[#E2FEF0] text-[#0eb683]"
                          : "bg-[#E2FEF0] text-[#0eb683]"
                      }  flex items-center justify-center font-extrabold`}
                    >
                      {icon}
                    </span>
                    <div className="flex flex-col">
                      <p className="text-[#5C6F7F] text-sm">
                        {trx?.[0]?.type.charAt(0).toUpperCase()}
                        {trx?.[0]?.type.slice(1)}
                      </p>
                      <p className="text-[#071827] font-bold">{amount}</p>
                    </div>
                  </td>
                  <td className="py-3 px-6 border-b border-gray-200">
                    {reference}
                  </td>
                  <td className="py-3 px-6 border-b border-gray-200">
                    {Cdate}
                  </td>
                  <td className="py-3 px-6 border-b border-gray-200 text-right">
                    <span className="hover:underline cursor-pointer text-[#0eb683] text-sm">
                      View receipt
                    </span>{" "}
                  </td>
                </tr>
              </tbody>
            );
          })
        ) : (
          <div>Loading..</div>
        )}
      </table>
    </div>
  );
};

export default WalletAccountTable;
