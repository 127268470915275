import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OnboardingFooter from "../../components/base-components/OnboardingFooter";
import PasswordInput from "../../components/base-components/PasswordInput";
import AppCheckBox from "../../components/widgets/AppCheckBox";
import LoadingScreen from "../Notifications/LoadingScreen2";
import PasswordChecklist from "react-password-checklist";
import { useUpdateUserOnboardingInfo } from "../../sevices/hooks/users";

const CreatePassword = ({
  confirmValid,
  getDetails,
  backFn,
  nextFn,
  error,
  userDetail,
}: {
  confirmValid?: (isValid: boolean) => void;
  getDetails: (data: any, isValid: boolean) => void;
  backFn: () => void;
  nextFn: () => void;
  error?: string;
  userDetail: any;
}) => {
  const { accountType, id, userID } = userDetail;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const [check, setCheck] = useState(true);
  const [loading, setLoading] = useState(false);
  const updateInfo = useUpdateUserOnboardingInfo(userID || id);
  const navigate = useNavigate();

  useEffect(() => {
    var IsValid =
      Boolean(password.trim()) &&
      Boolean(confirmPassword.trim()) &&
      password.trim() === confirmPassword.trim();
    setPasswordIsValid(IsValid);

    if (passwordIsValid) {
      getDetails({ password, confirmPassword }, passwordIsValid);
    }
  }, [confirmPassword, password, passwordIsValid]);

  const checkPasswordValidity = (value: React.SetStateAction<string>) => {
    if (!confirmValid) return;
    if (value === password.trim()) {
      confirmValid(true);
    } else {
      confirmValid(false);
    }
  };



  const handlePasswordChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setConfirmPassword(event.target.value);
    checkPasswordValidity(event.target.value);
  };

  const isPasswordValid =
    password.trim().length >= 5 &&
    /[!@#$%^&*()_+[\]{};':"\\|,.<>/?]+/.test(password) &&
    /\d/.test(password) &&
    /[A-Z]/.test(password) &&
    password.trim() === confirmPassword.trim();

  const createPassword = () => {
    if (accountType !== "private") {
      nextFn();
    } else {

      setLoading(true);
      updateInfo
        .mutateAsync({ password })
        .then((res) => {

          nextFn();
          setLoading(false);
        })
        .catch((res) => {});
    }
  };
  return (
    <LoadingScreen
      showLoading={loading}
      msg={"Creating password..."}
      bg="#f2faf799"
    >
      <div className="py-10 xxs:px-4  md:px-6">
        <div className="text w-full ">
          <h1 className="text-[24px] leading-[36px] font-bold pb-1 text-[#071827] ">
            Create password
          </h1>
          <p className="text-[16px] leading-[24px] font-normal text-[#5C6F7F]">
            Secure your account from unauthorized access and fraud with a unique
            password:
          </p>
        </div>
        <div className="text-[#ff3838] text-base my-2 ">{error}</div>
        <div className=" bg-none md:bg-white xxs:p-0 md:p-7 flex flex-col gap-5 rounded-md mt-5 md:max-w-lg">
          <PasswordInput
            // value={password}
            onChange={handlePasswordChange}
            label="Create Password"
            required={true}
            name={"password"}
          />
          <PasswordInput
            // value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            label="Confirm password"
            required={true}
            name={"confirmPassword"}
          />
          <div className=" mt-1">
            {password !== "" && (
              <PasswordChecklist
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "capital",
                  "match",
                ]}
                minLength={5}
                value={password}
                valueAgain={confirmPassword}
                onChange={(isValid) => {}}
              />
            )}
          </div>
        </div>

        <OnboardingFooter
          backFn={backFn}
          nextFn={createPassword}
          nextBtnLabel="Proceed"
          nextDisabled={!isPasswordValid}
        />
      </div>
    </LoadingScreen>
  );
};

export default CreatePassword;
