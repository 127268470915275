import React from "react";
import "../base-components/standard.css";
const Button = ({
  type,
  ripple,
  id,
  className,
  onClick,
  style,
  children,
  disable,
}: any) => {
  React.useEffect(() => {
    const btnRipple = document.querySelectorAll("button");
    btnRipple.forEach((btn: any) => {
      btn.onclick = (e: any) => {
        const x = e?.clientX - btn.offsetLeft / 2;
        const y = e?.clientY - btn.offsetTop / 2;

        let ripple = document.createElement("span");
        ripple.className =
          ` absolute left-[${x}] top-[${y}] ` +
          "bg-white -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-full animate-ripple";

        btn.appendChild(ripple);

        setTimeout(() => {
          ripple.remove();
        }, 1000);
      };
    });
  });

  return (
    <button
      type={type ? type : "button"}
      id={id}
      className={`overflow-hidden rounded-md shadow-md shadow-gray-400/50 bg-[#0eb683] select-none relative py-3 text-[#fff] font-semibold tracking-wider btn-ripple disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none  ${
        className ? className : ""
      }`}
      data-ripple={ripple}
      onClick={onClick}
      disabled={disable}
      style={style}
    >
      {children}
    </button>
  );
};

export default Button;
