import React from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/misc/logo-on-light.svg";

const TermsOfService = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen w-screen  bg-[#fff] overflow-y-scroll">
      <div className=" xxs:p-5 md:px-14">
        <div className="flex  justify-between items-center mb-9">
          <div className="flex items-center ">
            <button
              onClick={() => {
                navigate(-1);
              }}
              className=" md:bg-slate-400 rounded-full md:p-2 xxs:mr-2 flex transition-all active:scale-90 hover:scale-110"
            >
              <BsChevronLeft size={23} />
            </button>
            <h1 className=" xxs:text-sm md:text-md    text-black font-sans font-extrabold ">
              TERMS OF SERVICE{" "}
            </h1>
          </div>

          <div className="xxs:w-20 md:w-32 flex  ">
            <img src={logo} alt="" />
          </div>
        </div>

        <ol>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold mb-3 ">
            ACCEPTANCE OF TERMS:
          </li>
        </ol>
        <ol>
          <li className="font-sans font-medium text-xs mb-4 leading-loose">
            These Terms of Service (the “Agreement”) applies to and governs the
            use of and the provision of the services on our platform accessed
            through our website https://ttfcx.com/ (the “website”), or on mobile
            application (both website and mobile application shall be referred
            to as the “Platform”) and includes any updates, modifications, and
            features. It is entered into by and between you (hereinafter “You”,
            “Your”, “User”) and TTFCX Enterprises (hereinafter “TTFCX”, “We”,
            “Us”, “Our”) and becomes effective as of the date of acceptance of
            this Agreement when you complete your account sign up or click, “I
            Accept” (“Effective Date”) before performing any transaction. The
            terms “you” and “your” in uppercase or lowercase shall mean the
            entity (e.g., individual, company, corporation, partnership, sole
            proprietor, etc.) or entering into this Agreement with TTFCX. You
            understand that by performing the above actions that include
            accessing, registering with, completing the creation of an account,
            signing in, and clicking, “I Agree” or “I Accept” or any button that
            conveys such acceptance or agreement and using any of these services
            that you are agreeing to be bound by the terms and conditions of our
            services.
          </li>
        </ol>
        <ol start={2}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold mb-3">
            PURPOSE AND OVERVIEW OF SERVICES:
          </li>
        </ol>
        <ol>
          <li className="font-sans font-medium text-xs mb-4 leading-loose">
            {" "}
            <span className="font-bold">(a)</span> TTFCX provides data and a
            secure platform for to enable and facilitate secure and timely cross
            border payments for its verified registered Users originating from
            and going out to its growing list of approved destinations around
            the world. <br />
            <span className="font-bold">(b)</span> Registered and verified Users
            intend to use our platform to perform transactions from wire payouts
            to remittances and to use the data we provide to monitor their
            cashflow, in accordance with the terms and conditions set forth more
            specifically herein. In consideration of the promises and mutual
            agreements contained in this Agreement and other good and valuable
            consideration, the receipt and sufficiency of which are hereby
            acknowledged, the Parties hereto, intending to be legally bound
            hereby, agree as follows:
          </li>
        </ol>
        <ol start={3}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold mb-3 ">
            DEFINITIONS:
          </li>
        </ol>

        <ol>
          <li className="font-sans font-medium text-xs mb-4 leading-loose">
            <span className="font-bold">(a)</span> “Account”: This is a secure,
            personalized portal created when you sign up for our services and
            through which you will be able to access our services and perform
            all your transactions. It is comprised of your username, password,
            personal identification number (PIN) and any information related to
            you. It is secured by a password and/or PIN which is requested each
            time you want to log in/access the platform. <br />
            <span className="font-bold">(b)</span> “User”: Refers to you the
            customer that has created an account on our platform and signed up
            to access our services. <br />
            <span className="font-bold">(c)</span> “Transactions”: This includes
            any of our services you use, whether at the initiating or the
            receiving end of our services and not excluding any other
            interactive services available on the platform. <br />
            <span className="font-bold">(d)</span> ‘Platform”: This refers to
            our web and mobile platforms. <br />
            <span className="font-bold">(e)</span> “Currency”: is the coin and
            paper money of a country that is designated as legal tender and
            circulates and is customarily used and accepted as a medium of
            exchange in the country of issuance. <br />
            <span className="font-bold">(f)</span> “Funds”: means Currency or a
            claim that can be converted into Currency through a financial
            institution, electronic payments network, or other formal or
            informal payment system. <br />
            <span className="font-bold">(g)</span> “Wallet”: A digital portal
            within the Platform used to receive and store funds for use in
            incoming or outgoing transactions. <br />
            <span className="font-bold">(h)</span> “Portal”: means any app,
            website, interface, or other digital portal we provide from time to
            time to enable you to use Our services under the Agreement. <br />
            <span className="font-bold">(i)</span> “Services”: include any or
            all the following services stated in Section 2. <br />
            <span className="font-bold">(j)</span> “Partner Service Provider”:
            are all those who we work with including but not limited to
            liquidity partners to provide our services to you. <br />
            <span className="font-bold"> (k)</span> "Transaction History": means
            the record of your Transactions which are accessible through our
            Portal. <br />
            <span className="font-bold"> (l)</span> “Party” or “Parties”: refers
            singularly to TTFCX or the User and jointly to both TTFCX and the
            User.
          </li>
        </ol>
        <ol start={4}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold mb-3 ">
            SERVICES AND ACCESS TO THE PLATFORM:{" "}
          </li>
        </ol>
        <ol>
          <li className="font-sans font-medium text-xs mb-4 leading-loose">
            <span className="font-bold">(a)</span> Some of our services are made
            available on the platform on behalf of our partner service
            providers. <br />
            <span className="font-bold"> (b)</span> You must not access, use, or
            attempt to use the platform to provide any transactions unless you
            are at least 18 years old. Depending on the country in which you
            live, local laws may set a different age or impose additional rules
            on your ability to enter into an agreement with Us and to use the
            platform. If this is the case, it is your obligation to comply with
            the local laws which affect you. <br />
            <span className="font-bold">(c)</span> As a User for the purpose of
            this Agreement, you shall sign up and create an Account. Your TTFCX
            login information, including username and password (and/or PIN), may
            only be used by you the User to whom TTFCX has assigned it and that
            this login information may not be shared with or used by any other
            person. You shall be responsible for the security of our login
            information and will use best efforts to prevent unauthorized access
            into your account. <br />
            <span className="font-bold">(d)</span> You shall be assigned various
            wallets into which you will be able to fund your incoming and
            outgoing transactions and pay for any other services on the
            platform. <br />
            <span className="font-bold"> (e)</span> You shall be able to
            transfer funds from only one assigned wallet into your Nigerian
            (NGN) bank account. <br />
            <span className="font-bold">(f)</span> As part of our incentives to
            all our Users, you may earn revenue on the transactions of any new
            User within the definitions of this Agreement, that you have
            referred or introduced to our services. <br />
            <span className="font-bold">(g) </span>
            We shall conduct directly and through our partner service providers,
            thorough due diligence on you, the User as well as your transactions
            to the extent required to provide our services. We may require that
            this information be updated from time to time to ensure your
            security and the integrity of the platform. Any User information we
            collect shall be subject to the terms of this Agreement, including
            the Confidential Information and Data Security provisions. <br />
            <span className="font-bold">(h)</span> It is your responsibility as
            a User not to use our services for any illegal or fraudulent
            transactions. It is also your responsibility to verify the source
            and legality of your incoming and outgoing transactions. <br />
            <span className="font-bold">(i)</span> You may purchase through our
            platform stablecoins, or any other Currency listed on the platform
            from our partners. <br />
            <span className="font-bold"> (j) </span>
            We shall charge you a fee on every purchase or outgoing transaction
            made on the platform. These fees are non- refundable. <br />
            <span className="font-bold">(k)</span> Currency daily rates listed
            on the platform by our partners are subject to market fluctuations
            and this may affect our transaction fees. <br />
            <span className="font-bold">(l)</span>
            The rates mentioned in 4(j) are subject to a limited window provided
            by our partner service providers. If a rate changes after you lock
            in and make a payment, you will be refunded if you decide not to
            continue your transaction with the new rates. <br />
            <span className="font-bold">(m)</span> You are solely responsible
            for the security of any financial information you provide to Us and
            must promptly inform Us of any changes thereto (e.g., change of bank
            account information). Changes should be communicated to TTFCX by
            entering the updated information through your Account. <br />
            <span className="font-bold">(n)</span> You may not be able to use
            Our services, or some aspects of Our services, if you are in certain
            regions, countries, or jurisdictions. This restriction may also
            apply where you are temporarily accessing the platform from those
            regions, countries, or jurisdictions (for example if you are
            travelling).
          </li>
        </ol>
        <ol start={5}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold mb-3 ">
            HOW TO USE OUR SERVICES:
          </li>
        </ol>
        <ol>
          <li className="font-sans font-medium text-xs mb-3">
            (a) To perform any outgoing transactions on the platform:{" "}
          </li>
        </ol>

        <ul className="list-disc list-inside pl-5">
          <li className="font-sans font-medium text-xs mb-1">
            Ensure that your wallet is funded
          </li>
          <li className="font-sans font-medium text-xs mb-1">
            Check the daily rate provided by our partner service provider
          </li>
          <li className="font-sans font-medium text-xs mb-1">
            Fill out the details of the transaction in the spaces provided{" "}
          </li>
          <li className="font-sans font-medium text-xs mb-1">
            Double check to ensure the above information is correct{" "}
          </li>
          <li className="font-sans font-medium text-xs mb-1">
            Complete the transaction{" "}
          </li>
          <li className="font-sans font-medium text-xs mb-1">
            It takes a maximum of 48 hours for the transaction to be completed
            and there is a short window after which the transaction becomes
            irreversible
          </li>
          <li className="font-sans font-medium text-xs mb-1">
            Our process is transparent, and you can monitor your outgoing
            transaction on our platform
          </li>
          <li className="font-sans font-medium text-xs mb-1">
            You will receive an email notification on the status of your
            transaction
          </li>
        </ul>
        <ol>
          <li className="font-sans font-medium text-xs mb-3">
            (b) You shall receive a notification on any incoming transaction
            that enters your designated wallet.
          </li>
        </ol>
        <ol start={6}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold mb-3 ">
            MAINTAINING YOUR ACCOUNT:
          </li>
        </ol>
        <ol>
          <li className="font-sans font-medium text-xs mb-4 leading-loose">
            (a) After you log into your account, you will be able view and
            interact with the data and actions associated with your Account.
            This page is your User Dashboard, and it shall also show you the
            status of any of your pending transaction being processed. <br />
            (b) Your Dashboard shall show you a complete history and the details
            of all the transactions you have performed through your Account on
            the platform. <br />
            (c) User shall always ensure the accuracy of the details of any
            transactions made on the platform as We shall not be responsible for
            any errors in the details of the transaction information (For
            example, the amount of funds stated in the outgoing transaction and
            the details of its destination). <br />
            (d) Upon closing of a User’s Account, the User shall confirm that
            there is no pending transactions or funds available in any of their
            User Wallets.
          </li>
        </ol>
        <ol start={7}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold mb-3 ">
            PROHIBITED TRANSACTIONS:{" "}
            <span className="font-sans font-medium text-xs">
              Our integrity and that of the platform shall always be a priority.
              Our mandate is to provide a trusted and secure platform for our
              Users to carry out their transactions discreetly and successfully.
              We shall not tolerate any actions or transactions that may put our
              services at risk to other Users. Users shall not:
            </span>
          </li>
        </ol>
        <ol>
          <li className="font-sans font-medium text-xs mb-4 leading-loose">
            (a) Perform any transactions that break any applicable law in any
            originating country or country of destination for incoming or
            outgoing transactions. <br />
            (b) Ensure that their outgoing or incoming transactions are not
            associated in anyway with fraudulent activities that include money
            laundry. <br />
            (c) Perform any transactions that are associated with or linked to
            terrorist activities. <br />
            (d) Perform any transactions where incoming or outgoing transactions
            emerge or are going to destinations on the internationally
            sanctioned list. <br />
            (e) Try to hack into Our database or illegally, fraudulently and
            without any authorization try to gain access to with or without the
            purpose of illegally acquiring the contents of Our financial
            information and bank accounts, as well as that of our partner
            service providers and related information including but not limited
            to digital Wallets, crypto/Stablecoin Wallets, that of other Users
            and Our partner service providers. <br />
            (f) Use any device, software, or routine to interfere or attempt to
            interfere with the proper working of the platform or any transaction
            being conducted on the platform.
          </li>
        </ol>
        <ol start={8}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold mb-3 ">
            USER OBLIGATIONS:{" "}
            <span className=" font-sans text-xs font-medium">
              {" "}
              User agrees to provide Us with any necessary information to
              provide Our services contemplated in this Agreement, including but
              not limited to its:{" "}
            </span>
          </li>
        </ol>
        <ol>
          <li className="font-sans font-medium text-xs mb-4 leading-loose">
            (a) Full legal name: True, accurate, current, and complete evidence
            of your identity <br />
            (b) Email address <br />
            (c) Details of one or more bank accounts <br />
            (d) Valid International Passport ID or any other valid government
            approved identity documentation that contain your full name and
            passport photograph. <br />
            (e) Business Registration Certificate (For entities). <br />
            (f) Tax ID <br />
            (g) Valid contact information: phone number and valid address <br />
            (h) Director’s full names and contact details (For entities) <br />
            (i) Any other information which We may reasonably request.
          </li>
        </ol>

        <ol start={9}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold mb-3 ">
            OUR RIGHT TO REJECT OR DELAY CERTAIN TRANSACTIONS: <span className="font-sans font-medium text-xs">  For outgoing or incoming funds transactions, we may refuse or delay
            its processing or completion for the following reasons:</span>
          </li>
        
        </ol>
        <ol>
          <li className="font-sans font-medium text-xs mb-4 leading-loose">
            (a) To perform high level due diligence checks on the transaction
            and its source or recipients. <br />
             (b) To confirm that We have Your
            consent to proceed with the transaction. <br />
             (c) We suspect or believe
            that there is a breach of the terms of this Agreement, or the
            transaction may be breaching applicable laws or enabling illegal,
            fraudulent or prohibited activities. <br />
             (d) There is suspicion that
            processing the transaction would enable money laundry or acts of
            terrorism (e) The security of the platform is or may be compromised
            (f) We are unable to verify your identity. <br />
             (g) The is inadequate
            information required to process the transaction. <br />
             (h) We are obliged
            to do so by any law, regulation, court order or instruction from a
            regulator, law enforcement or government body. <br />
             (i) There are
            insufficient funds in the applicable Wallet required to commence or
            complete the transaction
          </li>
        </ol>
        <ol start={10}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold mb-3 ">
            COLLECTION OF INFORMATION:
          </li>
        </ol>
        <ol>
          <li className="font-sans font-medium text-xs mb-4 leading-loose">
            (a) User Information for Account set up, Know Your Customer (KYC)
            and Anti-Money Laundering (AML) due diligence checks during Account
            sign up, periodically and/or before certain transactions are carried
            out on the platform. <br />
             (b) Verification of User Details. We may also
            need to verify the identity of the recipient for outgoing
            transactions. <br />
             (c) All information provided by you will be treated
            securely and strictly in accordance with all applicable law in your
            jurisdiction TTFCX ENTERPRISES Updated JUNE 2022. <br />
             (d) Provision of
            Our services. You explicitly consent to us accessing, processing,
            and retaining your personal information for the purposes of
            providing Our services described in the Agreement
          </li>
        </ol>
        <ol start={11}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold mb-3 ">
            CONFIDENTIAL INFORMATION:
          </li>
        </ol>
        <ol>
          <li className="font-sans font-medium text-xs mb-4 leading-loose">
            (a) Confidential Information also includes Our transaction
            processes, and all business information available to provide Our
            services on the platform. The User shall hold the Confidential
            Information in strict confidence and will restrict its use of
            Confidential Information to the purposes anticipated in this
            Agreement. <br />
             (b) User hereby acknowledges that any of such information
            in Section 11(a) disclosed on the platform shall be treated as
            Confidential Information. Notwithstanding the foregoing to the
            contrary, the User shall not be obligated to hold confidential any
            information from the Our platform which (i) is or becomes known to
            the public; (ii) is rightfully received by the User from a third
            party without a duty of confidentiality; or (iii) was already known
            to the User prior to the disclosure, and that knowledge was
            evidenced in writing prior to the date of disclosure.  <br />
            (c) The User
            acknowledges that unauthorized disclosure or use of the Confidential
            Information by the User may irreparably damage Our services and that
            adequate compensation from damages in addition to all other remedies
            available in equity shall be demanded by lawful action.
          </li>
        </ol>
        <ol start={12}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold mb-3 ">
            DATA SECURITY:
          </li>
        </ol>
        <ol>
          <li className="font-sans font-medium text-xs mb-4 leading-loose">
            We will safeguard your right to protect your privacy and data in
            accordance with the Nigerian Data Protection Regulation 2019 as
            amended (NDPR) and any applicable privacy and data protection laws,
            rules and regulations in your jurisdiction and provide a safe
            conduct for transactions that require the exchange of your personal
            data. Please refer to our <a className="hover:underline text-[#0eb683]" href="/privacystatement">Privacy Statement</a>.{" "}
          </li>
        </ol>
        <ol start={13}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold mb-3 ">
            INTELLECTUAL PROPERTY:
          </li>
        </ol>
        <ol>
          <li className="font-sans font-medium text-xs mb-4 leading-loose">
            (a) Our platform, Our Services and Our Website, and the content, and
            all intellectual property relating to them and contained in them
            (including but not limited to copyrights, process flow, transaction
            flow, any patents, database rights, trademarks, and service marks)
            are owned by Us, Our affiliates, Our subsidiaries, or partner
            service providers. All rights, title, and interest in and to Our
            platform, our services and Our website shall remain Our property
            and/or the property of such other partner service providers. <br />
             (b) Our
            platform, our services and Our website may be used only for the
            purposes permitted by this Agreement or described on Our website.
            You are authorized solely to view and to retain a copy of Your data
            from the pages of Our platform and website solely for your own
            personal or business use. You may not duplicate, publish, modify,
            create derivative works from, participate in the transfer or sale
            of, or post on the internet, or in any way distribute or exploit Our
            platform, Our process flow, Our transactions flow, Our services or
            Our website or any portion of it for any public or commercial use.
            You may not: (i) use any robot, bugs, devices, or technology to
            access Our platform, our services or website; and/or (ii) remove or
            alter any copyright, trademark or other proprietary notice or legend
            displayed in or on Our platform or website in any form or media. <br />
             (c)
            The name "TTFCX", “TTFCX Enterprises” and other names and
            indications of ownership of Our products and/or services referred to
            in the platform, Our Services or website are our exclusive marks or
            the exclusive marks of Our partner service providers. Other
            products, services and company names appearing on the website may be
            trademarks of their respective owners, and therefore you should not
            use, copy, or reproduce them in any way.
          </li>
        </ol>
        <ol start={14}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold mb-3 ">
            LIABILIY: <span className="font-sans font-medium text-xs">We may be liable under the following circumstances:</span>
          </li>
       
        </ol>
        <ol>
          <li className="font-sans font-medium text-xs  leading-loose">(a) Unauthorized Transaction from your Account </li>
          <li className="font-sans font-medium text-xs mb-4 leading-loose">
            (b) Failure of outgoing or incoming transaction to get to its
            intended destination{" "}
          </li>
          <li className="font-sans font-medium text-xs leading-loose">
            <span className="font-extrabold  text-md mx-2"> 14.1</span> Where an authorized transaction has been made from your
            Account, please contact us immediately through the support section
            on the platform and if you are unable to access your Account, please
            call the customer support number available on Our website and we
            will immediately halt, recall and/or trace the transaction.
          </li>
          <li className="font-sans font-medium text-xs leading-loose"> 
          <span className="font-extrabold text-md mx-2"> 14.2</span> Where it is proven that through no fault of Yours there was an
            unauthorized access into Your Account and such transaction was
            initiated or completed by the unauthorized user, we will ensure that
            proper investigation is made and make all efforts to return Your
            funds to into Your Wallet. This could take some time so Your
            patience and cooperation would be of assistance to Us.{" "}
          </li>
          <li className="font-sans font-medium text-xs leading-loose">
          <span className="font-extrabold text-md mx-2"> 14.3</span> The refund shall be credited into the appropriate Wallet in the
            same Currency it was debited.
          </li>
          <li className="font-sans font-medium text-xs leading-loose">
          <span className="font-extrabold text-md mx-2"> 14.4</span>Remember that We are not responsible for the protection and
            security of your login information. Negligence in securing it
            properly leading to such unauthorized access and transaction on Your
            Account shall absolve Us of such liability, but We will make
            reasonable efforts to recover Your funds.
          </li>
          <li className="font-sans font-medium text-xs leading-loose">
          <span className="font-extrabold text-md mx-2"> 14.5</span> Failure of Your transactions to get to the intended destination
            shall be grounds for a full refund. You shall immediately be
            notified of this occurrence as well as how We will recover the funds
            and ensure that the occurrence never happens again on the platform.
          </li>
          <li className="font-sans font-medium text-xs leading-loose">
          <span className="font-extrabold text-md mx-2"> 14.6</span> If it was not possible for us to process your transaction, due
            to circumstances beyond our reasonable control that include but are
            not limited to (i) issues with our partner service providers, (ii)
            malfunctions in communication facilities, (iii) period of platform
            updates and maintenance on which you shall be given prior
            notification to pause any transactions
          </li>
        </ol>
        <ol start={15}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold my-3">
            NO LIABILITY:{" "} <span className="font-sans font-medium text-xs">We will not be liable for any damages or losses under the following
            circumstances</span>
          </li>
          
        </ol>
        <ol>
          <li  className="font-sans font-medium text-xs mb-4 leading-loose">
            (a) If You have acted fraudulently. In this circumstance, you must
            cooperate with Us and law enforcement agencies, if We need to
            involve them. If We carry out an investigation and decide that You
            are not entitled to a refund because You acted fraudulently, we can
            charge You for our reasonable costs in carrying out the
            investigation and deduct these from any funds in your Account’s
            Wallets. If there are insufficient funds in Your Account’s Wallets
            to repay Us, you must repay the amount outstanding to Us immediately
            on demand from Us. <br />
             (b) The time of report and the time of the
            incident being reported are over 4 weeks apart. <br />
             (c) Through Your own
            fault by negligence, carelessness or any other reason compromised
            the security of Your Account by not properly securing your login
            information pursuant to Sections 4(c) and Section 14.4 of this
            Agreement, you will be liable for all losses arising from the misuse
            or misplacement of Your login information. <br />
             (d) If You provided
            incorrect or incomplete transaction information or details. We will
            make reasonable efforts to recover the funds and We may charge a
            reasonable fee, reflective of Our efforts, for doing this. <br />
             (e) If
            you make a claim for funds left in Your Wallet more than 12 months
            after the date on which you closed your Account with Us. <br />
             (f) Any
            losses or delays in delivery of messages arising out of the use of
            any internet or telecommunications service provider or caused by any
            browser or other software which is not under our control. <br />
             (g) Any
            use by You of the platform, Our Website or Our services which is in
            breach of the terms of the Agreement. <br />
             (h) Any use by You of the
            platform, Our Website or Our services for personal or commercial or
            business transactions which we are not directly or indirectly privy
            to or a direct or indirect party to, and/or in which we have no
            direct or indirect beneficial interest outside the provision of Our
            services. We shall have no liability to You for any loss of profit,
            loss of business, business interruption, or loss of business
            opportunity. <br />
             (i) Any loss or damage which We could not reasonably
            have foreseen as being likely to occur because of Our action or
            omission. <br />
             (j) Any loss or damage You suffered by you because of Your
            using Our platform, Our website, or Our services on a faulty device. <br />
            (k) Any loss or damage You suffered by You as a result of Your using
            Our platform, our website or Our services because of any other
            factors that are beyond our control.
          </li>
        </ol>
        <ol start={16}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold mb-3">
            NO WARRANTY:
          </li>
        </ol>
        <ol >
          <li  className="font-sans font-medium text-xs mb-4 leading-loose">
            (a) You agree that your use of Our services is at Your sole risk,
            and You acknowledge that, our services and materials are provided
            “as is”, and “as available” and that We and Our partner service
            providers make no warranty of any kind, express or implied, as to
            Our services and materials, including but not limited to,
            merchantability, or fitness for a particular purpose or use.
          </li>
        </ol>

        <ol start={17}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold mb-3">
            LIMITATION OF LIABILITY:
          </li>
        </ol>

        <ol>
          <li  className="font-sans font-medium text-xs mb-4 leading-loose">
            (a) Under no circumstances, including but not limited to negligence,
            shall We be held liable for any special, indirect, incidental,
            exemplary; punitive, or consequential damages of any kind whatsoever
            (including, without limitation, attorneys’ fees) in any way due to,
            resulting from, or arising in connection with Our platform, Our
            website or Our services, or the Agreement or failure of the User to
            perform their obligations by adhering strictly to the purpose of Our
            services and the terms of the Agreement. To the fullest extent
            permissible by applicable law, and under no circumstances will Our
            total aggregate liability in connection with any claim arising out
            of or relating to the use of Our platform, our website or Our
            services exceed the amount of fees charged by Us and paid by You
            under this Agreement. <br />
             (b) Without prejudice to any other provision
            under Section 15, or where the law says You are not liable, You
            shall be liable to and shall indemnify Us, Our subsidiaries,
            affiliates, officers, directors, employees, partner service
            providers, for all loss, damage, claims, actions or demands,
            including reasonable legal fees, arising out of any use by You (or
            anyone acting on your behalf) of the platform, and/or Our Website,
            and/or Our services which is in breach of your Agreement with Us,
            (ii) ) infringes Our intellectual property rights. <br />
             (c) Pursuant to
            Section 17(b), You shall have the right, at your expense, to assume
            the exclusive defense and control of any such matter and We will
            fully cooperate with You in asserting any available defense. This
            Section 17(b) is contingent on Our providing prompt notice to You of
            any such third-party claim and shall not apply if the claim stems
            from any negligence, willful misconduct, or breach of this Agreement
            by Us. <br />
             (d) The limitation of liability shall not apply to any
            indemnity obligations, or infringement of intellectual property, or
            misappropriation of proprietary data belonging to Us or Our partner
            service providers.
          </li>
        </ol>
        <ol start={18}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold  mb-3">
            COMMUNICATION:
          </li>
        </ol>
        <ol>
          <li className="font-sans font-medium text-xs leading-loose">
           <span className="font-extrabold text-md mx-2">18.1</span>  The Agreement will be entered into electronically, and the
            following categories of information ("Communications") may be
            provided by electronic means:
          </li>
          <li  className="font-sans font-medium text-xs mb-4 leading-loose">
            (a) Our Agreement with you and any amendments, modifications, or
            supplements to it. <br />
             (b) Your secure PIN each time you want to access
            and login to your Account on Our platform. <br />
             (c) Your data and records
            (e.g., of transactions) using the platform. <br />
             (d) Notice of platform
            updates. <br />
             (e) Any initial, periodic, or other disclosures or notices
            provided in connection with Our Services, like new features, removal
            of certain services and including without limitation those required
            by law. <br />
             (f) Any customer support communications, including without
            limitation, communications with respect to claims of error,
            downtimes, or unauthorized use of Our portal, services, or website;
            and. <br />
             (g) Any other communication related to Our platform, Our
            website, Our Services or Us.
          </li>
        </ol>

        <ol>
          <li className="font-sans font-medium text-xs leading-loose">
          <span className="font-extrabold text-md mx-2">18.2</span>  We may be unable to provide Communications in paper format or
            through other non-electronic means. You can withdraw your consent to
            receive Communications electronically, but if You do, we will treat
            this as notice that you wish to terminate Your Agreement with us. To
            withdraw Your consent, you must contact us using our contact
            information at the end of these Terms and Service, or on Our
            website, or in the portal.
          </li>
          <li className="font-sans font-medium text-xs leading-loose">
          <span className="font-extrabold text-md mx-2">18.3</span>  To access the platform and Communications, you must have an
            internet browser that supports 256-bit encryption, or such higher
            level of encryption as We may introduce in order to comply with law,
            regulation, or good industry practice in order to protect the
            security of Our Users' data, funds, and Our systems.
          </li>
          <li className="font-sans font-medium text-xs leading-loose">
          <span className="font-extrabold text-md mx-2">18.4</span>  In addition, you must promptly update us with any change in
            Your email address by updating your profile on the platform.
          </li>
        </ol>
        <ol start={19}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold my-3">
            CHANGES TO THE TERMS OF SERVICE:
          </li>
        </ol>
        <ol start={19.1}>
          <li className="font-sans font-medium text-xs leading-loose">
          <span className="font-extrabold text-md mx-2">19.1</span>  We may change, remove, vary, or add to any of Our terms of
            service Agreement with you at any time. These changes may include,
            but not be limited to:
          </li>
        </ol>
        <ol>
          <li  className="font-sans font-medium text-xs mb-4 leading-loose ml-5">
            (a) Modifying or withdrawing Our platform or any part of Our
            services. <br />
             (b) Introducing new products and services, which will then
            form part of Our services. <br />
             (c) Updating Our fees. <br />
              (d) Reflect any
            change in law, regulation, codes of practice or guidance, or a
            recommendation, decision or order of a court, regulator or
            government body, or new statements, codes of practice or industry
            guidance designed to enhance consumer protection. <br />
             (e) To reflect any
            changes in Our business organization, structure, or the partner
            service providers We use. <br />
             (f) To provide You with extra benefits or
            additional services. <br />
             (g) To reflect changes in costs of providing
            Our services, like market fluctuations that affect daily Currency
            rates, technology costs, inflation and/or the costs of maintaining
            facilities. <br />
             (h) To reflect any cost associated with development of
            new products or services We introduce, or any new feature on an
            existing product or service. <br />
             (i) To clarify Our terms of service and
            make Our services more beneficial or favorable to You. <br />
             (j) We may
            introduce, increase, decrease or cease transaction limits for
            certain types of transactions from time to time to ensure that We
            are able to keep providing quality services to You, to comply with
            the latest applicable law, regulation, codes of practice or
            guidance, or a recommendation that is focused on consumer
            protection. <br />
             (k) To take into consideration and tackle any existing
            identified or foreseeable risk or to try to anticipate unforeseeable
            risk to protect and strengthen Our platform. <br />
             (l) To reduce the risk
            of financial crime occurring on the platform.
          </li>
        </ol>
        <ol>
          <li className="font-sans font-medium text-xs leading-loose">
           <span className="font-extrabold text-md mx-2"> 19.2</span> Whenever We decide to make a change, we will act reasonably, and
            any change will be proportionate to the circumstances giving rise to
            the change.
          </li>
          <li className="font-sans font-medium text-xs leading-loose">
           <span className="font-extrabold text-md mx-2">19.3</span>  You will always be notified by email and through Your Account
            whenever We are about to make any changes. However, there are
            circumstances where We may make changes first and notify you
            afterwards such as:
          </li>
          <li className="font-sans font-medium text-xs leading-loose ml-5">
            (a) Where We are expected to comply with the latest applicable law,
            regulation, codes of practice or guidance, or a recommendation. <br />
             (b)
            Where We are introducing, increasing, decreasing, or ceasing
            transaction limits for certain types of transactions from time to
            time to ensure that We can keep providing quality services to You. <br />
            (c) Where We are introducing a new service or functionality (and
            even if We introduce new charges for that service), provided the
            introduction of the new service does not affect Your use of Our
            existing services. <br />
             (d) Where the daily Currency rates We use in
            outgoing or incoming transactions change. These rates as already
            mentioned change frequently each day because of market fluctuation
            and factors within the purview of our partner service providers.
            Rates as they change are always displayed on the platform to keep
            you informed and guided before commencing your transaction.{" "}
          </li>
          <li className="font-sans font-medium text-xs leading-loose">
           <span className="font-extrabold text-md mx-2">19.4</span>  We will always make the latest version of Our terms of use
            available on Our website. You should check Our website regularly so
            that you are familiar with the up-to-date terms of the Agreement.
          </li>
        </ol>
        <ol start={20}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold my-3">
            TERMINATION:
          </li>
        </ol>
        <ol>
          <li className="font-sans font-medium text-xs mb-4 leading-loose">
            (a) We reserve the right to suspend or to permanently terminate, any
            of Our services with immediate effect without providing any prior
            reason or because of any breach of the Agreement or upon
            instructions from a regulatory body or an order from a court of
            competent jurisdiction. <br />
             (b) You may terminate the Agreement with
            immediate effect by closing your Account on Our platform.
            Instructions on how to do so are available on the platform and/or
            You may contact customer support for assistance with any funds
            available in Your Wallet. <br />
             (c) Any outstanding fees payable to Us or
            payable from Us to You shall be paid up during Account closing and
            transfer conditions pursuant to Section 4(e) of the Agreement shall
            apply. <br />
             (d) You shall be notified of any funds available in Your
            Wallet when you are closing Your Account, but You shall not be able
            to claim such funds 12 months after closure of Your Account and
            termination of the Agreement. <br />
             (e) When an Account Closure is
            complete, your login information like Your Account password shall be
            deleted from Our secure servers but we shall retain some of Your
            personal information in our servers for a period mandated by data
            protection laws or regulation applicable in Your jurisdiction.
          </li>
        </ol>
        <ol start={21}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold  mb-3 ">
            COMPLAINTS:
          </li>
        </ol>

        <ol>
          <li className="font-sans font-medium text-xs mb-4 leading-loose">
            (a) If You wishes to make a complaint or a suggestion regarding Our
            platform or Our services, contact Customer Support on the platform
            or through the provided phone numbers or send an email to the
            address on Our website. <br />
             (b) Complaints, claims, or suggestions
            regarding to any transaction or service must be made within 48 hours
            from the expiry of such service. <br />
             (c) We will acknowledge receipt and
            respond depending on the type of complaint made. Where an
            investigation is required, resolution may take a bit longer, but we
            will make the best efforts to respond to regular complaints, claims
            or suggestions within 48 working hours or two <br />
             (2) business days from
            the time of acknowledgement of receipt.
          </li>
        </ol>

        <ol start={22}>
          <li className="text-md list-outside list-decimal  text-xs   text-black font-sans font-extrabold mb-3">
            GENERAL:
          </li>
        </ol>
        <ol>
          <li className="font-sans font-medium text-xs mb-4 leading-loose">
            (a) Governing Law: This Agreement shall be governed by and construed
            in accordance with the laws of Nigeria. <br />
             (b) No Waiver: Our failure
            to exercise or enforce any right or provision of the Agreement will
            not constitute a waiver of such right or provision. <br />
             (c)
            Non-Transferable Rights: (i)You cannot transfer any of your rights
            under this Agreement, including any right to funds in Your Wallet in
            Your Account on Our platform, to any other person; (ii) We can
            assign all or any of Our rights under this Agreement to another
            person or entity. We may also transfer all or any of our
            obligations, but only to a person or entity We reasonably consider
            capable of performing them. <br />
             (d) Entire Agreement: This Agreement
            constitutes the entire agreement between the Parties and supersedes
            all prior understandings or agreements relating to the subject
            matter of this Agreement. <br />
             (e) Severability: If any provision of the
            Agreement is found by dispute resolution, mediation or an arbitrator
            or court of competent jurisdiction to be invalid, the Parties
            nevertheless agree that the dispute resolution panel, mediator,
            arbitrator, or court shall endeavor to give appropriately valid
            effect to the intention of the Agreement as reflected in the
            provision, and the other provisions of the Agreement will remain in
            full force and effect. <br />
             (f) External Links: Any external links to
            third-party/partner service provider websites that may be on Our
            website are provided as a convenience to You. These sites are not
            controlled by Us in any way, and We are not responsible for the
            accuracy, completeness, legality, or any other aspect of these other
            sites including any content provided on them. You access such
            websites at Your own risk. <br />
             (g) Security: We take the security of Our
            platform, website, and services very seriously and we work hard,
            using standard security measures, and reasonable efforts to make
            sure that your information remains secure. TTFCX seeks to facilitate
            secure, guaranteed, and timely, cross-border transactions and
            related services for verified individuals and businesses in Africa. <br />
            (h) Contact Us: You can contact us on{" "}
            <a
              className="hover:underline text-[#0eb683]"
              href="mailto:hello@ttfcx.com"
            >
              hello@ttfcx.com
            </a>{" "}
            or send a message across through your Account on the Platform or
            call us on any of the phone numbers provided on the Platform.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TermsOfService;
