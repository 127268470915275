import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import hamburger from "../../assets/misc/hamburger-button.svg";
import { useSidebarState } from "../../store";
import ngFlag from "../../assets/flags/ng.png";
import usFlag from "../../assets/flags/usd.png";
import ukFlag from "../../assets/flags/pound.png";
import euFlag from "../../assets/flags/euro.png";
import logo from "../../assets/misc/FaviconAsset_ttfcx.svg";
import { motion, AnimatePresence } from "framer-motion";
import Notification from "../../components/dashboard-components/Notification";
import { AiOutlineClose } from "react-icons/ai";
import { BsChevronLeft } from "react-icons/bs";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";
import RefreshIcon from "../../assets/iconsComponents/RefreshIcon";
import {
  useAccActivity,
  useAccountNgn,
  useAccountUsd,
  useBuyCurrency,
  useWalletBalance,
} from "../../sevices/hooks/wallets";
import AppNumericInput from "../../components/widgets/AppNumericInput";
import { useGbpRates, useRates, useRatesEur } from "../../sevices/hooks/tags";
import { numberWithCommas } from "./Withdrawals";
import DashBoardEmptyState from "../../components/dashboard-components/DashBoardEmptyState";
import Activity from "../../components/wallet-components/Activity";
import WalletWidget from "../../components/dashboard-components/WalletWidget";
import ReactLoading from "react-loading";
import SwapModal from "../../components/dashboard-components/SwapModal";
import FundModal from "../../components/dashboard-components/FundModal";
import { AxiosResponse } from "axios";
import { RefetchOptions, RefetchQueryFilters, QueryObserverResult } from "react-query";
import TabContext from "../../store/context/TabProvider";
import DashboardTable from "../../components/dashboard-components/DashboardTable";

const Wallets = () => {
   const [userDetails] = useOutletContext<any>();
  const [currentTab, setCurrentTab] = useState("fiat");
  const toggleSideBarOpen = useSidebarState((state) => state.toggleSidebar);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const navigate = useNavigate();
  const [amountValue, setAmountValue] = useState<any>();
  const [loading, setLoading] = useState(false);

  //Balance
  const accNgn = useWalletBalance("ngn");
  const accUsd = useWalletBalance("usd");

  //rates
  const getRates = useRates(); //usd
  const eurRate = useRatesEur();
  const gbpRate = useGbpRates();

  //BUY GBP
  const [gbpAmountValue, setGdpAmountValue] = useState<any>();
  const [NGNGBPAmountValue, setNGNGBPAmountValue] = useState<any>();

  //USD
  const [transferConfirm, setTransferConfirm] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [currentAssetTab, setCurrentAssetTab] = useState("buy");
  const [showBuyCurrency, setShowBuyCurrency] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fundedSuccess, setfundedSuccess] = useState(false);

  //
  const [usdAmountValue, setUsdAmountValue] = useState<any>();
  const [NGNAmountValue, setNGNAmountValue] = useState<any>();

  //hooks for Gbp operations
  const [currentGbpTab, setCurrentGbpTab] = useState("buy");
  const [showBuyGbp, setShowBuyGbp] = useState(false);
  const [confirmBuyGbp, setConfirmBuyGbp] = useState(false);
  const [gbpBuySuccess, setGbpBuySuccess] = useState(false);
  const [confirmSellGbp, setConfirmSellGbp] = useState(false);
  const [gbpSellSuccess, setGbpSellSuccess] = useState(false);

  //hooks for EUR operations
  const [currentEurTab, setCurrentEurTab] = useState("buy");
  const [showBuyEur, setShowBuyEur] = useState(false);
  const [confirmBuyEur, setConfirmBuyEur] = useState(false);
  const [eurBuySuccess, setEurBuySuccess] = useState(false);
  const [confirmSellEur, setConfirmSellEur] = useState(false);
  const [eurSellSuccess, setEurSellSuccess] = useState(false);

  // const buyCurrency = useBuyCurrency();

  //BUY EUR
  const [eurAmountValue, setEurAmountValue] = useState<any>();

  const borderColor =
    NGNAmountValue?.value >= Number(getRates?.data?.data) * Number(100)
      ? { borderColor: "#0EB683" }
      : NGNAmountValue?.value === ""
      ? { borderColor: "#0EB683" }
      : NGNAmountValue?.value === undefined
      ? { borderColor: "#0EB683" }
      : { borderColor: "#e01" };

  const userActivities = useAccActivity();



  const ngnBalanace = userDetails?.ngnBalance;
  //@ts-ignore
  const { setTab, tab } = useContext(TabContext);

  const usdBalance = userDetails?.ngnBalance;
  // const hasNgnData = !!usdBalance;
  // const hasUsdData = !accUsd?.data?.data;

  //actions
  const fundNgn = () => setShowModal((prev) => !prev);
  const initiateBuy = () => setShowBuyCurrency((prev) => !prev);
  const initiateBuyEur = () => setShowBuyEur((prev) => !prev);
  const initiateBuyGBP = () => setShowBuyGbp((prev) => !prev);

  function commas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  let regEx =
    /\b([1-9][0-9][0-9]|[1-9][0-9][0-9][0-9]|[1-9][0-9][0-9][0-9][0-9]|[1-9][0-9][0-9][0-9][0-9][0-9]|[1-9][0-9][0-9][0-9][0-9][0-9][0-9])\b/;



    const amountErr =
    tab === "buy"
      ? `Minimum amount is NGN ${commas(
          Number(getRates?.data?.data?.buyRate) * Number(500)
        )} `
      : `Minimum amount is USD ${commas(
          (Number(getRates?.data?.data?.sellRate) * Number(10)) /
            Number(getRates?.data?.data?.sellRate)
        )} `;



   const GbpErr = `Minimum amount is NGN ${commas(
    Number( gbpRate?.data?.data?.buyRate * 100).toFixed()
  )}`;
  const EurErr = `Minimum amount is NGN ${commas(
    Number( eurRate?.data?.data?.buyRate * 100).toFixed()
  )}`;

  return (
    <>
      <div className="  bg-[#f7fbfb] h-full w-full p-5   md:pl-8 md:pr-9">
        <div className="w-16 ml-auto block md:hidden">
          <img src={logo} alt="" />
        </div>
        <div className="flex flex-col md:flex-row md:justify-between  ">
          <div className="text flex flex-col items-start ">
            <button
              className="py-1 md:hidden rounded-md transition-all active:scale-90"
              onClick={(e) => {
                toggleSideBarOpen();
              }}
            >
              <img src={hamburger} alt="menu" className="w-10" />
            </button>
            <div className="">
              <h1 className="font-bold text-2xl text-[#071827] pb-2 pl-1 ">
                Wallets
              </h1>
            </div>
            <p className="pb-5 pl-1 text-[#5C6F7F] text-lg">
              Select the wallet you wish to use
            </p>
          </div>
          {/* Toggle switch */}
          <div className="toggle md:max-w-[240px] w-full h-12 md:h-10 flex border border-[#0eb683] xxs:mb-6 md:mb-8 self-center bg-white justify-between rounded-lg cursor-pointer p-1">
            <div
              className={`px-2 flex-1 flex items-center justify-center transition-all duration-500 rounded-md ${
                currentTab === "fiat" ? "bg-[#0eb683] text-white" : ""
              }`}
              onClick={() => {
                navigate("/dashboard/wallets", { replace: true });
              }}
            >
              Fiat
            </div>
            <div
              className={`px-2 flex-1 flex items-center justify-center transition-all duration-500 rounded-md ${
                currentTab === "stable" ? "bg-[#0eb683] text-white" : ""
              }`}
              onClick={() =>
                navigate("/dashboard/wallets/stablecoin", { replace: true })
              }
            >
              Stablecoin
            </div>
          </div>
        </div>
        {currentTab === "fiat" ? (
          <div>
            <div className="w-full  flex xxs:gap-5 md:gap-7 flex-1  whitespace-no wrap max-w-full  overflow-x-scroll xxs:mt-0 md:mt-4">
              <WalletWidget
                color="#FFF"
                currency="NGN Balance"
                symbol="₦‎"
                buttonColor={"#0eb683"}
                amount={
                  numberWithCommas(ngnBalanace) === "NaN"
                    ? "0.00"
                    : ` ${numberWithCommas(ngnBalanace)}`
                }
                border={"1px solid #0eb683"}
                action={fundNgn}
                button="Fund"
                textColor={"#fff"}
                navigate={"/dashboard/wallet/ngn"}
                flag={ngFlag}
                disable={false}
              />

              <WalletWidget
                color="#C4FFF4"
                currency="USD Balance"
                symbol="$"
                buttonColor={"#fff"}
                textColor={"#0eb683"}
                amount={
                  numberWithCommas(userDetails?.usdBalance) === "NaN"
                    ? "0.00"
                    : ` ${numberWithCommas(userDetails?.usdBalance)}`
                }
                border={"1px solid #C4FFF4"}
                action={initiateBuy}
                button="Buy | Sell"
                navigate={"/dashboard/wallet/usd"}
                flag={usFlag}
                disable={false}
              />
              <WalletWidget
                color="#FFDECF"
                currency="GBP Balance"
                symbol="£"
                buttonColor={"#fff"}
                textColor={"#0eb683"}
                amount={
                  numberWithCommas(userDetails?.gbpBalance) === "NaN"
                    ? "0.00"
                    : ` ${numberWithCommas(userDetails?.gbpBalance)}`
                }
                border={"1px solid #FFDECF"}
                action={initiateBuyGBP}
                button="Buy | Sell"
                navigate={"/dashboard/wallet/gbp"}
                flag={ukFlag}
                disable={true}
              />
              <WalletWidget
                color="#E8EAFF"
                currency="EUR Balance"
                symbol="€"
                buttonColor={"#fff"}
                textColor={"#0eb683"}
                amount={
                  numberWithCommas(userDetails?.eurBalance) === "NaN"
                    ? "0.00"
                    : ` ${numberWithCommas(userDetails?.eurBalance)}`
                }
                border={"1px solid #E8EAFF"}
                action={initiateBuyEur}
                button="Buy | Sell"
                navigate={"/dashboard/wallet/eur"}
                flag={euFlag}
                disable={true}
              />
            </div>

            <div>
              {showBuyGbp && (
                <SwapModal
                  baseCurrency={"gbp"}
                  baseCurrencySymbol={"£ "}
                  currency={"ngn"}
                  currencySymbol={"₦ "}
                  sellRate={gbpRate?.data?.data?.sellRate}
                  rate={gbpRate?.data?.data?.buyRate}
                  confirmation={confirmSellGbp}
                  setConfirmation={setConfirmSellGbp}
                  inputValue={
                    tab === "buy"
                      ? NGNGBPAmountValue?.value
                      : gbpAmountValue?.value
                  }
                  inputValueFormatted={NGNGBPAmountValue?.formattedValue}
                  inputValueFloat={NGNGBPAmountValue?.floatValue}
                  setInputValue={setNGNGBPAmountValue}
                  openModal={showBuyGbp}
                  loading={loading}
                  setLoading={setLoading}
                  setOpenModal={setShowBuyGbp}
                  balance={userDetails?.ngnBalance}
                  baseBalance={userDetails?.gbpBalance}
                  computedValue={
                    tab === "buy"
                      ? gbpAmountValue?.value
                      : NGNGBPAmountValue?.value
                  }
                  computedValueFloat={gbpAmountValue?.floatValue}
                  computedValueFormatted={gbpAmountValue?.formattedValue}
                  setComputedValue={setGdpAmountValue}
                  errorMessage={GbpErr}
                  success={gbpBuySuccess}
                  setSuccess={setGbpBuySuccess}
                  // initiateBuy={purchaseUsd}
                  setFundModal={fundNgn}
                  refetchRates={function <TPageData>(
                    options?:
                      | (RefetchOptions & RefetchQueryFilters<TPageData>)
                      | undefined
                  ): Promise<
                    QueryObserverResult<AxiosResponse<any, any>, unknown>
                  > {
                    throw new Error("Function not implemented.");
                  }}
                />
              )}
              {showBuyEur && (
                <SwapModal
                  baseCurrency={"eur"}
                  baseCurrencySymbol={"€ "}
                  currency={"ngn"}
                  currencySymbol={"₦ "}
                  sellRate={eurRate?.data?.data?.sellRate}
                  rate={eurRate?.data?.data?.buyRate}
                  confirmation={confirmSellEur}
                  setConfirmation={setConfirmSellEur}
                  inputValue={NGNAmountValue?.value}
                  inputValueFormatted={NGNAmountValue?.formattedValue}
                  inputValueFloat={NGNAmountValue?.floatValue}
                  setInputValue={setNGNAmountValue}
                  openModal={showBuyEur}
                  loading={loading}
                  setLoading={setLoading}
                  setOpenModal={setShowBuyEur}
                  balance={userDetails?.ngnBalance}
                  baseBalance={userDetails?.eurBalance}
                  computedValue={eurAmountValue?.value}
                  computedValueFloat={eurAmountValue?.floatValue}
                  computedValueFormatted={eurAmountValue?.formattedValue}
                  setComputedValue={setEurAmountValue}
                  errorMessage={EurErr}
                  success={eurSellSuccess}
                  setSuccess={setEurSellSuccess}
                  // initiateBuy={purchaseUsd}
                  setFundModal={fundNgn}
                  refetchRates={function <TPageData>(
                    options?:
                      | (RefetchOptions & RefetchQueryFilters<TPageData>)
                      | undefined
                  ): Promise<
                    QueryObserverResult<AxiosResponse<any, any>, unknown>
                  > {
                    throw new Error("Function not implemented.");
                  }}
                />
              )}
            </div>
            <>
              {userActivities?.data?.data.length > 0 ? (
                <div className="">
                  <DashboardTable tableData={userActivities?.data?.data} />
                </div>
              ) : (
                <>
                  <DashBoardEmptyState />
                </>
              )}
            </>
          </div>
        ) : (
          <div></div>
        )}

        <div className=" ">
          {showModal && (
            <FundModal
              fundModal={showModal}
              confirm={showConfirmationModal}
              setConfirm={setShowConfirmationModal}
              loading={loading}
              success={showSuccess}
              setValue={setAmountValue}
              setSuccess={setShowSuccess}
              value={amountValue?.value}
              currency={"ngn"}
              valueFormatted={amountValue?.formattedValue}
              setFund={fundNgn}
            />
          )}
        </div>
      </div>

      <div>
        {showBuyCurrency && (
          <SwapModal
            baseCurrency={"usd"}
            baseCurrencySymbol={"$ "}
            currency={"ngn"}
            currencySymbol={"₦ "}
            rate={getRates?.data?.data?.buyRate}
            sellRate={getRates?.data?.data?.sellRate}
            confirmation={showConfirmation}
            setConfirmation={setShowConfirmation}
            inputValue={
              tab === "buy" ? NGNAmountValue?.value : usdAmountValue?.value
            }
            inputValueFormatted={NGNAmountValue?.formattedValue}
            inputValueFloat={NGNAmountValue?.floatValue}
            setInputValue={setNGNAmountValue}
            openModal={showBuyCurrency}
            loading={loading}
            setLoading={setLoading}
            setOpenModal={setShowBuyCurrency}
            balance={ngnBalanace}
            baseBalance={usdBalance}
            computedValue={
              tab === "buy" ? usdAmountValue?.value : NGNAmountValue?.value
            }
            computedValueFloat={usdAmountValue?.floatValue}
            computedValueFormatted={usdAmountValue?.formattedValue}
            setComputedValue={setUsdAmountValue}
            errorMessage={amountErr}
            success={showSuccessModal}
            setSuccess={setShowSuccessModal}
            // initiateBuy={purchaseUsd}
            setFundModal={fundNgn}
            refetchRates={function <TPageData>(
              options?:
                | (RefetchOptions & RefetchQueryFilters<TPageData>)
                | undefined
            ): Promise<QueryObserverResult<AxiosResponse<any, any>, unknown>> {
              throw new Error("Function not implemented.");
            }}
          />
        )}
      </div>
    </>
  );
};

export default Wallets;
