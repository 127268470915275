import React, { useState } from "react";
import { BiRefresh } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import SuccessMobile from "../../../assets/iconsComponents/SuccessMobile";
import { motion, AnimatePresence } from "framer-motion";

import WalletCompartment from "../../../components/dashboard-components/WalletCompartment";
import { AiOutlineClose, AiOutlineCopy } from "react-icons/ai";
import RefreshIcon from "../../../assets/iconsComponents/RefreshIcon";
import { BsChevronLeft } from "react-icons/bs";
import usdc from "../../../assets/coins/usdc.png";
import InfoIcon from "../../../assets/iconsComponents/InfoIcon";
import QrCode from "qrcode.react";
import Toastify from "toastify-js";
import Notification from "../../../components/dashboard-components/Notification";
import EmptyState from "../../../components/base-components/EmptyState";
import { useWalletAccount, useWalletBalance } from "../../../sevices/hooks/wallets";
import { useUsers } from "../../../sevices/hooks/users";
import Warning from "../../../components/wallet-components/Warning";
import ConvertModal from "../../../components/wallet-components/ConvertModal";
import SwapModal from "../../../components/dashboard-components/SwapModal";
import { useUsdcRates } from "../../../sevices/hooks/tags";
import { IoMdClose } from "react-icons/io";
import { AxiosResponse } from "axios";
import { RefetchOptions, RefetchQueryFilters, QueryObserverResult } from "react-query";

const UsdcWallet = ({ currency = "usdc" }: { currency?: string }) => {
  const navigate = useNavigate();
  const [qrCodeModal, setQrCodeModal] = useState(false);
  const [currentTab, setCurrentTab] = useState("buy");
  const [BuyModal, setBuyModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSwapConfirmation, setShowSwapConfirmation] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSwapSuccessModal, setShowSwapSuccessModal] = useState(false);
  const [copyStatus, setCopyStatus] = useState("");
  const usdcRates = useUsdcRates();
  //fund
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [fields, setFields] = useState({
    amount: "",
  });
  const [showConvert, setShowConvert] = useState(false);
  const [loading, setLoading] = useState(false);

  const onfieldChange = ({ name, value }: { value: any; name: any }) => {
    setFields({ ...fields, [name]: value });
  };
  //usdc wallet
  const usdcAcc = useWalletBalance("usdc");
  const getUserDetails = useUsers(
    //@ts-ignore
    localStorage.getItem("user_id"),
    localStorage.getItem("accessToken")
  );

  const copyToClipBoard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyStatus("Wallet address copied to clipboard");
    let timeout = setTimeout(() => {
      setCopyStatus("");
      clearTimeout(timeout);
    }, 1500);
  };

  const showToast = () => {
    setQrCodeModal(false);
    Toastify({
      text: "You have received X USDC - Kindly check your USDC wallet.",
      className: "info",
      style: {
        background: "#D3FDE9",
      },
    }).showToast();
  };

  function truncateString(str: string, num: number) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  const receiveUsdc = () => {
    setQrCodeModal(true);
  };

  return (
    <div className="bg-white md:bg-[#f7fbfb] h-full w-full p-5 md:pt-10 md:px-8 ">
      <div className="flex items-center gap-24 md:gap-4 ">
        <button
          onClick={() => {
            navigate("/dashboard/wallets/stablecoin", { replace: true });
          }}
          className=" bg-[#ECF6F3] rounded-full p-2 flex transition-all active:scale-90 text-slate-70"
        >
          <BsChevronLeft size={24} />
        </button>
        <p className="text-[#071827] font-semibold">
          {currency.toUpperCase()} Wallet
        </p>
      </div>
      <div className=" bg-white flex flex-col md:p-5 mt-7 rounded-lg">
        <div className="flex flex-col items-start gap-3 mb-5 md:flex-row md:justify-between">
          <div className="w-full md:w-fit xxs:mb-2">
            <WalletCompartment
              text=" Balance"
              icons={<BiRefresh />}
              amount={"0.00 "}
              currency={currency.toUpperCase()}
              setter={receiveUsdc}
            />
          </div>
          <div className="md:flex md:flex-col md:items-end">
            <div className=" flex gap-2   md:items-end">
              <div
                onClick={() => {
                  setShowConvert(true);
                }}
                className="buy border text-center transition-all active:scale-90 cursor-pointer md:w-32 md:px-5   bg-white border-[#0eb683] text-[#0eb683] rounded-md xxs:text-sm xxs:flex-1 md:flex-initial flex items-center md:gap-1 justify-center xxs:h-12"
              >
                Convert
                {/* <span className="xxs:hidden md:block">{currency.toUpperCase()}</span>  */}
              </div>
              {qrCodeModal && (
                <div>
                  <motion.div
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      opacity: 0,
                      transition: {
                        // delay: 0.3,
                        duration: 0.1,
                      },
                    }}
                    className="showFcy_modal backdrop-blur-sm bg-[#13111173] fixed w-full h-full top-0 left-0 justify-center items-center z-10"
                  >
                    <motion.div
                      initial={{
                        x: 600,
                        scale: 0,
                      }}
                      animate={{
                        x: 0,
                        scale: 1,
                        transition: {
                          duration: 0.3,
                        },
                      }}
                      exit={{
                        x: 600,
                        scale: 0,
                        transition: {
                          delay: 0.3,
                        },
                      }}
                      className="flex h-screen items-center justify-center md:justify-end"
                    >
                      <motion.div
                        initial={{
                          x: 600,
                          opacity: 0,
                        }}
                        animate={{
                          x: 0,
                          opacity: 1,
                          transition: {
                            delay: 0.3,
                            duration: 0.3,
                          },
                        }}
                        exit={{
                          x: 100,
                          opacity: 1,
                          transition: {
                            duration: 0.3,
                          },
                        }}
                        className="bg-white  shadow-2xl w-full max-w-md md:max-w-md max-h-full xxs:rounded-lg  md:h-full md:rounded-l-3xl xxs:px-5 xxs:py-8 md:pt-5 md:px-10 overflow-y-scroll z-10"
                      >
                        <div className="card flex flex-col  ">
                          <button
                            onClick={() => {
                              setQrCodeModal(false);
                            }}
                            className="transition-all active:scale-90 hover:bg-[#E5F2EA] text-[#1E3448] rounded-full p-2 self-end "
                          >
                            <AiOutlineClose size={28} />
                          </button>
                          <div className="mb-3">
                            <h1 className="text-xl text-[#071827] font-bold text-center">
                              Receive {currency.toUpperCase()}
                            </h1>
                          </div>
                          <div className="mx-auto">
                            <div className="flex flex-col   gap-3 relative w-fit h-fit">
                              <div className="qrcode_wrapper flex items-center justify-center w-48 h-48 border-4 border-[#0eb683]  rounded-2xl">
                                {" "}
                                <QrCode
                                  value={
                                    getUserDetails?.data?.data?.stableWallet
                                      ?.depositAddresses?.MATIC
                                  }
                                  size={160}
                                />
                              </div>
                              <div className="blockage  ">
                                <div className="h-3 md:h-2 w-32 bg-white mb-1 absolute -top-1 right-8"></div>
                                <div className="w-3 h-32 md:w-2 bg-white mb-1 absolute top-8 -left-1 "></div>
                                <div className="w-3 h-32 md:w-2 bg-white mb-1 absolute -right-1 md:right-0 top-8 "></div>
                                <div className="h-3 md:h-2 w-32 bg-white mb-1 absolute bottom-1 md:bottom-2 right-8 "></div>
                              </div>
                            </div>
                          </div>
                          <p className="text-[#071827] font-bold text-center xxs:mt-2 ">
                            {" "}
                            Scan code to copy your{" "}
                            <span className="text-[#0eb683] font-bold">
                              {currency.toUpperCase()}{" "}
                            </span>{" "}
                            wallet address
                          </p>
                          {information()}
                          <div className="h-40 w-fit my-3">
                            <Warning currency="usdc" />
                          </div>

                          <button
                            onClick={() => {
                              showToast();
                            }}
                            className="bg-[#0eb683] text-white w-full p-3 rounded-md  transition-all active:scale-90 mt-6"
                          >
                            Finish
                          </button>
                        </div>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </div>
              )}
              <div
                // disabled
                onClick={() => {
                  setBuyModal(true);
                }}
                className=" border text-center transition-all active:scale-90 cursor-pointer xxs:w-14 md:w-44 md:px-5   text-white border-[#0eb683] bg-[#0eb683] rounded-md xxs:text-sm flex gap-1 md:flex-initial items-center justify-center  xxs:flex-auto xxs:h-12"
              >
                <span> Buy | Sell</span> <span>{currency.toUpperCase()}</span>
              </div>

              {BuyModal && (
                <SwapModal
                  baseCurrency={currency}
                  baseCurrencySymbol={" "}
                  currency={"ngn"}
                  currencySymbol={"₦ "}
                  rate={usdcRates?.data?.data?.buyRate}
                  confirmation={showConfirmation}
                  setConfirmation={setShowConfirmation}
                  inputValue={"100"}
                  inputValueFormatted={"0"}
                  inputValueFloat={"0"}
                  setInputValue={() => { } }
                  openModal={BuyModal}
                  loading={loading}
                  setLoading={setLoading}
                  setOpenModal={setBuyModal}
                  balance={"0"}
                  baseBalance={"0"}
                  computedValue={"0"}
                  computedValueFloat={"0"}
                  computedValueFormatted={"0"}
                  setComputedValue={() => { } }
                  errorMessage={""}
                  success={showSuccessModal}
                  setSuccess={setShowSuccessModal}
                  // initiateBuy={() => {}}
                  setFundModal={setShowModal} refetchRates={function <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined): Promise<QueryObserverResult<AxiosResponse<any, any>, unknown>> {
                    throw new Error("Function not implemented.");
                  } }                />
              )}

              <Link
                // aria-disabled={true}
                // to={""}
                to={"/dashboard/wallet/transfer/usdc"}
                className=" border text-center transition-all active:scale-90 cursor-pointer md:w-32  md:px-5  bg-white border-[#0eb683] text-[#0eb683] rounded-md xxs:text-sm flex md:gap-1 items-center justify-center  xxs:flex-1 md:flex-initial xxs:h-12 "
              >
                <span>Transfer</span>
                {/* <span className="xxs:hidden md:block">{currency.toUpperCase()}</span> */}
              </Link>
            </div>
            <div className="md:text-right md:mt-8 xxs:mt-4">
              <p className=" text-sm text-justify md:hidden text-[#5C6F7F] ">
                Securely make transfers to USDC wallets with a fee of a 0.1%
                Transfer speeds typically depend on the blockchain network. All
                funds are secured
              </p>

              <p className="text-sm hidden md:flex items-end flex-col  font-medium text-[#5C6F7F] md:text-right">
                Securely make transfers to USDC wallets with a fee of a 0.1%
                <br />
                <span className="md:text-right">
                  {" "}
                  Transfer speeds typically depend on the blockchain network
                </span>{" "}
                <span className=""> All funds are secured</span>
              </p>
            </div>
          </div>
        </div>

        {/* FUND */}
        <AnimatePresence>
          {showModal && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50 "
            >
              <motion.div
                initial={{
                  x: 600,
                  scale: 0,
                }}
                animate={{
                  x: 0,
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 600,
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173]"
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="flex flex-col bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative xxs:rounded-md md:rounded-l-md  p-5 md:px-7 "
                >
                  <div className="flex items-end justify-end pb-3">
                    <div
                      onClick={() => {
                        setShowModal(false);
                      }}
                      className="close  flex items-center justify-center h-10 w-10  rounded-full
                    hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center "
                    >
                      <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                        <IoMdClose size={29} className="" />
                      </span>
                    </div>
                  </div>
                  <div className="absolute top-6 ">
                    <h1 className="font-bold text-xl text-[#071827] mb-1">
                      Fund your account
                    </h1>
                    <p className="text-[#5C6F7F] text-sm pb-4">
                      Fund your NGN balance via direct bank transfer
                    </p>
                  </div>
                  <input
                    type="number"
                    name="amount"
                    className="w-full  p-3 bg-[#f7fbfb] rounded-md border my-6 outline-[#0eb683] placeholder:text-sm"
                    placeholder="Enter the amount you wish to transfer "
                    onChange={(e) => {
                      onfieldChange({
                        name: "amount",
                        value: e.target.value,
                      });
                    }}
                  />
                  <div>
                    <Notification />
                  </div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowModal(false);
                      setShowConfirmationModal(true);
                    }}
                    className="bg-[#0eb683] rounded-md px-10 p-3 transition-all active:scale-90 mt-16 text-white "
                  >
                    Continue
                  </button>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <>
        <EmptyState />
      </>
      <div>
        <AnimatePresence>
          {showConfirmationModal && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
            >
              <motion.div
                initial={{
                  x: 600,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173]"
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="flex flex-col bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative xxs:rounded-md md:rounded-l-md  p-5 md:px-7"
                >
                  <div className="flex items-end justify-end pb-3">
                    <div
                      onClick={() => {
                        setShowConfirmationModal(false);
                      }}
                      className="close  flex items-center justify-center h-10 w-10  rounded-full
                    hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center "
                    >
                      <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                        <IoMdClose size={29} className="" />
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-3 items-center pb-2 absolute top-5">
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        setShowConfirmationModal(false);
                        setShowModal(true);
                      }}
                      className="bg-[#f7fbfb] p-2 rounded-full cursor-pointer transition-all active:scale-90"
                    >
                      <BsChevronLeft />
                    </div>

                    <h1 className="font-bold text-xl text-[#071827]">
                      Confirmation
                    </h1>
                  </div>
                  <p className="text-sm text-[#5C6F7F] mb-9">
                    Fund your NGN wallet via direct bank transfer
                  </p>
                  <div className="flex items-center justify-between">
                    <h1 className="text-[#071827] font-bold">
                      You are transferring:
                    </h1>
                    <p className="text-[#0EB683] font-bold text-xl">NGN 0.00</p>
                  </div>
                  <div className="py-5">
                    <Notification
                      text={
                        "Once you have transferred the amount, click confirm below"
                      }
                      info={"To:"}
                    />
                  </div>

                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      setShowConfirmationModal(false);
                      setShowSuccess(true);
                    }}
                    className="flex flex-row justify-between md:flex-col mb-4"
                  >
                    <button className="border border-[#0eb683] text-[#0eb683] md:p-3 rounded-md md:mb-4 cursor-pointer transition-all active:scale-90  py-3 px-8 hover:bg-[#0eb683] hover:text-white ">
                      Confirm
                    </button>
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <AnimatePresence>
          {showSuccess && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.1,
                },
              }}
              className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
            >
              <motion.div
                initial={{
                  x: 600,
                  scale: 0,
                }}
                animate={{
                  x: 0,
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 600,
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173]"
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="flex flex-col bg-white justify-center  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative xxs:rounded-md md:rounded-l-md  p-5 md:px-7"
                >
                  <div className="icon self-center">
                    <SuccessMobile width={"200px"} height={"200px"} />
                  </div>
                  <h1 className="text-3xl font-bold text-[#071827] self-center">
                    Wallet Funded
                  </h1>

                  <p className="text-sm text-[#5C6F7F] self-center">
                    Your wallet has been successfully funded
                  </p>

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowSuccess(false);
                    }}
                    className="bg-[#0eb683] text-white rounded-md px-28 py-3 self-center transition-all active:scale-90 "
                  >
                    Done
                  </button>
                </motion.div>
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      // scale:1,
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      // scale:10,
                      duration: 0.3,
                    },
                  }}
                  className="mobile-card flex md:hidden justify-center flex-col gap-8 bg-white w-full max-w-sm h-[450px] rounded-3xl p-5 "
                >
                  <div className="icon self-center">
                    <SuccessMobile width={"100px"} height={"100px"} />
                  </div>
                  <h1 className="text-3xl font-bold text-[#071827] self-center">
                    Wallet Funded
                  </h1>

                  <p className="text-sm text-[#5C6F7F] self-center">
                    Your wallet has been successfully funded
                  </p>

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowSuccess(false);
                    }}
                    className="bg-[#0eb683] text-white rounded-md px-16 py-3 self-center transition-all active:scale-90 "
                  >
                    Done
                  </button>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
        {showConvert && (
          <ConvertModal
            closeModal={setShowConvert}
            currency={currency.toUpperCase()}
          />
        )}
      </div>
    </div>
  );

  function information() {
    return (
      <div className="relative xxs:mt-4 md:mt-4">
        <div className="w-full h-48 rounded-md bg-[#F7FBFB] px-3 md:px-5 flex flex-col gap-2 md:gap-3 ">
          <div className="flex flex-col gap-2 mt-3">
            <p className="text-xs font-extrabold ">
              Copy:{" "}
              <span className="text-[#0eb683] font-light">{copyStatus}</span>
            </p>
          </div>

          <div className="mt-1 flex flex-col gap-3 relative">
            <div
              onClick={() =>
                copyToClipBoard(
                  getUserDetails?.data?.data?.stableWallet?.depositAddresses
                    ?.MATIC
                )
              }
              className="flex items-center  gap-1 border border-[#0eb683] w-20 py-1 px-3 rounded-md bg-white transition-all active:scale-90 cursor-pointer "
            >
              <div className="text-[#0eb683]">
                <AiOutlineCopy />
              </div>
              <p className="text-sm text-[#0eb683] font-medium">Copy</p>
            </div>
            <p className="xxs:hidden md:block text-[#5C6F7F] text-sm font-semibold">
              {truncateString(
                getUserDetails?.data?.data?.stableWallet?.depositAddresses
                  ?.MATIC,
                35
              )}
            </p>
            <p className="text-[#5C6F7F] text-sm font-semibold md:hidden">
              {truncateString(
                getUserDetails?.data?.data?.stableWallet?.depositAddresses
                  ?.MATIC,
                30
              )}
            </p>
            <div className="flex flex-col bg-white    p-1 rounded-md   w-full ">
              {/* <InfoIcon weight={"20px"} height={"20px"} /> */}
              <h1 className="font-bold text-[#071827]">Network:</h1>
              <p className="text-xs text-[#E01A00] font-light ">
                {" "}
                <span className="text-[#E01A00]">
                  Ensure you are transferring over the
                </span>{" "}
                Polygon (MATIC) Chain{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="w-1 h-20 bg-red-600 absolute top-10 rounded-full "></div>
      </div>
    );
  }
};

export default UsdcWallet;
