import React from 'react'
import RefreshIcon from '../../assets/iconsComponents/RefreshIcon'
import WalletIcon from '../../assets/iconsComponents/WalletIcon'

const FiatWalletCard = ({ text, to, currency, amount, props}: any) => {

    const refresh = (props:any)=>{
     props.refetch()
  }

  return (
    <div>
         <div
      className={`flex items-center gap-4    xxs:bg-[#fff] md:bg-[#fff] md:py-6 md:pr-4 px-4 w-full h-[144px] md:w-[350px] md:h-[154px]  border-2 border-[#0eb683]  rounded-lg cursor-pointer relative   `}
    >
      <div className=" text-#98999cE] bg-[#E7FCF5] p-3  rounded-full ">
        <WalletIcon stroke="#0B0C0E" />
      </div>
      <div className=" flex flex-col gap-3">
        <div className="">
          <div className="xxs:text-center md:text-left">
            
            <span className="xxs:text-[#5C6F7F] md:text-[#5C6F7F]">
              {" "}
              {`${text}`}
            </span>
          </div>
          <p className="font-bold text-lg xxs:text-[#0b0c0e] md:text-[#5C6F7F]">
            {" "}
            <span className="xxs:text-[#5C6F7F] md:text-[#5C6F7F] font-bold">
              {currency}
            </span>{" "}
            <span>{amount}</span>{" "}
          </p>
        </div>

        
      </div>
      <button
      onClick={(e)=>{
        e.stopPropagation()
      refresh(props)
      }}
       className="reload_icon border border-[#0eb683] text-[#0eb683] rounded-full p-1 transition-all active:scale-90 ml-6 absolute  top-4 right-4 md:top-auto ">
        <RefreshIcon/>
      </button>
    </div>
    </div>
  )
}

export default FiatWalletCard