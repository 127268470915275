/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import logo from "../../assets/misc/FaviconAsset_ttfcx.svg";
import hamburger from "../../assets/misc/hamburger-button.svg";
import { TabSelector } from "../../components/widgets/TabSelector";
import { MdAdd } from "react-icons/md";
import { HiPlus } from "react-icons/hi";
import { TabPanel, useTabs } from "../../components/widgets/TabWidget";
import { useSidebarState } from "../../store";
import { useNavigate } from "react-router-dom";
import BeneficiaryEmptyState from "../../components/beneficiary-component/BeneficiaryEmptyState";
import BeneficiaryCard from "../../components/beneficiary-component/BeneficiaryCard";
import { BeneficiaryType, useBeneficiaries } from "../../sevices/hooks/wallets";
import { useBeneficiariesState } from "../../store/useBeneficiaries";
import BeneficiaryDetailsModal from "../../components/beneficiary-component/BeneficiaryDetailsModal";
import { BsSearch } from "react-icons/bs";
import { IoPersonAdd } from "react-icons/io5";

const Beneficiaries = () => {
  const [selectedTab, setSelectedTab] = useTabs(["Fiat", "Stablecoins"]);
  const toggleSideBarOpen = useSidebarState((state) => state.toggleSidebar);
  const navigate = useNavigate();
  const beneficiaries = useBeneficiaries();
  const setBeneficiaries = useBeneficiariesState(
    (state) => state.setBeneficiaries
  );
  const showBeneficiaryDetails = useBeneficiariesState(
    (state) => state.showBeneficiaryDetails
  );
  const { isLoading, data } = beneficiaries;
  const hasData = !!data?.data?.length;

  // const dataLength = data?.data?.length ;

  const changeTab = () => {
    navigate("/dashboard/beneficiaries/add-beneficiary", { replace: true });
  };

  useEffect(() => {
    setBeneficiaries(beneficiaries?.data?.data || []);
  }, [beneficiaries?.data?.data]);
  const [query, setQuery] = useState("");

  const searchKey = ["bank", "name"];

  return (
    <div className="h-screen w-full bg-[#f7fbfb] p-5 md:pr-5 md:pl-8 ">
      <div className="w-16 ml-auto md:hidden">
        <img src={logo} alt="logo" />
      </div>
      <button
        className=" rounded-m  block md:hidden"
        onClick={() => toggleSideBarOpen()}
      >
        <img src={hamburger} alt="logo" className="w-10" />
      </button>

      <div className="flex items-center justify-between md:mr-2">
        <h1 className="font-bold text-2xl text-[#071827] md:pb-5">
          Beneficiaries
        </h1>
        <div
          className="hidden md:flex items-center text-white font-medium bg-[#0eb683] p-3 rounded-md gap-2 px-8 transition-all active:scale-90 cursor-pointer mt-2"
          onClick={changeTab}
        >
          <IoPersonAdd size={23} />
          <p className="pt-1">Add Beneficiary</p>
        </div>

        <div className=" md:hidden mt-7">
          <button
            className="block md:hidden items-center bg-[#0eb683] p-3 px-10 rounded-md shadow-lg  transition-all active:scale-90 cursor-pointer  text-white  "
            onClick={changeTab}
          >
            {" "}
            <p className="flex items-center gap-2 ">
              <IoPersonAdd />
              Add
            </p>
          </button>
          {}
        </div>
      </div>
      <nav className=" border-b-2 border-[#E8E9EB]  md:px-0 pb-4 flex gap-6 md:justify-start  md:mt-5 ">
        <TabSelector
          className={` cursor-pointer relative bg-transparent flex items-center gap-1 text-[#5C6F7F] ${
            selectedTab === "Fiat"
              ? "text-[#0eb684] font-semibold before:block  before:h-[2px] before:w-full before:bg-[#0eb683] before:absolute before:bottom-[-18px] before:transition-all"
              : ""
          } `}
          isActive={selectedTab === "Fiat"}
          onClick={() => setSelectedTab("Fiat")}
        >
          <span>Fiat</span>
          {/* @ts-ignore */}
          {/* {data?.data?.length === 0 ? "":
          <div className="bg-[#FF9636] h-5 px-3  w-fit  text-[#fff] font-bold flex items-center justify-center rounded-md  top-0 right-[-36px]" >{data?.data?.length}</div>
          } */}
        </TabSelector>

        <TabSelector
          className={` cursor-pointer relative bg-transparent text-[#5C6F7F] ${
            selectedTab === "Stablecoins"
              ? "text-[#0eb684] font-semibold before:block before:w-full before:h-[2px] before:bg-[#0eb683] before:absolute before:bottom-[-18px] before:transition-all"
              : ""
          } `}
          isActive={selectedTab === "Stablecoins"}
          onClick={() => setSelectedTab("Stablecoins")}
        >
          Stablecoin
        </TabSelector>
      </nav>
      <div className="md:mt-5 xxs:mt-1">
        <div>
          <header className=" py-4 md:py-0">
            <div className="search flex items-center bg-[#fff] text-[#5C6F7F] p-1 px-4 gap-1 rounded-md">
              <BsSearch />
              <input
                type="text"
                placeholder="Search for a beneficiary"
                className="flex-1 p-2 bg-transparent outline-none w-full text-[#5C6F7F]"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            {/* <button className="bg-white text-[#0eb683] hidden md:flex  rounded-md px-9 py-2 border border-[#0eb683]">search</button> */}
          </header>
        </div>
      </div>
      <div className=" ">
        <TabPanel
          hidden={selectedTab !== "Fiat"}
          className="flex flex-col gap-3 "
        >
          <div
            className="
           grid sm:grid-cols-2 xxs:gap-3 md:gap-2 lg:gap-2 md:grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] xxs:mt-1 md:mt-5"
          >
            {data?.data
              .filter((b: BeneficiaryType) =>
                // @ts-ignore
                searchKey.some((key: BeneficiaryType) =>
                  // @ts-ignore
                  b[key].toLowerCase().includes(query)
                )
              )
              .map((beneficiary, index) => {
                return (
                  <BeneficiaryCard
                    beneficiary={beneficiary as any}
                    key={index + "beneficiary"}
                  />
                );
              })}
          </div>
        </TabPanel>
        <TabPanel
          hidden={selectedTab !== "Stablecoins"}
          className="flex flex-col gap-3 mt-5"
        ></TabPanel>
      </div>
      {!hasData && !isLoading && <BeneficiaryEmptyState />}

      {isLoading && !hasData && <div>Loading Please wait...</div>}

      {showBeneficiaryDetails && <BeneficiaryDetailsModal />}
    </div>
  );
};

export default Beneficiaries;
