import React, { useState } from "react";
import { BsPlus } from "react-icons/bs";
import LinkIcon from "../../assets/iconsComponents/LinkIcon";
import ShareIcon from "../../assets/iconsComponents/ShareIcon";
import useQueryParams from "../../hooks/useQueryParams";
import { useGenerateBeneficiaryForm } from "../../sevices/hooks/users";
import { BeneficiaryType, useBeneficiaries } from "../../sevices/hooks/wallets";
import { useBeneficiariesState } from "../../store/useBeneficiaries";
import { TabSelector } from "../widgets/TabSelector";
import { useTabs } from "../widgets/TabWidget";
import BeneficiaryCard from "./BeneficiaryCard";
import BeneficiaryDetailsModal from "./BeneficiaryDetailsModal";
import { motion, AnimatePresence } from "framer-motion";
import ReactLoading from "react-loading";
import CopyIcon from "../../assets/iconsComponents/CopyIcon";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation } from "react-router-dom";

interface Iprops {
  onClick: () => void;
}

const StableCoinBeneficiaryLayout = ({ onClick }: { onClick: () => void }) => {
  const [selectedFlow, setSelectedFlow] = useTabs([
    "Beneficiary Information",
    "Edit Information",
    "Add Beneficiary",
  ]);

  const beneficiaryForm = useGenerateBeneficiaryForm();
  const [isLoading, setIsLoading] = useState(false);
  const [formToken, setFormToken] = useState<any>();
  const [formLink, setFormLink] = useState(false);
  const { ref: extRef, token: extToken } = useQueryParams(["ref", "token"]);
  const [shareFormModal, setShareFormModal] = useState(false);
  const [copyStatus, setCopyStatus] = useState("");
  const location = useLocation();
  let params = new URLSearchParams(location.pathname);

  const showBeneficiaryDetails = useBeneficiariesState(
    (state) => state.showBeneficiaryDetails
  );
  const beneficiaries = useBeneficiaries();
  const { data } = beneficiaries;

  const generateForm = () => {
    setIsLoading(true);
    beneficiaryForm
      .mutateAsync({})
      .then(({ data }) => {
        setIsLoading(false);
        setFormToken(data);
        setFormLink(true);
      })
      .catch((e) => {});
  };

  const copyToClipBoard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyStatus("Copied to clipbaord");
    let timeout = setTimeout(() => {
      setCopyStatus("");
      clearTimeout(timeout);
    }, 1500);
    setFormLink(false);
    setFormToken(undefined);
  };

  return (
    <>
      <div className=" h-full xxs:px-3">
        <div
          className=" grid grid-cols-[repeat(auto-fill,_minmax(15rem,_1fr))] gap-3 mt-10
         "
        >
          <div
            onClick={onClick}
            className=" border border-[#0eb683] gap-4 bg-white w-full xxs:h-28 rounded-lg flex md:flex-col items-center md:h-48 p-5 transition-all active:scale-90 cursor-pointer md:justify-center  "
          >
            <div className="round rounded-full w-14 h-14   flex items-center justify-center bg-[#0eb683] text-white ">
              <BsPlus size={28} />
            </div>
            <div className="name ">
              <p className="text-[#071827]  text-sm">Add new beneficiary </p>
            </div>
          </div>

          <div
            onClick={() => {
              setShareFormModal(true);
            }}
            className="border border-[#0eb683] gap-4 bg-white w-full   rounded-lg flex md:flex-col items-center h-28  md:h-48 p-5 transition-all active:scale-90 cursor-pointer md:justify-center  "
          >
            <div className="round rounded-full w-14 h-14  flex items-center justify-center bg-[#0eb683]  ">
              <ShareIcon width="24" height="24" stroke="white" />
            </div>
            <div className="name ">
              <p className="text-[#071827]  text-sm">Share beneficiary form</p>
            </div>
          </div>

          <AnimatePresence>
            {shareFormModal && (
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    // delay: 0.3,
                    duration: 0.1,
                  },
                }}
                className="modal bg-[#a59d9d34] backdrop-blur-base fixed w-full h-full top-0 left-0 justify-center items-center z-10"
              >
                <motion.div
                  initial={{
                    x: 600,
                    scale: 0,
                  }}
                  animate={{
                    x: 0,
                    scale: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 600,
                    scale: 0,
                    transition: {
                      delay: 0.3,
                    },
                  }}
                  className="flex h-screen items-center justify-center md:justify-end"
                >
                  <motion.div
                    initial={{
                      x: 600,
                      opacity: 0,
                    }}
                    animate={{
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 100,
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    className="card flex flex-col bg-white  w-full max-w-md max-h-full rounded-l-3xl p-6 md:h-full "
                  >
                    <button
                      onClick={() => {
                        setShareFormModal(false);
                      }}
                      className="hover:bg-[#f2faf7] rounded-full p-2 self-end md:mb-6"
                    >
                      <AiOutlineClose size={28} />
                    </button>
                    <div className=" ">
                      <h1 className="font-bold text-xl text-[#071827] pb-3">
                        Share form with an external beneficiary
                      </h1>
                      <p className="text-[#5C6F7F] text-base pb-4">
                        You can either generate a link to share or email the
                        beneficiary to supply their account details
                      </p>

                      {formLink ? (
                        <>
                          <div className="flex items-center gap-4">
                            <div className="flex items-center justify-center  rounded-lg text-[#0eb683] border border-[#E2E9F0]] md:w-72 h-14  ">
                              <LinkIcon height="25px" width="30px" />
                              <input
                                type="text"
                                disabled
                                value={` ${window.location.origin}/external/beneficiary-form?ref=${formToken?.ref}&token=${formToken?.token}`}
                                className="w-full  mr-3 ontline-none bg-white "
                              />
                            </div>
                            <button
                              disabled={!formToken}
                              onClick={() => {
                                copyToClipBoard(
                                  ` ${window.location.origin}/external/beneficiary?ref=${beneficiaryForm?.data?.data?.ref}&token=${beneficiaryForm?.data?.data?.token}`
                                );
                              }}
                              className="border  border-[#0eb683] bg-[#fff] flex gap-2 items-center rounded-lg transition-all active:scale-90 px-5 py-3"
                            >
                              <CopyIcon height="30px" />
                              <p className="text-[#0eb683]">Copy</p>
                            </button>
                          </div>
                          <button className="flex items-center justify-center rounded-lg border border-[#0eb683] w-full p-3 mt-5">
                            <LinkIcon height="25px" width="30px" />
                            <p className="text-[#0eb683]">Share link</p>
                          </button>
                          <div className="flex items-center gap-4 justify-center my-7">
                            <div className="h-[1px] w-44 bg-[#0eb683]"></div>
                            <p className="text-[#071827] font-semibold">Or</p>
                            <div className="h-[1px] w-44 bg-[#0eb683]"></div>
                          </div>

                          <div className="relative flex items-center gap-4">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              className="p-4 border  outline-[#0eb683] rounded-lg md:w-64 required"
                              placeholder="Beneficiary Email"
                            />
                            <span className="text-red-600 absolute top-4 left-2">
                              *
                            </span>
                            <button className="text-white px-5 p-4  bg-[#0eb683] rounded-lg transition-all active:scale-90">
                              Share form
                            </button>
                          </div>
                          <p className="text-white px-2 bg-green-300 rounded-full absolute top-11">
                            {copyStatus}
                          </p>
                          <div className="flex"></div>
                        </>
                      ) : (
                        <>
                          <div
                            onClick={generateForm}
                            className="flex items-center justify-center gap-2 rounded-lg text-[#0eb683] border border-[#0eb683] w-full py-4 transition-all active:scale-90 cursor-pointer "
                          >
                            <LinkIcon height="25px" width="30px" />
                            <button className=" ">
                              <p> Click here generate a shareable link!</p>
                              {formToken
                                ? ` ${window.location.origin}/external/beneficiary-form?ref=${formToken?.ref}&token=${formToken?.token}`
                                : "Generate a shareable link!"
                                ? isLoading && (
                                    <ReactLoading
                                      type="bubbles"
                                      color="#0eb683"
                                      height="20px"
                                      width="20px"
                                    />
                                  )
                                : formToken}
                            </button>
                          </div>

                          <div className="flex items-center gap-4 justify-center my-7">
                            <div className="h-[1px] w-44 bg-[#0eb683]"></div>
                            <p className="text-[#071827] font-semibold">Or</p>
                            <div className="h-[1px] w-44 bg-[#0eb683]"></div>
                          </div>

                          <div className="relative flex items-center ">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              className="p-4 border border-[#E2E9F0]  outline-[#0eb683] rounded-lg md:w-64 required"
                              placeholder="Beneficiary Email"
                            />
                            <span className="text-red-600 absolute top-4 left-2">
                              *
                            </span>
                            <button className="text-white p-4 ml-2 bg-[#0eb683] rounded-lg transition-all active:scale-90">
                              Share form
                            </button>
                          </div>
                          <p className="text-white px-2 bg-green-300 rounded-full absolute top-11">
                            {copyStatus}
                          </p>
                        </>
                      )}
                    </div>
                  </motion.div>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};

export default StableCoinBeneficiaryLayout;
