import React from "react";
import { Link } from "react-router-dom";
// import footerImg from "../../assets/images/landing/green.png";
import overlayImg from "../../assets/images/landing/blkMask.png";
import mobile from "../../assets/images/landing/mobi.png";
import Logo from "../../assets/images/landing/newLogo.png";
// import Logo from "../../assets/"
// import { partnerData } from "../../utils/partners";

const LandingPageFooter = () => {
  // const totalWidth = partnerData.length * 120;

  const handleFintracClick = () => {
    window.open(
      "https://www10.fintrac-canafe.gc.ca/msb-esm/public/detailed-information/msb-details/7b226d73624f72674e756d626572223a3137323035342c227072696d617279536561726368223a7b226f72674e616d65223a225454464358222c2273656172636854797065223a317d7d/",
      "_blank"
    );
  };
  const currentYear = new Date().getFullYear();
  return (
    <div className="">
      {/* <div className="bg-[#0eb683] h-[400px] w-full text-center px-3 md:px-0 ">
            <div className="text md:pt-20 pt-10 ">
              <h1 className="text-[#fff] text-3xl md:text-5xl font-bold pb-7 md:p-7">
                Join 100s of customers <br /> trusting us
              </h1>
              <p className="text-[#fff] text-[20px]  pb-10">
                Gain access to constantly deliver your business payments within 3
                business days
              </p>
            </div>
            <Link
              to={"/request-access"}
              className="text-[#fff]  bg-[#0C0D1F] p-4 rounded-lg px-5 mr-4 font-semibold shadow-md"
            >
              Request Access
            </Link>
            <Link
              to={"/login"}
              className="bg-[#fff]  text-[#0eb683] p-4 rounded-lg px-5 font-semibold shadow-md"
            >
              Log in
            </Link>
          </div> */}
      {/* Partners */}
      {/* <>
        <div className="bg-[#fff] shadow-md">
          <div className="flex items-center justify-center flex-col md:-space-y-2  ">
            <h1 className=" text-center  text-[#606060]  font-normal text-xl md:ml-28 xxs:ml-0 md:my-5 md:px-5 md:pb-1 pt-1 ">
              Partners
            </h1>
          </div>
          <div
            className={`w-[${totalWidth}%] h-20  border-gray-600 overflow-hidden relative  `}
          >
            <div className="xxs:hidden w-[100%] md:flex items-center  justify-around h-22 overflow-hidden   ">
              {partnerData.map((i: any) => {
                return (
                  <div key={i?.id} className={` ml-7 mb-[58px] `}>
                    <img
                      src={i?.image}
                      alt={i?.name}
                      style={{
                        maxWidth: "70%",

                      }}
                    />
                  </div>
                );
              })}
            </div>

            <div className="md:hidden w-[200%] xxs:flex items-center h-20 absolute left-0  overflow-hidden gap-5 image">
              {partnerData.map((i: any) => {
                return (
                  <div
                    key={i?.id}
                    className="flex justify-center items-center w-[35rem] image mb-9"
                  >
                    <img
                      src={i?.image}
                      alt={i?.name}
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                );
              })}
            </div>
            <div className="md:hidden w-[200%] xxs:flex items-center h-20 absolute left-0  overflow-hidden gap-5 follow">
              {partnerData.map((i: any) => {
                return (
                  <div
                    key={i?.id}
                    className="flex justify-center items-center w-[35rem]  mb-9"
                  >
                    <img
                      src={i?.image}
                      alt={i?.name}
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </> */}

      {/* footer */}
      <div
        className="bg-[#0C0D1F] xxs:hidden sm:hidden md:block md:h-[400px] w-full relative"
        style={{
          overflow: "hidden",
          backgroundImage: `url('${overlayImg}')`,
          backgroundSize: "100% 100%",
          backgroundPosition: "center",

          position: "relative",
        }}
      >
        <div className="flex items-center justify-center w-full">
          <div className="flex space-x-40 pb-10 pt-10 ">
            <div className="pt-2">
              <div className="header ">
                <img src={Logo} alt="logo" width={140} />
              </div>
              <div className="body pt-4">
                <p className="text-[#fff] text-base font-thin">
                  hello@ttfcx.com
                </p>
              </div>
            </div>
            <div className=" flex flex-col  ">
              <div className="header text-[#fff] text-base font-bold">
                <p> St. Vincent</p>
              </div>
              <div className="body pt-4">
                <p className="text-[#fff] font-thin text-sm">
                  Euro House,
                  <br /> Richmond Hill Road,
                  <br /> Kingstown
                  <br /> VC0100
                </p>
              </div>
            </div>
            <div className=" flex flex-col  ">
              <div className="header text-[#fff] text-base font-bold">
                <p className="whitespace-nowrap">Canada</p>
              </div>
              <div className="body pt-4">
                <p className="text-[#fff] font-thin text-sm ">
                  70 George Butchart Drive,
                  <br /> North York, Ontario,
                  <br /> M3K 0G4
                  <br /> Canada
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="text-center ">
            <p className="text-[#fff] text-[16px] leading-normal">
              Trusted Transfers&reg; is a registered trademark of TTFCX
              Enterprises.
            </p>
            <p className="text-[#fff] text-[16px] leading-normal">
              Trusted Transfers is a financial technology company and not a
              bank. <br /> We are a regulated Money Service Business with{" "}
              <span
                onClick={handleFintracClick}
                className="text-[#0eb683] hover:underline cursor-pointer"
              >
                FINTRAC
              </span>
              <br /> We possess FX Authorization from St. Vincent, SCUML
              Certificate & BDC License.
              <br />
              Banking services are provided by our duly licensed partner banks
              and CBN, FCA, FDIC & SEC regulated providers.
            </p>
          </div>
        </div>
        <div className="mx-48 mt-8">
          <div className="bg-[#fff] h-[2px]   "></div>
        </div>
        <div className="w-full  ">
          <p className="text-center tracking-tight select-none pl-14  text-sm font-thin text-[#fff]">
            © {currentYear} TTFCX. All rights reserved
          </p>
        </div>
      </div>
      {/* mobile */}
      <div
        className="bg-[#0C0D1F] sm:block md:hidden h-[750px] w-full relative overflow-y-auto"
        // style={{ overflow: "auto" }}
      >
        <div
          className="  w-full  bg-cover bg-center pt-14 px-5 h-full"
          style={{
            backgroundImage: `url('${mobile}')`,
            backgroundSize: "100% 100%",
            backgroundPosition: "cover",
            width: "100%",
            height: "100vh",
            marginBottom: "20px",
            position: "relative",
          }}
        >
          <div className="">
            <div className="border-b border-[#fff]  ">
              <div className="container flex flex-col gap-8 ">
                <div className="header ">
                  <img src={Logo} alt="logo" width={130} />
                </div>
                <div className="body ">
                  <p className="text-[#fff] text-base font-bold">
                    hello@ttfcx.com
                  </p>
                </div>
              </div>
              {/* <div className="header text-[#fff] text-base font-bold my-8 tracking-tighter">
                <p>Delivering trusted cross-border payments</p>
              </div> */}
            </div>
            <div className="container flex flex-col header-body-gap w-fit my-8">
              <div className="header text-[#fff] text-base font-bold">
                <p> St. Vincent</p>
              </div>
              <div className="body pt-4">
                <p className="text-[#fff] font-thin text-sm">
                  Euro House,
                  <br /> Richmond Hill Road,
                  <br /> Kingstown
                  <br /> VC0100
                </p>
              </div>
            </div>
            <div className=" flex flex-col mb-8">
              <div className="header text-[#fff] text-base font-bold">
                <p>Canada</p>
              </div>
              <div className="body pt-4">
                <p className="text-[#ffff] font-thin text-sm">
                  70 George Butchart Drive,
                  <br /> North York, Ontario,
                  <br /> M3K 0G4
                  <br /> Canada
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="text-center">
              <p className="text-[#fff] text-[16px] leading-normal">
                Trusted Transfers&reg; is a registered trademark of TTFCX
                Enterprises. Trusted Transfers is a financial technology company
                and not a bank.
                <br /> We are a regulated Money Service Business with{" "}
                <span
                  onClick={handleFintracClick}
                  className="text-[#0eb683] hover:underline"
                >
                  FINTRAC
                </span>{" "}
                We possess FX Authorization from St. Vincent, SCUML Certificate
                & BDC License. Banking services are provided by our duly
                licensed partner banks and CBN, FCA, FDIC & SEC regulated
                providers.
              </p>
            </div>
          </div>
          <div className="w-full pt-5">
            <p className="text-center tracking-tight select-none   text-sm font-thin text-[#fff] pr-4">
              © {currentYear} TTFCX. All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageFooter;
