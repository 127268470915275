import React, { InputHTMLAttributes } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface IInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  label: string;
  name: string;
  type: string;
  required?: boolean;
  disabled?: boolean;
  value?: string;
  maxChar?: number;
  onChange: (newValue: string) => void;
}

const InputField: React.FC<IInputProps> = ({
  label,
  name,
  type,
  required,
  disabled,
  value,
  maxChar,
  onChange,
  ...rest
}) => {
  const validationSchema = yup.object().shape({
    [name]: yup.string().required(required ? `${label} is required` : ""),
    // .max(maxChar || undefined),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
    if (maxChar && e.target.value.length > maxChar) {
      e.target.value = e.target.value.slice(0, maxChar);
    }
  };

  return (
    <div className="relative z-0 w-full">
      <input
        {...rest}
        {...register(name)}
        id={name}
        type={type}
        disabled={disabled}
        placeholder=" "
        value={value}
        onChange={handleChange}
        className={`pt-3.5 pb-2.5 block w-full px-2.5 mt-0 bg-white md:bg-[#F2FAF7] border rounded-[4px] ${
          errors[name]
            ? "border-[#E01A00]"
            : "appearance-none focus:outline-none outline-[#0eb683] focus:ring-0 focus:border-[#0eb683] border-gray-200"
        }`}
      />
      <label
        htmlFor={name}
        className="absolute duration-300 top-3 -z-1 origin-0 text-[#6F798B] text-[14px] leading-[21px] font-normal pl-3"
      >
        {required && <span className="text-[#E01A00]">*</span>}
        {label}
      </label>
      {errors && errors[name] && (
        //@ts-ignore
        <p className="text-[#E01A00]">{errors[name].message}</p>
      )}
    </div>
  );
};

export default InputField;
