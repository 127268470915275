/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import { IPotentialUser } from '../../types/Users'
import { useInvitationVerify } from '../../sevices/hooks/users/index'
import LoadingScreen from '../Notifications/LoadingScreen2'

const Invites = (props: any) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [loading] = useState(true);
  const invitationVerify = useInvitationVerify()





  useEffect(() => {
    let params = new URLSearchParams(location.search);
    const code = params.get("code");
    const token = params.get("token");
    const data: IPotentialUser = { code: code || "", token: token || "" };

    invitationVerify.mutateAsync(data)
      .then(res => {

      navigate(`/onboarding?code=${code}&token=${token}`, {
        state: { code, email: res?.data?.data?.email }
      })
    })

    .catch(err => {
      navigate('/login')
    })

  }, [location.search]);



  return <div className="invite-screen">{loading && <LoadingScreen />}</div>;
};

export default Invites;
