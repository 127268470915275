import React from "react";
import brandLogo from "../../assets/misc/design-top.svg";
import brandLogo2 from "../../assets/misc/design-bottom.svg";
import logo from "../../assets/misc/logo-on-dark.svg";
import { useNavigate } from "react-router-dom";

const OnboardingSuccess = () => {
  const navigate = useNavigate();

  const showLogin = () => {
    navigate("/login", { replace: true });
  };

  return (
    <div>
      <img src={brandLogo} alt="" className="absolute w-28  md:w-44 " />
      <img
        src={brandLogo2}
        alt=""
        className="absolute w-28 md:w-44 right-0 bottom-0"
      />
      <div className=" flex items-center py-5 flex-col gap-5 bg-[#0C0D1F] h-screen justify-center">
        <img src={logo} alt="" className="w-60 inline" />
        <h1 className="text-white font-bold text-xl py-5 md:text-2xl">
          Congratulations!
        </h1>
        <p className="text-center text-white md:text-xl">
          You have successfully created your account,
          <br />
          please proceed to login
        </p>
        <button
          className="bg-[#0eb683] px-32 py-3 rounded-md text-white"
          onClick={showLogin}
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default OnboardingSuccess;
