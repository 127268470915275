import create from 'zustand'

interface IBeneficiaries {
  showBeneficiaryDetails: boolean;
  beneficiaries: any[];
  selectedBeneficiaryId: string;
  dBeneficiaryId: string;


    setShowBeneficiaryDetails: (shouldShow: boolean) => void
    setBeneficiaries: (data: any) => void
    setSelectedBeneficiaryId: (id: string) => void
    setDBeneficiaryId: (id: string) =>void
  }


  export const  useBeneficiariesState = create<IBeneficiaries>((set) => ({
    showBeneficiaryDetails: false,
    beneficiaries: [],
    selectedBeneficiaryId: '',
    dBeneficiaryId: "",


    setShowBeneficiaryDetails: (shouldShow: boolean) => set((state: any) => ({ showBeneficiaryDetails: shouldShow  })),
    setBeneficiaries: (data: any) => set((state: any) => ({ beneficiaries: data  })),
    setSelectedBeneficiaryId: (id: string) => set((state: any) => ({ selectedBeneficiaryId: id  })),
    setDBeneficiaryId:(id: string) => set((state :any)=> ({
      dBeneficiaryId:id})),
   }))