import React from "react";
import { CgSoftwareUpload } from "react-icons/cg";
import { useNavigate } from "react-router-dom";


const UsdcBeneficiary = ({ onClick, placeholder }: { onClick: () => void, placeholder: string }) => {

  const navigate = useNavigate()
  return (
    <div className="bg-[#f7fbfb] h-full w-full p-5">
        
      <div className="flex items-center md:hidden justify-between">
        <p className="text-[#5C6F7F] text-xs">Reference Code</p>
        <h1 className="text-[#0eb683] font-medium">ABC123</h1>
      </div>
      <p className="text-[#FA0204] text-sm font-medium pt-5 md:hidden">
        Always input this reference code during transactions so your wallet is
        credited correctly
      </p>
      <div className="md:bg-white md:p-5 md:pt-5 md:max-w-lg ">

      
      <div className="flex items-center justify-between pt-5">
        <h1 className="text-[#071827] font-bold text-xl">
          Beneficiary Information
        </h1>
        <button className="text-[#0EB683] transition-all flex border border-[#0eb683] active:scale-90 items-center p-2 rounded-md shadow-md"><CgSoftwareUpload size={22}/> Share Form</button>
      </div>
      <form className="flex flex-col gap-4 pt-5">
        <div className="mid relative">
          <input
            type="text"
            name=""
            className={`py-4 px-4 w-full md:bg-[#F2FAF7] md:w-full border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-sm `}
            placeholder= {placeholder}
            required
          />
          <span className="text-red-500 absolute left-2 bottom-4">*</span>
        </div>
        <div className="mid relative">
          <input
            type="text"
            name=""
            className={`py-4 px-4 w-full md:bg-[#F2FAF7] md:w-full border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-sm `}
            placeholder="Beneficiary Name"
            required
          />
          <span className="text-red-500 absolute left-2 bottom-4">*</span>
        </div>
        <div className="mid relative">
          <input
            type="text"
            name=""
            className={`py-4 px-4 w-full md:bg-[#F2FAF7] md:w-full border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-sm `}
            placeholder="Alias"
            required
          />
          <span className="text-red-500 absolute left-2 bottom-4">*</span>
        </div>
       
<div className="flex justify-between">
<button onClick={()=>{navigate(-1)}} className="text-[#0eb683] p-3 px-6 rounded-md bg-white self-end transition-all md:mt-10 active:scale-90 border border-[#0eb683]">Previous</button>
        <button onClick={onClick} className="bg-[#0eb683] p-3 px-6 rounded-md text-white self-end transition-all md:mt-10 active:scale-90 border border-[#0eb683]">Continue</button>
</div>
        
     
      </form>
        </div>
    </div>
  );
};

export default UsdcBeneficiary;
