import React, { SetStateAction, useEffect, useState } from "react";
import OnboardingFooter from "../../components/base-components/OnboardingFooter";
import Input from "./comp/Input";
import ReactPhoneInput, { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DragDropComponent from "../../components/base-components/DragDropComponent";
import { GenderData, IDOptionType, IDTypeOptions } from "./IndividualUser";
import CustomSelect from "../../components/base-components/CustomSelect";
import { BsCheckCircleFill } from "react-icons/bs";
import { BiUserCheck } from "react-icons/bi";
import { MdOutlineClose } from "react-icons/md";
import SmartCameraWeb from "../../components/SmartCameraWeb";
import SelfiePopUp from "./comp/SelfiePopUp";
import { useGetCountry, useGetState } from "../../sevices/hooks/tags";
import { useShowSelfie } from "../../store/useShowSelfie";
import useQueryParams from "../../hooks/useQueryParams";
import {
  useUpdateUserOnboardingInfo,
  useVerifyAccount,
} from "../../sevices/hooks/users";
import LoadingScreen from "../Notifications/LoadingScreen2";
import { formatPhoneNumber } from "./refresh-initiative/Controller";

const ContactPerson = ({
  getDetails,
  email,
  backFn,
  nextFn,
  userDetail,
}: {
  getDetails: (data: any, isValid: boolean) => void;
  email: string;
  backFn: () => void;
  nextFn: () => void;
  userDetail: any;
}) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [IDType, setIDType] = useState<IDOptionType>(null);
  const [gender, setGender] = useState<IDOptionType>(null);
  const [country, setCountry] = useState<IDOptionType>(null);
  const [state, setState] = useState<IDOptionType>(null);
  const [postCode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [selfieData, setSelfieData] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [bvn, setBvn] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [userId, setUserId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const setSelfieModal = useShowSelfie((state) => state.setSelfieModal);
  const [middleName, setMiddleName] = useState("");
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});
  const showSelfieModal = useShowSelfie((state) => state.showSelfieModal);
  const { accountType } = userDetail;
  const Icountry = useGetCountry();
  const states = useGetState();
  const { code, token } = useQueryParams(["token", "code"]);
  const kyc = useVerifyAccount();

  const [errorMessages, setErrorMessages] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    dob: "",
    phone: "",
    idNumber: "",
    bvn: "",
    // email: "",
    gender: "",
    address1: "",
    // address2: "",
    country: "",
    state: "",
    city: "",
    postCode: "",
    IDType: "",
    // idDocUrl: "",
    // selfieData: "",
  });

  const isFormValid = () => {
    setErrorMessage("");
    const errors = {
      firstName: "",
      lastName: "",
      middleName: "",
      dob: "",
      phone: "",
      bvn: "",
      // email: "",
      gender: "",
      address1: "",
      // address2: "",
      country: "",
      state: "",
      city: "",
      postCode: "",
      IDType: "",
      // selfieData: "",
      idNumber: "",
    };

    if (!firstName?.trim()) {
      errors.firstName = "First Name is required";
    }
    if (!lastName?.trim()) {
      errors.lastName = "Last Name is required";
    }
    if (!dob?.trim()) {
      errors.dob = "Date of Birth is required";
    }
    if (!middleName?.trim()) {
      errors.middleName = "Middle Name is required";
    }
    if (!phone) {
      errors.phone = "Phone Number is required";
    }
    if (!bvn?.trim()) {
      errors.bvn = "BVN is required";
    }
    // if (!email?.trim()) {
    //   errors.email = "Email is required";
    // }
    if (!gender) {
      errors.gender = "Gender is required";
    }
    if (!address1?.trim()) {
      errors.address1 = "Address Line 1 is required";
    }

    if (!country) {
      errors.country = "Country is required";
    }
    if (!state) {
      errors.state = "State is required";
    }
    if (!city?.trim()) {
      errors.city = "City is required";
    }
    if (!postCode?.trim()) {
      errors.postCode = "Post Code is required";
    }
    if (!IDType) {
      errors.IDType = "ID type is required";
    }

    // if (selfieData.length === 0) {
    //   //@ts-ignore
    //   errors.selfieData = "Selfie is required";
    // }

    if (!idNumber?.trim()) {
      errors.idNumber = "ID Number is required";
    }

    setErrorMessages(errors);

    return Object.values(errors).every((error) => error === "");
  };

  // useEffect(() => {
  //   isFormValid();
  // }, [gender, state, country, selfieData, IDType, phone]);

  const countryOptions =
    Icountry?.data?.data.data.map(
      (
        {
          countryCode,
          name,
          index,
        }: { countryCode: string; name: string; index: number },
        id: number
      ) => ({
        label: name,
        slug: countryCode,
        id,
      })
    ) || [];

  const stateOptions =
    states?.data?.data.data.map(
      ({ name, index }: { name: string; index: number }, id: number) => ({
        label: name,
        slug: name,
        id,
      })
    ) || [];

  const submitKyc = async () => {
    if (isFormValid()) {
      setLoading(true);
      getDetails(
        {
          firstName,
          lastName,
          dob,
          phone,
          bvn,
          email,
          gender: gender?.slug,
          address1,
          address2,
          country: country?.slug,
          state: state?.slug,
          city,
          postCode,
          selfieData,
          refereeCode: code,
          idNumber: idNumber,
          idType: IDType?.slug,
          middleName: middleName,
        },
        isFormValid()
      );

      nextFn();
    }
  };

  const handleInputChange = (
    fieldName: string,
    value: SetStateAction<string>
  ) => {
    setErrorMessage("");
    //@ts-ignore
    if (errorMessages[fieldName]) {
      setErrorMessages({ ...errorMessages, [fieldName]: "" });
    }

    switch (fieldName) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "dob":
        setDob(value);
        break;
      case "middleName":
        setMiddleName(value);
        break;
      // case "phone":
      //   setPhone(value);
      //   break;
      case "bvn":
        setBvn(value);
        break;
      //  case "email":
      //    setDob(value);
      //    break;
      // case "gender":
      //   setDob(value);
      //   break;
      case "address1":
        setAddress1(value);
        break;
      //  case "country":
      //    setDob(value);
      //    break;
      case "city":
        setCity(value);
        break;
      case "postCode":
        setPostcode(value);
        break;
      case "idNumber":
        setIdNumber(value);
        break;
      //  case "idDocUrl":
      //    setDob(value);
      //    break;
      //  case "selfieData":
      //    setDob(value);
      //    break;
    }
  };

  // useEffect(() => {
  //   const data = localStorage.getItem("user_data");
  //   if (data !== null) {
  //     try {
  //       const userData = JSON.parse(data);
  //       setUserData(userData);
  //       if (userData !== null) {
  //         getDetails(
  //           {
  //             //@ts-ignore
  //             userID: userData.id,
  //           },
  //           true
  //         );
  //         nextFn();
  //       }
  //     } catch (error) {}
  //   }
  // }, []);

  React.useEffect(() => {
    // Function to handle window resize and update mobile state
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <LoadingScreen
      showLoading={loading}
      msg="Validating your details..."
      bg="#f2faf799"
    >
      {" "}
      <div className="px-[5%] md:px-0 pt-10">
        <div className="text pb-4">
          <h1 className="text-[24px] leading-[36px] font-bold pb-1 text-[#071827]">
            Business Representative
          </h1>
          <p className="text-[16px] leading-[24px] font-normal text-[#5C6F7F]">
            Complete basic details about yourself:
          </p>
        </div>

        <div className="bg-none md:bg-white flex flex-col gap-3 p-0 md:p-5 md:w-full md:max-w-lg ">
          {errorMessage && (
            <div className="error text-[#e01a00] text-sm text-center bg-rose-200 border-dashed border-2 border-[#a10] rounded-md py-2">
              {errorMessage}
            </div>
          )}
          <div className="relative ">
            <Input
              label="First Name"
              name="firstName"
              type="text"
              value={firstName}
              required={true}
              errorMessages={errorMessages}
              onChange={(evt) => handleInputChange("firstName", evt)}
            />
            <div className="text-red-500 text-xs">
              {errorMessages.firstName}
            </div>
          </div>
          <div className="relative ">
            <Input
              label="Middle Name"
              name="middleName"
              type="text"
              value={middleName}
              required={true}
              errorMessages={errorMessages}
              onChange={(evt) => handleInputChange("middleName", evt)}
            />

            <div className="text-red-500 text-xs">
              {errorMessages.middleName}
            </div>
          </div>
          <div className="relative ">
            <Input
              label="Last Name"
              name="lastName"
              type="text"
              value={lastName}
              required={true}
              errorMessages={errorMessages}
              onChange={(evt) => handleInputChange("lastName", evt)}
            />

            <div className="text-red-500 text-xs">{errorMessages.lastName}</div>
          </div>

          <div className="relative w-full">
            <small className="text-[#0B0C0E] text-sm font-normal ">
              <span className="text-[#E01A00]">*</span>
              Gender{" "}
            </small>
            <CustomSelect
              selectedOption={gender?.slug}
              setSelectOption={setGender}
              placeholder={"Choose gender"}
              options={GenderData || []}
              name={"gender"}
              errors={errorMessages}
            />

            <div className="text-red-500 text-xs">{errorMessages.gender}</div>
          </div>

          <div className="relative ">
            <Input
              label="Date of Birth"
              name="dob"
              type="date"
              value={dob}
              required={true}
              errorMessages={errorMessages}
              onChange={(evt) => handleInputChange("dob", evt)}
            />

            <div className="text-red-500 text-xs">{errorMessages.dob}</div>
          </div>

          <ReactPhoneInput
            containerStyle={{
              border: `0.5px solid ${
                errorMessages.phone ? "#E01A00" : "#CBE0D6"
              } `,
              padding: "5px",
              backgroundColor: isMobile ? "#fff" : "#F2FAF7",
              borderRadius: "8px",
            }}
            buttonStyle={{
              backgroundColor: "transparent",
              border: "none",
              borderRight: "1px solid #0EB683",
              margin: "10px 0px",
              padding: "0px 11px",
            }}
            inputStyle={{
              width: "100%",
              border: "none",
              padding: "0px 0px 0px 72px",
              backgroundColor: isMobile ? "#fff" : "#F2FAF7",
              height: 40,
            }}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
            }}
            enableAreaCodes={true}
            country="ng"
            placeholder="Enter the best mobile number to reach you"
            onChange={(data) => setPhone(data)}
            // {...props}
          />
          <div className="text-red-500 text-xs">{errorMessages.phone}</div>
          <small className="text-[10px] leading-[16px] text-[#5C6F7F] ">
            This should be the number linked to your BVN. You will be required
            to confirm this via an OTP.
          </small>

          <div className="relative ">
            <Input
              label="Email"
              name="email"
              type="email"
              required={true}
              value={email}
              disabled={true}
              onChange={() => {}}
            />
            {email?.trim()?.length > 1 && (
              <BsCheckCircleFill
                size={20}
                className="text-[#0eb683] absolute top-[50%] -translate-y-1/2 right-5"
              />
            )}
          </div>
          <div className="relative ">
            <Input
              label="BVN"
              name="bvn"
              type="number"
              required={true}
              value={bvn}
              maxChar={11}
              errorMessages={errorMessages}
              onChange={(evt) => handleInputChange("bvn", evt)}
            />
            {bvn.trim().length === 11 && (
              <BsCheckCircleFill
                size={20}
                className="text-[#0eb683] absolute top-[40%] -translate-y-1/2 right-2"
              />
            )}
            <div className="text-red-500 text-xs">{errorMessages.bvn}</div>
            <div className="flex items-center gap-2  ">
              <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]  ">
                We use your{" "}
                <span className="text-[#0eb683]">
                  {" "}
                  Bank Verification Number
                </span>{" "}
                <span className="font-bold underline">only</span> for identity
                verification
              </small>
            </div>
          </div>
          <div className="relative mt-3">
            <Input
              label="Address Line 1"
              type="text"
              name="address1"
              value={address1}
              required={true}
              errorMessages={errorMessages}
              onChange={(evt) => handleInputChange("address1", evt)}
            />

            <div className="text-red-500 text-xs">{errorMessages.address1}</div>
          </div>
          <div className="relative">
            <Input
              label="Address Line 2"
              type="text"
              name="address2"
              required={false}
              value={address2}
              onChange={(evt) => setAddress2(evt)}
            />
            {/* {bvn.trim().length === 11 && (
              <BsCheckCircleFill
                size={20}
                className="text-[#0eb683] absolute top-[50%] -translate-y-1/2 right-5"
              />
            )} */}
          </div>
          <div className="flex items-center justify-between gap-4">
            <div className="mb-3 w-[50%] relative">
              <small className="text-[#0B0C0E] text-sm font-normal ">
                <span className="text-[#E01A00]">*</span>
                Country{" "}
              </small>
              <CustomSelect
                selectedOption={country?.slug}
                setSelectOption={setCountry}
                placeholder={"select country"}
                options={countryOptions || []}
                errors={errorMessages}
                name={"country"}
              />

              <div className="text-red-500 text-xs">
                {errorMessages.country}
              </div>
            </div>

            {/* <div className="relative w-full">

          </div> */}
            <div className="mb-3 w-[50%] relative">
              <small className="text-[#0B0C0E] text-sm font-normal ">
                <span className="text-[#E01A00]">*</span>
                State{" "}
              </small>

              <CustomSelect
                selectedOption={state?.slug}
                setSelectOption={setState}
                placeholder={"select city"}
                options={stateOptions || []}
                errors={errorMessages}
                name={"state"}
              />

              <div className="text-red-500 text-xs">{errorMessages.state}</div>
            </div>
          </div>
          <div className="relative w-full">
            <Input
              label="City"
              type="text"
              name="city"
              value={city}
              required={true}
              errorMessages={errorMessages}
              onChange={(evt) => handleInputChange("city", evt)}
            />

            <div className="text-red-500 text-xs">{errorMessages.city}</div>
          </div>
          <div className="relative w-full">
            <Input
              label="Post code"
              type="text"
              name="postCode"
              value={postCode}
              required={true}
              errorMessages={errorMessages}
              onChange={(evt) => handleInputChange("postCode", evt)}
            />

            <div className="text-red-500 text-xs">{errorMessages.postCode}</div>
          </div>
          <div className="relative w-full">
            <small className="text-[#0B0C0E] text-sm font-normal ">
              <span className="text-[#E01A00]">*</span>
              Upload means of identification{" "}
            </small>
            <CustomSelect
              selectedOption={IDType?.slug}
              setSelectOption={setIDType}
              placeholder={"ID Type"}
              name={"IDType"}
              errors={errorMessages}
              options={IDTypeOptions || []}
            />

            <div className="text-red-500 text-xs">{errorMessages.IDType}</div>
          </div>
          <div className="relative">
            <Input
              label="Selected ID Number"
              type="text"
              name="idNumber"
              value={idNumber}
              required={true}
              errorMessages={errorMessages}
              onChange={(evt) => handleInputChange("idNumber", evt)}
            />
            <div className="text-red-500 text-xs">{errorMessages.idNumber}</div>
            <div className="flex items-center gap-2  ">
              <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]  ">
                Please enter ID Number{" "}
                <span className="text-[#0eb683]"> Associated</span>{" "}
                <span className="font-bold underline"></span> with the ID type
                you selected
              </small>
            </div>
          </div>
          <div className="mt-4">
            <small className="text-[#0B0C0E] text-sm font-normal ">
              <span className="text-[#E01A00]">*</span>
              Selfie Verification{" "}
            </small>
            <div
              onClick={() => setSelfieModal(true)}
              className="pt-3.5 pb-2.5 block w-full px-2.5 mt-0 bg-[#F2FAF7] border appearance-none hover:outline-none outline-[#0eb683] hover:ring-0 hover:border-[#0eb683] border-gray-200 rounded-[4px] cursor-pointer text-center "
            >
              {selfieData.length !== 0 ? (
                <span className="text-[#0EB683] flex items-center justify-center gap-2 ">
                  <BiUserCheck size={20} /> <span>Selfie Added</span>{" "}
                </span>
              ) : (
                <span className="text-[#0EB683] hover:underline">
                  Tap to begin
                </span>
              )}
            </div>
            <div className="text-red-500 text-xs">
              {/* {errorMessages.selfieData} */}
            </div>
            <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]  ">
              This is required to verify the ID number provided.
            </small>
          </div>
        </div>
        <SelfiePopUp>
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-base md:text-xl text-[#333333] font-bold ">
              Camera Access Request
            </h1>
            <div
              onClick={() => setSelfieModal(false)}
              className="cursor-pointer  hover:text-[#E01A00] flex items-center "
            >
              <MdOutlineClose size={25} />
            </div>
          </div>

          <div className=" bg-[#F2FAF7] border-2 rounded-md border-[#0eb683] h-full">
            <SmartCameraWeb setSelfieData={setSelfieData}></SmartCameraWeb>
          </div>
        </SelfiePopUp>
        <OnboardingFooter backFn={backFn} nextFn={submitKyc} />
      </div>
    </LoadingScreen>
  );
};

export default ContactPerson;
