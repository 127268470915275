import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import "./styles/standard.css";
import Select, { components, SingleValueProps } from "react-select";

const customStyles = {
  menu: (base: any) => ({ ...base, width: "100px" }),

  control: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    width: "max-content",
    height: "50px",
    padding: 0,
    borderRadius: "0.375rem 0.375rem 0.375rem 0.375rem",
    fontSize: "12px",
    border: state.isFocused ? "1px solid #0eb683" : "1px solid #0eb683",
    boxShadow: "none",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: "15px",
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: "0 5px 0 0",
    color: "black",
  }),
};

const AppNumericInput = ({
  name,
  label,
  info,
  min,
  max,
  style,
  labelStyle,
  infoStyle,
  rightLabel,
  rightLabelStyle,
  isInsufficient,
  insufficientLabel,
  className,
  currencies,
  selectedCurrency,
  setSelectedCurrency,
  handleCurrencyChange,
  placeholder,
  placeholderStyle,
  ...props
}: any) => {
  const [currencyValue, setCurrencyValue] = useState("");
  useEffect(() => {
    const currencyValue = currencies?.find(
      (item: { value: string }) => item.value === selectedCurrency.toUpperCase()
    );
    setCurrencyValue(currencyValue);
  }, [currencies, selectedCurrency]);
  // console.log(currencyValue, "currencyValue");
  const customSingleValue: React.FC<SingleValueProps<any>> = (props) => (
    <components.SingleValue {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={props.data.image}
          alt="flag"
          style={{
            width: "20px",
            height: "20px",
            marginRight: "5px",
          }}
        />
        <span className="text-[16px] leading-[21px] text-[#5C6F7F]">
          {props.data.value}
        </span>
      </div>
    </components.SingleValue>
  );

  return (
    <div className="app-special-input ">
      <div
        className="label-group"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <p className="label " style={{ textAlign: "left", ...labelStyle }}>
          {label}
        </p>
      </div>
      <div>
        <div className="flex items-center gap-3">
          {currencies && (
            <div className="">
              <Select
                options={currencies}
                // placeholder="Currency"
                value={currencyValue}
                styles={customStyles}
                isSearchable={false}
                onChange={handleCurrencyChange}
                components={{
                  SingleValue: customSingleValue,
                  Option: (props) => (
                    <components.Option {...props}>
                      <div className="flex items-center gap-1">
                        <img
                          src={props.data.image}
                          alt="flag"
                          style={{
                            width: "25px",
                            height: "25px",
                          }}
                        />
                        <span className="text-[16px] leading-[21px] text-[#5C6F7F]">
                          {props.data.value}
                        </span>
                      </div>
                    </components.Option>
                  ),
                }}
              />
            </div>
          )}
          <div className={`app-input `} style={style}>
            <div className="insufficient" hidden={!isInsufficient}>
              {insufficientLabel ?? "Insufficient"}
            </div>
            <NumberFormat
              {...props}
              placeholder={placeholder}
              style={placeholderStyle}
            />
          </div>
        </div>
      </div>

      <p style={rightLabelStyle}>{rightLabel}</p>
      <p className="info " style={{ ...infoStyle }}>
        <span className="text-[#0eb683]">{info}</span>
      </p>
    </div>
  );
};

export default AppNumericInput;
