import React, { useState } from "react";
import DashBoardEmptyState from "../../components/dashboard-components/DashBoardEmptyState";
import WalletCard from "../../components/dashboard-components/WalletCard";
import usdt from "../../assets/coins/usdt.png";
import usdc from "../../assets/coins/usdc.png";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/misc/FaviconAsset_ttfcx.svg";
import { useSidebarState } from "../../store";

import hamburger from "../../assets/misc/hamburger-button.svg";

const StableCoinWallets = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("fiat");
  const toggleSideBarOpen = useSidebarState((state) => state.toggleSidebar);

  return (
    <div className="  bg-[#f7fbfb] h-full w-full p-5   md:pl-8 md:pr-8">
      <div className="w-16 ml-auto block md:hidden">
        <img src={logo} alt="" />
      </div>
      <div className="flex flex-col md:flex-row md:justify-between  ">
        <div className="text flex flex-col items-start ">
          <button
            className="py-1 md:hidden rounded-md transition-all active:scale-90"
            onClick={(e) => {
              toggleSideBarOpen();
            }}
          >
            <img src={hamburger} alt="menu" className="w-10" />
          </button>
          <div className="">
            <h1 className="font-bold text-2xl text-[#071827] pb-2 pl-1 ">
              Wallets
            </h1>
          </div>
          <p className="pb-5 pl-1 text-[#5C6F7F] text-lg">
            Select the wallet you wish to use
          </p>
        </div>
        {/* Toggle switch */}
        <div className="toggle md:max-w-[240px] w-full h-12 md:h-10 flex border border-[#0eb683] mb-6 md:mb-8 self-center bg-white justify-between rounded-lg cursor-pointer p-1 ">
          <div
            className={`px-2 flex-1 flex items-center justify-center transition-all duration-500 rounded-md ${
              currentTab === "fiat" ? "" : ""
            }`}
            onClick={() => {
              navigate("/dashboard/wallets", { replace: true });
            }}
          >
            Fiat
          </div>
          <div
            className={`px-2 flex-1 flex items-center justify-center transition-all duration-500 rounded-md ${
              currentTab === "fiat" ? "bg-[#0eb683] text-white" : ""
            }`}
            onClick={() =>
              navigate("/dashboard/wallets/stablecoin", { replace: true })
            }
          >
            Stablecoin
          </div>
        </div>
      </div>
      {currentTab === "fiat" && (
        <div>
          {" "}
          <div>
            <div className=" wallet_card flex w-full overflow-x-auto xxs:gap-5 md:gap-7 xxs:mt-0 md:mt-4">
              <WalletCard
                icon={usdc}
                title={"Wallet"}
                to={"/dashboard/wallet/usdc"}
                amount="0.00"
                currency="USDC"
                // button={""}
              />

              <WalletCard
                to={"/dashboard/wallet/usdt"}
                // button={""}
                icon={usdt}
                title={"Wallet"}
                amount="0.00"
                currency="USDT"
              />
            </div>

            <>
              <DashBoardEmptyState />
            </>
          </div>
        </div>
      )}
    </div>
  );
};

export default StableCoinWallets;
