import React, { useState } from "react";
import { IOnboardingUser } from "./UserDetails";
import Controller from "./Controller";
import Logo from "../../../assets/misc/logo-on-light.svg";
import AppCheckBox from "../../../components/widgets/AppCheckBox";
import { useForm } from "../../../context/OnboardingFormContext";

const Summary = ({
  handleClick,
  currentStep,
  steps,
  setErrMsg,
}: IOnboardingUser) => {
  const [check, setCheck] = useState(true);
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useForm();

  return (
    <div className="md:w-full  ">
      <div className=" pb-4 pt-10">
        <h1 className="text-[24px] leading-[36px] font-bold pb-1 text-[#071827] ">
          Summary
        </h1>
        <p className=" text-[#5C6F7F] text-[16px] font-normal leading-[24px]">
          Please confirm your information is accurate before you submit your
          Onboarding Request.
        </p>
      </div>
      <div className=" bg-white flex flex-col gap-3   md:w-full pb-5 ">
        <div className=" ">
          {/* <div className="logo pl-4 lg:pl-9  block">
              <img src={Logo} alt="" className=" w-36" />
            </div> */}
          {/* <div className="logo px-4 lg:px-9 mt-10   block">
            <h1 className="lg:text-[30px] lg:leading-[42px] text-[20px] leading-[32px] font-medium text-[#071827]">
              Summary
            </h1>
            <h3 className="text-[#5C6F7F] text-[16px] font-normal leading-[24px]">
              Please confirm your information is accurate before you submit your
              Onboarding Request.
            </h3>
          </div> */}
          <div className=" px-4 lg:px-9 mt-8">
            <div className="  block bg-[#EAEAEA4D] p-4">
              <h1 className="text-[14px] leading-[21px] lg:text-[18px] lg:leading-[25px] font-medium text-[#071827]">
                Application Summary
              </h1>
            </div>
          </div>
          <div className=" px-4 lg:px-9 mt-5">
            <div className="  flex items-center justify-between bg-[#fff] py-4 lg:py-4">
              <h1 className=" text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] font-semibold text-[#071827]">
                User Details
              </h1>
              {/* <h1
                //   onClick={() => {
                //     nextOption("individual");
                //     setIsSummary(false);
                //   }}
                className="text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] cursor-pointer  font-bold text-[#0EB683]"
              >
                Click here to edit this section
              </h1> */}
            </div>
            <div className="w-[100%] space-y-4 mt-6">
              {/* <div className="flex items-center justify-between"> */}
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    First Name:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {state.firstName || ""}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Last Name:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {state.lastName || ""}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Gender:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {state.gender || ""}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Date of Birth:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {state.dob || ""}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Phone number:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {state.phoneNumber || ""}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Email:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {state.email || ""}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    BVN:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {state.bvn || ""}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Address 1:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {state.address1 || ""}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Address 2:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {state.address2 || "--"}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Country:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {state.country || ""}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    State:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {state.state || ""}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    City:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {state.city || ""}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Post Code:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {state.postCode || ""}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Means of Identification:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {state.IDType || ""}
                  </h1>
                </div>
              </div>
              {/* <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    File:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {idFile}
                  </h1>
                </div>
              </div> */}
            </div>
            <div className="  flex items-center justify-between bg-[#fff] py-4 lg:py-6">
              <h1 className=" text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] font-semibold text-[#071827]">
                Bank Details
              </h1>
              <h1
                //   onClick={() => {
                //     nextOption("bank");
                //     setIsSummary(false);
                //   }}
                className="text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] cursor-pointer  font-bold text-[#0EB683]"
              >
                Click here to edit this section
              </h1>
            </div>
            <div className="w-[100%] space-y-4 mt-5">
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Bank:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {state.bank.bank.bankName || ""}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Account Name:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {state.accountName || ""}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Account Number:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {state.accountNumber || ""}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="flex  md:items-center xxs:gap-1  md:gap-3 mt-6 px-4 lg:px-9">
            <AppCheckBox
              getChecked={(value: boolean) => {
                setCheck(value);
              }}
            />
            <small className=" xxs:text-left  md:text-sm ">
              I agree to the website's{" "}
              <button
                //   onClick={() =>
                //     navigate("/onboarding/Terms-Of-Use", { replace: true })
                //   }
                className="text-[#0eb683] transition-all active:scale-90"
              >
                terms of use
              </button>{" "}
              and{" "}
              <button
                //   onClick={() =>
                //     navigate("/onboarding/terms-Of-Service", { replace: true })
                //   }
                className="text-[#0eb683] transition-all active:scale-90"
              >
                terms of service
              </button>{" "}
            </small>
          </div>
        </div>
      </div>
      <Controller
      nextDisabled={!check}
        handleClick={handleClick}
        currentStep={currentStep}
        steps={steps}
        setErrMsg={setErrMsg}
      />
    </div>
  );
};

export default Summary;
