import React from 'react'
import { FC } from "react"
import SvgIconConstituentValues from './types'

export interface SvgIcon extends FC<SvgIconConstituentValues>{}

const InfoIcon = ({
    height= "24px",
    width= "24px",
    ...props
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
<path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0EB683" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12 6.01953V6" stroke="#0EB683" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12 10V18" stroke="#0EB683" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default InfoIcon