import React from 'react'
import { FC } from "react"
import SvgIconConstituentValues from './types'

export interface SvgIcon extends FC<SvgIconConstituentValues>{}

const WithdrawalIcon = ({
    height ="24px",
    width ="24px",
    stroke ="#5c6F7F",
    ...props
}:React.SVGProps<SVGAElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width= {width} height= {height} viewBox="0 0 24 24" fill="none">
    <path d="M2 3C2 2.44772 2.44772 2 3 2H21C21.5523 2 22 2.44772 22 3V13C22 13.5523 21.5523 14 21 14H3C2.44772 14 2 13.5523 2 13V3Z" stroke= {stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22 18H2" stroke= {stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22 22H2" stroke= {stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 10C13.1046 10 14 9.10457 14 8C14 6.89543 13.1046 6 12 6C10.8954 6 10 6.89543 10 8C10 9.10457 10.8954 10 12 10Z" stroke= {stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.01611 8.01611L6 8" stroke= {stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.0161 8.01611L18 8" stroke= {stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default WithdrawalIcon