import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./utils/theme";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AuthProvider } from "./store/context/AuthProvider";
import { TabProvider } from "./store/context/TabProvider";
import { BusinessProvider } from "./context/BusinessUser";
import { UserProvider } from "./context/UserContext";
import { FormProvider } from "./context/OnboardingFormContext";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      {process.env.NODE_ENV !== "production" && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
      <ChakraProvider theme={theme}>
        <AuthProvider>
          <FormProvider>
            <UserProvider>
              <TabProvider>
                <BusinessProvider>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </BusinessProvider>
              </TabProvider>
            </UserProvider>
          </FormProvider>
        </AuthProvider>
      </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
