import React, { useEffect, useState } from "react";
import { FaRegCopy } from "react-icons/fa";
import CopyIcon from "../../assets/iconsComponents/CopyIcon";
import InfoIcon from "../../assets/iconsComponents/InfoIcon";
import { useAccountNgn, useWalletBalance } from "../../sevices/hooks/wallets";
import { useOutletContext } from "react-router-dom";

const Notification = ({ text, info, name }: any) => {
  const [copyStatus, setCopyStatus] = useState("");
  const [userDetails] = useOutletContext<any>();
  const accountNgn = useWalletBalance("ngn");
  //copy
  const copyToBankClipBoard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyStatus(userDetails?.ngnLBPBankName);
    let timeout = setTimeout(() => {
      setCopyStatus("");
      clearTimeout(timeout);
    }, 1500);
  };
  const copyToNameClipBoard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyStatus(userDetails?.ngnLBPAccountName);
    let timeout = setTimeout(() => {
      setCopyStatus("");
      clearTimeout(timeout);
    }, 1500);
  };
  const copyToNumberClipBoard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyStatus(userDetails?.ngnLBPSubAccountNumber);
    let timeout = setTimeout(() => {
      setCopyStatus("");
      clearTimeout(timeout);
    }, 1500);
  };

  return (
    <div className="bg-[#F7FBFB] rounded-lg p-5 relative ">
      <div className="flex items-center text-[#0eb683] gap-2 pb-2">
        <InfoIcon />
        <p className="text-black font-bold leading-tight ">{info}</p>
      </div>
      <div className="flex items-center  pl-8 relative pb-3">
        <div>
          <div className="flex gap-2">
            <button
              onClick={() => copyToBankClipBoard(userDetails?.ngnLBPBankName)}
              className="  text-[#0eb683]  transition-all active:scale-90 "
            >
              <FaRegCopy />
            </button>
            <h1 className="text-[#5C6F7F] text-base mt-1 ">
              {userDetails?.ngnLBPBankName}
            </h1>
          </div>
          <div className="flex gap-2">
            <button
              onClick={() =>
                copyToNumberClipBoard(userDetails?.ngnLBPSubAccountNumber)
              }
              className="  text-[#0eb683]  transition-all active:scale-90 "
            >
              <FaRegCopy />
            </button>
            <h1 className="font-medium text-[#0eb683] break-all">
              {userDetails?.ngnLBPSubAccountNumber}
            </h1>
          </div>
          <div className="flex gap-2">
            <button
              onClick={() =>
                copyToNameClipBoard(userDetails?.ngnLBPAccountName)
              }
              className="  text-[#0eb683]  transition-all active:scale-90 "
            >
              <FaRegCopy />
            </button>
            <p className="text-[#5C6F7F] text-base">
              {userDetails?.ngnLBPAccountName}
            </p>
          </div>
        </div>

        <p className="bg-slate-200 rounded-md text-slate-600 text-sm font-bold absolute xxs:top-[-26px] xxs:left-16 md:left-8 md:top-[-14px]">
          {copyStatus}
        </p>
      </div>

      <div className="pl-8 flex flex-col gap-4 pr-6">
        <small className="text-[#5C6F7F] leading-tight">
          This works like a regular bank account number. Transfer from any
          source and funds would be credited to your Naira balance immediately.
        </small>
        <small className="text-[#5C6F7F] leading-tight">
          Note transfers may typically take up to 10 minutes to reflect.
          Additionally, withdrawals cannot be made from this balance at a
          physical bank.
        </small>
        <small className="text-[#0eb683] leading-tight ">{text}</small>
      </div>
      <div className="absolute bg-[#FF705E] h-16 w-1 top-20 left-0"></div>
    </div>
  );
};

export default Notification;
