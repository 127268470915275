import React from 'react'
import { FC } from "react"
import SvgIconConstituentValues from './types'

export interface SvgIcon extends FC<SvgIconConstituentValues>{}

const ShareIcon = ({
    height = "28px",
    width ="28px",
    stroke ="#0EB683",
    ...props
    
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}viewBox="0 0 24 24" fill="none">
<path d="M3 14V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V14" stroke= {stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12 3L17 8.44444M12 17V3V17ZM12 3L7 8.44446L12 3Z" stroke={stroke}  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default ShareIcon