/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import "../../index.css";
// import { HiOutlineMenuAlt4 } from 'react-icons/hi'
import hamburger from "../../assets/misc/hamburger-button.svg";
import BGImg from "../../assets/images/landing/bg.png";
import Logo from "../../assets/misc/logo-on-light.svg";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import image1 from "../../assets/ttfcximage.png";
import image2 from "../../assets/real.png";

const LandingPageHeader = () => {
  // Call to action text
  const [ctaText] = useState([
    { text: "Delivering ### cross-border payments", keyword: "trusted" },
    //   {
    //     text: "Swap your USDC & USDT Receive local currency at the best rates ###",
    //     keyword: "instantly",
    //   },
    {
      text: "Swap local fiat ### receive foreign ###",
      keyword: "settlement",
    },
  ]);

  // State to track the current CTA
  const [currentCtaIndex, changeCtaIndex] = useState(0);

  // Function to move to next CTA
  const next = () => {
    changeCtaIndex(currentCtaIndex + 1);
    if (currentCtaIndex > 0) changeCtaIndex(0);
  };

  // Function to render CTA based on the current CTA index
  const renderCtaText = (index: number) => {
    let cta = ctaText[index];

    let splittedText = cta.text.split("###");
    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    };

    return (
      <motion.div
        initial={{ y: "50%" }}
        animate={{ y: "0%" }}
        className="max-w-[630px] w-full relative "
      >
        <motion.div
          initial="hidden"
          animate="visible"
          variants={variants}
          className="h-80 flex items-end pb-10 "
        >
          <motion.h1 className="text-4xl md:text-5xl font-bold absolute ">
            {splittedText[0]}{" "}
            <span
              className={`text-[#0EB683] ${
                cta?.keyword === "settlement" && "hidden"
              }`}
            >
              {cta?.keyword}
            </span>
            <br />{" "}
            <p className=" xxs:whitespace-normal md:whitespace-nowrap">
              {splittedText[1]}{" "}
              <span
                className={`text-[#0EB683] ${
                  cta?.keyword === "trusted" && "hidden"
                }`}
              >
                {cta?.keyword}
              </span>
            </p>
          </motion.h1>
        </motion.div>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={variants}
          className="indicators flex gap-4 my-2 absolute bottom-1"
        >
          {ctaText.map((_, currentIndex) => (
            <motion.div
              key={currentIndex}
              initial="hidden"
              animate="visible"
              variants={variants}
              className={`indicator w-20 h-1 rounded-xl ${
                index === currentIndex ? "bg-[#0EB683]" : "bg-white"
              }  `}
            ></motion.div>
          ))}
        </motion.div>
      </motion.div>
    );
  };

  // Effect for Timing the CTA Sequence
  useEffect(() => {
    let timerId = setInterval(next, 5000);
    return () => clearInterval(timerId);
  }, [currentCtaIndex]);

  return (
    <header
      className="h-[100%] p-5 px-[8%]  "
      style={{
        // backgroundImage: `url('${BGImg}')`,
        // background: "rgb(1,69,132)",
        background:
          "linear-gradient(264deg, rgba(68, 123, 175, 0.372) 1%, rgba(255,255,255,1) 81%)",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <nav className="flex items-center">
        <a href="/" className="transition-all active:scale-90">
          <img src={Logo} alt="" className="w-44 h-14" />
        </a>

        <div className="nav-options ml-auto hidden md:flex items-center gap-10">
          <Link to="/login" className="font-semibold">
            Login
          </Link>
          <Link
            to={"/request-access"}
            className="text-[#fff] bg-[#014584] border border-[#014584] shadow-sm   p-3 px-5 rounded-full  active:scale-90 transition-all duration-500 hover:opacity-80 "
          >
            Request Access
          </Link>
        </div>
      </nav>
      <div className="w-full flex flex-col items-center justify-center">
        <div className="text-center mt-14 mb-6">
          <h1
            style={{
              fontFamily: "DM Sans",
            }}
            className="text-[#15112D] font-bold text-[40px] leading-[40px] md:text-[52px]  md:leading-[68px] tracking-tight"
          >
            Delivering{" "}
            <span
              style={{
                fontFamily: "DM Sans",
              }}
              className="text-[#0eb683]"
            >
              Reliable
            </span>{" "}
            Fintech{" "}
          </h1>
          <p
            style={{
              fontFamily: "DM Sans",
            }}
            className="text-[#15112D] font-bold text-[35px] leading-[40px] md:text-[52px]  md:leading-[68px] tracking-tight"
          >
            APIs & Infrastructure
          </p>
        </div>
        <motion.p
          initial={{ x: "50%", opacity: 0 }}
          animate={{ x: "0%", opacity: 1 }}
          transition={{ delay: 1 }}
          style={{
            fontFamily: "DM Sans",
          }}
          className="text-[20px] text-[#393939] font-light leading-[30px] my-2 text-center"
        >
          We provide technology products and services centred around building,{" "}
          maintaining and utilizing robust suites
          <br className="hidden md:inline" />
          of Fintech APIs and interfaces to enable individual or enterprise
          users’ business payment needs.
        </motion.p>
        <motion.div
          initial={{ y: "50%", opacity: 0 }}
          animate={{ y: "0%", opacity: 1 }}
          transition={{ delay: 1 }}
          className="cta flex gap-3 md:gap-5 mt-5 "
        >
          <Link
            to={"/request-access"}
            className="bg-[#014584] border border-[#014584]  shadow-sm text-white p-3 px-5 rounded-full active:scale-90 transition-all duration-500 hover:opacity-80 "
          >
            Request Access
          </Link>
          <Link
            to={"/login"}
            className="bg-white border border-[#014584] text-[#014584]  p-3 px-5 rounded-full active:scale-90 transition-all duration-500 hover:opacity-80 shadow-sm"
          >
            Login
          </Link>
        </motion.div>

        <div className="md:flex justify-center my-14 space-x-5 w-[70%] hidden">
          <div className="w-3/4 bg-[#ECFAF2] p-4 rounded-lg  flex items-center ">
            <img
              src={image1}
              alt=" Image"
              className=" w-full  h-auto rounded-lg"
            />
          </div>
          <div className="w-3/4 bg-[#FCF2E8] p-4 rounded-lg h-full">
            <img
              src={image2}
              alt=" Image"
              className="w-full h-auto rounded-lg"
            />
          </div>
        </div>

        <div className="w-full   bg-[#ECFAF2] p-4 rounded-lg block my-5  items-center md:hidden">
          <img
            src={image1}
            alt=" Image"
            className=" w-full  h-auto rounded-lg"
          />
        </div>
        <div className="w-full  md:hidden block bg-[#FCF2E8] p-4 rounded-lg h-full">
          <img src={image2} alt=" Image" className="w-full h-auto rounded-lg" />
        </div>
      </div>
    </header>
  );
};

export default LandingPageHeader;
