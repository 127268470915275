import React, { useEffect, useState } from "react";
import { BsChevronLeft, BsPlus } from "react-icons/bs";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ShareIcon from "../../../assets/iconsComponents/ShareIcon";
import AddFiatComponent from "../../../components/beneficiary-component/AddFiatComponent";
import BeneficiaryLayout from "../../../components/beneficiary-component/BeneficiaryLayout";
import PaymentInstruction from "../../../components/wallet-components/PaymentInstruction";
import Summary from "../../../components/wallet-components/Summary";
import TransferBeneficiaryFlow from "../../../components/wallet-components/TransferBeneficiaryFlow";
import TransferFYCTabs from "../../../components/widgets/OTPInput/TransferFYCTabs";
import { TabSelector } from "../../../components/widgets/TabSelector";
// import OnboardingTabs from '../../../components/widgets/OnboardingTabs';
import { TabPanel, useTabs } from "../../../components/widgets/TabWidget";

import { transferSteps } from "../../../types/transfer-data";

const WalletTransfer = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const navigate = useNavigate();
  const { currency } = useParams();
  const [steps, setSteps] = useState(transferSteps);
  const [beneficiairyDetails, setbeneficiairyDetails] = useState({});
  const [summaryData, setSummaryData] = useState({});
  const [selectedTab, setSelectedTab] = useTabs(
    transferSteps.map((step, index) => step.name)
  );

  const nextStep = () => {
    if (currentTab < formSteps.length) {
      setCurrentTab(currentTab + 1);
    }
  };
  const nextSteps = () => {
    if (currentTab < formSteps.length) {
      setCurrentTab(currentTab + 1);
    }
  };
  const previous = () => {
    if (currentTab > 0) {
      setCurrentTab(currentTab - 1);
    }
  };
  const previousTab = () => {
    if (currentTab > 0) {
      setCurrentTab(currentTab - 1);
    }
  };
  const handleSaveSummaryData = (newData: any) =>
    setSummaryData((prevData: any) => ({
      ...prevData,
      ...newData,
    }));

  // const handleBeneficiaryDetails =(newBeneficiary: any)=>
  // setbeneficiairyDetails((prevBeneficiaryData: any)=>({
  //   ...newBeneficiary,
  //   ...prevBeneficiaryData,
  // }))
  const [currentFlow, setcurrentFlow] = useState("Beneficiary");

  // const stepUpdate = (currentStep: string, value: boolean) => {
  //   const updatedSteps = steps.map((step) =>
  //     step.name === currentStep ? { ...step, completed: value } : step
  //   );
  //   setSteps(updatedSteps);
  // };
  // console.log(stepUpdate);


  
  const transferFlow = (currentFlow: any) => {
    return currentFlow === "Beneficiary"
      ? steps
      : steps.filter((step) => step.name !== "Beneficiary");
  };



  const formSteps = [
    {
      content: <BeneficiaryLayout onClick={nextStep} /> 
    },
    {
      content: (
        <TransferBeneficiaryFlow
          onClick={nextSteps}
          saveBeneficiaryDetails={setbeneficiairyDetails}
        />
      ),
    },
    {
      // name: 'Form 3  displaying from Step',
      content: (
        <PaymentInstruction
          onClick={nextStep}
          prev={previous}
          beneficiaryDetails={beneficiairyDetails}
          setData={handleSaveSummaryData}
        />
      ),
    },
    {
      // name: 'Form 3  displaying from Step',
      content: <Summary pref={previousTab} summaryData={summaryData} />,
    },
  ];

  return (
    <div className="md:bg-[#f7fbfb] ">
      <div className="h-full w-full px-5 pt-5 ">
        <header className="flex items-center gap-24 md:gap-4 md:ml-3">
          <button
            onClick={() => {
              navigate(`/dashboard/wallet/${currency}`, { replace: true });
            }}
            className=" bg-[#ECF6F3] rounded-full p-2 flex transition-all active:scale-90   "
          >
            <BsChevronLeft />
          </button>
          <p className="font-semibold text-lg">
            Transfer {currency?.toUpperCase()}
          </p>
        </header>
        <nav
          className={`tabs overflow-auto  border-t-2 pt-3 mt-6 border-[#f7fbfb] md:border-0 relative  ${
            selectedTab === "Business"
              ? "hidden "
              : "flex md:flex-row md:items-center  md:justify-center md:gap-48 md:before:block md:before:bg-slate-200 md:before:absolute md:before:w-[760px] md:before:h-[2px] md:before:transition-all md:before:top-[33px] before:right-[4] before:ml-7"
          }`}
        >
          {transferFlow(currentFlow).map((step, index) => (
            <TransferFYCTabs
              key={index}
              completed={step.completed}
              active={step.name === selectedTab}
              position={index}
              label={step.label}
              subText={step.description}
              showOnDesktop={index === 1}
            />
          ))}
        </nav>
     
      </div>
      <main className="md:ml-5">{formSteps[currentTab].content}</main>
    </div>
  );
};

export default WalletTransfer;
