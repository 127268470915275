import React, { useState } from "react";
import { IOnboardingUser } from "./UserDetails";
import PasswordInput from "../../../components/base-components/PasswordInput";
import PasswordChecklist from "react-password-checklist";
import Controller from "./Controller";
import { IFormData, useForm } from "../../../context/OnboardingFormContext";

const Password = ({
  handleClick,
  currentStep,
  steps,
  setErrMsg,
}: IOnboardingUser) => {
  const { state, dispatch } = useForm();
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (name: string, value: string) => {
    setErrMsg("");
    dispatch({ type: "UPDATE_FIELD", field: name as keyof IFormData, value });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const isPasswordValid =
    state.password.trim().length >= 5 && // Minimum length
    /[!@#$%^&*()_+[\]{};':"\\|,.<>/?]+/.test(state.password) && // Special character
    /\d/.test(state.password) && // Number
    /[A-Z]/.test(state.password) && // Capital letter
    state.password.trim() === state.confirmPassword.trim(); // Match confirmation
  return (
    <div>
      <div className=" pb-4 pt-10">
        <h1 className="text-[24px] leading-[36px] font-bold pb-1 text-[#071827] ">
          Would you like to update your password?
        </h1>
        <p className=" text-[#5C6F7F] text-[16px] font-normal leading-[24px]">
          Secure your account from unauthorized access and fraud with a unique
          password:
        </p>
      </div>
      <div className="md:w-full md:max-w-lg">
        <form className="bg-none md:bg-white flex flex-col gap-3 p-0 md:p-5  ">
          <PasswordInput
            value={state.password}
            onChange={(e) => handleInputChange("password", e.target.value)}
            label="Create Password"
            required={true}
            name={"password"}
          />
          <PasswordInput
            value={state.confirmPassword}
            onChange={(e) =>
              handleInputChange("confirmPassword", e.target.value)
            }
            label="Confirm password"
            required={true}
            name={"confirmPassword"}
          />
          <div className=" mt-1">
            {state.password !== "" && (
              <PasswordChecklist
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "capital",
                  "match",
                ]}
                minLength={5}
                value={state.password}
                valueAgain={state.confirmPassword}
                onChange={(isValid) => {}}
              />
            )}
          </div>
        </form>
        <Controller
          nextDisabled={!isPasswordValid}
          handleClick={handleClick}
          currentStep={currentStep}
          steps={steps}
          setErrMsg={setErrMsg}
        />
      </div>
    </div>
  );
};

export default Password;
