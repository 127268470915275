import React, { useEffect, useState } from "react";
import OTPInput from "../../components/widgets/OTPInput";
import { useRequestAccess } from "../../sevices/hooks/users";
import ReactLoading from "react-loading";

import { useNavigate } from "react-router-dom";

const RequestAccess = ({ setModal, setSuccess, setFailed }: any) => {
  const [otpValue, setOtpValue] = useState("");
  const [detailsValid, setDetailsValid] = useState(false);
  const [spin, setSpin] = useState(false);
  const [loading, setLoading] = useState(false);

  const requestAccess = useRequestAccess();
  const [fields, setFields] = useState({
    email: "",
    referralCode: "",
    firstName: "",
    lastName: "",
  });
  const [disable, setDisable] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setSuccess(true);
  }, []);

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    setSpin(true);
    requestAccess
      .mutateAsync(fields)
      .then((res) => {
        setSpin(false);
        setModal(true);
        setSuccess(true);
        setOtpValue("");
        setDetailsValid(false);
        setFields({
          email: "",
          referralCode: "",
          firstName: "",
          lastName: "",
        });

        // setFields((fields.email = ""));
      })
      .catch((e) => {
        setSpin(false);
        setOtpValue("");
        setSuccess(false);
        setFailed(setFailed);
        setModal(true);

        setFields({
          email: "",
          referralCode: "",
          firstName: "",
          lastName: "",
        });
      });
  };

  const validateField = () => {
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if (
      regEx.test(fields.email) &&
      fields.firstName !== "" &&
      fields.lastName !== ""
    ) {
      setDisable(false);
    } else if (
      (!regEx.test(fields.email) && fields.email === "") ||
      (fields.email === "" && fields.firstName === "" && fields.lastName)
    ) {
      setDisable(true);
    }
  };

  const onFieldChange = ({ name, value }: { value: any; name: string }) => {
    setFields({ ...fields, [name]: value });
  };

  useEffect(() => {
    onFieldChange({ name: "referralCode", value: otpValue });
  }, [otpValue]);

  return !detailsValid ? (
    <div className=" ">
      <div className="input-field xs:py-4 xxs:py-0 ">
        <div className="flex flex-col md:flex-row items-center gap-3 pt-3 md:mb-5">
          <input
            onChange={(e) => {
              onFieldChange({ name: "firstName", value: e.target.value });
              validateField();
            }}
            type="text"
            name="firstName"
            placeholder="First Name"
            className="border py-4 pl-3 w-full rounded-md outline-[#0eb683]"
          />
          <input
            onChange={(e) => {
              onFieldChange({ name: "lastName", value: e.target.value });
              validateField();
            }}
            type="text"
            name="LastName"
            placeholder="Last Name"
            className="border p-3 py-4 inline rounded-md w-full outline-[#0eb683] "
          />
        </div>
        <input
          onChange={(e) => {
            onFieldChange({ name: "email", value: e.target.value });
            validateField();
          }}
          type="email"
          name="email"
          placeholder="Email"
          className="border p-3 py-4 xxs:mt-3 rounded-md w-full outline-[#0eb683] "
        />
      </div>

      <div className="flex flex-col justify-center items-center mt-4">
        <button
          disabled={disable}
          onClick={() => {
            setDetailsValid(true);
          }}
          type="submit"
          className=" bg-[#0eb683] p-3 xs:mt-5 shadow-md shadow-gray-400/50  w-[100%] items-center rounded-md font-medium text-white transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
        >
          {loading ? (
            <div className=" xxs:ml-[145px]  md:ml-[194px]">
              <ReactLoading
                type="spinningBubbles"
                color="#fff"
                height="27px"
                width="27px"
              />
            </div>
          ) : (
            "Continue"
          )}
        </button>

        <small className="text-white md:text-[#000] text-center xxs:pt-1 xs:pt-3 xxs:mb-9 ">
          By clicking Continue, I agree to the website's
          <br />
          <button
            onClick={() => navigate("/terms-of-use", { replace: true })}
            className="text-[#0eb683] underline underline-offset-3 transition-all active:scale-90"
          >
            Terms of use
          </button>{" "}
          and{" "}
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate("/privacystatement", { replace: true });
            }}
            className="text-[#0eb683] underline underline-offset-3 transition-all active:scale-90"
          >
            Privacy statement
          </button>{" "}
        </small>
      </div>
    </div>
  ) : (
    <>
      <form className="refrence  " onSubmit={onSubmitForm}>
        <div className="otp-box xs:py-4 xxs:py-2 flex flex-col gap-5">
          <p className="text-white md:text-[#000] text-center">
            Enter your referee's referral code
          </p>
          <OTPInput
            autoFocus
            length={5}
            className="otpContainer flex gap-[8px] justify-center w-full xs:py-5 xxs:py-2 m-auto"
            inputClassName="otpInput w-12 h-12 md:w-[50px] md:h-[50px] bg-white border-2 border-[#0EB683] outline-[#0EB683] text-[#0EB683] text-2xl text-center font-bold rounded-lg"
            autoCapitalize={true}
            onChangeOTP={(otp) => setOtpValue(otp.toUpperCase())}
            onPaste={(otp) => setOtpValue(otp.toUpperCase())}
          />
          <button
            onClick={onSubmitForm}
            type="submit"
            className=" bg-[#0eb683] p-3 xs:mt-5 xxs:mt-2  w-[100%] items-center rounded-md font-medium text-white transition-all active:scale-90"
          >
            {spin ? (
              <div className="xxs:ml-[140px] md:ml-[190px]">
                <ReactLoading
                  type="spinningBubbles"
                  color="#fff"
                  height="27px"
                  width="27px"
                />
              </div>
            ) : (
              "  Request Access"
            )}
          </button>
        </div>
      </form>
      <div className="flex justify-center items-center mt-3">
        <small className="text-white md:text-[#000] text-center xxs:pt-1 xs:pt-3 xxs:mb-9">
          By clicking Request Access, I agree to the website's
          <br />
          <button
            onClick={() => navigate("/terms-of-use", { replace: true })}
            className="text-[#0eb683] underline underline-offset-3 transition-all active:scale-90"
          >
            Terms of use
          </button>{" "}
          and{" "}
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate("/privacystatement", { replace: true });
            }}
            className="text-[#0eb683] underline underline-offset-3 transition-all active:scale-90"
          >
            privacy statement
          </button>{" "}
        </small>
      </div>
    </>
  );
};

export default RequestAccess;
