import React from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/misc/logo-on-light.svg";

const PrivacyStatement = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen w-screen  bg-[#fff] overflow-y-scroll">
      <div className=" xxs:p-5 md:px-14">
        <div className="flex  justify-between items-center mb-9">
          <div className="flex items-center ">
            <button
              onClick={() => {
                navigate("/login", { replace: true });
              }}
              className=" md:bg-slate-400 rounded-full md:p-2 xxs:mr-2 flex transition-all active:scale-90 hover:scale-110"
            >
              <BsChevronLeft size={23} />
            </button>
            <h1 className="xxs:text-sm md:text-base   text-black font-sans font-extrabold ">
              PRIVACY STATEMENT{" "}
            </h1>
          </div>

          <div className="xxs:w-20 md:w-32 flex  ">
            <img src={logo} alt="" />
          </div>
        </div>
        <ul>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose">
            TTFCX (“TTFCX”, “we”, “us” or “our”) will treat the data you provide
            us with as private and shall only use it in accordance with this
            Privacy Statement. “You”, “Your”, “Customer”, “Client” include
            anyone who registers to use our services and/or visits our website
            located at{" "}
            <a className="text-[#0eb683]" href="https://www.ttfcx.com/">https://www.ttfcx.com/</a> , all
            referred to collectively as “Users”. This Privacy Statement
            describes how we collect and process your personal information
            through your use of our web and mobile application (collectively and
            individually “the Platform”) on which you register and log into your
            User account with us (the “Account”), to enable you to use our
            services. This ensures the security of your Account information and
            your personal details provided during the sign up, onboarding
            process and before and/or after you perform your transaction. For
            the purposes of the Platform, TTFCX is the data controller of your
            Personal Data.
          </li>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose mt-5">
            Our services are not intended for children under the age of 13 and
            we do not knowingly collect personal information from them.
          </li>
        </ul>
        <ol start={1}>
          <li className="text-md list-outside list-decimal   text-black font-sans font-extrabold my-5">
            WHAT DATA DO WE COLLECT AND WHY WE COLLECT THEM:
          </li>
        </ol>
        <ul>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose">
            All references to ‘Personal Data’ or ‘Personal Information’ in this
            Privacy Statement refer to any personal information about a natural
            person, which enables that individual to be identified directly or
            indirectly by reference to the data provided. Business information
            refers to information provided by a business entity/corporate
            person.
          </li>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose underline">
            (a) Information you give us:
          </li>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose mt-2">
            We collect the following information when you open an Account with
            us:
            <ul className="list-disc pl-10">
              <li>
                Personal identification information, such as name, email,
                address, and phone number
              </li>
              <li>
                Business identification information, such as registration, key
                personnel, address
              </li>
              <li>
                Information on your browser, IP address, location, and device
              </li>
              <li>
                Information on your behavior on our Platform, including pages
                viewed, links clicked
              </li>
              <li>
                Keystrokes, and actions taken including copy/paste functions
              </li>
              <li>Demographic information</li>
              <li>
                Transaction information, including information related to the
                use of our services, types of Services requested, dates and
                times of such requests, payment methods, and other similar
                information
              </li>
              <li>Location data, including User location searches</li>
              <li>
                Communications data, including messages, comments, or file
                transmissions
              </li>
              <li>
                About your internet connection, the equipment you use to access
                our services, and usage details
              </li>
            </ul>
          </li>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose ">
            You may give us additional information that qualifies as Personal or
            Business Information as part of your use of the Account and/or any
            of the services on our Platform. We may also collect this through
            forms you may be required to fill on the Platform. Some information
            like bank account name and number (only) may be required to perform
            your transactions as part of our services on the Platform. However,
            all data collection is done with appropriate notice and only after
            consent either express or implied is given. Consider this one of
            such notices given. When required, filings are made in accordance
            with all relevant data protection laws and authorities.
          </li>

          <li className=" md:px-6 font-sans font-medium text-xs leading-loose mt-2">
            We also use the information to enable us to communicate with you
            about your activities, new services updates, Platform maintenance,
            promotional offers, update our records, display customized content,
            and recommend products and services that might be of interest to
            you. We also use this information to improve our security protocols
            in order to prevent, detect, or report fraud or abuses of our
            Platform and enable third parties to conduct technical, logistical,
            or other functions on our behalf.
          </li>

          <li className=" md:px-6 font-sans font-medium text-xs leading-loose underline">
            (c) Information from other sources:
          </li>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose mt-2">
            We may receive information about you from other sources that include
            our partner service providers such as identity verification/KYC/KYB
            service provider, APIs, or social network plugins, which we have no
            control over the data gathered and add it to your Account
            information and only according to this Privacy Statement.
          </li>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose mt-2">
            You will also be required to provide login details of your choice,
            namely a username and password that will be securely kept by us.
          </li>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose mt-2">
            You can access your Personal Data, a broad range of information
            about your Account, and your transaction history on the Platform,
            which help you track and manage transactions effectively. This
            information is available on your Account Dashboard accessed by
            securely logging into your Account on the Platform. You also have
            the ability to edit your personal data at any time to alter any
            personal information that may have changed or become obsolete.
            Should you believe that any personal information we hold for you is
            incorrect, please contact our Customer Support section on the
            Platform or through the details available on our website.
          </li>
        </ul>
        <ol start={2}>
          <li className="xxs:text-sm md:text-base list-outside list-decimal      text-black font-sans font-extrabold my-5">
            HOW WE COLLECT YOUR DATA:
          </li>
        </ol>
        <ul>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose">
            You provide your data to us during registration, onboarding, and
            when you use our services. We collect and process data when you:{" "}
            <br />
            (a) Register and create an Account on our Platform <br />
            (b) Download the Platform or use our services <br />
            (c) Visit the Platform via your browser’s cookies. <br />
            (d) Submit content to the Platform, like referrals, reviews,
            messages, or comments. <br />
            (e) Voluntarily complete a User survey or provide feedback on any of
            our messaging facilities or via email. <br />
            (f) Enable access to your device’s location-tracking functionality,
            whether the Platform is running in the foreground (open and
            on-screen) or background (open but not on-screen). Certain
            functionality may be affected while location data is turned off.
            <br /> (g) Users expressly and directly provide personal/business
            data to us through the Platform when performing their transactions
            related to our services. <br />
            (h) Automatic Information: We automatically receive and store
            certain types of information whenever you interact with us. These
            may include frequent transaction activities on your Account.
          </li>
        </ul>
        <ol start={3}>
          <li className="xxs:text-sm md:text-base list-outside list-decimal      text-black font-sans font-extrabold my-5">
            USE OF DATA
          </li>
        </ol>
        <ul>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose">
            The Data we collect helps us provide our services, resources,
            personalize your experience, and continually improve our processes.
            These purposes include: <br />
          </li>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose">
            <ul className=" ">
              <li>
                (a) Processing of Account Activities: We use your Personal Data
                to process your Account activities, transactions, and provide
                our services on the Platform. We also use it to communicate with
                you about our products, services, new features, administrative
                and/or business information, and promotional offers.
              </li>

              <li>
                (b) Provide, troubleshoot, and improve the Platform: We use your
                Personal Data for actions that include providing functionality,
                analyzing service and/or technical performance, fixing errors,
                and improving usability and effectiveness of the Platform.
              </li>

              <li>
                (c) Recommendations: We may use your Personal Data to recommend
                products and services that might be of interest to you and to
                identify your preferences.
              </li>

              <li>
                (d) Comply with legal and contractual requirements: In certain
                cases, we are required by law to collect and process your
                Personal Data.
              </li>

              <li>
                (e) Customer Support: We use your personal information to
                communicate with you in relation to the Platform and our
                services through different channels such as phone call, email,
                and chat services.
              </li>

              <li>
                (f) Fraud Prevention: To enhance your safety and the security of
                the Platform, we process information to prevent or detect fraud
                and abuse to maintain the trust of our customers, and the
                integrity of our Platform, our business reputation, and our
                partners.
              </li>

              <li>(g) For research and development</li>
            </ul>
          </li>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose">
            We provide software services and may collect data from Users in
            order to perform our services. You acknowledge, accept, and agree
            that without the data collected as set forth above, we will not be
            able to provide our services and/or execute most key functionalities
            on the Platform.
          </li>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose mt-2">
            Users consent to the release of this information and data to the us
            for the purpose of the performance of our services for the User’s
            benefit. This release of information and the relationship between
            TTFCX and its Users is subject to the Terms of Use Agreement.
          </li>
        </ul>
        <ol start={4}>
          <li className="xxs:text-sm md:text-base list-outside list-decimal text-black font-sans font-extrabold my-5">
            {" "}
            THIRD-PARTY SERVICES:
          </li>
        </ol>
        <ul>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose">
            We will never disclose your information to third parties without
            your consent. However, some of our business partners and partner
            service providers will only collect, use, and disclose your
            information to the extent necessary to allow them to perform the
            services they provide to us which enables us conduct and facilitate
            our services to you. They are bound by a non-disclosure and will
            never disclose such information. Some of them are:
          </li>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose">
            <ul className="">
              <li>
                (a) Partner service providers: We engage with technology
                providers, financial institutions, other companies, and
                individuals to perform functions on our behalf and transactions
                that you request or authorize. Examples include real-time APIs
                that automate our services on the Platform, identity
                verification services (KYC/KYB), e-mail services, performance
                analysis, fraud screening, and online chat customer support
                service. They require access to personal information to perform
                their functions but may not use it for other purposes and must
                process the personal information in accordance with this Privacy
                Statement and as permitted by the applicable data protection
                legislation in their jurisdiction. Such partner service
                providers may be based in the jurisdictions that mandate
                adequate levels of protection of personal data.
              </li>

              <li>
                (b) Advertising and Third-party Analytics providers: We may
                display interest-based ads to you on the Platform through
                advertising tools. We may also develop or work with third-party
                analytics providers to collect information about the usage of
                our Platform and to enable us to improve how our Platform works.
                These analytics use technology to aid us in collecting
                information about the activities on our Platform and to report
                usage trends to us.
              </li>

              <li>
                (c) Business Transfers: As we continue to develop and scale our
                business, we might sell or buy other businesses or services. In
                such transactions, user or other customer information may be one
                of the transferred business assets but remain subject to the
                confidentiality covenants made in any pre-existing Privacy
                Statement. In the unlikely event that TTFCX or most of its
                assets are acquired, user information may be one of the
                transferred assets.
              </li>

              <li>
                (d) Protection and Compliance with the Law: We may disclose
                Account and other Personal Data when we believe disclosure is
                appropriate or required to comply with the law, to enforce or
                apply our Terms of Service and other agreements we have with
                you, or to protect the rights, property, or safety of our
                Platform and our users. This also includes exchanging
                information with other companies and organizations for fraud
                protection reduction as well as sharing information with our
                attorneys or auditors.
              </li>

              <li>
                (e) Responsibility for Third Party Storage: TTFCX shall in no
                manner whatsoever be held responsible for the way third parties
                and/or partner service providers process and/or store the
                personal data of our users. We shall only be responsible for our
                obligations to you in relation to your Personal Data as the data
                controller under the applicable laws and we do not warrant the
                standard of the privacy policies of any of the third parties.
              </li>

              <li>
                (f) Links: The services on our Platform may include links to
                other websites. Clicking on those links may direct you away from
                our Platform. We are not responsible for the privacy practices
                of other sites and encourage you to read their privacy
                statements. Once you leave our platform, application or website
                or are redirected to a third-party platform, application, or
                website, you are no longer under TTFCX’s Privacy Statement or
                Terms of Service.
              </li>
            </ul>
          </li>
        </ul>
        <ol start={5}>
          <li className="xxs:text-sm md:text-base list-outside list-decimal      text-black font-sans font-extrabold my-5">
            SECURITY:
          </li>
        </ol>
        <ul>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose">
            <ul className="">
              <li>
                (a) To protect your Personal Data, we take reasonable
                precautions and follow industry best practices to make sure it
                is not lost, misused, accessed, disclosed, altered, or
                destroyed. User data is stored in an encrypted server using
                secure socket layer technology (SSL) and stored with an AES-256
                encryption. This is leased from a reputable third-party hosting
                service. Although no method of transmission over the Internet or
                electronic storage is 100% secure, our server host follows all
                PCI-DSS requirements and implements additional accepted industry
                standards.
              </li>

              <li>
                (b) We maintain physical, electronic, and procedural safeguards
                in connection with the collection, storage, and disclosure of
                personal customer information. Our security procedures mean that
                we may occasionally request proof of identity before we disclose
                personal information to you.
              </li>

              <li>
                (c) You have control over your Personal Data and can edit it
                whenever you want to. It is important for you to take steps to
                protect against unauthorized access to your Account and your
                password. You should keep your password and any other security
                layer confidential. The security of your Account relies on your
                protection of your password and an additional layer of security
                like a Personal Identification Number (PIN). Make sure that you
                do not share them with anyone. If you do so for any reason, the
                third party may have access to your Account and your personal
                information, and you may be responsible for actions taken using
                your password and PIN (where available). If you believe someone
                else has obtained access to your password, please change it
                immediately and contact us right away. Note that the regulatory
                bodies allow you to request that we erase your Personal Data
                after the statutory retention period elapses.
              </li>
            </ul>
          </li>
        </ul>
        <ol start={6}>
          <li className="xxs:text-sm md:text-base list-outside list-decimal      text-black font-sans font-extrabold my-5">
            DATA RETENTION AND DELETION:
          </li>
        </ol>
        <ul>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose">
            While we may keep your Data to enable your continued use of the
            Platform for as long as it is required to fulfil the relevant
            purposes described in this Privacy Statement, Users may delete their
            Account at any time. When that happens, we delete the data that we
            do not need to retain for regulatory, tax, insurance, and litigation
            purposes, or other legal requirements.
          </li>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose mt-2">
            We may retain location, device, and usage data for these purposes
            for a minimum of 5 years (Nigeria Data Protection Act 2023); for
            purposes of safety, security, fraud prevention and detection, and
            research and development. We may be unable to delete a User’s
            account, if there is an outstanding obligation, debt or an
            unresolved claim or dispute until they are resolved or fulfilled.
          </li>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose mt-2">
            We also retain certain information necessary, for purposes of
            safety, security, and fraud prevention. This applies where a User’s
            Account is deactivated because of unsafe or fraudulent activities to
            prevent them from opening a new account in the future.
          </li>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose mt-2">
            Data retention and deletion varies in different jurisdictions and
            where User is from a different jurisdiction, the relevant applicable
            data protection laws shall apply to protect the confidentiality of
            their data.
          </li>
        </ul>

        <ol start={7}>
          <li className="xxs:text-sm md:text-base list-outside list-decimal      text-black font-sans font-extrabold my-5">
            UNDERSTANDING TERMS:
          </li>
        </ol>
        <ul>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose">
            By using this Platform, you represent that you understand and accept
            our Terms of Service and Privacy Statement and that you are liable
            for any decisions you make and activities you make on the Platform.
          </li>
        </ul>
        <ol start={8}>
          <li className="xxs:text-sm md:text-base list-outside list-decimal      text-black font-sans font-extrabold my-5">
            CHANGES TO PRIVACY STATEMENT:
          </li>
        </ol>
        <ul>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose">
            Unless stated otherwise, our current Privacy Statement applies to
            all information that we have about you and your Account. We reserve
            the right to modify this privacy statement at any time, so please
            review it frequently. Changes and clarifications will take effect
            immediately upon their posting on the web and mobile platforms. If
            we make material changes to this statement, we will notify you here
            that it has been updated, so that you are aware of what information
            we collect, how we use it, and under what circumstances, if any, we
            use and/or disclose it. Such modifications will not affect your
            Account registration and profile details unless required to the
            contrary by law. Continued use of the Platform will be considered as
            your acceptance thereof.
          </li>
        </ul>
        <ol start={9}>
          <li className="xxs:text-sm md:text-base list-outside list-decimal      text-black font-sans font-extrabold my-5">
            GENERAL:
          </li>
        </ol>
        <ul>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose">
            <ul className="">
              <li>
                (a) We shall take no responsibility whatsoever for events beyond
                its control inclusive of, but not limited to, acts of God or
                natural disasters, pandemics and including power cuts and
                strikes.
              </li>

              <li>
                (b) It is the responsibility of each customer to check the Terms
                of Use and Privacy Statement regularly for updates.
              </li>
            </ul>
          </li>
        </ul>
        <ol start={10}>
          <li className="xxs:text-sm md:text-base list-outside list-decimal  text-black font-sans font-extrabold my-5">
            QUESTIONS AND CONTACT INFORMATION:
          </li>
        </ol>
        <ul>
          <li className=" md:px-6 font-sans font-medium text-xs leading-loose">
            If you would like to access, correct, amend, or delete any personal
            information we have about you, register a complaint, or simply want
            more information, contact our Customer Support on the chat available
            on the Platform, on our website or through phone call and email
            <a href="www.compliance@ttfcx.com">compliance@ttfcx.com</a> also
            available on our website.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyStatement;
