import { AxiosResponse } from "axios";
import { IFile } from "../../../components/base-components/DragDropComponent";
import useQueryAction from "../../../lib/useQueryAction";
import useQueryMutation from "../../../lib/useQueryMutation";
import {
  api,
  makeGetRequest,
  makePostRequest,
  makePatchRequest,
  makePutRequest,
} from "../../api";
import { UserData } from "../../../types/Users";

interface BusinessDetails {
  country: string;
  state: string;
  industry: string;
  registration: string;
  BasicCountry: string;
  businessName: string;
  bvn: string;
  dateOfRegistration: string;
  description: string;
  ContactEmail: string;
  ContactCountry: string;
  ContactState: string;
  addressLine_1: string;
  city: string;
  postalCode: string;
  ContactRegisteredCountry: string;
  ContactRegisteredState: string;
  phoneNumber: string;
  type: string;
}

export interface IBusinessUserData {
  firstName: string;
  lastName: string;
  email: string;
  refereeCode: string;
  accountType: string;
  userBankCode: string;
  phoneNumber: string;
  dob: string;
  bvn: string;
  address1: string;
  address2: string;
  country: string;
  state: string;
  postCode: string;
  gender: string;
  city: string;
  middleName: string;
  idNumber: string;
  idType: string;
  password:string
  business?: BusinessDetails;
}

export const useUsers = (userId: string, token: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.users.getUser(userId)),
    queryKey: [userId],
  });
};

export const useUpdateUser = (userId: string) => {
  return useQueryMutation({
    mutationFn: (data: UserData) =>
      makePutRequest(data, api.users.updateUser(userId)),
  });
};

export const useInvitees = (userId: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.users.invitees(userId)),
    queryKey: ["invitee"],
  });
};
export const useUpdateUserOnboardingInfo = (userId: string) => {
  return useQueryMutation({
    mutationFn: (data: any) =>
      makePostRequest(data, api.users.updateUserInfo(userId)),
    // queryKey: ["updateUserInfo"],
  });
};

export const useRequestOtp = (userId: string) => {
  return useQueryMutation({
    mutationFn: () => makeGetRequest(api.users.otpRequest(userId)),
  });
};

export const useVerifyUserMutation = () => {
  return useQueryMutation({
    mutationFn: (data: { email: string; password: string }) =>
      makePostRequest(data, api.users.verify),
  });
};

export const useSignUpVerifyMutation = () => {
  return useQueryMutation({
    mutationFn: (data: { verificationCode: string }) =>
      makePostRequest(data, api.users.signUpVerify),
  });
};

export const useUpdateBank = () => {
  return useQueryMutation({
    mutationFn: (data: { accountNumber: string; bankName: string }) =>
      makePatchRequest(data, api.users.updateBank),
  });
};

export const useRequestAccess = () => {
  return useQueryMutation({
    mutationFn: (data: {
      email: string;
      firstName: string;
      lastName: string;
      referralCode: string;
    }) => makePostRequest(data, api.users.requestAccess),
  });
};

export const useCompleteOnboarding = () => {
  return useQueryMutation({
    mutationFn: (data: { email: string }) =>
      makePostRequest(data, api.users.onboardingCompleted),
  });
};

export const useInvitationVerify = () => {
  return useQueryMutation({
    mutationFn: (data: { token: string; code: string }) =>
      makePostRequest(data, api.users.invitationVerify),
  });
};

export const useIndividualKyc = () => {
  return useQueryMutation({
    mutationFn: (data: {
      bvn: string;
      dob: string;
      firstname: string;
      lastname: string;
    }) => makePostRequest(data, api.users.individualKyc),
  });
};

export const useUser = () => {
  return useQueryMutation({
    mutationFn: (data: UserData) => makePostRequest(data, api.users.users),
  });
};

export const useBusinessAcc = () => {
  return useQueryMutation({
    mutationFn: (data: any) => makePostRequest(data, api.users.businessAcc),
  });
};
export const useBusinessAccountKYC = () => {
  return useQueryMutation({
    mutationFn: (data: IBusinessUserData) =>
      makePostRequest(data, api.users.businessAccountKYC),
  });
};

export const useBusinessIndividual = () => {
  return useQueryMutation({
    mutationFn: (data: any) => makePostRequest(data, api.users.businessPersons),
  });
};

export const useBusinessCorporate = () => {
  return useQueryMutation({
    mutationFn: (data: any) =>
      makePostRequest(data, api.users.businessCorporate),
  });
};

export const useBusinessUserKyc = () => {
  return useQueryMutation({
    mutationFn: (data: {
      id_number: string;
      business_type: string;
      country: string;
      email: string;
    }) => makePostRequest(data, api.users.businessUserKYC),
  });
};

export const useUserLogin = () => {
  return useQueryMutation({
    mutationFn: (data: { verificationCode: string; email: string }) =>
      makePostRequest(data, api.users.userLogin),
  });
};

export const useCheckEmail = () => {
  return useQueryMutation({
    mutationFn: (data: { email: string }) =>
      makePostRequest(data, api.users.checkEmail),
  });
};
export const useEmail = () => {
  return useQueryMutation({
    mutationFn: (data: { email: string }) =>
      makePostRequest(data, api.users.onboardingChecks),
  });
};

export const useRecoverPassword = () => {
  return useQueryMutation({
    mutationFn: (data: { email: string }) =>
      makePostRequest(data, api.users.recoverPassword),
  });
};
export const useRefreshOnboarding = () => {
  return useQueryMutation({
    mutationFn: (data: any) =>
      makePostRequest(data, api.users.onboardingRefresh),
  });
};

export const useVerifyAccount = () => {
  return useQueryMutation({
    mutationFn: (data: {
      idNumber: string;
      idType: string;
      middleName: string;
      refereeCode: string;
      accountType: string;
      individual: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        country: string;
        city: string;
        dateOfBirth: string;
        bvn: string;
        addressLine_1: string;
        addressLine_2: string;
        state: string;
        postalCode: string;
        gender: string;
        type: string;
      };
    }) => makePostRequest(data, api.users.verifySmile),
  });
};

export const useRefresh = () => {
  return useQueryMutation({
    mutationFn: (data: {
      idNumber: string;
      idType: string;
      middleName: string;
      refereeCode: string;
      accountType: string;
      individual: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        country: string;
        city: string;
        dateOfBirth: string;
        bvn: string;
        addressLine_1: string;
        addressLine_2: string;
        state: string;
        postalCode: string;
        gender: string;
        type: string;
      };
    }) => makePostRequest(data, api.users.refreshKYC),
  });
};

export const useResetPassword = () => {
  return useQueryMutation({
    mutationFn: (data: {
      newPassword: string;
      email: string;
      verificationCode: string;
    }) => makePostRequest(data, api.users.resetPassword),
  });
};
export const useUsersInvitation = () => {
  return useQueryMutation({
    mutationFn: (data: {
      email: string;
      lastName: string;
      firstName: string;
    }) => makePostRequest(data, api.users.usersInvitations),
  });
};

export const useGenerateBeneficiaryForm = () => {
  return useQueryMutation({
    mutationFn: () =>
      makePostRequest(undefined, api.users.generateBeneficiaryForm),
  });
};

export const useUsersDocs = () => {
  return useQueryMutation({
    mutationFn: (data: any) => makePostRequest(data, api.users.userDocs),
  });
};

export const useBankList = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.users.BankList),
    queryKey: ["anc_banks"],
  });
};
export const useResolveBankName = () => {
  return useQueryMutation({
    mutationFn: (data: {
      bankCode: string;
      accountNumber: string;
    }): Promise<AxiosResponse<any>> =>
      makePostRequest(data, api.users.resolveBankName),
    // queryKey: [" BankName "]
  });
};
