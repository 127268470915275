import React from "react";

import "../Styles/loading-screen.css"

type LoadingPropType = { children?:any, showLoading?:boolean, msg?:string, bg?: string }

const LoadingScreen = (props: LoadingPropType) => {
  return props.showLoading ? 
    <div className="loading-screen" style={{ backgroundColor: props.bg }}>
      <div className="sk-cube-grid">
        <div className="sk-cube sk-cube1"></div>
        <div className="sk-cube sk-cube2"></div>
        <div className="sk-cube sk-cube3"></div>
        <div className="sk-cube sk-cube4"></div>
        <div className="sk-cube sk-cube5"></div>
        <div className="sk-cube sk-cube6"></div>
        <div className="sk-cube sk-cube7"></div>
        <div className="sk-cube sk-cube8"></div>
        <div className="sk-cube sk-cube9"></div>
      </div>
      <p className="text-[#0eb683] font-semibold">{props.msg || "Please Wait..."}</p>
    </div>
  : props.children
};

export default LoadingScreen;
