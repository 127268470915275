import React from "react";
import { BsCheck2 } from "react-icons/bs";
import { TabSelector } from "./TabSelector";
import { HiOutlineChevronRight } from "react-icons/hi";

interface ITab {
  completed: boolean;
  active: boolean;
  showOnDesktop?: boolean;
  position: number;
  label: string;
  subText: string;
  tab?: string;
  onClick?: () => void;
}

const OnboardingTabs = ({
  completed,
  active,
  tab,
  showOnDesktop,
  position,
  label,
  subText,
  onClick,
}: ITab) => {
  return (
    <TabSelector
      isActive={active}
      onClick={onClick}
      className={`p-2 px-2 flex rounded-full md:rounded-md items-center md:items-start transition-all duration-500 gap-5  cursor-pointer shrink-0 ${
        showOnDesktop ? "hidden md:flex" : "flex"
      } ${active ? "bg-none  " : "bg-transparent"}`}
    >
      <div
        className={`hidden md:flex w-7 h-7 rounded-full  justify-center shrink-0 transition-all duration-500 md:border md:mb-10 md:border-[#0EB683] items-center ${
          completed
            ? "bg-[#007954] md:border-[#007954]"
            : "bg-[#F6FCFA] md:bg-white"
        }`}
      >
        {completed && <BsCheck2 color="#fff" />}
        {!completed && (
          <span
            className={`${
              completed
                ? "text-white transition-all duration-500"
                : "text-[#0EB683]"
            } hidden`}
          >
            {position}
          </span>
        )}
        {!completed && active ? (
          <div className="hidden md:block dot rounded-full h-2 w-2 bg-[#0EB683]"></div>
        ) : null}
      </div>
      <div className="details flex flex-col text-left">
        <div className="flex items-center gap-4">
          <span
            className={`${
              active
                ? "text-[#0EB683] md:text-[#0EB683] text-sm"
                : "text-[8px] leading-[18px] text-[#5C6F7F]"
            } md:text-lg md:font-semibold  md:text-[#0EB683] transition-all duration-500`}
          >
            <p>{label}</p>
          </span>
          <span className="md:hidden block">

          <HiOutlineChevronRight size={23}/>
          </span>
        </div>
        <span className="text-xs text-gray-500 hidden md:inline ">
          {subText}
        </span>
      </div>
    </TabSelector>
  );
};

export default OnboardingTabs;
