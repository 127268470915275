import React from "react";
import LandingPageMid from "../../components/base-components/LandingPageMid";
import LandingPageSection from "../../components/base-components/LandingPageSection";
import LandingPageFooter from "../../components/base-components/LandingPageFooter";
import LandingPageHeader from "../../components/base-components/LandingPageHeader";

const LandingPage = () => {
  return (
    <div>
      <LandingPageHeader />
      {/* <LandingPageSection />
        <LandingPageMid/> */}
      <LandingPageFooter />
    </div>
  );
};

export default LandingPage;
