import React, { useEffect, useState } from "react";
import OTPInput from "../widgets/OTPInput";
import { motion } from "framer-motion";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";
import AppCheckBox from "../widgets/AppCheckBox";
import {
  useBeneficiaries,
  useBeneficiaryIdentity,
  useTransferUsd,
} from "../../sevices/hooks/wallets";
import { useNavigate, useParams } from "react-router-dom";
import { useRequestOtp } from "../../sevices/hooks/users";


interface Itransfer {
  credit: string;
  serviceFee: string;
  debit: string;
  selectedReason: string;
  transferAmount: string;
  prev?: () => void;
  setData: (arg0: boolean) => void;
  sourceOfFunds: string;
  PayInvoice: any;
  SoF: any;
}

const BeneficiarySummary: React.FC<Itransfer> = ({
  credit,
  serviceFee,
  debit,
  selectedReason,
  transferAmount,
  prev,
  setData,
  sourceOfFunds,
  PayInvoice,
  SoF,
}) => {
  const beneficiaries = useBeneficiaries();
  const transferUsd = useTransferUsd();
  const [otpValue, setOtpValue] = useState("");
  const { refetch, data, isLoading } = beneficiaries;
  const { beneficiaryId } = useParams();
  const navigate = useNavigate();

  //@ts-ignore
  const beneficiary = useBeneficiaryIdentity(beneficiaryId);

  const [infoCorrect, setInfoCorrect] = useState(false);
  const [agreementToDebit, setAgreementToDebit] = useState(false);
  const [agreementToTerms, setAgreementToTerms] = useState(false);
  const [authorizeTTFCX, setAuthorizeTTFCX] = useState(false);
  //amount

  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [otp, setOtp] = useState("")

  const getOtp = useRequestOtp(
    //@ts-ignore
    localStorage.getItem("user_id")
  );


  const initiateUsdTrf = (e: any) => {
    e.preventDefault();
    transferUsd
      .mutateAsync({
        units: Number(transferAmount),
        otp: otpValue,
        //@ts-ignore
        beneficiaryId: beneficiaryId,
      })
      .then((res) => {
        setShowVerificationModal(false);
        setSuccess(true);
      })
      .catch((e) => {});
  };


  return (
    <>
      <div className=" flex gap-10 lg:pr-10 mt-10  xxs:h-screen">
        <div className="w-full">
          <div className="bg-[#f7fbfb]  md:bg-white p-5 md:px-5 md:my-5 ">
            <h1
              className="text-[
                        #071827
                        ] text-2xl font-bold"
            >
              Summary
            </h1>
            <div className=" flex items-center justify-between py-2  ">
              <p>Your beneficiary will receive:</p>

              <h1 className="text-[#0eb683] font-bold text-lg">$ {credit}</h1>
            </div>
            <div className="box bg-[#E2F0EA] p-5 flex flex-col gap-4">
              {/* <div className="flex items-center  justify-between">
                <h1 className="text-xs text-[#5c6f7f]">Swift Code:</h1>
                <p>1234567890</p>
              </div> */}
              <div className="flex items-center  justify-between">
                <h1 className="text-xs text-[#5C6F7F]">Beneficiary Name:</h1>
                <p className="whitespace-normal text-right ">
                  {beneficiary?.data?.data?.billingDetails?.name}
                </p>
              </div>
              <div className="flex items-center  justify-between">
                <h1 className="text-xs text-[#5C6F7F]">Account Number:</h1>
                <p>{beneficiary?.data?.data?.accountNumber || "NA"}</p>
              </div>
              <div className="flex items-center justify-between">
                <h1 className="text-xs text-[#5C6F7F]">Address Line 1:</h1>
                <p className="ml-1 break-words">
                  {beneficiary?.data?.data?.billingDetails?.line1}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <h1 className="text-xs text-[#5C6F7F]">Address Line 2:</h1>
                <p className="break-words ">
                  {beneficiary?.data?.data?.billingDetails?.line2 || "NA"}
                </p>
              </div>
              <div className="flex items-center  justify-between">
                <h1 className="text-xs text-[#5C6F7F]">
                  Beneficiary Postal Code:
                </h1>
                <p>{beneficiary?.data?.data?.billingDetails?.postalCode}</p>
              </div>
              <div className="flex items-center  justify-between">
                <h1 className="text-xs text-[#5C6F7F]">
                  Beneficiary District:
                </h1>
                <p>{beneficiary?.data?.data?.billingDetails?.district}</p>
              </div>
              <div className="flex items-center justify-between">
                <h1 className="text-xs text-[#5C6F7F]">City:</h1>
                <p>{beneficiary?.data?.data?.billingDetails?.city}</p>
              </div>
              <div className="flex items-center  justify-between">
                <h1 className="text-xs text-[#5C6F7F]">Bank Country:</h1>
                <p className="ml-3">
                  {beneficiary?.data?.data?.bankAddress?.country}
                </p>
              </div>
              <div className="flex items-center  justify-between">
                <h1 className="text-xs text-[#5C6F7F]">Purpose of Payment:</h1>
                <p>{selectedReason}</p>
              </div>

              <div className="flex items-center  justify-between">
                <h1 className="text-xs text-[#5C6F7F]">
                  Service Fee @0.25% ($):
                </h1>
                <p className="">
                  {"$ " + serviceFee === "" ? "0.00" : serviceFee}
                </p>
              </div>
              <div className="flex items-center  justify-between">
                <h1 className="text-xs text-[#5C6F7F]">
                  Offshore Charges ($):
                </h1>
                <p className="">$ 50.00</p>
              </div>
              <div className="flex items-center  justify-between">
                <h1 className="text-xs text-[#5C6F7F]">
                  Total amount to be <br /> debited including charges:
                </h1>
                <p className="text-[#0eb683] font-bold text-lg">
                  $ {transferAmount}
                </p>
              </div>
            </div>
            <>
              <p className="text-[#0eb683]  text-sm md:text-xs mt-2 md:hidden  ">
                If the information provided is correct and this transaction
                receives banking partner authorization, value will be delivered
                to recipient within 3 working days
              </p>
              <div className="md:hidden ">
                <h1 className="text-[#071827] text-2xl font-bold pb-5">
                  Confirmation
                </h1>
                <p className="text-[#0B0C0E] text-sm pb-3">I confirm that:</p>
                <div className=" flex flex-col gap-5">
                  <div className="flex  gap-4 ">
                    <div>
                      <AppCheckBox
                        getChecked={(value: boolean) => {
                          setInfoCorrect(value);
                        }}
                      />
                    </div>
                    <p className="text-[#5C6F7F] text-sm">
                      The information I have provided is correct
                    </p>
                  </div>

                  <div className="flex gap-4 ">
                    <div className="">
                      <AppCheckBox
                        getChecked={(value: boolean) => {
                          setAgreementToDebit(value);
                        }}
                      />
                    </div>
                    <p className="text-[#5C6F7F] text-sm">
                      I agree my FCY wallet be debited along with all associated
                      charges
                    </p>
                  </div>
                  <div className="flex gap-4 ">
                    <div className="">
                      <AppCheckBox
                        getChecked={(value: boolean) => {
                          setAgreementToTerms(value);
                        }}
                      />
                    </div>
                    <p className="text-[#5C6F7F] text-sm">
                      I agree to be bound by Trusted Transfer's Offshore
                      Transfer{" "}
                      <button
                        onClick={() =>
                          navigate("/terms-of-service", { replace: true })
                        }
                        className="text-[#0EB683] transition-all active:scale-90 underline"
                      >
                        Terms of Service
                      </button>
                    </p>
                  </div>
                  <div className=" flex gap-4">
                    <div>
                      <AppCheckBox
                        getChecked={(value: boolean) => {
                          setAuthorizeTTFCX(value);
                        }}
                      />
                    </div>
                    <p className="text-[#5C6F7F] text-sm">
                      By clicking the button below, Trusted Transfers is
                      authorized to transfer the funds to the beneficiary
                      account{" "}
                    </p>
                  </div>
                </div>
              </div>
            </>
            <div className=" xxs:mt-10 xxs:mb-16">
              <div className=" btn md:hidden  flex items-center justify-between">
                <button
                  onClick={() => setData(false)}
                  className="p-4 md:p-3 px-8 text-[#0eb683] bg-white border border-[#0eb683] rounded-lg transition-all active:scale-90"
                >
                  Previous
                </button>
                <button
                  disabled={
                    !infoCorrect ||
                    !agreementToDebit ||
                    !agreementToTerms ||
                    !authorizeTTFCX
                  }
                  onClick={() => {
                    setShowVerificationModal(true);
                  }}
                  className="p-4 md:p-3 text-white bg-[#0eb683] border rounded-lg transition-all active:scale-90 "
                >
                  Initiate Transfer
                </button>
              </div>
            </div>
          </div>

          <div className="btn hidden md:flex items-center justify-between">
            <button
              onClick={() => setData(false)}
              className="p-4 md:p-3 px-8 text-[#0eb683] bg-white border border-[#0eb683] rounded-lg transition-all active:scale-90"
            >
              Previous
            </button>
          </div>
        </div>

        <div className="hidden md:flex flex-col p-5 h-fit  md:bg-white  md:px-5 md:my-5   ">
          <h1 className="text-[#071827] text-2xl font-bold pb-5">
            Confirmation
          </h1>
          <p className="text-[#0eb683] text-sm pb-8">
            If the information provided is correct and this transaction receives
            banking partner authorization, value will be delivered to recipient
            within 3 working days
          </p>
          <p className="text-[#0B0C0E] text-sm pb-3">I confirm that:</p>
          <div className="flex flex-col gap-5">
            <div className=" flex items-center gap-5 ">
              <div>
                <AppCheckBox
                  getChecked={(value: boolean) => {
                    setInfoCorrect(value);
                  }}
                />
              </div>

              <label htmlFor="checkbox" className="text-[#5C6F7F] text-sm ">
                {" "}
                The information I have provided is correct
              </label>
            </div>

            <div className=" flex items-center gap-5">
              <div>
                <AppCheckBox
                  getChecked={(value: boolean) => {
                    setAgreementToDebit(value);
                  }}
                />
              </div>

              <label htmlFor="checkbox" className="text-[#5C6F7F] text-sm">
                {" "}
                I agree my FCY wallet be debited along with all associated
                charges
              </label>
            </div>
            <div className=" flex items-center gap-5">
              <div>
                <AppCheckBox
                  getChecked={(value: boolean) => {
                    setAgreementToTerms(value);
                  }}
                />
              </div>

              <label htmlFor="checkbox" className="text-[#5C6F7F] text-sm">
                {" "}
                <p>
                  {" "}
                  I agree to be bound by Trusted Transfer's Offshore Transfer{" "}
                  <button
                    onClick={() =>
                      navigate("/terms-of-service", { replace: true })
                    }
                    className="text-[#0EB683] transition-all active:scale-90 underline"
                  >
                    Terms of Service
                  </button>
                </p>
              </label>
            </div>

            <label
              htmlFor="checkbox"
              className="text-[#5C6F7F] text-sm flex gap-5"
            >
              <div>
                <AppCheckBox
                  getChecked={(value: boolean) => {
                    setAuthorizeTTFCX(value);
                  }}
                />
              </div>
              <div>
                <p>
                  Trusted Transfers is authorized to transfer the funds to the
                  beneficiary account
                </p>{" "}
              </div>
            </label>
          </div>
          <div className="mt-10 self-end">
            <button
              disabled={
                !infoCorrect ||
                !agreementToDebit ||
                !agreementToTerms ||
                !authorizeTTFCX
              }
              onClick={() => {
                getOtp.mutateAsync({});
                setShowVerificationModal(true);
              }}
              className="p-4 md:p-3 text-white bg-[#0eb683] border rounded-lg transition-all active:scale-90  disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90 "
            >
              Initiate Transfer
            </button>
          </div>
        </div>
      </div>

      {showVerificationModal && (
        <div>
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                // delay: 0.3,
                duration: 0.1,
              },
            }}
            className="confirmation_modal backdrop-blur-sm bg-[#13111137] fixed w-full h-full top-0 left-0 justify-center md:justify-end items-center z-10"
          >
            <motion.div
              initial={{
                x: 600,
                scale: 0,
              }}
              animate={{
                x: 0,
                scale: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                x: 600,
                scale: 0,
                transition: {
                  delay: 0.3,
                },
              }}
              className="flex h-screen items-end md:items-center  md:justify-center "
            >
              <motion.div
                initial={{
                  x: 600,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className="card flex flex-col bg-white h-[350px] w-full max-w-md md:max-w-md md:max-h-full rounded-t-3xl md:rounded-3xl p-10 "
              >
                <div className="flex flex-col gap-3">
                  <div className=" ">
                    <h1 className="text-3xl text-[#0eb683] font-extrabold self-center text-center mb-8">
                      Verification
                    </h1>
                  </div>
                  <div className=" mt-1 md:mt-0">
                    <p className="text-[#5C6F7F] text-sm ">
                      Enter the 5-digit code sent to your email
                    </p>
                    <OTPInput
                      autoFocus
                      length={5}
                      className="otpContainer flex justify-between  w-full py-3 mt-4 md:mt-0 "
                      inputClassName="otpInput w-12 h-12 md:w-[50px] md:h-[50px] bg-white border border-[#0EB683] outline-[#0EB683] text-[#0EB683] text-2xl text-center font-bold rounded-lg"
                      autoCapitalize={true}
                      onChangeOTP={(otp) => {
                        setOtpValue(otp.toUpperCase());
                      }}
                      onPaste={(otp) => {
                        setOtpValue(otp.toUpperCase());
                      }}
                    />
                  </div>
                  <div className="flex items-center justify-center">
                    <button
                      onClick={initiateUsdTrf}
                      className="bg-[#0eb683] w-full self-center justify-center  text-white p-3 rounded-md transition-all active:scale-90 mt-4 md:mt-0 "
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      )}
      {success && (
        <div>
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                // delay: 0.3,
                duration: 0.1,
              },
            }}
            className="confirmation_modal bg-[#13111173] backdrop-blur-sm fixed w-full top-0 left-0 justify-center md:justify-end h-full items-center z-50"
          >
            <motion.div
              initial={{
                x: 600,
                scale: 0,
              }}
              animate={{
                x: 0,
                scale: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                x: 600,
                scale: 0,
                transition: {
                  delay: 0.3,
                },
              }}
              className="flex h-screen items-center justify-center md:justify-end"
            >
              <motion.div
                initial={{
                  x: 600,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className="desktop-card md:flex hidden justify-center flex-col gap-6 bg-white  w-full max-w-md h-[450px] md:h-full rounded-l-3xl p-5 "
              >
                <div className="icon self-center">
                  <SuccessMobile width={"200px"} height={"200px"} />
                </div>
                <h1 className="text-2xl font-bold text-[#071827] self-center">
                  Transfer initiated
                </h1>

                <p className="text-sm text-[#5C6F7F] self-center text-center">
                  Your recepient will receive value within 1-3 business days
                </p>

                <button
                  onClick={() => {
                    setSuccess(false);
                  }}
                  className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                >
                  Done
                </button>
              </motion.div>
              {/* Mobile */}

              <motion.div
                initial={{
                  x: 600,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className="mobile-card flex md:hidden justify-center flex-col gap-8 bg-white  w-full max-w-sm h-[450px] rounded-3xl p-5 "
              >
                <div className="icon self-center">
                  <SuccessMobile width={"100px"} height={"100px"} />
                </div>
                <h1 className="text-2xl font-bold text-[#071827] self-center">
                  Transfer initiated
                </h1>

                <p className="text-sm text-[#5C6F7F] self-center text-center">
                  Your recepient will receive value within 1-3 business days
                </p>

                <button
                  onClick={() => {
                    setSuccess(false);
                  }}
                  className="bg-[#0eb683] text-white rounded-md px-16 py-3 self-center transition-all active:scale-90"
                >
                  Done
                </button>
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      )}
    </>
  );
};

export default BeneficiarySummary;
