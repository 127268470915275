import React from 'react'
import { FC } from "react"
import SvgIconConstituentValues from './types'

export interface SvgIcon extends FC<SvgIconConstituentValues>{}


const CopyIcon = ({
    height="16px",
    width="16px"
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
    <path d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z" stroke="#0EB683" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.3335 10.0007H2.66683C1.93045 10.0007 1.3335 9.40372 1.3335 8.66732V2.66732C1.3335 1.93094 1.93045 1.33398 2.66683 1.33398H8.66683C9.40323 1.33398 10.0002 1.93094 10.0002 2.66732V3.33398" stroke="#0EB683" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default CopyIcon