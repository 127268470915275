import React, { useRef, useState } from "react";
import { useNgnBeneficiaryState } from "../../store/useNgnBeneficiary";
import { NgnBeneficiaryType } from "../../types/NgnBeneficiary";
import { capitalize } from "../../pages/dashboard/Transactions";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { FiTrash } from "react-icons/fi";
import { useOutsideAlerter } from "../../hooks/OutsideAlerter";
import { FaMoneyBillTransfer } from "react-icons/fa6";

export function createAcronym(str: string): string {
  if (!str) return "";

  const words: string[] = str.split(/\s+/);

  const ftw: string[] = words.slice(0, 2);
  const acronym: string = ftw.reduce(
    (acc, word) => acc + word[0].toUpperCase(),
    ""
  );

  return acronym;
}
type CardProps = {
  b: any;
  delete: () => void;
};
const NgnBeneficiaryCard: React.FC<CardProps> = ({
  b,

  delete: deleteAction,
}) => {
  const [popup, setPopup] = useState(false);
  const [deactivated, setDeactivated] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(popupRef, popup, () => setPopup(false));
  const setSelectedNgnBeneficiaryId = useNgnBeneficiaryState(
    (state) => state.setSelectedBeneficiaryId
  );
  const setShowNgnBeneficiaryModal = useNgnBeneficiaryState(
    (state) => state.setShowBeneficiaryDetails
  );

  const { accountName, id, bankName, accountNumber } = b;

  const acronym = createAcronym(accountName);

  return (
    // <div
    //   key={id + "-"}
    //   onClick={() => {
    //     // setSelectedNgnBeneficiaryId(id);
    //     // setShowNgnBeneficiaryModal(true);
    //   }}
    //   className=""
    // >
    //   <div className="border border-[#0eb683] gap-4 bg-white w-full h-28  rounded-lg flex md:flex-col items-end md:h-48 p-5 transition-all  cursor-pointer xxs:relative ">
    //     <div className="inline-block relative ">
    //       <button
    //         onClick={() => setPopup(!popup)}
    //         className="absolute top-0 right-0"
    //       >
    //         <BiDotsVerticalRounded />
    //       </button>
    //       <div
    //         ref={popupRef}
    //         className={`absolute py-4  md:right-[1200%] shadow-md rounded-md bg-white w-[200px] transition-all origin-top-left duration-300 transform z-50 ${
    //           popup ? "scale-100" : "scale-0"
    //         }`}
    //       >
    //         <button
    //           onClick={(e) => {
    //             e.stopPropagation();
    //             setPopup(false);
    //             setSelectedNgnBeneficiaryId(id);
    //             setShowNgnBeneficiaryModal(true);
    //           }}
    //           className="flex items-center py-3 px-4 w-full transition-all duration-300 hover:bg-[rgba(0,0,0,0.1)] text-[#0eb683] "
    //         >
    //           <FaMoneyBillTransfer className="mr-5" size={20} /> Send To
    //         </button>
    //         <button
    //           onClick={(e) => {
    //             e.stopPropagation();
    //             setSelectedNgnBeneficiaryId(id);
    //             setPopup(false);
    //             deleteAction && deleteAction();
    //           }}
    //           className="flex items-center py-3 px-4 w-full transition-all duration-300 hover:bg-[rgba(0,0,0,0.1)] text-[#D92D20] "
    //         >
    //           <FiTrash className="mr-5" size={20} /> Delete
    //         </button>
    //       </div>
    //     </div>
    //     <div className="flex md:flex-col items-center  self-center gap-4 md:gap-0">
    //       <div className="flex justify-center items-center ">
    //         <div className="bg-[#E5F2EA] flex justify-center align-middle items-center p-3 h-12 w-12  text-center text-[#0eb683] font-extrabold rounded-full self-center  ">
    //           <p className="font-bold text-lg">{acronym}</p>
    //         </div>
    //       </div>
    //       <div className="md:text-center flex flex-col items-start lg:items-center">
    //         <h1 className="text-[#071827] font-semibold">
    //           {capitalize(accountName)}
    //         </h1>
    //         <p className="text-sm text-[#5C6F7F]">{bankName}</p>
    //         <p className="text-sm text-[#5C6F7F]">{accountNumber}</p>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div
      key={id}
      onClick={() => {
        setSelectedNgnBeneficiaryId(id);
        setShowNgnBeneficiaryModal(true);
      }}
      className=""
    >
      <div className="border border-[#0eb683] gap-4 bg-white w-full h-28  rounded-lg flex md:flex-col items-center md:h-48 p-5 transition-all active:scale-90 cursor-pointer xxs:relative ">
        <div className="flex justify-center items-center">
          <div className="bg-[#E5F2EA] flex justify-center align-middle items-center p-3 h-12 w-12  text-center text-[#0eb683] font-extrabold rounded-full self-center  ">
            <p className="font-bold text-lg">{acronym}</p>
          </div>
        </div>
        <div className="name flex flex-col items-start md:items-center">
          <h1 className="text-[text-[#071827] font-semibold">
            {b?.accountName}
          </h1>
          <p className="text-sm text-[#5C6F7F]">
            {bankName} - {accountNumber}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NgnBeneficiaryCard;
