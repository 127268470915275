/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Logo from "../../assets/misc/logo-on-light.svg";
import brandLogo from "../../assets/misc/design-top.svg";
import brandLogo2 from "../../assets/misc/design-bottom.svg";
import logo from "../../assets/misc/logo-on-dark.svg";
import BrandImg from "../../assets/misc/design.svg";
import { BsChevronLeft, BsShop } from "react-icons/bs";
import OnboardingTabs from "../../components/widgets/OnboardingTabs";
import { TabPanel, useTabs } from "../../components/widgets/TabWidget";
import { onboardingSteps } from "../../utils/onboarding-data";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import BusinessUser from "./BusinessUser";
import IndividualUser from "./IndividualUser";
import ChooseBank from "./ChooseBank";
import CreatePassword from "./CreatePassword";
// import { IUserSignUpData } from "../../types/Users";
import { useEmail, useUser } from "../../sevices/hooks/users";
import useQueryParams from "../../hooks/useQueryParams";
import LoadingScreen from "../Notifications/LoadingScreen2";
import TabContext from "../../store/context/TabProvider";
import contain from ".././../assets/images/onboarding/Ellipse.png";
import icon from ".././../assets/images/onboarding/shop-store.png";
import icon2 from ".././../assets/images/onboarding/user-account-profile.png";
import UserSummary from "./UserSummary";
import BusinessOwner from "./BusinessOwner";
import ContactPerson from "./ContactPerson";
import BusinessSummary from "./BusinessSummary";

const Onboarding = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [steps, setSteps] = useState(onboardingSteps);
  const [selectedTab, setSelectedTab] = useTabs(
    onboardingSteps.map((step) => step.name)
  );

  //@ts-ignore
  const { business, busInc, busType, busPhone } = useContext(TabContext);
  const [showWelcome, setShowWelcome] = useState(true);
  const [userEmail, setUserEmail] = useState("");
  const [userStepValid, setUserStepValid] = useState(false);
  const [bankStepValid, setBankStepValid] = useState(false);
  const [passwordStepValid, setPasswordStepValid] = useState(false);
  const [isSummary, setIsSummary] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentFlow, setCurrentFlow] = useState("individual");
  const [userDetails, setUserDetails] = useState<any>({});

  const { code, token } = useQueryParams(["token", "code"]);

  const updateStep = (currentStep: string, value: boolean) => {
    const updatedSteps = steps.map((step) =>
      step.slug === currentStep ? { ...step, completed: value } : step
    );

    setSteps(updatedSteps);
  };

  const updateBusinessFlow = (currentFlow: any) => {
    return currentFlow === "business"
      ? steps.filter((step) => step.slug !== "individual")
      : steps.filter((step) => !step.slug.includes("business"));
  };

  const confirmPasswordValid = (value: boolean) => {
    if (value) setPasswordStepValid(true);
  };

  useEffect(() => {
    localStorage.removeItem("user_data");
    const locationState = location?.state as { code: string; email: string };

    setUserEmail(locationState?.email);
  }, []);

  const getData = useEmail();

  //Api declaration
  const createUserAccount = useUser();

  const showUserSummary = () => {
    setIsSummary(true);
  };

  useEffect(() => {
    const locationState = location?.state as { code: string; email: string };
    getData
      .mutateAsync({ email: locationState?.email })
      .then((res) => {
        localStorage.setItem("user_data", JSON.stringify(res.data.data));
      })
      .catch((err) => {});
  }, []);

  const errMsg = createUserAccount.errorMessage;

  const nextOption = (step: string) => {
    setCurrentFlow("individual");
    updateStep(step, true);
    setSelectedTab("individual");
    setUserDetails({
      ...userDetails,
      accountType: "private",
    });
  };
  return (
    <>
      {!isSummary ? (
        <div className="grid grid-rows-[auto_1fr] md:grid-rows-1 md:grid-cols-[auto_1fr] min-h-screen h-auto md:h-screen md:min-h-0 ">
          <aside className="transition-all md:w-72 lg:w-80 overflow-x-hidden flex flex-col">
            <div className="logo pl-9 py-10 hidden md:block">
              <img src={Logo} alt="" className=" w-36" />
            </div>
            <header className="flex md:hidden justify-between items-center p-3">
              <button
                className="w-10 h-10 flex justify-center items-center bg-[#ECF6F3] rounded-full"
                onClick={() => navigate(-1)}
              >
                <BsChevronLeft size={18} />
              </button>
              <h1 className="mx-auto font-semibold text-lg">Onboarding</h1>
              <div />
            </header>
            <nav
              className={`overflow-auto py-3 px-5 ${
                selectedTab === "choose"
                  ? "hidden md:flex md:flex-col "
                  : "flex md:flex-col"
              }`}
            >
              {updateBusinessFlow(currentFlow).map((step, index) => {
                return (
                  <OnboardingTabs
                    key={index}
                    completed={step.completed}
                    active={step.name === selectedTab}
                    position={index}
                    label={step.label}
                    subText={step.description}
                    showOnDesktop={index === 0}
                  />
                );
              })}
            </nav>

            <div className="brand hidden md:block mt-auto ">
              <img src={BrandImg} className="w-36" alt="" />
            </div>
          </aside>
          <main
            className={`overflow-y-auto py-3 md:px-[10%] ${
              selectedTab === "choose"
                ? "bg-white md:h-full md:bg-[#F7FBFB]"
                : "bg-[#F7FBFB]"
            }`}
          >
            <h1 className="hidden md:block text-[32px] leading-[48px] font-bold py-8 px-8 md:px-0 border-b border-[#CBE0D6] md:ml-6">
              Onboarding
            </h1>
            <TabPanel hidden={selectedTab !== "choose"}>
              <div className="select-type mt-16 px-5">
                <h1 className="text-[24px] leading-[2px] font-bold mb-4 text-[#071827]">
                  Account Type
                </h1>
                <p className="text-[16px] leading-[24px] text-[#5C6F7F] font-normal">
                  Kindly select your account type:
                </p>

                <div className="chooser flex flex-col md:flex-row gap-5 md:gap-10 py-5 md:mt-8 ">
                  <div
                    className="option gap-4 md:gap-2 p-6 flex md:flex-col md:justify-center items-center md:text-center cursor-pointer  active:scale-90 w-full md:w-[350px] md:h-72 border border-[#0EB683] md:border-0 bg-[#F6FCFA] md:bg-white rounded-lg  transform transition duration-500 hover:scale-110 shadow-sm"
                    onClick={() => {
                      updateStep("choose", true);
                      setCurrentFlow("business");
                      setSelectedTab("business-representative");
                      //  updateStep("business-representative", false);
                      setUserDetails({
                        ...userDetails,
                        accountType: "business",
                      });
                    }}
                  >
                    <figure className="icon shrink-0 w-20 h-20 rounded-full  text-white flex justify-center items-center">
                      <img src={contain} alt="contain" className="relative " />
                      <img src={icon} alt="contain" className="absolute" />
                    </figure>
                    <div className="details">
                      <h2 className="font-bold text-[20px] leading-[30px] md:my-2">
                        I’m a business
                      </h2>

                      <p className="text-[#5C6F7F] text-[14px] leading-[21px]">
                        Any{" "}
                        <span className="font-extrabold text-md underline">
                          BUY
                        </span>{" "}
                        transaction your referee performs earns you commission
                        of up to{" "}
                        <p className="text-[#0eb683] font-bold">
                          NGN1.50 / Foreign Currency
                        </p>
                         
                      </p>
                    </div>
                    <div className="md:hidden">
                      <AiOutlineRight size={24} />
                    </div>
                  </div>

                  <div
                    className="option gap-4 md:gap-2 p-6 flex md:flex-col md:justify-center items-center md:text-center cursor-pointer  active:scale-90 w-full md:w-[350px]  md:h-72 border border-[#0EB683] md:border-0 bg-[#F6FCFA] md:bg-white rounded-lg  transform transition duration-500 hover:scale-110 shadow-sm"
                    onClick={() => nextOption("choose")}
                  >
                    <div className="icon shrink-0 w-20 h-20 rounded-full  text-white flex justify-center items-center">
                      <img src={contain} alt="contain" className="relative" />
                      <img src={icon2} alt="contain" className="absolute" />
                    </div>
                    <div className="details">
                      <h2 className="font-bold text-[20px] leading-[30px] md:my-2">
                        I’m an individual
                      </h2>
                      <p className="text-[#5C6F7F] text-[14px] leading-[21px]">
                        Any{" "}
                        <span className="font-extrabold text-md underline">
                          BUY
                        </span>{" "}
                        transaction your referee performs earns you commission
                        of up to{" "}
                        <p className="text-[#0eb683] font-bold">
                          NGN1.00 / Foreign Currency
                        </p>
                         
                      </p>
                    </div>
                    <div className="md:hidden">
                      <AiOutlineRight size={24} />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel hidden={selectedTab !== "business"}>
              <BusinessUser
                email={userEmail}
                userDetail={userDetails}
                getDetails={(data, isValid) => {
                  if (isValid) {
                    setUserDetails({ ...userDetails, ...data });
                  }
                  setUserStepValid(isValid);
                }}
                backFn={() => {
                  updateStep("password", false);
                  // setCurrentFlow("individual");
                  setSelectedTab("password");
                }}
                nextFn={() => {
                  updateStep("business", true);
                  setSelectedTab("business-owner");
                }}
              />
              {/* <OnboardingFooter
              backFn={() => {
                updateStep("choose", false);
                setCurrentFlow("individual");
                setSelectedTab("choose");
              }}
              nextFn={() => {
                updateStep("business", true);
                setSelectedTab("individual");
              }}
            /> */}
            </TabPanel>
            <TabPanel hidden={selectedTab !== "business-owner"}>
              <BusinessOwner
                setIsSummary={setIsSummary}
                userDetail={userDetails}
                email={userEmail}
                getDetails={(data, isValid) => {
                  if (isValid) {
                    setUserDetails({ ...userDetails, ...data });
                  }
                  setUserStepValid(isValid);
                }}
                backFn={() => {
                  updateStep("business", false);
                  // setCurrentFlow("business");
                  setSelectedTab("business");
                }}
                nextFn={() => {
                  updateStep("business-owner", true);
                  setSelectedTab("business-contact");
                  showUserSummary();
                }}
              />
            </TabPanel>
            <TabPanel hidden={selectedTab !== "business-representative"}>
              <ContactPerson
                userDetail={userDetails}
                email={userEmail}
                getDetails={(data, isValid) => {
                  if (isValid) {
                    setUserDetails({ ...userDetails, ...data });
                  }
                  setUserStepValid(isValid);
                }}
                backFn={() => {
                  // updateStep("business-owner", false);
                  // setCurrentFlow("business-owner");
                  // setSelectedTab("business-owner");

                  updateStep("choose", false);
                  setCurrentFlow("individual");
                  setSelectedTab("choose");
                }}
                nextFn={() => {
                  updateStep("business-representative", true);
                  setSelectedTab("bank");
                }}
              />
            </TabPanel>
            <TabPanel hidden={selectedTab !== "individual"}>
              <IndividualUser
                userDetail={userDetails}
                email={userEmail}
                getDetails={(data, isValid) => {
                  if (isValid) {
                    setUserDetails({ ...userDetails, ...data });
                  }
                  setUserStepValid(isValid);
                }}
                backFn={() => {
                  if (currentFlow !== "business") {
                    updateStep("business-representative", false);
                    setSelectedTab("choose");
                  }
                }}
                nextFn={() => {
                  updateStep("individual", true);
                  setSelectedTab("bank");
                }}
              />
            </TabPanel>
            <TabPanel hidden={selectedTab !== "bank"}>
              <ChooseBank
                getDetails={(data, isValid) => {
                  if (isValid) {
                    setUserDetails({ ...userDetails, ...data });
                  }
                }}
                userDetail={userDetails}
                backFn={() => {
                  if (currentFlow !== "business") {
                    updateStep("individual", false);
                    setSelectedTab("individual");
                  } else {
                    updateStep("bank", false);
                    setSelectedTab("business-representative");
                  }
                }}
                currentFlow={currentFlow}
                nextFn={() => {
                  updateStep("bank", true);
                  setSelectedTab("password");
                }}
              />
            </TabPanel>
            <TabPanel hidden={selectedTab !== "password"}>
              <CreatePassword
                error={errMsg}
                userDetail={userDetails}
                confirmValid={confirmPasswordValid}
                getDetails={(data, isValid) => {
                  if (isValid) {
                    setUserDetails({ ...userDetails, ...data });
                  }
                }}
                // nextDisabled={!passwordStepValid}
                backFn={() => {
                  updateStep("bank", false);
                  setSelectedTab("bank");
                }}
                // nextFn={() => {
                //   updateStep("password", true);
                //   setSelectedTab("business");
                // }}
                nextFn={() => {
                  if (currentFlow !== "business") {
                    updateStep("password", true);
                    showUserSummary();
                  } else {
                    updateStep("password", true);
                    setSelectedTab("business");
                  }
                }}
              />
            </TabPanel>
          </main>
          <>
            {showWelcome && (
              <div className="fixed w-full h-full top-0 left-0">
                <img
                  src={brandLogo}
                  alt=""
                  className="absolute w-28  md:w-44 "
                />
                <img
                  src={brandLogo2}
                  alt=""
                  className="absolute w-28 md:w-44 right-0 bottom-0"
                />
                <div className=" flex items-center flex-col gap-5 bg-[#0C0D1F] h-screen justify-center ">
                  <img src={logo} alt="" className="w-52 inline" />

                  <h1 className="text-white font-bold text-4xl py-5">
                    Welcome
                  </h1>
                  <p className="text-center text-white md:text-xl xxs:px-5">
                    You are few clicks away from Guaranteed Liquidity,
                    <br />
                    Funds Security and Payment Remittance.
                  </p>

                  <button
                    onClick={() => setShowWelcome(false)}
                    className="bg-[#0eb683] px-32 py-3 rounded-md text-white transition-all active:scale-90 xxs:px-20"
                  >
                    Create account
                  </button>
                  <small className="text-white">
                    Already have an account?{" "}
                    <Link
                      to="/login"
                      className="text-[#0eb683] transition-all active:scale-90"
                    >
                      Login
                    </Link>{" "}
                  </small>
                </div>
              </div>
            )}
          </>
        </div>
      ) : (
        <>
          {currentFlow === "individual" ? (
            <UserSummary
              nextOption={nextOption}
              setIsSummary={setIsSummary}
              getDetails={(data, isValid) => {
                if (isValid) {
                  setUserDetails({ ...userDetails, ...data });
                }
              }}
              userDetail={userDetails}
            />
          ) : (
            <>
              <BusinessSummary
                setIsSummary={setIsSummary}
                userDetail={userDetails}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Onboarding;
