import React from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/misc/logo-on-light.svg";
const OnboardingTermsOfUse = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen w-screen  bg-[#fff] overflow-y-scroll">
      <div className=" xxs:p-5 md:px-14">
        <div className="flex  justify-between items-center mb-9">
          <div className="flex items-center ">
            <button
              onClick={() => {
                navigate(-1);
              }}
              className=" md:bg-slate-400 rounded-full md:p-2 xxs:mr-2 flex transition-all active:scale-90 hover:scale-110"
            >
              <BsChevronLeft size={23} />
            </button>
            <h1 className="xxs:text-sm md:text-md    text-black font-sans font-extrabold ">
              GENERAL TERMS OF USE{" "}
            </h1>
          </div>

          <div className="xxs:w-20 md:w-32 flex  ">
            <img src={logo} alt="" />
          </div>
        </div>

        <ol className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            These general Terms and Conditions ("Terms of Use") shall apply to
            the terms and conditions around your use of the website,{" "}
            <a
              className="hover:underline text-[#0eb683]"
              href="https://www.ttfcx.com/ "
            >
              https://www.ttfcx.com/{" "}
            </a>{" "}
            (the “website”), the web platform, mobile application (“Platform”)
            and related materials and documentation made available by TTFCX
            (including all updates and other modifications thereto) through any
            of its Platforms.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Access to our Platform is by invitation. Before registering, logging
            in, a n d / or using our Platforms, please read and understand this
            Terms of Use and the Terms of Service. By using the Platform, you
            are bound by it together with any other terms and conditions that
            may be applicable for any type of service or product TTFCX makes or
            develops in future.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            If you do not agree to our Terms of Use, you may not access the
            Platform or tools. If you use any of the services offered on the
            Platform on behalf of a business, you represent and warrant that you
            can enter into this Agreement with TTFCX on behalf of that business,
            that you accept the terms and conditions contained herein on behalf
            of that business, and that you have received a copy of the
            Agreement.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            All the terms and conditions listed on this page shall together be
            referred to as the “Terms of Use". "User/Client/Customer", "You" and
            “Your" refers to you, the person accessing the Service an d
            accepting TTFCX's terms and conditions. Whenever a User uses the
            services on the Platforms, they agree to be bound by the Terms of
            Service, including any amendment, which may periodically take place.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            The Terms of Use contained herein represent a complete, final, and
            exclusive Agreement between the Parties and TTFCX (“TTFCX
            Enterprises”, “we”, “us, “our” or “The Company”) and should be read
            alongside any other and all prior Agreements, representations, and
            understandings
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            All Services may be date and time-stamped and we may issue unique
            transaction identification (“ID”) for proper authentication and
            determination of the relevant service period.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX cannot be held liable in any case for any damage or loss
            caused directly or indirectly by the Platform or by the contents
            provided by a third-party unless they are based on intentional or
            gross negligence.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX shall not be liable if the User is unable to use our Services
            or access the Platform due to congestion, network failure or any
            other electronic communication media used for such purposes or
            third-party actions of which TTFCX has no control.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            All indicated dates and times are based on WAT (West Africa Time)
            unless stipulated otherwise.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Any other legal relationship between the Parties and TTFCX, for
            every circumstance not regulated by the Terms of Use, shall be
            subject to accepted standards of practice in accordance with the
            laws and regulations of the jurisdiction of the User.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          AMENDMENTS TO THE TERMS OF USE
        </h1>
        <ol start={11} className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX can change the Terms of Use at any time at its own discretion.
            All changes shall become effective on their publication on TTFCX’s
            website, and the User shall be notified by email.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            If the User considers any amendment carried out to be intolerable,
            they may cease using TTFCX’s Services.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            The continued use of TTFCX’s Services after the date when the Terms
            of Use are meant to come into effect shall correspond to a complete
            acceptance of the revision performed.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          MAINTAINING YOUR ACCOUNT{" "}
        </h1>
        <ol start={14} className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            An individual or business who wants to have access to our Services
            shall receive an invitation to create an Account on the Platform. By
            creating an Account and using TTFCX’s Services, you warrant that you
            are legally eligible to conduct transactions or authorized to do so
            on behalf of your business. If it comes to TTFCX’s notice that you
            are ineligible or lack such authorization, you shall be removed from
            the Platform and may be investigated for signs of illegal activity.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            The right to access and/or use the Platform (including any or all
            the Services offered via the web or mobile application) may be
            illegal in certain countries. Parties are responsible for
            determining whether their accessing and/or use of TTFCX’s Services
            is compliant with applicable laws in their jurisdiction and they
            attest that TTFCX’s Services are not illegal in the territory where
            they reside. The Platform does not constitute an offer,
            solicitation, or invitation by TTFCX in any countries where its
            Services are deemed illegal. Parties should ensure that they would
            be acting legally in the country where they are located while
            accepting this Terms of Use and participating in TTFCX’s Services.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            When a new account is created, Parties are responsible for the
            accuracy and up-to-datedness of all the personal and business
            information and contact details provided, including name, location,
            identity documents, bank account information, and contact details to
            mention a few. TTFCX may close or suspend an Account if the
            information provided is found to be false, inaccurate, or
            incomplete.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            All personal information is stored in TTFCX’s secure and encrypted
            database. Please visit our <a className="hover:underline, text-[#0eb683]" href="/privacystatement">Privacy Statement</a> for more details.{" "}{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            After creating an Account, Parties must keep their login, personal
            information, and transaction details confidential and secure. All
            transactions where their data have been entered correctly shall be
            regarded as valid. All Account registrations shall be subject to
            rigorous KYC processes, sometimes through third-party partners
            engaged for such services. TTFCX shall not be liable for any claims
            in the event that the User compromised or exposed their login
            details and/or personal data through no direct fault of ours.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            The User may close their Account/opt out whenever they wish by
            deactivating the Account. The effective closure of the Account shall
            correspond to the termination of the Terms of Use and Terms of
            Service.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            In accordance with the legal period of warranty requested by law,
            all the personal data saved in TTFCX’s database shall be deleted at
            the User’s express request after the expiration of the respite
            period provided by the data protection regulations or laws in your
            jurisdiction.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            A closed Account may be reopened when the User reactivates the
            Account. In such circumstances, they shall be subject to the Terms
            of Use and Terms of Service that are in force at the date of the
            re-opening.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX retains the right to exclude a User from its Services at its
            discretion. If the Account has been closed due to an infringement or
            violation pursuant to the Terms of Use and/or Terms of Service, or
            because they performed a prohibited action leading to fraud or
            illegal activity, that User may be reported to the appropriate
            authorities in their jurisdiction.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            If a User does not access their Account by "logging in" with their
            Account name and security details for a period of twelve calendar
            months, that account shall be deemed,” Inactive".{" "}
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          USER CONDUCT ON THE PLATFORM:
        </h1>
        <ol start={24} className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            It is your responsibility as a User not to use our services for any
            illegal or fraudulent transactions. It is also your responsibility
            to verify the source and legality of your incoming and outgoing
            transactions.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX charges you a fee on any service purchased or outgoing
            transactions made on the Platform. These fees are non- refundable.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Daily FX rates for listed currencies displayed on the Platform are
            subject to market fluctuations.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            These rates are subject to a limited window. If a rate changes after
            you lock in and make an outgoing transaction, you will be refunded
            if you decide not to continue your transaction with the new rates.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            You are solely responsible for the bank/transaction information you
            provide to TTFCX and must promptly inform TTFCX of any changes
            thereto (e.g., change of bank account number). Changes should be
            communicated to TTFCX by entering the updated information through
            your Account on the Platform or contacting TTFCX customer support.{" "}
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          SUSPENSION OF USE OF PLATFORM:
        </h1>
        <ol start={29} className="flex flex-col gap-3 mb-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX may suspend your access to the Platform or immediately
            terminate this Terms of Use if you:
          </li>
        </ol>
        <ul className="flex flex-col gap-3 mb-3 md:pl-10">
          <li className="font-sans font-medium text-xs">
            <span className="font-bold">(a)</span> Breach the Terms of Use,
          </li>
          <li className="font-sans font-medium text-xs">
            <span className="font-bold">(b)</span> Deliberately provide false
            registration information{" "}
          </li>
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(c)</span> Consistently fail of our
            KYC/Due Diligence processes{" "}
          </li>
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(d)</span> Repeatedly provide misleading
            information or withhold information required for TTFCX to operate on
            the platform or provide its services{" "}
          </li>
          <li className="font-sans font-medium text-xs">
            <span className="font-bold">(e)</span> Commit any criminal activity
            including perpetration or intention to commit fraud on the Platform
            or directly or indirectly through the Platform
          </li>
          <li className="font-sans font-medium text-xs">
            <span className="font-bold">(f)</span> Fail to comply with TTFCX's
            best practices requirements for security management or to respond to
            an inquiry from TTFCX, concerning the accuracy or completeness of
            the information you are required to provide pursuant to this Terms
            of Use
          </li>
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(g) </span> If TTFCX reasonably suspects
            criminal and/or fraudulent activity on your TTFCX Account or payment
            activities
          </li>
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(h)</span> If any amendable breach is
            not cured within period of notice from TTFCX
          </li>
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(i)</span> In the event that certain
            third-party licenses or access to third-party components of the
            Platform are terminated
          </li>
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(j)</span> Immediately, without prior
            notice, if TTFCX reasonably believes your breach compromises the
            integrity and/or security of the Platform in any material fashion
            and/or the security of other users
          </li>
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(k)</span> Fraudulent transactions are
            being run with your Account
          </li>
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(l)</span> You are linked to terrorist
            related activities
          </li>
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(m)</span> Laws and regulations of your
            jurisdiction prohibits our Services{" "}
          </li>
          <li className="font-sans font-medium text-xs">
            {" "}
            <span className="font-bold">(n)</span> Unprofessional treatment of
            TTFCX’s services, tools, or team members{" "}
          </li>
        </ul>
        <ol start={30} className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Transactions once initiated are irreversible unless TTFCX at its
            sole discretion can suspend the transaction before a given period
            elapses. This however is not guaranteed.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            {" "}
            The User is obliged to treat confidentially the terms of their
            obligation with TTFCX, as well as all other information and data
            they acquire in connection with the Platform and not use it for
            purposes outside the scope of this Terms of Use or pass it on to
            third parties.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          LEGAL USE OF THE PLATFORM AND OF ITS COMPONENTS
        </h1>

        <ol start={32} className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Under no circumstances shall the Platform be used for any purpose
            different from the Services for which it was developed. TTFCX shall
            not be liable for any unauthorized access to a User’s Account or
            attempts to use and the results of using the Platform or Services
            for unauthorized purposes and in a manner not intended for by TTFCX.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            All Intellectual Property including Trademarks, Domains, Terms of
            Use, Logos, Images and any other material developed by TTFCX
            (graphics, pictures, text etc.), as well as any other algorithm,
            software, client list or information, content or underlying idea
            developed by TTFCX for the Platform, are owned by TTFCX and
            protected by copyright in their entirety and may not be modified,
            reproduced and redistributed, in whole or in part, without TTFCX’s
            express previous written permission. All necessary legal actions
            will be undertaken to ensure compliance to this policy.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Any action aimed to interfere with the normal functioning of the
            Platform including, but not limited to, bugging, registration of
            false User details, duplicate registrations, manipulation of
            Platform’s system, hacking, releasing or propagating viruses, worms
            or logic bombs or similar fraudulent activity is strictly prohibited
            and shall be pursued to the fullest extent permitted by law.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX reserves the right to restrict access to certain parts of the
            Platform and to certain Services offered, including, but not limited
            to Live Chat and Internal Messaging system.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX may at any time and without previous notice, remove or amend
            the design, interface, look and feel of the Platform or any of the
            Services offered on the platform, as well as alter available Service
            charges where such alterations do not affect Services already in
            progress.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Under some circumstances, TTFCX may provide the Parties with
            software designed internally or by third parties in order to
            optimize the functioning of the Platform or to benefit from
            additional Services. In such circumstances, the User may be asked to
            accept further Terms and Conditions related to the utilization of
            the new component. Any attempt to interfere with, modify or reverse
            engineer any software provided is strictly prohibited.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX is not liable for any network failures caused by the
            electronic equipment used by a User while accessing the Platform or
            for faults due to their Mobile Network Operator or Internet Service
            Provider while accessing the platform or any failures caused by
            third-parties’ actions that TTFCX has no control of.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            The section headings appearing in this Terms of Use are inserted
            only as a matter of convenience and in no way define, limit,
            construe or describe the scope or extent of such section or in any
            way affect such section.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX is always working to improve the Platform so there may be some
            downtime, technical issues, or interruptions on some features in; or
            aspects of the Platform. Contact customer care with the information
            provided on both the Platform and the website if you are affected
            and exercise some patience while you are instructed on the next
            steps to take.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            {" "}
            TTFCX shall not be deemed in default hereunder, nor shall it be
            responsible for, any cessation, interruption or delay in the
            performance of its obligations hereunder, due to earthquake, flood,
            fire, storm, natural disaster, pandemic, act of God, war, terrorism,
            armed conflict, national or international lockdown, labor strike,
            lockout, or boycott, provided that the User relying upon this clause
            shall receive written notice thereof promptly and, in any event,
            within five (5) days of discovery thereof, and shall take all steps
            reasonably necessary under the circumstances to mitigate the effects
            of the force majeure event upon which such notice is based;
            provided, however, that in the event a force majeure event described
            in this Section extends for a period in excess of thirty (30) days
            in the aggregate, TTFCX or the User may decide whether to terminate
            the contract.{" "}
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          SHARING TTFCX PLATFORM
        </h1>
        <ol start={42} className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Parties may be given the privilege of inviting an individual or
            business to the Platform for in-app bonus points or for a commission
            at the sole discretion of TTFCX, provided they make confidentiality
            a priority. Such sharing may not be done in such a way as to suggest
            any form of association, approval, sponsorship, or endorsement on
            our part without our express written consent.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Where the TTFCX Platform contains links to other websites and
            resources provided by our partners or other third-parties, these
            links are provided for information only. TTFCX does not make any
            representations regarding the accuracy and reliability of contents
            available on such third-party websites and accepts no responsibility
            for them or for any loss or damage that may arise from their
            utilization.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          PROMOTIONS & REWARDS
        </h1>
        <ol start={44} className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX may make available on the Platform bonus offers, promotions or
            reward programs. This includes viewing third-party content for
            rewards. More information will be communicated to you on the
            website, your email, and our social media pages.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Users’ eligibility for any offer or promotion and/or participation
            in the rewards program shall be subject to the terms and conditions
            for the respective bonus offer, promotion, or rewards program also
            available on the website, by email and TTFCX’s social media pages
            always.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX reserves the right to deny admission or to terminate admission
            to any of the bonuses, promotions and/or special offerings, as well
            as to modify, suspend or discontinue their validity at its sole
            discretion and without informing the User.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          COMPLAINTS AND SUGGESTIONS
        </h1>
        <ol start={47} className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            If the User wishes to make a complaint or a suggestion regarding
            TTFCX, the Platform or the Services offered, contact our Customer
            Centre section through your Account on the Platform or through the
            provided phone numbers or send us an email.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Complaints, claims, or suggestions regarding the acceptance,
            settlement, or resettlement of a TTFCX service must be made within
            48 hours from the expiry of such service.{" "}
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          WAIVER
        </h1>
        <ol start={49} className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            In no event shall any delay, failure or omission (in whole or in
            part) in enforcing, exercising or pursuing any right, power,
            privilege, claim or remedy conferred by or arising under these Terms
            of Use or by law, be deemed to be or construed as a waiver of that
            or any other right, power, privilege, claim or remedy in respect of
            the circumstances in question, or operate so as to bar the
            enforcement of that, or any other right, power, privilege, claim or
            remedy, in any other instance at any time or times subsequently.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          SEVERABILITY
        </h1>
        <ol start={50} className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            If any provision of these Terms of Use is found by any court or
            administrative or regulatory body of competent jurisdiction to be
            invalid or unenforceable, such invalidity or unenforceability shall
            not affect the other provisions of these Terms of Use, which shall
            remain in full force and effect.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            In such instances, the part declared invalid or unenforceable shall
            be amended in a manner consistent with the applicable law to
            reflect, as closely as possible, TTFCX's original intent.{" "}
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          INDEMNIFICATION{" "}
        </h1>
        <ol start={52} className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            You agree that TTFCX shall have no liability whatsoever for any use
            you make of the Platform. You agree to indemnify and hold harmless
            TTFCX from any and all claims, damages, liabilities, costs, and fees
            (including reasonable attorneys’ fees) arising from your use of the
            Platform or your breach of this Agreement.
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          NO WARRANTY/LIMITATION OF LIABILITY
        </h1>
        <ol start={53} className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            The Platform and all content, software, materials and other
            information provided by TTFCX or otherwise accessible to you in
            connection with this agreement or TTFCX’s services, are provided “as
            is” and TTFCX, and their respective affiliates make no warranty of
            any kind (and disclaim all warranties of any kind) with respect to
            the foregoing, whether express or implied, in each case to the
            maximum extent permitted under applicable law without limiting the
            foregoing, TTFCX makes no warranty but shall do its reasonable best
            to ensure that the Platform meets your requirements and is available
            on an uninterrupted, secure, or error-free basis. TTFCX makes no
            warranty but shall do its best to provide reasonable standard
            quality in the Services on the Platform. TTFCX or its partners shall
            not be liable to you or to any third-party under any tort, contract,
            negligence, strict liability or other legal or equitable theory for:{" "}
            <br /> <span className="font-bold">(a)</span> any lost profits,
            interruption of business, or other special, indirect, incidental or
            consequential damages of any kind arising out of the use or
            inability to use the Platform even if TTFCX has been advised of the
            possibility of such loss or damages and whether or not such loss or
            damages are foreseeable, or <br />{" "}
            <span className="font-bold">(b)</span> any damages relating to the
            website, tools, or TTFCX services, or your use thereof.{" "}
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          APPLICABLE LAW AND PLACE OF JURISDICTION
        </h1>
        <ol start={54} className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            The applicable provisions of the Laws of Nigeria shall govern any of
            TTFCX’s Services used by the User and any Regulations and Rules made
            in terms thereof, as amended from time to time. It shall be the
            responsibility of the User to ensure that they are aware of these
            provisions. The language used in any transaction related to this
            Terms of Use shall be English.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            You commit to us that by opening an Account on our Platform and
            using of our Services, you do not violate any laws applicable to
            you. You take responsibility for any consequences of your breach of
            this section.{" "}
          </li>
        </ol>
        <h1 className="text-md    text-black font-sans font-extrabold my-5">
          TERMINATION{" "}
        </h1>
        <ol start={56} className="flex flex-col gap-3">
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            TTFCX reserves the right to suspend or to permanently terminate, any
            of its Services to a User without providing any prior reason.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Any funds belonging to the User shall be promptly paid into the
            User’s bank account provided in the Platform from which it was paid,
            less any outstanding fees or charges owed to TTFCX.{" "}
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            This Terms of Use are valid immediately Parties sign up to create an
            Account on the Platform and remain valid indefinitely, until User
            closes their account or opt out and delete the Platform or is
            terminated by TTFCX.
          </li>
          <li className="list-outside list-decimal md:px-6 font-sans font-medium text-xs">
            Typos, mistakes, and transmission errors are excluded from this, as
            long as they are not caused with intent or by gross negligence.{" "}
          </li>
        </ol>
      </div>
      <p className="font-sans font-medium text-xs px-5 mb-11">
        TTFCX is currently available on the web; and at a date which will be
        communicated to you, will be available for download on Google Play store
        for Android device users and the App Store for Apple iOS device users.{" "}
      </p>
    </div>
  );
};

export default OnboardingTermsOfUse;
