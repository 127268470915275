import { AxiosResponse } from "axios";
import useQueryAction from "../../../lib/useQueryAction";
import useQueryMutation from "../../../lib/useQueryMutation";
import { api, makeGetRequest, makePostRequest } from "../../api";

export const useRates = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.tags.ratesUsd),
    queryKey: ["rates", "USD"],
  });
};

export const useRatesEur = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.tags.ratesEur),
    queryKey: ["rates", "EUR"],
  });
};

export const useGbpRates = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.tags.gbpRates),
    queryKey: ["rates", "GBP"],
  });
};
export const useUsdcRates = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.tags.usdcRates),
    queryKey: ["rates", "USDC"],
  });
};
export const useUsdtRates = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.tags.usdtRates),
    queryKey: ["rates", "USDT"],
  });
};

export const useGetCountry = ()=>{
    return useQueryAction({
        queryFn: ()=> makeGetRequest(api.tags.countries),
        queryKey:['getCountry']
    })
}
export const useGetState = ()=>{
    return useQueryAction({
      queryFn: () => makeGetRequest(api.tags.newStates),
      queryKey: ["newStates"],
    });
}

export const useStates = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.tags.states),
    queryKey: ["states"],
  });
};
