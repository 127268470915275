import React from 'react'
import illustration from "../../assets/misc/no-trans-illustrations.svg";

const DashBoardEmptyState = () => {
  return (
    <div><div className="div flex flex-col items-center justify-center pt-10">
    <div className="w-40">
      <img src={illustration} alt="" />
    </div>
    <h3 className=" text-lg md:text-2xl font-extrabold text-[#071827]">
      You haven't initiated any transactions
    </h3>
    <p className="text-center text-[#5C6F7F]">
      You have no recent transactions
      <br />
      Fund your wallet to begin
    </p>
  </div></div>
  )
}

export default DashBoardEmptyState