import React, { useState } from "react";
import Logo from "../../assets/misc/logo-on-light.svg";
import AppCheckBox from "../../components/widgets/AppCheckBox";
import { useNavigate } from "react-router-dom";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { useCompleteOnboarding } from "../../sevices/hooks/users";

const BusinessSummary = ({
  setIsSummary,
  userDetail,
}: {
  setIsSummary: React.Dispatch<React.SetStateAction<boolean>>;
  userDetail: any;
}) => {
  const [check, setCheck] = useState(true);
  const [activeAccordion, setActiveAccordion] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const complete = useCompleteOnboarding();

  // console.log(userDetail);

  const toggleAccordion = (accordionId: React.SetStateAction<string>) => {
    setActiveAccordion(accordionId === activeAccordion ? "" : accordionId);
  };

  const onboardingComplete = () => {
    setLoading(true);
    complete
      .mutateAsync({
        //@ts-ignore
        email: userDetail?.email,
      })
      .then((res) => {
        setLoading(false);
        navigate("/business_success");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className="bg-[#ECF6F3] w-full h-full px-5 lg:px-20 py-10">
      <div className=" bg-white flex flex-col gap-3   md:w-full  ">
        <div className="py-10 ">
          <div className="logo pl-4 lg:pl-9 ">
            <img src={Logo} alt="" className=" w-36" />
          </div>
          <div className="logo pl-4 lg:pl-9 mt-10  ">
            <h1 className="text-[30px] leading-[42px] font-medium text-[#071827]">
              Business Onboarding
            </h1>
            <h3 className="text-[#5C6F7F] text-[18px] leading-[26px] font-normal mt-2">
              Please check that all information is accurate before you submit
              your Onboarding Request.
            </h3>
          </div>
          <div className="logo px-4 lg:px-9 mt-8">
            <div className="  bg-[#EAEAEA4D] p-4">
              <h1 className="text-[18px] leading-[25px] font-medium text-[#071827]">
                Application Information
              </h1>
            </div>
          </div>
          <div className="logo px-4 lg:px-9 mt-5">
            <div className=" flex items-center justify-between bg-[#fff] py-4 lg:p-4">
              <h1 className="text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] font-semibold text-[#071827]">
                Business Details
              </h1>
              {/* <h1 className="text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] cursor-pointer  font-bold text-[#0EB683]">
                Click here to edit this section
              </h1> */}
            </div>
            <div className=" flex flex-col gap-4  bg-[#fff] px-0 lg:px-4">
              {/* <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                Business Name:
              </h1> */}
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Business Name:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {userDetail?.businessName || "--"}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Business Nature:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {userDetail?.businessNature || "--"}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Registration Number:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {userDetail?.incorNum || "--"}
                  </h1>
                </div>
              </div>
              {/* <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Registration Documents:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >

                    {userDetail?.businessName || "--"}
                  </h1>
                </div>
              </div> */}
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Business Address:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {userDetail?.businessAddress || "--"}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Business Phone Number:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {userDetail?.businessPhone || "--"}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Business Email:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {userDetail?.businessEmail || "--"}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Website :
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {userDetail?.website || "--"}
                  </h1>
                </div>
              </div>
            </div>
            <div className=" my-4 flex items-center justify-between bg-[#fff] p-4">
              <h1 className="text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] font-semibold text-[#071827]">
                Ultimate Business Owners
              </h1>
              {/* <h1 className="text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] cursor-pointer  font-bold text-[#0EB683]">
                Click here to edit this section
              </h1> */}
            </div>
            <div className=" flex flex-col gap-4  bg-[#fff] px-4">
              <>
                <div className=" rounded-lg overflow-hidden">
                  <div
                    className={`flex items-center accordion-title text-[#0EB683]  py-2  cursor-pointer hover:text-[#366b45] ${
                      activeAccordion === "accordion1" && "bg-gray-100"
                    }`}
                    onClick={() => toggleAccordion("accordion1")}
                  >
                    <span className=" inline-flex items-center">
                      {activeAccordion === "accordion1" ? (
                        <MdOutlineKeyboardArrowUp size={24} />
                      ) : (
                        <MdOutlineKeyboardArrowDown size={24} />
                      )}
                    </span>
                    <span className="font-semibold "> Business Owner 1</span>
                  </div>
                  {activeAccordion === "accordion1" && (
                    <div className="accordion-content  py-2 flex flex-col gap-4 border rounded-md ">
                      {/* <div className="flex items-center gap-4 ">
                        <div className="w-[40%] ">
                          <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                            Type:
                          </h1>
                        </div>
                        <div className="w-full ">
                          <h1
                            className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                            style={{ textAlign: "left" }}
                          >

                            {userDetail?.businessName || "--"}
                          </h1>
                        </div>
                      </div> */}
                      <div className="flex items-center gap-4 ">
                        <div className="w-[40%] ">
                          <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                            Full Name:
                          </h1>
                        </div>
                        <div className="w-full ">
                          <h1
                            className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                            style={{ textAlign: "left" }}
                          >
                            {/* @ts-ignore */}
                            {userDetail?.fullName || "--"}
                          </h1>
                        </div>
                      </div>

                      <div className="flex items-center gap-4 ">
                        <div className="w-[40%] ">
                          <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                            Email Address:
                          </h1>
                        </div>
                        <div className="w-full ">
                          <h1
                            className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                            style={{ textAlign: "left" }}
                          >
                            {/* @ts-ignore */}
                            {userDetail?.email || "--"}
                          </h1>
                        </div>
                      </div>
                      <div className="flex items-center gap-4 ">
                        <div className="w-[40%] ">
                          <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                            Phone Number:
                          </h1>
                        </div>
                        <div className="w-full ">
                          <h1
                            className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                            style={{ textAlign: "left" }}
                          >
                            {/* @ts-ignore */}
                            {userDetail?.phone || "--"}
                          </h1>
                        </div>
                      </div>
                      <div className="flex items-center gap-4 ">
                        <div className="w-[40%] ">
                          <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                            Address Line 1:
                          </h1>
                        </div>
                        <div className="w-full ">
                          <h1
                            className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                            style={{ textAlign: "left" }}
                          >
                            {/* @ts-ignore */}
                            {userDetail?.address2 || "--"}
                          </h1>
                        </div>
                      </div>
                      <div className="flex items-center gap-4 ">
                        <div className="w-[40%] ">
                          <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                            Address Line 2:
                          </h1>
                        </div>
                        <div className="w-full ">
                          <h1
                            className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                            style={{ textAlign: "left" }}
                          >
                            {/* @ts-ignore */}
                            {userDetail?.address2 || "--"}
                          </h1>
                        </div>
                      </div>
                      <div className="flex items-center gap-4 ">
                        <div className="w-[40%] ">
                          <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                            State:
                          </h1>
                        </div>
                        <div className="w-full ">
                          <h1
                            className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                            style={{ textAlign: "left" }}
                          >
                            {/* @ts-ignore */}
                            {userDetail?.state || "--"}
                          </h1>
                        </div>
                      </div>
                      <div className="flex items-center gap-4 ">
                        <div className="w-[40%] ">
                          <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                            Country :
                          </h1>
                        </div>
                        <div className="w-full ">
                          <h1
                            className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                            style={{ textAlign: "left" }}
                          >
                            {/* @ts-ignore */}
                            {userDetail?.country || "--"}
                          </h1>
                        </div>
                      </div>
                      <div className="flex items-center gap-4 ">
                        <div className="w-[40%] ">
                          <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                            Post Code:
                          </h1>
                        </div>
                        <div className="w-full ">
                          <h1
                            className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                            style={{ textAlign: "left" }}
                          >
                            {/* @ts-ignore */}
                            {userDetail?.postCode || "--"}
                          </h1>
                        </div>
                      </div>
                      <div className="flex items-center gap-4 ">
                        <div className="w-[40%] ">
                          <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                            % Shareholding:
                          </h1>
                        </div>
                        <div className="w-full ">
                          <h1
                            className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                            style={{ textAlign: "left" }}
                          >
                            {/* @ts-ignore */}
                            {userDetail?.percentage || "--"}
                          </h1>
                        </div>
                      </div>
                      {/* <div className="flex items-center gap-4 ">
                        <div className="w-[40%] ">
                          <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                            Means of Identification:
                          </h1>
                        </div>
                        <div className="w-full ">
                          <h1
                            className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                            style={{ textAlign: "left" }}
                          >

                            {userDetail?.businessName || "--"}
                          </h1>
                        </div> */}
                      {/* </div> */}
                    </div>
                  )}
                </div>
              </>
            </div>
            <div className=" flex items-center justify-between bg-[#fff] p-4">
              <h1 className="text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] font-semibold text-[#071827]">
                Contact Person
              </h1>
              {/* <h1 className="text-[16px] cursor-pointer leading-[25px] font-bold text-[#0EB683]">
                Click here to edit this section
              </h1> */}
            </div>
            <div className=" flex flex-col gap-4  bg-[#fff] px-4">
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Means of Identification:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {userDetail?.idType || "--"}
                  </h1>
                </div>
              </div>
              {/* <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Means of Identification:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >

                    {userDetail?.businessName || "--"}
                  </h1>
                </div>
              </div> */}
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    First Name:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {userDetail?.firstName || "--"}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Last Name:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {userDetail?.lastName || "--"}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Phone Number:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {userDetail?.phone || "--"}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    BVN:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {userDetail?.bvn || "--"}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Email Address:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {userDetail?.email || "--"}
                  </h1>
                </div>
              </div>
              {/* <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Means of identification as business employee:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >

                    {userDetail?.businessName || "--"}
                  </h1>
                </div>
              </div> */}
            </div>
            <div className=" my-4 flex items-center justify-between bg-[#fff] p-4">
              <h1 className="text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] font-semibold text-[#071827]">
                Bank Details
              </h1>
              {/* <h1 className="text-[16px] cursor-pointer leading-[25px] font-bold text-[#0EB683]">
                Click here to edit this section
              </h1> */}
            </div>
            <div className=" flex flex-col gap-4  bg-[#fff] px-4">
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Bank:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {userDetail?.bank || "--"}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Account Number:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {userDetail?.accountNumber || "--"}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Account Name:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {userDetail?.accountName || "--"}
                  </h1>
                </div>
              </div>
            </div>
            <div className="logo  mt-8">
              <div className="  bg-[#EAEAEA4D] p-4">
                <h1 className="text-[18px] leading-[25px] font-medium text-[#071827]">
                  Legal Agreements
                </h1>
              </div>
            </div>
          </div>

          <div className="flex  md:items-center xxs:gap-1  md:gap-3 mt-5 md:ml-1 pl-10">
            <AppCheckBox
              getChecked={(value: boolean) => {
                setCheck(value);
              }}
            />
            <small className=" xxs:text-left  md:text-sm ">
              I agree to the website{" "}
              <button
                onClick={() =>
                  navigate("/onboarding/Terms-Of-Use", { replace: true })
                }
                className="text-[#0eb683] transition-all active:scale-90"
              >
                terms of use
              </button>{" "}
              and{" "}
              <button
                onClick={() =>
                  navigate("/onboarding/terms-Of-Service", { replace: true })
                }
                className="text-[#0eb683] transition-all active:scale-90"
              >
                terms of service
              </button>{" "}
            </small>
          </div>
          <div className="flex justify-between px-10 mt-6">
            <button
              onClick={() => setIsSummary(false)}
              className="bg-[rgb(255, 255, 255)] hover:[#def8f0] text-[#0eb683] shadow-sm font-normal py-3 px-8 rounded-lg text-[16px] border border-[#0eb683] leading-[24px]"
            >
              Back
            </button>
            <button
              disabled={!check}
              onClick={onboardingComplete}
              className="bg-[#0eb683] hover:bg-[##11ffbb text-white font-normal shadow-sm py-3 px-12 rounded-lg text-[16px] border leading-[24px] disabled:bg-opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? (
                <svg
                  className="animate-spin h-5 w-5 mr-3 text-white"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    fill="white"
                  />
                  <path
                    d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"
                    fill="green"
                  />
                </svg>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessSummary;
