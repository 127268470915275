import React from "react";
import { motion } from "framer-motion";
import { IoMdClose } from "react-icons/io";
import OTPInput from "../widgets/OTPInput";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import ReactLoading from "react-loading";
import { IOtp } from "../../types/Otp";

const OtpModal: React.FC<IOtp> = ({
  loading,
  setVerification,
  verify,
  setOtp,
  withdraw,
}) => {
  return (
    <div>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            // delay: 0.3,
            duration: 0.1,
          },
        }}
        className="confirmation_modal backdrop-blur-sm bg-[#13111137] fixed w-full h-full top-0 left-0 justify-center md:justify-end items-center z-50"
      >
        <motion.div
          initial={{
            x: 600,
            scale: 0,
          }}
          animate={{
            x: 0,
            scale: 1,
            transition: {
              duration: 0.3,
            },
          }}
          exit={{
            x: 600,
            scale: 0,
            transition: {
              delay: 0.3,
            },
          }}
          className="flex h-screen items-end md:items-center  md:justify-center "
        >
          <motion.div
            initial={{
              x: 600,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.3,
              },
            }}
            exit={{
              x: 100,
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            className="card flex flex-col bg-white h-[350px] w-full max-w-md md:max-w-md md:max-h-full rounded-t-3xl md:rounded-3xl p-10 z-50"
          >
            <div className="flex flex-col gap-3 my-auto relative">
              <button
                onClick={() => {
                    setVerification(false);
                //   setAccountName("");
                //   setBank({});
                //   setAccountNumber("");
                //   setAmountValue("");
                //   setReferenceValue("");
                }}
                className="text-right hover:bg-red-200 hover:text-red-600 rounded-full hover:rounded-full flex items-end justify-end md:text-slate-600 absolute xxs:top-[-45px] xxs:right-[-30px] md:bg-transparent xxs:bg-red-200 xxs:text-red-600 md:top-[-61px] md:right-[-33px] p-2 hover:rotate-90 hover:transform  transition duration-150 ease-in-out  "
              >
                <IoMdClose size={28} />
              </button>

              <div className=" ">
                <h1 className="text-3xl text-[#0eb683] font-extrabold self-center text-center ">
                  Verification
                </h1>
              </div>
              <div className=" mt-1 md:mt-0">
                <p className="text-[#5C6F7F] text-sm text-center">
                  Enter the 5-digit code sent to your email
                </p>
                <OTPInput
                  autoFocus
                  length={5}
                  className="otpContainer flex justify-between  w-full py-3 mt-4 md:mt-3 "
                  inputClassName="otpInput w-12 h-12 md:w-[50px] md:h-[50px] bg-white border border-[#0EB683] outline-[#0EB683] text-[#0EB683] text-2xl text-center font-bold rounded-lg"
                  autoCapitalize={true}
                  onChangeOTP={(OTP) => {
                    setOtp(OTP.toUpperCase());
                  }}
                  onPaste={(OTP) => {
                    setOtp(OTP.toUpperCase());
                  }}
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  onClick={() => {
                    setVerification(false);
                    // setConfirmationModal(true);
                  }}
                  className="text-[#0eb683] active:scale-90 border border-[#0eb683] md:p-3 rounded-md transition-all md:w-40 xxs:w-32 xxs:p-[17px] flex items-center justify-center  "
                >
                  {" "}
                  <span>
                    <MdOutlineKeyboardBackspace />
                  </span>{" "}
                  <span className="w-14">Back</span>{" "}
                </button>
                <button
                  // disabled={}
                  onClick={withdraw}
                  className="bg-[#0eb683]  self-center justify-center md:w-48 border border-[#0eb683]  text-white md:p-3 rounded-md transition-all active:scale-90  md:mt-0 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90 xxs:w-40 xxs:p-[17px] "
                >
                  {loading ? (
                    <div className="xxs:ml-14 md:ml-[68px]">
                      <ReactLoading
                        type="spinningBubbles"
                        color="#fff"
                        height="27px"
                        width="27px"
                      />
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default OtpModal;
