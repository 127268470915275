import React from "react";
import designLogo from "../../assets/misc/design-top.svg";
import designLogo2 from "../../assets/misc/design-bottom.svg";
import img from "../../assets/misc/Illustrations.svg";
import { useNavigate } from "react-router-dom";

const PasswordReset = () => {
  const navigate = useNavigate()
  return (
    <div>
      <img src={designLogo} alt="" className=" absolute w-28 md:w-44" />
      <img
        src={designLogo2}
        alt=""
        className=" absolute w-28 md:w-44 right-0 bottom-0"
      />
      <div className="bg-[#0C0D1F] h-screen flex items-center justify-center gap-5 flex-col ">
        <img src={img} alt="" className=" w-48 font-bold size={" />
        <h1 className = "text-white text-xl md:text-2xl font-bold ">Password reset</h1>
        <p className = "text-white ">Now you can log in using your new password</p>
        <button
        onClick={()=>{
          navigate("/login", { replace: true })
        }}
         className="bg-[#0eb683] py-2 px-32 rounded-md text-white">
          Login
        </button>
      </div>
    </div>
  );
};

export default PasswordReset;
