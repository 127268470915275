import React from "react";
import brandLogo from "../../assets/misc/design-top.svg";
import brandLogo2 from "../../assets/misc/design-bottom.svg";
import logo from "../../assets/misc/logo-on-dark.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSignUpVerifyMutation } from "../../sevices/hooks/users";


const SignUpVerify = () => {
    const location = useLocation()

    let params = new URLSearchParams(location.search);
    const code = params.get("code")
    const navigate = useNavigate()
    const signUpVerify  = useSignUpVerifyMutation()
   
    
    const proceedToLogin = (e: any) =>{
        signUpVerify
    //   @ts-ignore
        .mutateAsync({verificationCode: code})
        .then((res)=>{
            
            
            navigate("/login", { replace: true });
        })
        .catch((e)=>{
           
            
        })
    }

  return (
    <div>
    <img src={brandLogo} alt="" className="absolute w-28  md:w-44 " />
    <img
      src={brandLogo2}
      alt=""
      className="absolute w-28 md:w-44 right-0 bottom-0"
    />
    <div className=" flex items-center py-5 flex-col gap-5 bg-[#0C0D1F] h-screen justify-center">
      <img src={logo} alt="" className="w-60 inline" />

      <h1 className="text-white font-bold text-xl py-5 md:text-2xl">
       Email Verification Successful!
      </h1>
      <p className="text-center text-white md:text-xl">
      Your email has been verified successfully
        <br />
        Click on the button below to login to your account
      </p>

      <button className="bg-[#0eb683] transition-all active:scale-90 px-32 py-3 rounded-md text-white"
      onClick={proceedToLogin}
      >
       Proceed to login
      </button>
    </div>
  </div>
  )
}

export default SignUpVerify