import React, { useState } from "react";
import UserDetails from "./UserDetails";
import BankDetails from "./BankDetails";
import Password from "./Password";
import StepperSideBar from "./StepperSideBar";
import StepperMain from "./StepperMain";
import { useLocation } from "react-router-dom";
import Summary from "./Summary";
import BusinessOwners from "./BusinessOwners";
import BusinessDetails from "./BusinessDetails";
import { useUsers } from "../../../sevices/hooks/users";
import { useUserContext } from "../../../context/UserContext";
import BusinessSummary from "./BusinessSummary";

const private_steps = [
  {
    id: 1,
    description: "Select your account type",
    step: "Account Type",
  },
  {
    id: 2,
    description: "Complete basic details about yourself",
    step: "User Details",
  },
  {
    id: 3,
    description: "Complete basic details about your bank information",
    step: "Bank Details",
  },
  {
    id: 4,
    description: "Secure your account from unauthorized access and fraud",
    step: "Create Password",
  },
];

const business_steps = [
  {
    id: 1,
    description: "Select your account type",
    step: "Account Type",
  },
  {
    id: 2,
    description: "Complete basic details about the account manager",
    step: "Business Representatives",
  },
  {
    id: 3,
    description: "Complete basic details about your bank information",
    step: "Bank Details",
  },
  {
    id: 4,
    description: "Secure your account from unauthorized access and fraud",
    step: "Create Password",
  },
  {
    id: 5,
    description: "Complete basic details about your business",
    step: "Business Details",
  },
  {
    id: 6,
    description: "Complete basic details about business owners",
    step: "Ultimate Business Owners",
  },
];

const StepLayout = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const at = queryParams.get("at")
  const { setUser } = useUserContext();
  const [currentStep, setCurrentStep] = useState(1);
  const [errmsg, setErrMsg] = useState("");
  const [steps, setSteps] = useState<
    { id: number; description: string; step: string }[]
  >([]);
  const getUserDetails = useUsers(
    //@ts-ignore
    localStorage.getItem("user_id"),
    localStorage.getItem("accessToken")
  );

  React.useEffect(() => {
    const dx = at === "private" ? private_steps : business_steps;
    setSteps(dx);
  }, [at]);
  React.useEffect(() => {
    setUser(getUserDetails.result?.data);
  }, [getUserDetails.result]);

  const handleClick = (direction: any) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  const display_private_user_steps = (step: number) => {
    switch (step) {
      case 1:
        return (
          <UserDetails
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps && steps}
            setErrMsg={setErrMsg}
          />
        );
      case 2:
        return (
          <BankDetails
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps && steps}
            setErrMsg={setErrMsg}
          />
        );
      case 3:
        return (
          <Password
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps && steps}
            setErrMsg={setErrMsg}
          />
        );
      default:
        return (
          <Summary
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps && steps}
            setErrMsg={setErrMsg}
          />
        );
    }
  };

  const display_business_users_steps = (step: number) => {
    switch (step) {
      case 1:
        return (
          <UserDetails
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps && steps}
               setErrMsg={setErrMsg}
          />
        );
      case 2:
        return (
          <BankDetails
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps && steps}
               setErrMsg={setErrMsg}
          />
        );
      case 3:
        return (
          <Password
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps && steps}
               setErrMsg={setErrMsg}
          />
        );
      case 4:
        return (
          <BusinessDetails
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps && steps}
               setErrMsg={setErrMsg}
          />
        );
      case 5:
        return (
          <BusinessOwners
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps && steps}
               setErrMsg={setErrMsg}
          />
        );
      default:
        return (
          <BusinessSummary/>
        )
    }
  };

  return (
    <div className=" grid grid-rows-[auto_1fr] md:grid-rows-1 md:grid-cols-[auto_1fr] min-h-screen h-auto md:h-screen md:min-h-0">
      <StepperSideBar currentStep={currentStep} step={steps} at={at} />
      <StepperMain
        err={errmsg}
        // setErrMsg={setErrMsg}
        formSteps={
          at === "private"
            ? display_private_user_steps
            : display_business_users_steps
        }
        currentStep={currentStep}
      />
    </div>
  );
};

export default StepLayout;
