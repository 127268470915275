import React, { useState } from "react";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import Logo from "../../assets/misc/logo-on-light.svg";
import AppCheckBox from "../../components/widgets/AppCheckBox";
import { useNavigate } from "react-router-dom";
import { BsCheck2, BsX } from "react-icons/bs";

const BusinessOnboardingPreview = () => {
  const [isSummary, setIsSummary] = useState(true);
  const [check, setCheck] = useState(true);
  const [activeAccordion, setActiveAccordion] = useState("");
  const navigate = useNavigate();
  const toggleAccordion = (accordionId: React.SetStateAction<string>) => {
    setActiveAccordion(accordionId === activeAccordion ? "" : accordionId);
  };
  return (
    <div>
      {" "}
      <div className="bg-[#ECF6F3] w-full h-full px-5 flex items-center justify-center py-20">
        <div className=" bg-white flex flex-col gap-3 max-w-[80%]  md:w-full  ">
          <div className="py-10 ">
            <div className="logo pl-4 lg:pl-9 hidden md:block">
              <img src={Logo} alt="" className=" w-36" />
            </div>
            <div className="logo pl-4 lg:pl-9 mt-10  hidden md:block">
              <h1 className="text-[30px] leading-[42px] font-semibold text-[#071827]">
                Business Onboarding
              </h1>
              <h3 className="text-[#5C6F7F] text-[18px] leading-[26px] font-normal mt-2">
                This is a sample of your submission. Kindly update the
                highlighted sections with appropriate information.
              </h3>
            </div>
            <div className="logo px-4 lg:px-9 mt-8">
              <div className=" hidden md:block bg-[#EAEAEA4D] p-4">
                <h1 className="text-[18px] leading-[25px] font-semibold text-[#071827]">
                  Application Information
                </h1>
              </div>
            </div>
            <div className="logo px-4 lg:px-9 mt-5">
              <div className=" hidden md:flex items-center justify-between bg-[#fff] p-4">
                <h1 className="text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] font-semibold text-[#071827]">
                  Business Representative
                </h1>
                <h1 className="text-[16px] cursor-pointer leading-[25px] font-bold text-[#0EB683]">
                  Click here to edit this section
                </h1>
                <Notify status={"completed"} />
              </div>
              <div className=" hidden md:flex flex-col gap-4  bg-[#fff] px-4">
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  First Name:
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  Last Name:
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  Date of Birth:
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  Phone Number:
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  BVN:
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  Email Address:
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  ID Type:
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  Means of Identification:
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  Means of identification as business employee:
                </h1>
              </div>
              <div className=" my-4 hidden md:flex items-center justify-between bg-[#fff] p-4">
                <h1 className="text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] font-semibold text-[#071827]">
                  Bank Details
                </h1>
                <h1 className="text-[16px] cursor-pointer leading-[25px] font-bold text-[#0EB683]">
                  Click here to edit this section
                </h1>
                <Notify status={"completed"} />
              </div>
              <div className=" hidden md:flex flex-col gap-4  bg-[#fff] px-4">
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  Bank:
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  Account Number:
                </h1>
              </div>
              <div className=" hidden md:flex items-center justify-between bg-[#fff] py-4 lg:p-4">
                <h1 className="text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] font-semibold text-[#071827]">
                  Business Details
                </h1>
                <h1 className="text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] cursor-pointer  font-bold text-[#0EB683]">
                  Click here to edit this section
                </h1>
                <Notify status={"rejected"} />
              </div>
              <div className=" hidden md:flex flex-col gap-4  bg-[#fff] px-0 lg:px-4">
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  Business Name:
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  Business Nature:
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  Registration Number:
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  Country of Registration:
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  Registration Documents:
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  Business Address:
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  Business Phone Number:
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  Business Email:
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                  Website :
                </h1>
                {/* <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                    Post Code :
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                    Country :
                </h1>
                <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                    Means of Identification :
                </h1> */}
              </div>
              <div className=" my-4 hidden md:flex items-center justify-between bg-[#fff] p-4">
                <h1 className="text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] font-semibold text-[#071827]">
                  Ultimate Business Owners
                </h1>
                <h1 className="text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] cursor-pointer  font-bold text-[#0EB683]">
                  Click here to edit this section
                </h1>
                <Notify status={"rejected"} />
              </div>
              <div className=" hidden md:flex flex-col gap-4  bg-[#fff] px-4">
                <>
                  <div className=" rounded-lg overflow-hidden">
                    <div
                      className={`flex items-center accordion-title text-[#0EB683]  px-4 py-2  cursor-pointer hover:text-[#366b45] ${
                        activeAccordion === "accordion1" && "bg-gray-100"
                      }`}
                      onClick={() => toggleAccordion("accordion1")}
                    >
                      <span className=" inline-flex items-center">
                        {activeAccordion === "accordion1" ? (
                          <MdOutlineKeyboardArrowUp size={24} />
                        ) : (
                          <MdOutlineKeyboardArrowDown size={24} />
                        )}
                      </span>
                      <span className="font-semibold "> Business Owner 1</span>
                    </div>
                    {activeAccordion === "accordion1" && (
                      <div className="accordion-content px-4 py-2 flex flex-col gap-4 border rounded-md ">
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Type:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          First Name:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Last Name:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Email Address:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Phone Number:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Address Line 1:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Address Line 2:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          State:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Country :
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Post Code:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          % Shareholding:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Means of Identification:
                        </h1>
                      </div>
                    )}
                  </div>

                  <div className=" rounded-lg mt-4 overflow-hidden">
                    <div
                      className={`flex items-center accordion-title text-[#0EB683]  px-4 py-2  cursor-pointer hover:text-[#366b45] ${
                        activeAccordion === "accordion1" && "bg-gray-100"
                      }`}
                      onClick={() => toggleAccordion("accordion2")}
                    >
                      <span className="">
                        {activeAccordion === "accordion2" ? (
                          <MdOutlineKeyboardArrowUp size={24} />
                        ) : (
                          <MdOutlineKeyboardArrowDown size={24} />
                        )}
                      </span>
                      <span className="font-semibold"> Business Owner 2</span>
                    </div>
                    {activeAccordion === "accordion2" && (
                      <div className="accordion-content px-4 py-2 flex flex-col gap-4 border rounded-md ">
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Type:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          First Name:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Last Name:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Email Address:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Phone Number:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Address Line 1:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Address Line 2:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          State:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Country :
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Post Code:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          % Shareholding:
                        </h1>
                        <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F]">
                          Means of Identification:
                        </h1>
                      </div>
                    )}
                  </div>
                </>
              </div>

              {/* <div className="logo  mt-8">
                <div className=" hidden md:block bg-[#EAEAEA4D] p-4">
                  <h1 className="text-[18px] leading-[25px] font-medium text-[#071827]">
                    Legal Agreements
                  </h1>
                </div>
              </div> */}
            </div>

            {/* <div className="flex  md:items-center xxs:gap-1  md:gap-3 mt-5 md:ml-1 pl-10">
              <AppCheckBox
                getChecked={(value: boolean) => {
                  setCheck(value);
                }}
              />
              <small className=" xxs:text-left  md:text-sm ">
                I agree to the website{" "}
                <button
                  onClick={() =>
                    navigate("/onboarding/Terms-Of-Use", { replace: true })
                  }
                  className="text-[#0eb683] transition-all active:scale-90"
                >
                  terms of use
                </button>{" "}
                and{" "}
                <button
                  onClick={() =>
                    navigate("/onboarding/terms-Of-Service", { replace: true })
                  }
                  className="text-[#0eb683] transition-all active:scale-90"
                >
                  terms of service
                </button>{" "}
              </small>
            </div>
            <div className="flex justify-between px-10 mt-6">
              <button
                onClick={() => setIsSummary(false)}
                className="bg-[rgb(255, 255, 255)] hover:[#def8f0] text-[#0eb683] shadow-sm font-normal py-3 px-8 rounded-lg text-[16px] border border-[#0eb683] leading-[24px]"
              >
                Back
              </button>
              <button className="bg-[#0eb683] hover:bg-[##11ffbb text-white font-normal shadow-sm py-3 px-12 rounded-lg text-[16px] border leading-[24px]">
                Submit
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessOnboardingPreview;

const Notify = ({ status }: { status: string }) => {
  return (
    <div
      className={`text-[12px] leading-[18px] font-normal px-2 py-1 rounded-[24px] flex items-center gap-2 ${
        status === "rejected"
          ? "bg-[#FEE2E2] text-[#E01A00]"
          : "bg-[#E2FEF0] text-[#0eb683]  "
      }`}
    >
      <span>
        {status === "rejected" ? <BsX size={14} /> : <BsCheck2 size={14} />}
      </span>
      {status === "rejected"
        ? "KYC Verification Rejected"
        : "KYC Verification Complete"}
    </div>
  );
};
