import React from 'react'
import ShareIcon from '../../assets/iconsComponents/ShareIcon'
import { TabSelector } from '../widgets/TabSelector';
import { TabPanel, useTabs } from '../widgets/TabWidget';

const AddStableCoinComponent = () => {
    const [coinTab, setCoinTab] = useTabs(["USDC", "USDT"]);

  return (
    <div>
            <nav className="flex gap-32">
              <TabSelector
                className={`cursor-pointer relative bg-transparent ${
                  coinTab === "USDC"
                    ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                    : ""
                }`}
                isActive={coinTab === "USDC"}
                onClick={() => setCoinTab("USDC")}
              >
                <div className="flex items-center gap-2">
                  <div className=" w-7 h-7 rounded-full bg-white border border-[#0eb683] flex items-center "></div>
                  <h1 className="text-[#5C6F7F] font-medium xxs:text-sm md:text-base">USDC</h1>
                </div>
              </TabSelector>
              <TabSelector
                className={`cursor-pointer relative bg-transparent ${
                  coinTab === "USDT"
                    ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                    : ""
                }`}
                isActive={coinTab === "USDT"}
                onClick={() => setCoinTab("USDT")}
              >
                <div className="flex gap-2 items-center ">
                  <div className=" w-7 h-7 rounded-full bg-white border border-[#0eb683] "></div>
                  <h1 className="text-[#5C6F7F] font-medium xxs:text-sm md:text-base">USDT</h1>
                </div>
              </TabSelector>
            </nav>
            <main>
              <TabPanel hidden={coinTab !== "USDC"} className=" mt-8 ">
                <div className="card mt-8 md:bg-white md:max-w-lg md:flex  md:flex-col md:pl-8 md:pb-8 md:mt-12 md:pt-8 rounded-xl">
                  <header className="flex items-center justify-between mb-6 ">
                    <h1 className="text-[#071827] font-bold xxs:text-base md:text-lg">
                      Beneficiary Information
                    </h1>
                    <div className="text-[#0eb683] hidden md:flex md:justify-center md:gap-1 md:border-[#0eb683] md:p-2 font-medium md:mr-8 md:border rounded-md cursor-pointer transition-all active:scale-90">
                      <ShareIcon height="17px" width="17px" />
                      <p>Share Form</p>{" "}
                    </div>
                    <div className="bg-[#0eb683]  md:hidden border border-[#0eb683] p-2 font-medium text-[#fff] cursor-pointer transition-all active:scale-90 rounded-md shadow-md">
                      <p>Share Form</p>{" "}
                    </div>
                  </header>
                  <main className="flex flex-col gap-4">
                    <div className="mid relative">
                      <input
                        type="text"
                        name=""
                        className={`py-3 px-4 w-full bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:xxs:text-sm placeholder:md:text-base `}
                        placeholder="Beneficiary USDC Address"
                        required
                      />
                      <span className="text-red-500 absolute left-2 bottom-4">
                        *
                      </span>
                    </div>
                    <div className="mid relative">
                      <input
                        type="text"
                        name=""
                        className={`py-3 px-4 w-full bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:xxs:text-sm placeholder:md:text-base `}
                        placeholder="Beneficiary Name"
                        required
                      />
                      <span className="text-red-500 absolute left-2 bottom-4">
                        *
                      </span>
                    </div>
                    <div className="mid relative">
                      <input
                        type="text"
                        name=""
                        className={`py-3 px-4 w-full bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:xxs:text-sm placeholder:md:text-base`}
                        placeholder="Alias"
                        required
                      />
                      <span className="text-red-500 absolute left-2 bottom-4">
                        *
                      </span>
                    </div>
                    {/* <button className="bg-[#0eb683] text-white p-3 mt-10 max-w-md rounded-md transition-all active:scale-90">
                      Save Beneficiary
                    </button> */}
                  </main>
                </div>
                <button className="bg-[#0eb683] text-white p-3 mt-10 w-full md:max-w-lg rounded-md transition-all active:scale-90">
                  Save Beneficiary
                </button>
              </TabPanel>
              <TabPanel hidden={coinTab !== "USDT"} className=" mt-8 ">
                <div className="card mt-8 md:bg-white md:max-w-lg md:flex  md:flex-col md:pl-8 md:pb-8 md:mt-12 md:pt-8 rounded">

               
              <header className="flex items-center justify-between mb-6 ">
                    <h1 className="text-[#071827] font-bold text-lg ">
                      Beneficiary Information
                    </h1>
                    <div className="text-[#0eb683] hidden md:flex md:justify-center md:gap-1 md:border-[#0eb683] md:p-2 font-medium md:mr-8 md:border rounded-md cursor-pointer transition-all active:scale-90">
                      <ShareIcon height="17px" width="17px" />
                      <p>Share Form</p>{" "}
                    </div>
                    <div className="bg-[#0eb683]  md:hidden border border-[#0eb683] p-2 font-medium text-[#fff] cursor-pointer transition-all active:scale-90 rounded-md shadow-md">
                      <p>Share Form</p>{" "}
                    </div>
                  </header>
                
                <main className="flex flex-col gap-4">
                  <div className="mid relative">
                    <input
                      type="text"
                      name=""
                      className={`py-3 px-4 w-full bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-sm `}
                      placeholder="Beneficiary USDT Address"
                      required
                    />
                    <span className="text-red-500 absolute left-2 bottom-4">
                      *
                    </span>
                  </div>

                  <div className="mid relative">
                    <input
                      type="text"
                      name=""
                      className={`py-3 px-4 w-full bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-sm `}
                      placeholder="Beneficiary Name"
                      required
                    />
                    <span className="text-red-500 absolute left-2 bottom-4">
                      *
                    </span>
                  </div>
                  <div className="mid relative">
                    <input
                      type="text"
                      name=""
                      className={`py-3 px-4 w-full bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-sm `}
                      placeholder="Alias"
                      required
                    />
                    <span className="text-red-500 absolute left-2 bottom-4">
                      *
                    </span>
                  </div>
                  {/* <button className="bg-[#0eb683] text-white p-3 mt-10 max-w-md rounded-md transition-all active:scale-90">
                    Save Beneficiary
                  </button> */}
                </main>
                </div>
                <button className="bg-[#0eb683] text-white p-3 mt-10 w-full md:max-w-lg rounded-md transition-all active:scale-90">
                  Save Beneficiary
                </button>
              </TabPanel>
            </main>
          </div>
  )
}

export default AddStableCoinComponent