import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useUsers } from "../sevices/hooks/users";
import LoadingScreen from "../pages/Notifications/LoadingScreen2";
import Aside from "../components/dashboard-components/Aside";
import Nav from "../components/dashboard-components/Nav";
import { useGbpRates, useRates, useRatesEur } from "../sevices/hooks/tags";
import { useRateStore, useUserState } from "../store";
import InviteUsers from "../components/base-components/InviteUsers";
import AutoLogout from "../pages/authentication/AutoLogout";
import PopOver from "../components/base-components/PopOver";
import create from "zustand";
import WelcomeScreen from "./WelcomeScreen";
import { useUserContext } from "../context/UserContext";

interface IPopups {
  showProfileDrop: boolean;

  setShowProfileDrop: (shouldShow: boolean) => void;
}

export const usePopup = create<IPopups>((set) => ({
  showProfileDrop: false,

  setShowProfileDrop: (shouldShow: boolean) =>
    set((state: any) => ({ showProfileDrop: shouldShow })),
}));

const DashBoardLayout = () => {
  const showProfileDrop = usePopup((state) => state.showProfileDrop);
  const setShowProfileDrop = usePopup((state) => state.setShowProfileDrop);

  const setUserDetails = useUserState((state) => state.setUserDetails);
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const { setUser } = useUserContext();
  const currentRate = useRates();
  const eurRates = useRatesEur();
  const gbpRates = useGbpRates();
  // const location = useLocation()
  const getUserDetails = useUsers(
    //@ts-ignore
    localStorage.getItem("user_id"),
    localStorage.getItem("accessToken")
  );
  const [open, setOpen] = useState(true);

  useEffect(() => {
    let token = localStorage.getItem("accessToken") || "";
    let user_id = localStorage.getItem("user_id") || "";
    setToken(token);
    setUserId(user_id);
  }, [token, userId]);

  useEffect(() => {
    // console.log(getUserDetails);

    if (getUserDetails.status === "error") {
      navigate("/login");
    }

    if (getUserDetails.isError) {
      navigate("/login");
    }
 localStorage.setItem("user", JSON.stringify(getUserDetails.result?.data));
    setUserDetails(getUserDetails.result?.data);
    setUser(getUserDetails.result?.data);
  }, [
    getUserDetails.isError,
    getUserDetails.result?.data,
    getUserDetails.status,
    navigate,
    setUserDetails,
  ]);


  return (
    <LoadingScreen
      showLoading={
        getUserDetails.status === "loading" ||
        (!getUserDetails.isSuccess && getUserDetails.isError)
      }
    >
      <AutoLogout>
        {getUserDetails.result?.data.kycStatus !== "verified" ? (
          <WelcomeScreen />
        ) : (
          <div className="dashboard overflow-x-hidden h-screen">
            <div className="flex flex-1">
              <Aside
                buyUsd={currentRate?.data?.data?.buyRate}
                buyEur={eurRates?.data?.data?.buyRate}
                buyGbp={gbpRates?.data?.data?.buyRate}
              />
              <div className="grid grid-rows-[auto_1fr] w-full h-screen overflow-auto">
                <Nav
                  requiresLayout={true}
                  open={open}
                  setOpen={setOpen}
                  showProfileDrop={showProfileDrop}
                  //@ts-ignore
                  setShowProfileDrop={setShowProfileDrop}
                />

                <main className="overflow-x-hidden">
                  <Outlet  context={[getUserDetails.result?.data, getUserDetails.refetch]} />
                </main>
              </div>
            </div>
            <InviteUsers data={getUserDetails.result?.data} />
            {showProfileDrop && (
              <PopOver
                //@ts-ignore
                setShowProfileDrop={setShowProfileDrop}
                showProfileDrop={showProfileDrop}
              />
            )}
          </div>
        )}
      </AutoLogout>
    </LoadingScreen>
  );
};

export default DashBoardLayout;
