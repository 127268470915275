import React, { useState } from "react";
import ShareIcon from "../../assets/iconsComponents/ShareIcon";
import AppSelectInput from "../base-components/AppSelectInput";
import { useTabs } from "../widgets/TabWidget";
import { TabSelector } from "../widgets/TabSelector";
import { TabPanel } from "../widgets/TabWidget";
import { AiOutlineClose } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import CopyIcon from "../../assets/iconsComponents/CopyIcon";
import { useGenerateBeneficiaryForm } from "../../sevices/hooks/users";
import useQueryParams from "../../hooks/useQueryParams";
import LinkIcon from "../../assets/iconsComponents/LinkIcon";
import ReactLoading from "react-loading";
import { useCreateBeneficiary } from "../../sevices/hooks/wallets";
import {
  useAbaCountries,
  useAbaDistricts,
  useIbanCountries,
  useSwiftCountries,
} from "../../sevices/hooks/meta-data";
import FailedIcon from "../../assets/iconsComponents/FailedIcon";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";

import LoadingScreen from "../../pages/Notifications/LoadingScreen2";
import { HiInformationCircle } from "react-icons/hi";
import { IoMdClose, IoMdCopy } from "react-icons/io";
// import SendSelectInput from "../base-components/SendSelectInput";

const TransferBeneficiaryFlow = ({
  onClick,
  saveBeneficiaryDetails,
}: {
  onClick: () => void;
  saveBeneficiaryDetails: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [selectedTab, setSelectedTab] = useTabs([
    "SWIFT / BIC",
    "IBAN",
    "ABA Number",
  ]);
  const beneficiaryForm = useGenerateBeneficiaryForm();
  const [createdBeneficiary, setCreatedBeneficary] = useState("");
  const [failed, setFailed] = useState(false);
  const [shareFormModal, setShareFormModal] = useState(false);
  const [copyStatus, setCopyStatus] = useState("");
  const [formLink, setFormLink] = useState(false);
  const [success, setSuccess] = useState(false);
  const createBeneficiary = useCreateBeneficiary();
    const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const swiftCountries = useSwiftCountries();
  const ibanCountries = useIbanCountries();
  const abaCountries = useAbaCountries();
  const abaDistricts = useAbaDistricts();

  const swiftOptions =
    swiftCountries?.data?.data?.map(
      ({ name, code }: { name: string; code: string }) => ({
        name: code,
        label: name,
      })
    ) || [];

  const ibanOptions =
    ibanCountries?.data?.data?.map(
      ({ name, code }: { name: string; code: string }) => ({
        name: code,
        label: name,
      })
    ) || [];

  const abaOptions =
    abaCountries?.data?.data?.map(
      ({ name, code }: { name: string; code: string }) => ({
        name: code,
        label: name,
      })
    ) || [];

  saveBeneficiaryDetails(createdBeneficiary);

  const districtOptions =
    abaDistricts?.data?.data?.map(
      ({ name, code }: { name: string; code: string }) => ({
        name: code,
        label: name,
      })
    ) || [];

  const [swiftFields, setSwiftFields] = useState({
    beneficiaryName: "",
    billingDetails: {
      line1: "",
      line2: "",
      district: "",
      city: "",
      country: "",
      postalCode: "",
      name: "",
    },
    bankAddress: {
      bankName: "",
      city: "",
      country: "",
    },
    destinationType: "noneIban",
    accountNumber: "",
    routingNumber: "",
    // iban: "",
  });

  const SwiftBtn =
    swiftFields?.beneficiaryName.trim() !== "" &&
    swiftFields?.billingDetails?.line1.trim() !== "" &&
    // swiftFields?.billingDetails?.line2.trim() !== "" &&
    swiftFields?.billingDetails?.district.trim() !== "" &&
    swiftFields?.billingDetails?.city.trim() !== "" &&
    swiftFields?.billingDetails?.country.trim() !== "" &&
    swiftFields?.billingDetails?.postalCode.trim() !== "" &&
    swiftFields?.billingDetails?.name.trim() !== "" &&
    swiftFields?.bankAddress?.bankName.trim() !== "" &&
    swiftFields?.bankAddress?.city.trim() !== "" &&
    swiftFields?.bankAddress?.country.trim() !== "" &&
    swiftFields?.accountNumber.trim() !== "" &&
    swiftFields?.routingNumber.trim() !== "";
  // &&
  // swiftFields?.iban.trim() !== "";

  const [ibanFields, setIbanFields] = useState({
    beneficiaryName: "",
    billingDetails: {
      line1: "",
      line2: "",
      district: "",
      city: "",
      country: "",
      postalCode: "",
      name: "",
    },
    bankAddress: {
      bankName: "",
      city: "",
      country: "",
    },
    destinationType: "iban",
    accountNumber: "",
    routingNumber: "",
    iban: "",
  });

  const IbanBtn =
    ibanFields?.billingDetails?.line1.trim() !== "" &&
    ibanFields?.billingDetails?.district.trim() !== "" &&
    ibanFields?.billingDetails?.city.trim() !== "" &&
    ibanFields?.billingDetails?.country.trim() !== "" &&
    ibanFields?.billingDetails?.postalCode.trim() !== "" &&
    ibanFields?.billingDetails?.name.trim() !== "" &&
    ibanFields?.bankAddress?.city.trim() !== "" &&
    ibanFields?.bankAddress?.country.trim() !== "" &&
    ibanFields?.iban.trim() !== "";

  const [abaFields, setAbaFields] = useState({
    beneficiaryName: "",
    billingDetails: {
      line1: "",
      line2: "",
      district: "",
      city: "",
      country: "",
      postalCode: "",
      name: "",
    },
    bankAddress: {
      bankName: "",
      city: "",
      country: "",
    },
    destinationType: "usa",
    accountNumber: "",
    routingNumber: "",
    iban: "",
  });

  const AbaBtn =
    abaFields?.beneficiaryName.trim() !== "" &&
    abaFields?.billingDetails?.line1.trim() !== "" &&
    abaFields?.billingDetails?.line2.trim() !== "" &&
    abaFields?.billingDetails?.district.trim() !== "" &&
    abaFields?.billingDetails?.city.trim() !== "" &&
    abaFields?.billingDetails?.country.trim() !== "" &&
    abaFields?.billingDetails?.postalCode.trim() !== "" &&
    abaFields?.billingDetails?.name.trim() !== "" &&
    abaFields?.bankAddress?.city.trim() !== "" &&
    abaFields?.bankAddress?.country.trim() !== "" &&
    abaFields?.accountNumber.trim() !== "" &&
    abaFields?.routingNumber.trim() !== "";

  //swiftData
  const onSwiftFieldChange = ({
    name,
    value,
  }: {
    value: string;
    name: string;
  }) => {
    switch (name) {
      case "bankCity":
      case "bankCountry":
      case "bankName":
        swiftFields.bankAddress[
          name === "bankCity"
            ? "city"
            : name === "bankName"
            ? "bankName"
            : "country"
        ] = value;
        setSwiftFields({ ...swiftFields });

        break;
      case "line1":
      case "line2":
      case "district":
      case "city":
      case "country":
      case "postalCode":
        swiftFields.billingDetails[name] = value;
        setSwiftFields({ ...swiftFields });
        break;

      case "name":
        swiftFields.billingDetails.name = value;
        swiftFields.beneficiaryName = value;
        setSwiftFields({ ...swiftFields });
        break;
      default:
        setSwiftFields({ ...swiftFields, [name]: value });
    }
  };
  //IbanData
  const onIbanFieldChange = ({
    name,
    value,
  }: {
    value: string;
    name: string;
  }) => {
    switch (name) {
      case "bankCity":
      case "bankCountry":
        ibanFields.bankAddress[name === "bankCity" ? "city" : "country"] =
          value;
        setIbanFields({ ...ibanFields });

        break;
      case "line1":
      case "line2":
      case "district":
      case "city":
      case "country":
      case "postalCode":
        ibanFields.billingDetails[name] = value;
        setIbanFields({ ...ibanFields });
        break;

      case "name":
        ibanFields.billingDetails.name = value;
        ibanFields.beneficiaryName = value;
        setIbanFields({ ...ibanFields });
        break;

      default:
        setIbanFields({ ...ibanFields, [name]: value });
    }
  };

  //abaData
  const onAbaFieldChange = ({
    name,
    value,
  }: {
    value: string;
    name: string;
  }) => {
    switch (name) {
      case "bankCity":
      case "bankCountry":
        abaFields.bankAddress[name === "bankCity" ? "city" : "country"] = value;
        setAbaFields({ ...abaFields });

        break;
      case "line1":
      case "line2":
      case "district":
      case "city":
      case "country":
      case "postalCode":
        abaFields.billingDetails[name] = value;
        setAbaFields({ ...abaFields });
        break;
      case "name":
        abaFields.billingDetails.name = value;
        abaFields.beneficiaryName = value;
        setAbaFields({ ...abaFields });
        break;
      default:
        setAbaFields({ ...abaFields, [name]: value });
    }
  };

  const createSwiftBeneficiary = (e: any) => {
    e.preventDefault();
    setLoading(true);
    createBeneficiary
      // @ts-ignore
      .mutateAsync(swiftFields)

      .then((res) => {
        setLoading(false);
        setSuccess(true);
        setCreatedBeneficary(res?.data?.beneficiaryId);
      })
      .catch((e) => {
        if (e?.response?.config?.data?.includes("iban is required")) {
          setErrMsg(
            "Please Enter a valid Iban Number in the routing number field "
          );
        }
        setLoading(false);
        setFailed(true);
      });
  };

  const createIbanBeneficiary = (e: any) => {
    e.preventDefault();
    setLoading(true);
    createBeneficiary
      // @ts-ignore
      .mutateAsync(ibanFields)

      .then((res) => {
        setLoading(false);
        setSuccess(true);
        setCreatedBeneficary(res?.data?.beneficiaryId);
      })
      .catch((e) => {
        setLoading(false);
        setFailed(true);
      });
  };

  const createAbaBeneficiary = (e: any) => {
    e.preventDefault();
    setLoading(true);
    createBeneficiary
      // @ts-ignore
      .mutateAsync(abaFields)

      .then((res) => {
        setLoading(false);
        setSuccess(true);
        setCreatedBeneficary(res?.data?.beneficiaryId);
      })
      .catch((e) => {
        setLoading(false);
        setFailed(true);
      });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [formToken, setFormToken] = useState<any>();
  const { ref: extRef, token: extToken } = useQueryParams(["ref", "token"]);

  const copyToClipBoard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyStatus("Copied to clipbaord");
    let timeout = setTimeout(() => {
      setCopyStatus("");
      clearTimeout(timeout);
    }, 1500);
    setFormLink(false);
    setFormToken(undefined);
  };

  const generateForm = () => {
    setIsLoading(true);
    beneficiaryForm
      .mutateAsync({})
      .then(({ data }) => {
        setIsLoading(false);
        setFormToken(data);
        setFormLink(true);
      })
      .catch((e) => {});
  };

  return (
    <LoadingScreen showLoading={loading} msg="Processing..." bg="#fffc">
      <div className="bg-[#F7FBFB]">
        <nav className=" flex justify-between  px-5 md:justify-start md:gap-24 pt-9 ">
          <TabSelector
            className={`cursor-pointer relative bg-transparent  ${
              selectedTab === "SWIFT / BIC"
                ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                : ""
            }`}
            isActive={selectedTab === "SWIFT / BIC"}
            onClick={() => setSelectedTab("SWIFT / BIC")}
          >
            <div className="flex items-center gap-2">
              <div className=" bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>
              <h1 className="text-[#5C6F7F] xxs:text-sm md:text-base ">
                SWIFT / BIC
              </h1>
            </div>
          </TabSelector>

          <TabSelector
            className={`cursor-pointer relative bg-transparent  ${
              selectedTab === "IBAN"
                ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                : ""
            }`}
            isActive={selectedTab === "IBAN"}
            onClick={() => setSelectedTab("IBAN")}
          >
            <div className="flex items-center gap-2">
              <div className=" bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>
              <h1 className="text-[#5C6F7F] xxs:text-sm md:text-base">IBAN </h1>
            </div>
          </TabSelector>

          <TabSelector
            className={`cursor-pointer relative bg-transparent ${
              selectedTab === "ABA Number"
                ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                : ""
            }`}
            isActive={selectedTab === "ABA Number"}
            onClick={() => setSelectedTab("ABA Number")}
          >
            <div className="flex items-center gap-2">
              <div className=" bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>
              <h1 className="text-[#5C6F7F] xxs:text-sm md:text-base">
                ABA Number
              </h1>
            </div>
          </TabSelector>
        </nav>
        <main className="panel p-5">
          <TabPanel hidden={selectedTab !== "SWIFT / BIC"} className=" ">
            <div className="md:bg-white md:max-w-lg md:flex  md:flex-col md:pl-8 md:pb-8 md:mt-12 md:pt-8 rounded-xl">
              <div className="card ">
                <header className="flex items-center justify-between mb-6 ">
                  <h1 className="text-[#071827] font-bold xxs:text-base md:text-lg ">
                    Beneficiary Information
                  </h1>
                  {(!extRef || !extToken) && (
                    <>
                      <div
                        onClick={() => setShareFormModal(true)}
                        className="text-[#0eb683] rounded-md hidden md:flex md:justify-center md:gap-1 md:border-[#0eb683] md:p-2 font-medium md:mr-8 md:border transition-all active:scale-90 cursor-pointer"
                      >
                        <ShareIcon height="17px" width="17px" />
                        <p>Share Form</p>{" "}
                      </div>
                      <div
                        onClick={() => setShareFormModal(true)}
                        className="bg-[#0eb683]  md:hidden border border-[#0eb683] p-2 font-medium text-[#fff] cursor-pointer transition-all active:scale-90 rounded-md shadow-md"
                      >
                        <p>Share Form</p>{" "}
                      </div>
                    </>
                  )}
                </header>
                <div className="flex flex-col  gap-5">
                  <div className="mid relative  ">
                    <input
                      type="text"
                      name="routingNumber"
                      className={`py-3 px-4 w-full md:max-w-md border border-[#E2E9F0] rounded-md md:bg-[#F2FAF7]  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                      placeholder="Swift Code / Routing Number"
                      required
                      onChange={(e) => {
                        onSwiftFieldChange({
                          name: "routingNumber",
                          value: e.target.value,
                        });
                      }}
                    />
                    <span className="text-red-500 absolute left-2 bottom-4">
                      *
                    </span>
                    <div className="absolute left-0 bottom-[-17px] flex gap-2">
                      <HiInformationCircle fill="#0eb683" />
                      <small className="hidden md:block text-[#313131d7] text-[10px] font-semibold">
                        {" "}
                        Swift Code eg (BOFAUS3N) or Routing Number (011000015)
                        is required{" "}
                      </small>
                      <small className=" md:hidden  text-[#313131d7] text-xs font-semibold">
                        {" "}
                        Swift Code{" "}
                        <span className="text-[#0eb683] font-bold ">
                          OR
                        </span>{" "}
                        Routing Number is required
                      </small>
                    </div>
                  </div>

                  <div className="mid relative">
                    <input
                      type="text"
                      name="BeneficiaryName"
                      className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                      placeholder="Beneficiary Name"
                      onChange={(e) => {
                        onSwiftFieldChange({
                          name: "name",
                          value: e.target.value,
                        });
                      }}
                      required
                    />
                    <span className="text-red-500 absolute left-2 bottom-4">
                      *
                    </span>
                  </div>
                  <div className="mid relative">
                    <input
                      type="number"
                      name="accountNumber"
                      className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                      placeholder="Beneficiary Account Number"
                      onChange={(e) => {
                        onSwiftFieldChange({
                          name: "accountNumber",
                          value: e.target.value,
                        });
                      }}
                      required
                    />
                    <span className="text-red-500 absolute left-2 bottom-4">
                      *
                    </span>
                  </div>
                  <div className="mid relative">
                    <input
                      type="number"
                      name="accountNumber"
                      className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                      placeholder="Confirm Beneficiary Account Number"
                      onChange={(e) => {
                        onSwiftFieldChange({
                          name: "accountNumber",
                          value: e.target.value,
                        });
                      }}
                      required
                    />
                    <span className="text-red-500 absolute left-2 bottom-4">
                      *
                    </span>
                  </div>
                  <div className="mid relative">
                    <input
                      type="text"
                      name="line1"
                      className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                      placeholder="Address Line 1"
                      onChange={(e) => {
                        onSwiftFieldChange({
                          name: "line1",
                          value: e.target.value,
                        });
                      }}
                      required
                    />
                    <span className="text-red-500 absolute left-2 bottom-4">
                      *
                    </span>
                  </div>
                  <div className="mid relative">
                    <input
                      type="text"
                      name=""
                      className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                      placeholder="Address Line 2"
                      required
                      onChange={(e) => {
                        onSwiftFieldChange({
                          name: "line2",
                          value: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <div className="mid relative">
                    <input
                      type="text"
                      name="postalCode"
                      className={`py-3 px-4 w-full md:max-w-md border border-[#E2E9F0] rounded-md md:bg-[#F2FAF7] outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                      placeholder="Beneficiary Postal Code"
                      onChange={(e) => {
                        onSwiftFieldChange({
                          name: "postalCode",
                          value: e.target.value,
                        });
                      }}
                      required
                    />
                    <span className="text-red-500 absolute left-2 bottom-4">
                      *
                    </span>
                  </div>
                  <div className="mid max-w-md relative">
                    <input
                      type="text"
                      name="district"
                      className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683]  placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                      placeholder="Beneficiary District Code"
                      required
                      maxLength={2}
                      onChange={(e) => {
                        onSwiftFieldChange({
                          name: "district",
                          value: e.target.value.toUpperCase(),
                        });
                      }}
                    />
                    <div className="absolute left-0 bottom-[-17px] flex gap-2">
                      <HiInformationCircle fill="#0eb683" />
                      <small className=" hidden md:block text-[#313131d7] text-[10px] font-semibold">
                        {" "}
                        Enter 2 Character Beneficiary District Code e.g. 'NY'
                        for New York
                      </small>
                      <small className=" md:hidden text-[#313131d7] text-[10px] font-semibold">
                        {" "}
                        Beneficiary District Code e.g. 'NY' for New York
                      </small>
                    </div>

                    <span className="text-red-500 absolute left-2 bottom-4">
                      *
                    </span>
                  </div>
                  <div className="mid relative">
                    <input
                      type="text"
                      name="city"
                      className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                      placeholder="Beneficiary City"
                      onChange={(e) => {
                        onSwiftFieldChange({
                          name: "city",
                          value: e.target.value,
                        });
                      }}
                      required
                    />
                    <span className="text-red-500 absolute left-2 bottom-4">
                      *
                    </span>
                  </div>

                  <div className="mid max-w-md relative">
                    <AppSelectInput
                      options={swiftOptions || []}
                      label=""
                      className="swiftBeneficiarycountry"
                      placeholder="Beneficiary Country"
                      handleChange={(inputValue) => {
                        inputValue &&
                          onSwiftFieldChange({
                            name: "country",
                            value: inputValue.name,
                          });
                      }}
                    />
                    <span className="text-red-500 absolute left-2 bottom-4">
                      *
                    </span>
                  </div>

                  <div className="mid relative">
                    <input
                      type="text"
                      name="swiftBankName"
                      className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                      placeholder="Bank Name"
                      onChange={(e) => {
                        onSwiftFieldChange({
                          name: "bankName",
                          value: e.target.value,
                        });
                      }}
                      required
                    />
                    <span className="text-red-500 absolute left-2 bottom-4">
                      *
                    </span>
                  </div>
                  <div className="mid relative">
                    <input
                      type="text"
                      name="swiftBankcity"
                      className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                      placeholder="Bank City"
                      onChange={(e) => {
                        onSwiftFieldChange({
                          name: "bankCity",
                          value: e.target.value,
                        });
                      }}
                      required
                    />
                    <span className="text-red-500 absolute left-2 bottom-4">
                      *
                    </span>
                  </div>

                  <div className="mid max-w-md relative">
                    <AppSelectInput
                      options={swiftOptions || []}
                      label=""
                      placeholder="Bank Country"
                      handleChange={(inputValue) => {
                        inputValue &&
                          onSwiftFieldChange({
                            name: "bankCountry",
                            value: inputValue.name,
                          });
                      }}
                    />
                    <span className="text-red-500 absolute left-2 bottom-4">
                      *
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <button
              disabled={SwiftBtn !== true}
              onClick={createSwiftBeneficiary}
              className="bg-[#0eb683] text-white p-3 mt-10     md:max-w-lg rounded-md transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
            >
              Save Beneficiary
            </button>
          </TabPanel>
          <TabPanel hidden={selectedTab !== "IBAN"}>
            <div className="md:bg-white md:max-w-lg md:flex  md:flex-col md:pl-8 md:pb-8 md:mt-12 md:pt-8 rounded-xl">
              <header className="flex items-center justify-between mb-6 ">
                <h1 className="text-[#071827] font-bold xxs:text-base md:text-lg  ">
                  Beneficiary Information
                </h1>
                <div
                  onClick={() => setShareFormModal(true)}
                  className="text-[#0eb683] hidden md:flex md:justify-center md:gap-1 md:border-[#0eb683] md:p-2 font-medium md:mr-8 md:border rounded-md cursor-pointer transition-all active:scale-90"
                >
                  <ShareIcon height="17px" width="17px" />
                  <p>Share Form</p>{" "}
                </div>
                <div
                  onClick={() => setShareFormModal(true)}
                  className="bg-[#0eb683]  md:hidden border border-[#0eb683] p-2 font-medium text-[#fff] cursor-pointer transition-all active:scale-90 rounded-md shadow-md"
                >
                  <p>Share Form</p>{" "}
                </div>
              </header>
              <div className="flex flex-col gap-5">
                <div className="mid relative ">
                  <input
                    type="text"
                    name="routingNumber"
                    className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683]  placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                    onChange={(e) => {
                      onIbanFieldChange({
                        name: "beneficiaryName",
                        value: e.target.value,
                      });
                    }}
                    placeholder="IBAN Number"
                    required
                  />
                  <div className="absolute left-0 bottom-[-17px] flex gap-2">
                    <HiInformationCircle fill="#0eb683" />
                    <small className="  text-[#313131d7] text-[10px] font-semibold">
                      {" "}
                      Eg: AL472121100900000002356987411
                    </small>
                  </div>
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>
                <div className="mid relative">
                  <input
                    type="text"
                    name="beneficiaryName"
                    className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                    placeholder="Beneficiary Name"
                    required
                    onChange={(e) => {
                      onIbanFieldChange({
                        name: "name",
                        value: e.target.value.toUpperCase(),
                      });
                    }}
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>
                <div className="mid relative">
                  <input
                    type="text"
                    name="line1"
                    className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                    placeholder="Address Line 1"
                    required
                    onChange={(e) => {
                      onIbanFieldChange({
                        name: "line1",
                        value: e.target.value,
                      });
                    }}
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>
                <div className="mid relative">
                  <input
                    type="text"
                    name="postalCode"
                    className={`py-3 px-4 md:bg-[#F2FAF7] w-full md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                    placeholder="Beneficiary Postal Code"
                    required
                    onChange={(e) => {
                      onIbanFieldChange({
                        name: "postalCode",
                        value: e.target.value,
                      });
                    }}
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>
                <div className="mid max-w-md relative">
                  <input
                    type="text"
                    name="district"
                    className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                    placeholder="Beneficiary District "
                    required
                    onChange={(e) => {
                      onIbanFieldChange({
                        name: "district",
                        value: e.target.value,
                      });
                    }}
                  />

                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>
                <div className="mid relative">
                  <input
                    type="text"
                    name=""
                    className={`py-3 px-4 w-full md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                    placeholder="Beneficiary City"
                    required
                    onChange={(e) => {
                      onIbanFieldChange({
                        name: "city",
                        value: e.target.value,
                      });
                    }}
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>
                <div className="mid max-w-md relative">
                  <AppSelectInput
                    options={ibanOptions || []}
                    label=""
                    placeholder="Beneficiary Country"
                    handleChange={(inputValue) => {
                      inputValue &&
                        onIbanFieldChange({
                          name: "country",
                          value: inputValue.value,
                        });
                    }}
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>

                <div className="mid relative">
                  <input
                    type="text"
                    name="Bankcity"
                    className={`py-3 px-4 w-full md:md:bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                    placeholder="Bank City"
                    onChange={(e) => {
                      onIbanFieldChange({
                        name: "bankCity",
                        value: e.target.value,
                      });
                    }}
                    required
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>

                <div className="mid max-w-md relative">
                  <AppSelectInput
                    options={ibanOptions || []}
                    label=""
                    placeholder="Bank Country"
                    handleChange={(inputValue) => {
                      inputValue &&
                        onIbanFieldChange({
                          name: "bankCountry",
                          value: inputValue.name,
                        });
                    }}
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>
              </div>
            </div>
            <button
              disabled={IbanBtn !== true}
              onClick={createIbanBeneficiary}
              className="bg-[#0eb683] text-white p-3 mt-10  md:max-w-lg rounded-md transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
            >
              Save Beneficiary
            </button>
          </TabPanel>
          <TabPanel hidden={selectedTab !== "ABA Number"}>
            <div className="card  md:bg-white md:max-w-lg md:flex  md:flex-col md:pl-8 md:pb-8 md:mt-12 md:pt-8 rounded-xl">
              <header className="flex items-center justify-between mb-6 ">
                <h1 className="text-[#071827] font-bold xxs:text-base md:text-lg">
                  Beneficiary Information
                </h1>
                <div
                  onClick={() => setShareFormModal(true)}
                  className="text-[#0eb683] hidden md:flex md:justify-center md:gap-1 md:border-[#0eb683] md:p-2 font-medium md:mr-8 md:border rounded-md transition-all active:scale-90 cursor-pointer"
                >
                  <ShareIcon height="17px" width="17px" />
                  <p>Share Form</p>{" "}
                </div>
                <div
                  onClick={() => setShareFormModal(true)}
                  className="bg-[#0eb683]  md:hidden border border-[#0eb683] p-2 font-medium text-[#fff] cursor-pointer transition-all active:scale-90 rounded-md shadow-md"
                >
                  <p>Share Form</p>{" "}
                </div>
              </header>
              <div className="flex flex-col  gap-5">
                <div className="mid relative  ">
                  <input
                    type="text"
                    name=""
                    className={`py-3 px-4 w-full md:bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                    placeholder="ABA / Routing Number"
                    required
                    onChange={(e) => {
                      onAbaFieldChange({
                        name: "routingNumber",
                        value: e.target.value,
                      });
                    }}
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                  <div className="absolute left-0 bottom-[-17px] flex gap-2">
                    <HiInformationCircle fill="#0eb683" />
                    <small className="  text-[#313131d7] text-[10px] font-semibold">
                      {" "}
                      Eg: 020190231
                    </small>
                  </div>
                </div>
                <div className="mid relative">
                  <input
                    type="text"
                    name="Name"
                    className={`py-3 px-4 w-full md:bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                    placeholder="Beneficiary Name"
                    required
                    onChange={(e) => {
                      onAbaFieldChange({
                        name: "name",
                        value: e.target.value,
                      });
                    }}
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>
                <div className="mid relative">
                  <input
                    type="number"
                    name="accountNumber"
                    className={`py-3 px-4 w-full md:bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                    placeholder="Beneficiary's Account Number"
                    required
                    onChange={(e) => {
                      onAbaFieldChange({
                        name: "accountNumber",
                        value: e.target.value,
                      });
                    }}
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>
                <div className="mid relative">
                  <input
                    type="number"
                    name="accountNumber"
                    className={`py-3 px-4 w-full md:bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                    placeholder="Confirm Beneficiary Account Number"
                    required
                    onChange={(e) => {
                      onAbaFieldChange({
                        name: "accountNumber",
                        value: e.target.value,
                      });
                    }}
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>
                <div className="mid relative">
                  <input
                    type="text"
                    name="line1"
                    className={`py-3 px-4 w-full md:bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                    placeholder="Address Line 1"
                    required
                    onChange={(e) => {
                      onAbaFieldChange({
                        name: "line1",
                        value: e.target.value,
                      });
                    }}
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>
                <div className="mid relative">
                  <input
                    type="text"
                    name="line2"
                    className={`py-3 px-4 w-full md:bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                    placeholder="Address Line 2"
                    required
                    onChange={(e) => {
                      onAbaFieldChange({
                        name: "line2",
                        value: e.target.value,
                      });
                    }}
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>
                <div className="mid relative">
                  <input
                    type="text"
                    name="postalCode"
                    className={`py-3 px-4 w-full md:bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                    placeholder="Beneficiary Postal Code"
                    required
                    onChange={(e) => {
                      onAbaFieldChange({
                        name: "postalCode",
                        value: e.target.value,
                      });
                    }}
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>

                <div className="mid max-w-md relative">
                  <AppSelectInput
                    options={districtOptions || []}
                    label=""
                    placeholder="Beneficiary District"
                    handleChange={(inputValue) => {
                      inputValue &&
                        onAbaFieldChange({
                          name: "district",
                          value: inputValue.name,
                        });
                    }}
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>
                <div className="mid relative">
                  <input
                    type="text"
                    name="city"
                    className={`py-3 px-4 w-full md:bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base`}
                    placeholder="Beneficiary City"
                    required
                    onChange={(e) => {
                      onAbaFieldChange({
                        name: "city",
                        value: e.target.value,
                      });
                    }}
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>
                <div className="mid max-w-md relative">
                  <AppSelectInput
                    options={abaOptions || []}
                    label=""
                    placeholder="Beneficiary Country"
                    handleChange={(inputValue) => {
                      inputValue &&
                        onAbaFieldChange({
                          name: "country",
                          value: inputValue.name,
                        });
                    }}
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>

                <div className="mid relative">
                  <input
                    type="text"
                    name="city"
                    className={`py-3 px-4 w-full md:bg-[#F2FAF7]  md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-[#7d7c7c] placeholder:xxs:text-sm placeholder:md:text-base `}
                    placeholder="Bank City"
                    required
                    onChange={(e) => {
                      onAbaFieldChange({
                        name: "bankCity",
                        value: e.target.value,
                      });
                    }}
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>

                <div className="mid max-w-md relative">
                  <AppSelectInput
                    options={abaOptions || []}
                    label=""
                    placeholder="Bank Country"
                    handleChange={(inputValue) => {
                      inputValue &&
                        onAbaFieldChange({
                          name: "bankCountry",
                          value: inputValue.name,
                        });
                    }}
                  />
                  <span className="text-red-500 absolute left-2 bottom-4">
                    *
                  </span>
                </div>
              </div>
            </div>
            <button
              disabled={AbaBtn !== true}
              onClick={createAbaBeneficiary}
              className="bg-[#0eb683] ml-auto text-white p-3 mt-10  md:max-w-lg rounded-md transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
            >
              Save Beneficiary
            </button>
          </TabPanel>
        </main>

        <AnimatePresence>
          {shareFormModal && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
            >
              <motion.div
                initial={{
                  x: 600,
                  scale: 0,
                }}
                animate={{
                  x: 0,
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 600,
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173]"
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="flex flex-col bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative xxs:rounded-md md:rounded-l-md  p-5 md:px-7 "
                >
                  <div className="flex items-end justify-end pb-3">
                    <div
                      onClick={() => {
                        setShareFormModal(false);
                      }}
                      className="close  flex items-center justify-center h-10 w-10  rounded-full
                    hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center "
                    >
                      <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                        <IoMdClose size={29} className="" />
                      </span>
                    </div>
                  </div>
                  <div className=" ">
                    <h1 className="font-bold text-xl text-[#071827] pb-3">
                      Share form with an external beneficiary
                    </h1>
                    <p className="text-[#5C6F7F] text-base pb-4">
                      You can either generate a link to share or email the
                      beneficiary to supply their account details
                    </p>

                    {formLink ? (
                      <>
                        <div className="flex items-center gap-4">
                          <div className="flex  items-center justify-center tracking-tighter  rounded-lg text-[#0eb683] border border-[#E2E9F0]] md:w-72 h-11  ">
                            <LinkIcon height="25px" width="30px" />
                            <input
                              type="text"
                              disabled
                              value={` ${window.location.origin}/external/beneficiary-form?ref=${formToken?.ref}&token=${formToken?.token}`}
                              className="w-full  mr-3 ontline-none bg-white "
                            />
                          </div>
                          <button
                            disabled={!formToken}
                            onClick={() => {
                              copyToClipBoard(
                                ` ${window.location.origin}/external/beneficiary?ref=${beneficiaryForm?.data?.data?.ref}&token=${beneficiaryForm?.data?.data?.token}`
                              );
                            }}
                            className="border shadow-md shadow-gray-400/50 select-none tracking-tighter border-[#0eb683] bg-[#0eb683] flex gap-1  items-center rounded-lg transition-all active:scale-90 px-5 py-2"
                          >
                            <IoMdCopy color="#fff" />
                            <p className="text-[#fff]">copy</p>
                          </button>
                        </div>
                        <button className="flex items-center justify-center rounded-lg border border-[#0eb683] w-full p-3 mt-5">
                          <LinkIcon height="25px" width="30px" />
                          <p className="text-[#0eb683]">Share link</p>
                        </button>
                        <div className="flex items-center gap-4 justify-center my-7">
                          <div className="h-[1px] w-44 bg-[#0eb683]"></div>
                          <p className="text-[#071827] font-semibold">Or</p>
                          <div className="h-[1px] w-44 bg-[#0eb683]"></div>
                        </div>

                        <div className="flex items-center gap-4">
                          <div className="flex  items-center justify-center tracking-tighter  rounded-lg text-slate-800 border border-[#E2E9F0]] md:w-72 h-11  relative">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              className="w-full pl-2  mr-3 outline-none bg-white "
                              placeholder="Beneficiary Email"
                            />
                            <span className="text-red-600 absolute top-3 left-1">
                              *
                            </span>
                          </div>
                          <button
                            disabled={true}
                            className="border shadow-md shadow-gray-400/50 select-none tracking-tighter border-[#0eb683] bg-[#0eb683] flex gap-1  items-center rounded-lg transition-all active:scale-90 px-5 py-2 disabled:cursor-not-allowed disabled:bg-[#8fc0ad]"
                          >
                            <p className="text-[#fff] tracking-tighter text-sm">
                              share
                            </p>
                          </button>
                        </div>
                        <p className="text-white px-2 bg-green-300 rounded-full absolute top-11">
                          {copyStatus}
                        </p>
                        <div className="flex"></div>
                      </>
                    ) : (
                      <>
                        <div
                          onClick={generateForm}
                          className="flex items-center justify-center gap-1 rounded-lg text-[#0eb683] border border-[#0eb683] w-full py-3 transition-all active:scale-90 cursor-pointer shadow-md shadow-gray-400/50 select-none tracking-tighter"
                        >
                          <LinkIcon height="25px" width="30px" />
                          <p className="xxs:text-sm md:text-base">
                            {" "}
                            Click here to generate a shareable link!
                          </p>
                          <button className=" ">
                            {formToken
                              ? ` ${window.location.origin}/external/beneficiary-form?ref=${formToken?.ref}&token=${formToken?.token}`
                              : "Generate a shareable link!"
                              ? isLoading && (
                                  <ReactLoading
                                    type="bubbles"
                                    color="#0eb683"
                                    height="20px"
                                    width="20px"
                                  />
                                )
                              : formToken}
                          </button>
                        </div>

                        <div className="flex items-center gap-4 justify-center my-7">
                          <div className="h-[1px] w-44 bg-[#0eb683]"></div>
                          <p className="text-[#071827] font-semibold">Or</p>
                          <div className="h-[1px] w-44 bg-[#0eb683]"></div>
                        </div>

                        <div className="flex items-center gap-4">
                          <div className="flex  items-center justify-center tracking-tighter  rounded-lg text-slate-800 border border-[#E2E9F0]] md:w-72 h-11  relative">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              className="w-full pl-2  mr-3 outline-none bg-white "
                              placeholder="Beneficiary Email"
                            />
                            <span className="text-red-600 absolute top-3 left-1">
                              *
                            </span>
                          </div>
                          <button
                            disabled={true}
                            className="border shadow-md shadow-gray-400/50 select-none tracking-tighter border-[#0eb683] bg-[#0eb683] flex gap-1  items-center rounded-lg transition-all active:scale-90 px-5 py-2 disabled:cursor-not-allowed disabled:bg-[#8fc0ad]"
                          >
                            <p className="text-[#fff] tracking-tighter text-sm">
                              share
                            </p>
                          </button>
                        </div>
                        <p className="text-white px-2 bg-green-300 rounded-full absolute top-11">
                          {copyStatus}
                        </p>
                      </>
                    )}
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {success && createdBeneficiary && (
            // {createdBeneficiary && (
            <div>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    // delay: 0.3,
                    duration: 0.1,
                  },
                }}
                className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
              >
                <motion.div
                  initial={{
                    x: 600,
                    scale: 0,
                  }}
                  animate={{
                    x: 0,
                    scale: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 600,
                    scale: 0,
                    transition: {
                      delay: 0.3,
                    },
                  }}
                  className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173]"
                >
                  <motion.div
                    initial={{
                      x: 600,
                      opacity: 0,
                    }}
                    animate={{
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 100,
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    className="flex flex-col bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative justify-center   p-5 md:px-7 overflow-auto "
                  >
                    <div className="icon self-center">
                      <SuccessMobile width={"100px"} height={"100px"} />
                    </div>
                    <h1 className="text-2xl font-bold text-[#071827] self-center">
                      Beneficiary added
                    </h1>

                    <p className="  text-sm text-[#5C6F7F] self-center text-center">
                      Your beneficiary was successfully added
                    </p>
                    <button
                      onClick={onClick}
                      className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90 "
                    >
                      Continue
                    </button>
                  </motion.div>
                </motion.div>
              </motion.div>
            </div>
          )}
          {/* )} */}
        </AnimatePresence>

        <AnimatePresence>
          {failed && (
            <div>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    // delay: 0.3,
                    duration: 0.1,
                  },
                }}
                className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
              >
                <motion.div
                  initial={{
                    x: 600,
                    scale: 0,
                  }}
                  animate={{
                    x: 0,
                    scale: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 600,
                    scale: 0,
                    transition: {
                      delay: 0.3,
                    },
                  }}
                  className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173] "
                >
                  <motion.div
                    initial={{
                      x: 600,
                      opacity: 0,
                    }}
                    animate={{
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 100,
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    className="flex flex-col bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative justify-center   p-5 md:px-7 overflow-auto  "
                  >
                    <div className="icon self-center">
                      <FailedIcon width={"100px"} height={"100px"} />
                    </div>
                    <h1 className="text-2xl font-bold text-[#071827] self-center">
                      Unsuccessful
                    </h1>

                    {errMsg ? (
                      <p className="  text-sm text-[#5C6F7F] self-center text-center">
                        {errMsg}
                      </p>
                    ) : (
                      <p className="  text-sm text-[#5C6F7F] self-center text-center">
                        We couldn't add your beneficiary, please retry
                      </p>
                    )}

                    <button
                      onClick={() => {
                        setFailed(false);
                        // setShowInviteUserModal(true);
                      }}
                      className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                    >
                      Retry
                    </button>
                  </motion.div>
                </motion.div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </div>
    </LoadingScreen>
  );
};

export default TransferBeneficiaryFlow;
