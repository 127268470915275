import React from "react";

import RefreshIcon from "../../assets/iconsComponents/RefreshIcon";
import WalletIcon from "../../assets/iconsComponents/WalletIcon";

const WalletCompartment = ({ text, to, currency, amount, setter }: any) => {
  const receive = () => {
    setter(true);
  };

  return (
    <div
      className={`flex items-center md:gap-4  xxs:gap-9  xxs:bg-[#fff] md:bg-[#0eb683] md:py-6 md:pr-4 px-4 w-full h-[144px] md:w-[350px] md:h-[154px]  border-2 border-[#0eb683]  rounded-lg cursor-pointer  `}
    >
      <div className=" text-#98999cE] md:bg-[#fff] p-3 xxs:bg-[#161918] rounded-full ">
        <WalletIcon stroke="#0B0C0E" />
      </div>
      <div className=" flex flex-col gap-3">
        <div className="">
          <div className="xxs:text-center md:text-left">
            <span className="xxs:text-[#5C6F7F] md:text-[#fff] font-bold">
              {currency}
            </span>{" "}
            <span className="xxs:text-[#5C6F7F] md:text-[#fff]">
              {" "}
              {`${text}`}
            </span>
          </div>
          <p className="font-bold text-lg xxs:text-[#0b0c0e] md:text-[#fff]">
            {" "}
            <span>{amount}</span>{" "}
          </p>
        </div>

        <button
          disabled={true}
          onClick={receive}
          className=" border xxs:border-[#0eb683] md:border-[#fff] bg-[#fff] text-[#0eb683] rounded-md py-3 xxs:py-2 px-4 transition-all active:scale-90 w-36  flex gap-1  self-center text-center disabled:cursor-not-allowed"
        >
          Receive <span className=" font-bold">{currency}</span>
        </button>
      </div>
    </div>
  );
};

export default WalletCompartment;
