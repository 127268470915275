import React, { createContext, useContext, useReducer, ReactNode } from "react";

interface Bank {
  label: string;
  value: string;
  bank: {
    anchorId: string;
    bankName: string;
    cbnCode: string;
    createdAt: string;
    deletedAt: string | null;
    id: string;
    nipCode: string;
    updatedAt: string;
  };
}
export interface IFormData {
  firstName: string;
  lastName: string;
  middleName: string;
  idNumber: string;
  dob: string;
  email: string;
  selfieUrl: string;
  id_type: string;
  idDocUrl: string;
  phoneNumber: string;


  docType: string;
  bvn: string;
  file2: any;
  file1: any;
  businessId: string;
  country: string | null;
  IDType: string | null;
  state: string | null;
  gender: string | null;
  postCode: string;
  address1: string;
  address2: string;
  city: string;
  idFile: string;
  password: string;
  confirmPassword: string;
  selfieData: string[];
  bank: Bank;
  accountNumber: string;
  accountName: string;
  bankCode: string;
  bankId: string;
  businessType: string;
  // state: string;
  industry: string;
  registration: string;
  BasicCountry: string;
  businessName: string;
  businessEmail: string;
  // bvn: string;
  dateOfRegistration: string;
  description: string;
  ContactEmail: string;
  ContactCountry: string;
  ContactState: string;
  addressLine_1: string;
  businessCity: string;
  postalCode: string;
  ContactRegisteredCountry: string;
  ContactRegisteredState: string;
  businessphoneNumber: string;
  businessIncorNum: string;
  businessIDType: string;

  registrationEvidence: string;
  businessTypeSlug: string;
  website: string;
  companyType: string;
  certificate: null;
  registrationEvidenceDoc: null;

  //owners
  businessOwnerGender: string;
  fullName: string;
  businessOwnerAdrress1: string;
  businessOwnerAdrress2: string;
  businessOwnerEmail: string;
  businessOwnerPostCode: string;
  businessOwnerPhoneNumber: string;
  businessOwnerPercentage: string;
  businessOwnerIdFile: any;
  businessOwnerCountry: null;
  businessOwnerState: null;
  //corporate
  corporateBusinessName: string;
  corporateIncorNum: string;
  corporateBusinessPhone: string;
  corporateCountry: null;
  corporateBusinessEmail: string;
  corporateBusinessState: null;
  corporatePostCode: string;
  corporateBusinessAddress: string;
  corporateBusinessPercentage: string;
  corporateIdFile: string;
}
type FormAction = { type: "UPDATE_FIELD"; field: keyof IFormData; value: any };

const FormContext = createContext<
  { state: IFormData; dispatch: React.Dispatch<FormAction> } | undefined
>(undefined);

function formReducer(state: IFormData, action: FormAction): IFormData {
  switch (action.type) {
    case "UPDATE_FIELD":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
}

interface FormProviderProps {
  children: ReactNode;
}

function FormProvider({ children }: FormProviderProps) {
  const [state, dispatch] = useReducer(formReducer, {
    firstName: "",
    lastName: "",
    middleName: "",
    idNumber: "",
    dob: "",
    email: "",
    phoneNumber: "",
    bvn: "",
    selfieUrl: "",
    id_type: "",
   
    idDocUrl: "",
    businessType: "",
    country: null,
    IDType: null,
    state: null,
    gender: null,
    postCode: "",
    address1: "",
    businessId: "",
    address2: "",
    docType: "",
    city: "",
    idFile: "",

    password: "",
    confirmPassword: "",
    file1: [],
    file2: [],
    selfieData: [],
    bank: {
      label: "",
      value: "",
      bank: {
        anchorId: "",
        bankName: "",
        cbnCode: "",
        createdAt: "",
        deletedAt: "",
        id: "",
        nipCode: "",
        updatedAt: "",
      },
    },
    accountNumber: "",
    accountName: "",
    bankCode: "",
    bankId: "",
    //business
    industry: "",
    registration: "",
    BasicCountry: "",
    businessName: "",
    businessEmail: "",
    businessIncorNum: "",
    dateOfRegistration: "",
    description: "",

    ContactEmail: "",
    ContactCountry: "",
    ContactState: "",
    addressLine_1: "",
    businessCity: "",
    postalCode: "",
    ContactRegisteredCountry: "",
    ContactRegisteredState: "",
    businessphoneNumber: "",
    registrationEvidence: "",
    businessTypeSlug: "",
    website: "",
    companyType: "",
    certificate: null,
    registrationEvidenceDoc: null,
    businessIDType: "",

    //owners
    businessOwnerGender: "",
    fullName: "",
    businessOwnerAdrress1: "",
    businessOwnerAdrress2: "",
    businessOwnerEmail: "",
    businessOwnerPostCode: "",
    businessOwnerPhoneNumber: "",
    businessOwnerPercentage: "",
    businessOwnerIdFile: null,
    businessOwnerCountry: null,
    businessOwnerState: null,

    //corporate
    corporateBusinessName: "",
    corporateIncorNum: "",
    corporateBusinessPhone: "",
    corporateCountry: null,
    corporateBusinessEmail: "",
    corporateBusinessState: null,
    corporatePostCode: "",
    corporateBusinessAddress: "",
    corporateBusinessPercentage: "",
    corporateIdFile: "",
  });

  return (
    <FormContext.Provider value={{ state, dispatch }}>
      {children}
    </FormContext.Provider>
  );
}

function useForm() {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw new Error("useForm must be used within a FormProvider");
  }
  return context;
}

export { FormProvider, useForm };
