import React, { useEffect, useRef, useState } from "react";
import { BsCheck2 } from "react-icons/bs";
import { HiOutlineChevronRight } from "react-icons/hi";

interface IProps {
  step: Array<{ id: number; description: string; step: string }>;
  currentStep: number;
    userType: string | null;

}

const Stepper = ({ step, userType, currentStep }: IProps) => {
  const [newStep, setNewStep] = useState<any[]>([]);
  const stepsRef = useRef();

  const updateStep = (stepNumber: number, steps: string[]) => {
    const newSteps = [...steps];

    let count = 0;
    while (count < newSteps.length) {
      //current step
      if (count === stepNumber) {
        newSteps[count] = {
          //@ts-ignore
          ...newSteps[count],
          highlighted: true,
          selected: true,
          completed: true,
        };
        count++;
      }

      //step completed
      else if (count < stepNumber) {
        newSteps[count] = {
          //@ts-ignore
          ...newSteps[count],
          highlighted: false,
          selected: true,
          completed: true,
        };
        count++;
      }
      //step pending
      else {
        newSteps[count] = {
          //@ts-ignore
          ...newSteps[count],
          highlighted: false,
          selected: false,
          completed: false,
        };
        count++;
      }
    }

    return newSteps;
  };

  useEffect(() => {
    const stepsState = step.map((item: any, index: number) =>
      Object.assign(
        {},
        {
          description: item,
          completed: false,
          highlighted: index === 0 ? true : false,
          selected: index === 0 ? true : false,
        }
      )
    );
    //@ts-ignore
    stepsRef.current = stepsState;
    //@ts-ignore
    const current = updateStep(currentStep - 1, stepsRef.current);
    setNewStep(current);
  }, [step, currentStep]);

  const stepsDisplay = newStep.map((step, index) => {

    return (
      <div className="p-2 px-2 flex rounded-full md:rounded-md items-center md:items-start transition-all duration-500 gap-3  cursor-pointer shrink-0">
        <div
          className={`hidden md:flex w-[23px] h-[23px] rounded-full  justify-center shrink-0 transition-all duration-500 md:border md:mb-10  items-center mt-1 ${
            step.completed
              ? "bg-[#007954] md:border-[#007954]"
              : "bg-[#F6FCFA] md:bg-white md:border-[#0EB683]"
          }`}
        >
          {step.completed && <BsCheck2 color="#fff" />}
          {!step.completed && (
            <span
              className={`${
                step.completed
                  ? "text-white transition-all duration-500"
                  : "text-[#0EB683]"
              } hidden`}
            >
              {index}
            </span>
          )}
          {!step.completed && step.selected ? (
            <div className="hidden md:block dot rounded-full h-2 w-2 bg-[#0EB683]"></div>
          ) : null}
        </div>
        <div className="details flex flex-col text-left">
          <div className="flex items-center gap-4">
            <span
              className={`${
                step.selected
                  ? "text-[#0EB683] md:text-[#0EB683] text-sm"
                  : "text-[8px] leading-[18px] text-[#5C6F7F]"
              } md:text-[20px] md:leading-[30px] md:font-semibold transition-all duration-500`}
            >
              <p>{step.description.step}</p>
            </span>
            <span className="md:hidden block">
              <HiOutlineChevronRight size={23} />
            </span>
          </div>
          <span className="text-[14px] leading-[21px] text-[#5C6F7F] hidden md:inline ">
            {step.description.description}
          </span>
        </div>
      </div>
    );
  });

    return <div className="flex xs:flex-row md:flex-col xs:overflow-x-auto">{stepsDisplay}</div>;
};

export default Stepper;
