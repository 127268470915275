import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    ttfcx: {
      green: "#0EB683",
      light: "#fff",
      subTextColor: "#5C6F7F",
      Neutral200: "#E2E9F0",
      Neutral100: "#F2FAF7",
      blackText: "#000000",

    },
  },
  breakPoints:{
    pad:"68px"
  }
});

export default theme;
