import React, { SetStateAction, useEffect, useState } from "react";
import Controller from "./Controller";
import InputField from "../comp/InputField";
import CustomSelect from "../../../components/base-components/CustomSelect";
import { GenderData, IDTypeOptions } from "../IndividualUser";
import { BsCheckCircleFill } from "react-icons/bs";
import { useGetCountry, useGetState } from "../../../sevices/hooks/tags";
import { BiUserCheck } from "react-icons/bi";
import { MdOutlineClose } from "react-icons/md";
import SmartCameraWeb from "../../../components/SmartCameraWeb";
import { useShowSelfie } from "../../../store/useShowSelfie";
import SelfiePopUp from "../comp/SelfiePopUp";
import { IFormData, useForm } from "../../../context/OnboardingFormContext";
import { useUserContext } from "../../../context/UserContext";

export interface IOnboardingUser {
  handleClick: (direction: any) => void;
  currentStep: number;
  setErrMsg: React.Dispatch<SetStateAction<string>>;
  steps: {
    id: number;
    description: string;
    step: string;
  }[];
}

const UserDetails = ({
  handleClick,
  currentStep,
  steps,
  setErrMsg,
}: IOnboardingUser) => {

  //@ts-ignore
  const userData = JSON.parse(localStorage.getItem("user")) || {};

  const { state, dispatch } = useForm();
  // const { user } = useUserContext();
  const setSelfieModal = useShowSelfie((state) => state.setSelfieModal);
  const showSelfieModal = useShowSelfie((state) => state.showSelfieModal);



  const [formData, setFormData] = useState({
    firstName: userData.firstName || "",
    lastName: userData.lastName || "",
    middleName: userData.middleName || "",
    dob: userData.dob || "",
    email: userData.email || "",
    phoneNumber: userData.phoneNumber || "",
    bvn: userData.bvn || "",
    address1: userData.address1 || "",
    address2: userData.address2 || "",
    city: userData.city || "",
    postCode: userData.postCode || "",
    IDType: userData.IDType || null,
    state: userData.state || null,
    // ... set default values for other fields
  });

  const Icountry = useGetCountry();
  const states = useGetState();

  const countryOptions =
    Icountry?.data?.data.data.map(
      (
        {
          countryCode,
          name,
          index,
        }: { countryCode: string; name: string; index: number },
        id: number
      ) => ({
        label: name,
        slug: countryCode,
        id,
      })
    ) || [];

  const stateOptions =
    states?.data?.data.data.map(
      ({ name, index }: { name: string; index: number }, id: number) => ({
        label: name,
        slug: name,
        id,
      })
    ) || [];

  const handleInputChange = (name: string, value: string) => {
    setErrMsg("")
    dispatch({ type: "UPDATE_FIELD", field: name as keyof IFormData, value });

    // Update the local state (formData) if needed
    setFormData({
      ...formData,
      [name]: value,
    });
  };



  useEffect(() => {
    // Create an object to store the pre-filled data from userData
    const preFilledData = {
      firstName: state.firstName ? state.firstName : userData.firstName || "",
      lastName: state.lastName ? state.lastName : userData.lastName || "",
      middleName: userData.middleName || "",
      dob: state.dob ? state.dob : userData.dob || "",
      email: state.email ? state.email : userData.email || "",
      phoneNumber: state.phoneNumber
        ? state.phoneNumber
        : userData.phoneNumber || "",
      bvn: state.bvn ? state.bvn : userData.bvn || "",
      address1: state.address1 ? state.address1 : userData.address1 || "",
      address2: state.address2 ? state.address1 : userData.address2 || "",
      city: userData.city || "",
      postCode: userData.postCode || "",
      IDType: userData.IDType || null,
      state: state.state ? state.state : userData.state || null,
    };

    // Update the local state (formData) with the pre-filled data
    setFormData(preFilledData);

    // Dispatch actions to update the context state with pre-filled data
    for (const field in preFilledData) {
      if (preFilledData.hasOwnProperty(field)) {
        //@ts-ignore
        dispatch({ type: "UPDATE_FIELD", field, value: preFilledData[field] });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className=" pb-4 pt-10">
        <h1 className="text-[24px] leading-[36px] font-bold pb-1 text-[#071827] ">
          User Details
        </h1>
        <p className=" text-[#5C6F7F] text-[16px] font-normal leading-[24px]">
          Complete basic details about yourself:
        </p>
      </div>
      <div className="md:w-full md:max-w-lg">
        <form
          // onSubmit={handleFormSubmit}
          className="bg-none md:bg-white flex flex-col gap-3 p-0 md:p-5 "
        >
          <div className="relative ">
            <InputField
              label="First Name"
              name="firstName"
              type="text"
              required={true}
              value={state.firstName as string}
              onChange={(value: string) =>
                handleInputChange("firstName", value)
              }
            />
            <div className="flex items-center gap-2  ">
              <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]  ">
                Input your first name exactly as it appears on your bank account
                details.
              </small>
            </div>
          </div>
          <div className="relative ">
            <InputField
              label="Middle Name"
              name="middleName"
              type="text"
              required={true}
              value={state.middleName}
              onChange={(value: string) =>
                handleInputChange("middleName", value)
              }
            />
          </div>
          <div className="relative ">
            <InputField
              label="Last Name"
              name="lastName"
              type="text"
              required={true}
              value={state.lastName}
              onChange={(value: string) => handleInputChange("lastName", value)}
            />
            <div className="flex items-center gap-2  ">
              <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]  ">
                Input your last name exactly as it appears on your bank account
                details.
              </small>
            </div>
          </div>
          <div className="relative w-full">
            <small className="text-[#0B0C0E] text-sm font-normal ">
              <span className="text-[#E01A00]">*</span>
              Gender{" "}
            </small>
            <CustomSelect
              //@ts-ignore
              selectedOption={state.gender}
              setSelectOption={(opt) => handleInputChange("gender", opt.slug)}
              placeholder={"Choose Gender"}
              options={GenderData || []}
              name={"gender"}
              // errors={""}
            />
            {/* <div className="text-red-500 text-xs">{errorMessages.gender}</div> */}
          </div>
          <div className="relative ">
            <InputField
              label="Date of Birth"
              name="dob"
              type="date"
              required={true}
              value={state.dob}
              onChange={(value: string) => handleInputChange("dob", value)}
            />
            <div className="flex items-center gap-2  ">
              <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]  ">
                Enter your correct{" "}
                <span className="text-[#0eb683]"> Date of Birth</span>
              </small>
            </div>
          </div>
          <div className="relative ">
            <InputField
              label="Phone Number"
              name="phoneNumber"
              type="tel"
              maxChar={11}
              required={true}
              value={state.phoneNumber}
              onChange={(value: string) =>
                handleInputChange("phoneNumber", value)
              }
            />
            {/* {(state.phoneNumber?.trim()?.length || 0) > 1 && (
              <BsCheckCircleFill
                size={20}
                className="text-[#0eb683] absolute top-[50%]  right-3"
              />
            )} */}
            <div className="flex items-center gap-2  ">
              <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]  ">
                This should be the number linked to your BVN. You will be
                required to confirm this via a One-Time-Pin (OTP)
              </small>
            </div>
          </div>
          <div className="relative ">
            <InputField
              label="Email"
              name="email"
              type="email"
              required={true}
              disabled={true}
              value={state.email}
              onChange={(value: string) => handleInputChange("email", value)}
            />
            {(state.email?.trim()?.length || 0) > 1 && (
              <BsCheckCircleFill
                size={20}
                className="text-[#0eb683] absolute top-[50%] -translate-y-1/2 right-3"
              />
            )}
          </div>
          <div className="relative ">
            <InputField
              label="BVN"
              name="number"
              type="bvn"
              required={true}
              value={state.bvn}
              maxChar={11}
              onChange={(value: string) => handleInputChange("bvn", value)}
            />
            {state.bvn.trim().length === 11 && (
              <BsCheckCircleFill
                size={20}
                className="text-[#0eb683] absolute top-[40%] -translate-y-1/2 right-2"
              />
          )}
            <div className="flex items-center gap-2  ">
              <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]  ">
                We use your{" "}
                <span className="text-[#0eb683]">
                  {" "}
                  Bank Verification Number
                </span>{" "}
                <span className="font-bold underline">only</span> for identity
                verification
              </small>
            </div>
          </div>
          <div className="relative ">
            <InputField
              label="Address Line 1"
              name="address1"
              type="text"
              required={true}
              value={state.address1}
              onChange={(value: string) => handleInputChange("address1", value)}
            />
          </div>
          <div className="relative ">
            <InputField
              label="Address Line 2"
              name="address2"
              type="text"
              // required={true}
              value={state.address2}
              onChange={(value: string) => handleInputChange("address2", value)}
            />
          </div>
          <div className="flex items-center justify-between gap-4">
            <div className="mb-3 w-[50%] relative">
              <small className="text-[#0B0C0E] text-sm font-normal ">
                <span className="text-[#E01A00]">*</span>
                Country{" "}
              </small>
              <CustomSelect
                //@ts-ignore
                selectedOption={state.country}
                setSelectOption={(country) =>
                  handleInputChange("country", country.slug)
                }
                placeholder={"Select Country"}
                options={countryOptions || []}
                // errors={errorMessages}
                name={"country"}
              />
              {/* <div className="text-red-500 text-xs">{errorMessages.country}</div> */}
            </div>

            <div className="mb-3 w-[50%] relative">
              <small className="text-[#0B0C0E] text-sm font-normal ">
                <span className="text-[#E01A00]">*</span>
                State{" "}
              </small>

              <CustomSelect
                //@ts-ignore
                selectedOption={state.state}
                setSelectOption={(state) =>
                  handleInputChange("state", state.slug)
                }
                placeholder={"Select State"}
                options={stateOptions || []}
                // errors={errorMessages}
                name={"state"}
              />
              {/* <div className="text-red-500 text-xs">{errorMessages.state}</div> */}
            </div>
          </div>
          <div className="relative ">
            <InputField
              label="City"
              name="city"
              type="text"
              required={true}
              value={state.city}
              onChange={(value: string) => handleInputChange("city", value)}
            />
          </div>
          <div className=" ">
            <InputField
              label="Post Code"
              name="postCode"
              type="text"
              required={true}
              value={state.postCode}
              onChange={(value: string) => handleInputChange("postCode", value)}
            />
          </div>
          <div className="relative w-full">
            <small className="text-[#0B0C0E] text-sm font-normal ">
              <span className="text-[#E01A00]">*</span>
              Select means of identification{" "}
            </small>
            <CustomSelect
              //@ts-ignore
              selectedOption={state.IDType}
              setSelectOption={(IDType) =>
                handleInputChange("IDType", IDType.slug)
              }
              placeholder={"ID Type"}
              name={"IDType"}
              // errors={errorMessages}
              options={IDTypeOptions || []}
            />
            {/* <span className="text-[#E01A00] absolute left-2 top-9">*</span> */}
            {/* <div className="text-red-500 text-xs">{errorMessages.IDType}</div> */}
          </div>
          <div className=" relative">
            <InputField
              label={`
                  Input  Selected ID Number

             `}
              name="idNumber"
              type="text"
              required={true}
              value={state.idNumber}
              onChange={(value: string) => handleInputChange("idNumber", value)}
            />
            <div className="flex items-center gap-2  ">
              <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]  ">
                Please enter ID Number{" "}
                <span className="text-[#0eb683]"> Associated</span>{" "}
                <span className="font-bold underline"></span> with the ID type
                you selected
              </small>
            </div>
          </div>
          <div>
            <small className="text-[#0B0C0E] text-sm font-normal ">
              <span className="text-[#E01A00]">*</span>
              Selfie Verification{" "}
            </small>
            <div
              onClick={() => setSelfieModal(true)}
              className="pt-3.5 pb-2.5 block w-full px-2.5 mt-0 bg-[#F2FAF7] border appearance-none hover:outline-none outline-[#0eb683] hover:ring-0 hover:border-[#0eb683] border-gray-200 rounded-[4px] cursor-pointer text-center "
            >
              {state.selfieData.length !== 0 ? (
                <span className="text-[#0EB683] flex items-center justify-center gap-2 ">
                  <BiUserCheck size={20} /> <span>Selfie Added</span>{" "}
                </span>
              ) : (
                <span className="text-[#0EB683] hover:underline">
                  Tap to begin
                </span>
              )}
            </div>
            {/* <div className="text-red-500 text-xs">{errorMessages.selfieData}</div> */}
            <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]  ">
              This is required to verify the ID number provided.
            </small>
          </div>
        </form>
        <Controller
          // userKyc={handleFormSubmit}
          handleClick={handleClick}
          currentStep={currentStep}
          steps={steps}
          setErrMsg={setErrMsg}
        />
      </div>
      <SelfiePopUp>
        <div className="flex items-center justify-between mb-4">
          <h1 className="text-base md:text-xl text-[#333333] font-bold ">
            Camera Access Request
          </h1>
          <div
            onClick={() => setSelfieModal(false)}
            className="cursor-pointer  hover:text-[#E01A00] flex items-center "
          >
            <MdOutlineClose size={25} />
          </div>
        </div>

        <div className=" bg-[#F2FAF7] border-2 rounded-md border-[#0eb683] h-full">
          <SmartCameraWeb
            setSelfieData={(data: any) => handleInputChange("selfieData", data)}
          ></SmartCameraWeb>
        </div>
      </SelfiePopUp>
    </div>
  );
};

export default UserDetails;
