import React from 'react'
import svgIcon from "../../assets/misc/benefits.svg";

const BeneficiaryEmptyState = () => {
  return (
    <div><div className=" flex mt-40  flex-col items-center justify-center ">
    <img src={svgIcon} alt="" className=""/>
    <h1 className=" text-lg md:text-2xl font-extrabold text-[#071827]">
      You have no beneficiaries
    </h1>
    <p className="text-[#5C6F7F] text-sm">
      Add and save your beneficiaries using the button
    </p>
  </div></div>
  )
}

export default BeneficiaryEmptyState