import React, { useRef, useState } from "react";
import {
  useAccountNgn,
  useAccountWithdrawal,
  useCreateNgnBeneficiary,
  useNgnBeneficiary,
  useNgnBeneficiaryIdentity,
  useWalletBalance,
} from "../../../sevices/hooks/wallets";
import { motion, AnimatePresence } from "framer-motion";
import AppNumericInput from "../../../components/widgets/AppNumericInput";
import { AiOutlineClose } from "react-icons/ai";
import FailedIcon from "../../../assets/iconsComponents/FailedIcon";
import SuccessMobile from "../../../assets/iconsComponents/SuccessMobile";
import { BsChevronLeft } from "react-icons/bs";
import ReactLoading from "react-loading";
import { numberWithCommas } from "../Withdrawals";
import AppCheckBox from "../../../components/widgets/AppCheckBox";
import OTPInput from "../../../components/widgets/OTPInput";
import { useRequestOtp } from "../../../sevices/hooks/users";
import { useNgnBeneficiaryState } from "../../../store/useNgnBeneficiary";
import { BankType } from "../../../sevices/hooks/meta-data";
import { IoMdClose } from "react-icons/io";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useOutletContext } from "react-router-dom";
type Props = {
  delete: () => void;
};
const NgnBeneficiaryModal: React.FC<Props> = ({delete: deleteAction}) => {
  const [withdrawalModal, setWithdrawalModal] = useState(false);
  const [amountValue, setAmountValue] = useState<any>({ value: 0 });
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [beneficiary, setBeneficiary] = useState(false);
  const [reference, setReference] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [showVerification, setShowVerification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const overLayRef = useRef(null);
  const getOtp = useRequestOtp(
    //@ts-ignore
    localStorage.getItem("user_id")
  );

  const [bank, setBank] = useState<{
    label?: string;
    value?: string;

    bank?: BankType;
  }>({});
  const showNgnBeneficiaryModal = useNgnBeneficiaryState(
    (state) => state.showBeneficiaryDetails
  );
  const selectedNgnBeneficiaryId = useNgnBeneficiaryState(
    (state) => state.selectedBeneficiaryId
  );
  const setShowNgnBeneficiaryModal = useNgnBeneficiaryState(
    (state) => state.setShowBeneficiaryDetails
  );
  // const ngnBeneficiaries = useNgnBeneficiaryState(state => state.beneficiaries)
  const NgnBeneficiaryDetails = useNgnBeneficiaryIdentity(
    selectedNgnBeneficiaryId
  );
  const withdrwal = useAccountWithdrawal();
  let regEx =
    /\b([1-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9]|[1-9][0-9][0-9][0-9][0-9]|[1-9][0-9][0-9][0-9][0-9][0-9]|[1-9][0-9][0-9][0-9][0-9][0-0][0-0])\b/;

  const accountNgn = useWalletBalance("ngn");
  const [userDetails] = useOutletContext<any>();
  const cot = 50 + (0.5 / 1000) * amountValue?.floatValue;

  //withdrawal
  const withdrawNgn = (e: any) => {
    setLoading(true);
    e.preventDefault();
    withdrwal
      .mutateAsync({
        amount: amountValue?.floatValue + cot,
        accountName: NgnBeneficiaryDetails?.data?.data?.data?.accountName,
        accountNumber: NgnBeneficiaryDetails?.data?.data?.data?.accountNumber,
        narration: reference,
        otp,
        bankCode: NgnBeneficiaryDetails?.data?.data?.data?.bankCode,
        bankId: NgnBeneficiaryDetails?.data?.data?.data?.bankId,
        channel: "WEB",
        currency: "NGN",
        cot,
      })
      .then((_res) => {
        setShowVerification(false);
        setShowSuccess(true);
      })
      .catch((e: any) => {
        setLoading(false);
        setFailed(true);
      });
  };
  const handleOverLayClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.target === overLayRef.current && setShowNgnBeneficiaryModal(false);
  };
  return (
    <AnimatePresence>
      {showNgnBeneficiaryModal && (
        <div>
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                // delay: 0.3,
                duration: 0.1,
              },
            }}
            onClick={handleOverLayClick}
            className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
          >
            <motion.div
              initial={{
                x: 600,
                scale: 0,
              }}
              animate={{
                x: 0,
                scale: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                x: 600,
                scale: 0,
                transition: {
                  delay: 0.3,
                },
              }}
              ref={overLayRef}
              className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173]"
            >
              <motion.div
                initial={{
                  x: 600,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className="flex flex-col bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative xxs:rounded-md md:rounded-l-md  p-5 md:px-7"
              >
                {/* <button
                  onClick={() => setShowNgnBeneficiaryModal(false)}
                  className="hover:bg-[#f2faf7] rounded-full p-2 self-end"
                >
                  <AiOutlineClose size={28} />
                </button> */}
                <div className="flex items-end justify-end ">
                  <div
                    onClick={() => setShowNgnBeneficiaryModal(false)}
                    className="close  flex items-center justify-center h-10 w-10  rounded-full
                  hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center self-end"
                  >
                    <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                      <IoMdClose size={29} className="" />
                    </span>
                  </div>
                </div>
                <div>
                  <h1 className="absolute top-0 font-bold text-xl text-[#071827] pb-2 pt-10">
                    NGN Withdrawal
                  </h1>
                  <p className="text-[#5C6F7F] font-medium pt-10 xxs:pt-5 text-left">
                    Provide the following details for your withdrawal.
                    Withdrawals are limited to{" "}
                    <span className="text-[#0eb683]">N50,000,000.00</span> per
                    transaction
                  </p>
                </div>
                <div className="flex flex-col gap-3 mt-5">
                  <div className=" flex items-center justify-between">
                    <h1 className=" font-medium text-[#5C6F7F]">Bank</h1>
                    <h1 className=" font-medium text-[#071827]">
                      {NgnBeneficiaryDetails?.data?.data?.data?.bankName}
                    </h1>
                  </div>
                  <div className="flex items-center justify-between mt-2">
                    <h1 className="text-[#5C6F7F] font-medium ">
                      Account Number
                    </h1>
                    <h1 className="text-[#071827] font-medium ">
                      {NgnBeneficiaryDetails?.data?.data?.data?.accountNumber}
                    </h1>
                  </div>
                  <div className="relative ">
                    <AppNumericInput
                      className="bg-[#F2FAF7]"
                      value={amountValue?.formattedValue}
                      onValueChange={(value: any) => {
                        setAmountValue(value);
                      }}
                      thousandSeparator={true}
                      prefix="₦ "
                      style={{
                        backgroundColor: "#F2FAF7",
                      }}
                      labelStyle={{ color: "#000", fontWeight: "bold" }}
                      info={`Available Balance: N${numberWithCommas(
                        userDetails?.ngnBalance
                      )}`}
                      infoStyle={{
                        textAlign: "end",
                        color: "#0EB683",
                        fontWeight: "bold",
                      }}
                      // rightLabel={`NGN100 transfer service fee applies`}
                      // rightLabelStyle={{
                      //   fontStyle: "",
                      //   fontSize: "15px",
                      //   color: "#ED0000",
                      // }}
                      // onKeyUp={validateInput}
                      placeholder="Amount"
                      isAllowed={(nValue: any) =>
                        nValue.value.length <= 7 &&
                        nValue.value.indexOf("-") === -1
                      }
                      isInsufficient={
                        parseFloat(userDetails?.ngnBalance) <
                        parseFloat(amountValue?.value)
                      }
                      insufficientLabel="Insufficient balance"
                    />
                    <div className="relative">
                      <input
                        name="Reference"
                        className="w-full  p-4 bg-[#F2FAF7] rounded-md border  outline-[#0eb683] placeholder:text-sm border-[#0eb683] "
                        required
                        placeholder="Narration"
                        onChange={(evt) => setReference(evt.target.value)}
                      />
                      <div className="text-[#ee0000] font-bold absolute top-3 left-2 ">
                        *
                      </div>
                    </div>
                  </div>
                </div>

                <div className="xxs:mt-20  md:mt-12 text-center">
                  <p className="text-base text-[#5C6F7F]">
                    Withdrawal Service Fee:{" "}
                    <span className="font-bold">NGN50</span>
                  </p>
                  <p className="text-base text-[#5C6F7F]">
                    Your <span className="font-bold">C.O.T</span> is :{" "}
                    <span className="font-bold">
                      NGN{" "}
                      {Number.isNaN((0.5 / 1000) * Number(amountValue?.value))
                        ? 0.0
                        : (0.5 / 1000) * Number(amountValue?.value)}
                    </span>
                  </p>
                  <span className="text-xs text-[#5c6f7f] text-center">
                    (C.O.T is charged at 50 Kobo per NGN1,000)
                  </span>
                </div>
                <button
                  disabled={
                    !parseFloat(amountValue?.value) ||
                    reference?.trim() === "" ||
                    parseFloat(userDetails?.ngnBalance) - 50 <
                      parseFloat(amountValue?.value) ||
                    !regEx.test(amountValue.floatValue)
                  }
                  className="bg-[#0eb683] rounded-md px-10 p-3 transition-all active:scale-90 mb-8 md:mt-[65px] xxs:mt-20 text-white  disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
                  onClick={() => {
                    setConfirmationModal(true);
                  }}
                >
                  Continue
                </button>
                <button

                  className="text-[#0eb683] bg-white p-3 border border-[#0eb683] transition-all active:scale-90 text-center rounded-md xxs:text-sm cursor-pointer hover:bg-[#E01A00] hover:text-[#fff] hover:border-[#E01A00]"
                  onClick={() => {
                    setShowNgnBeneficiaryModal(false);
                     deleteAction && deleteAction();
                  }}
                >
                  Delete Beneficiary
                </button>
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      )}
      {confirmationModal && (
        <div>
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                // delay: 0.3,
                duration: 0.1,
              },
            }}
            className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-40"
          >
            <motion.div
              initial={{
                x: 600,
                scale: 0,
              }}
              animate={{
                x: 0,
                scale: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                x: 600,
                scale: 0,
                transition: {
                  delay: 0.3,
                },
              }}
              className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173] "
            >
              <motion.div
                initial={{
                  x: 600,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className="flex flex-col bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative xxs:rounded-md md:rounded-l-md  p-5 md:px-7"
              >
                {/* <button
                  onClick={() => setConfirmationModal(false)}
                  className="hover:bg-[#f2faf7] rounded-full p-2 self-end"
                >
                  <AiOutlineClose size={28} />
                </button> */}
                <div className="flex items-end justify-end ">
                  <div
                    onClick={() => setConfirmationModal(false)}
                    className="close  flex items-center justify-center h-10 w-10  rounded-full
                  hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center self-end"
                  >
                    <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                      <IoMdClose size={29} className="" />
                    </span>
                  </div>
                </div>

                <div>
                  <div className="absolute top-6 flex items-center gap-4 ">
                    <button
                      className=" rounded-full bg-[#ECF6F3] p-2 flex transition-all active:scale-90 cursor-pointer "
                      onClick={() => {
                        setConfirmationModal(false);
                      }}
                    >
                      <BsChevronLeft />
                    </button>
                    <h1 className="  text-lg font-bold text-[#071827]">
                      Confirmation
                    </h1>
                  </div>
                  <p className="text-[#5C6F7F] pt-3">
                    Confirm the following details for your withdrawal
                  </p>
                </div>
                {/* <div className="flex justify-between mt-5">
                  <p className="text-[#071827] font-semibold">
                    You are transferring
                  </p>
                  <h1 className="text-[#0eb683] text-lg font-bold">
                    NGN {numberWithCommas(amountValue?.value)}
                  </h1>
                </div> */}
                <div className="flex justify-between xxs:mt-8 md:mt-5">
                  <p className="text-[#5C6F7F] text-sm  font-semibold">
                    You are transferring:
                  </p>
                  <h1 className="text-[#0eb683] text-base font-semibold">
                    NGN {numberWithCommas(amountValue?.value)}
                  </h1>
                </div>
                <hr />
                <div className="flex justify-between mt-2">
                  <p className="text-[#5C6F7F] text-sm font-semibold">
                    Withdrawal Service Fee:
                  </p>
                  <h1 className="text-[#0eb683] text-base font-semibold">
                    NGN {numberWithCommas(50)}
                  </h1>
                </div>
                <hr />
                <div className="flex justify-between mt-2">
                  <p className="text-[#5C6F7F] text-sm font-semibold">C.O.T:</p>
                  <h1 className="text-[#0eb683] text-base font-semibold">
                    NGN{" "}
                    {Number.isNaN((0.5 / 1000) * Number(amountValue?.value))
                      ? 0.0
                      : (0.5 / 1000) * Number(amountValue?.value)}
                  </h1>
                </div>
                <hr />
                <div className=" mt-4">
                  <h1 className="text-[#071827] font-extrabold">To:</h1>
                  <div className="flex flex-col gap-2 mt-2">
                    <div className="flex items-center justify-between">
                      <h1 className="text-[#5C6F7F]">Bank</h1>
                      <h2 className="text-[#071827] font-medium">
                        {NgnBeneficiaryDetails?.data?.data?.data?.bankName}
                      </h2>
                    </div>
                    <div className="flex items-center justify-between">
                      <h1 className="text-[#5C6F7F]">Account Number</h1>
                      <h2 className="text-[#071827] font-medium">
                        {NgnBeneficiaryDetails?.data?.data?.data?.accountNumber}
                      </h2>
                    </div>
                    <div className="flex items-center justify-between">
                      <h1 className="text-[#5C6F7F]">Beneficiary</h1>
                      <h2 className="text-[#071827] font-medium">
                        {NgnBeneficiaryDetails?.data?.data?.data?.accountName}
                      </h2>
                      {/*  */}
                    </div>
                    <div className="flex items-center justify-between">
                      <h1 className="text-[#5C6F7F]">Total Amount Debited </h1>
                      <h2 className="text-[#0eb683] text-lg font-bold">
                        NGN{" "}
                        {numberWithCommas(
                          amountValue?.value * 1 +
                            50 +
                            (0.5 / 1000) * Number(amountValue?.value)
                        )}
                      </h2>
                    </div>
                  </div>
                  <p className=" text-[#5C6F7F] text-sm text-center pt-10">
                    By clicking the button below, I agree my NGN balance would
                    be debited of the amount above immediately
                  </p>
                </div>
                <button
                  className=" bg-[#0eb683] rounded-md px-10 p-3 transition-all active:scale-90 xxs:mt-20 md:mt-16 text-white"
                  onClick={() => {
                    getOtp.mutateAsync({}).then(() => {
                      setShowVerification(true);
                    });
                    // setConfirmationModal(false);
                    setWithdrawalModal(false);
                    setShowModal(false);
                  }}
                >
                  Continue
                </button>
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      )}
      {showVerification && (
        <div>
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                // delay: 0.3,
                duration: 0.1,
              },
            }}
            className="confirmation_modal backdrop-blur-sm bg-[#13111137] fixed w-full h-full top-0 left-0 justify-center md:justify-end items-center z-50"
          >
            <motion.div
              initial={{
                x: 600,
                scale: 0,
              }}
              animate={{
                x: 0,
                scale: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                x: 600,
                scale: 0,
                transition: {
                  delay: 0.3,
                },
              }}
              className="flex h-screen items-end md:items-center  md:justify-center "
            >
              <motion.div
                initial={{
                  x: 600,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className="card flex flex-col bg-white h-[350px] w-full max-w-md md:max-w-md md:max-h-full rounded-t-3xl md:rounded-3xl p-10 "
              >
                <div className="flex flex-col gap-3 relative my-auto">
                  <button
                    onClick={() => {
                      setShowVerification(false);
                      setAmountValue("");
                    }}
                    className="text-right hover:bg-red-200 hover:text-red-600 rounded-full hover:rounded-full flex items-end justify-end md:text-slate-600 md:bg-transparent odd:absolute xxs:top-[-45px] xxs:right-[-30px] xxs:bg-red-200 xxs:text-red-600 md:top-[-61px] md:right-[-33px] p-2 hover:rotate-90 hover:transform  transition duration-150 ease-in-out  "
                  >
                    <AiOutlineClose size={28} />
                  </button>
                  <div className=" ">
                    <h1 className="text-3xl text-[#0eb683] font-extrabold self-center text-center ">
                      Verification
                    </h1>
                  </div>
                  <div className=" mt-1 md:mt-0">
                    <p className="text-[#5C6F7F] text-sm text-center">
                      Enter the 5-digit code sent to your email
                    </p>
                    <OTPInput
                      autoFocus
                      length={5}
                      className="otpContainer flex justify-between  w-full py-3 mt-4 md:mt-0 "
                      inputClassName="otpInput w-12 h-12 md:w-[50px] md:h-[50px] bg-white border border-[#0EB683] outline-[#0EB683] text-[#0EB683] text-2xl text-center font-bold rounded-lg"
                      autoCapitalize={true}
                      onChangeOTP={(OTP) => {
                        setOtp(OTP.toUpperCase());
                      }}
                      onPaste={(OTP) => {
                        setOtp(OTP.toUpperCase());
                      }}
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <button
                      onClick={() => {
                        setShowVerification(false);
                        setConfirmationModal(true);
                      }}
                      className="text-[#0eb683] active:scale-90 border border-[#0eb683] md:p-3 rounded-md transition-all md:w-40 xxs:w-32 xxs:p-[17px] flex items-center justify-center  "
                    >
                      {" "}
                      <span>
                        <MdOutlineKeyboardBackspace />
                      </span>{" "}
                      <span className="w-14">Back</span>{" "}
                    </button>
                    <button
                      // disabled={}
                      onClick={withdrawNgn}
                      className="bg-[#0eb683]  self-center justify-center md:w-48 border border-[#0eb683]  text-white md:p-3 rounded-md transition-all active:scale-90  md:mt-0 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90 xxs:w-40 xxs:p-[17px]  "
                    >
                      {loading ? (
                        <div className="xxs:ml-14 md:ml-[68px]">
                          <ReactLoading
                            type="spinningBubbles"
                            color="#fff"
                            height="27px"
                            width="27px"
                          />
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      )}
      <AnimatePresence>
        {showSuccess && (
          <div>
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="confirmation_modal bg-[#13111173] backdrop-blur-sm fixed w-full top-0 left-0 justify-center md:justify-end h-full items-center z-50"
            >
              <motion.div
                initial={{
                  x: 600,
                  scale: 0,
                }}
                animate={{
                  x: 0,
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 600,
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                className="flex h-screen items-center justify-center md:justify-end"
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="desktop-card md:flex hidden justify-center flex-col gap-6 bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative xxs:rounded-md md:rounded-l-md p-5 "
                >
                  <div className="icon self-center">
                    <SuccessMobile width={"200px"} height={"200px"} />
                  </div>
                  <h1 className="text-2xl font-bold text-[#071827] self-center">
                    Withdrawal successful
                  </h1>

                  <p className="text-sm text-[#5C6F7F] self-center text-center">
                    Your recipient will receive value shortly
                  </p>

                  <button
                    onClick={() => {
                      setShowSuccess(false);
                      setConfirmationModal(false);
                      setShowNgnBeneficiaryModal(false);
                    }}
                    className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                  >
                    Done
                  </button>
                </motion.div>
                {/* Mobile */}

                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="mobile-card flex md:hidden justify-center flex-col gap-8 bg-white  w-full max-w-sm h-[450px] rounded-3xl p-5 "
                >
                  <div className="icon self-center">
                    <SuccessMobile width={"100px"} height={"100px"} />
                  </div>
                  <h1 className="text-2xl font-bold text-[#071827] self-center">
                    Withdrawal successful
                  </h1>

                  <p className="text-sm text-[#5C6F7F] self-center text-center">
                    Your recipient will receive value shortly
                  </p>

                  <button
                    onClick={() => {
                      setShowSuccess(false);
                      setConfirmationModal(false);
                      setShowNgnBeneficiaryModal(false);
                    }}
                    className="bg-[#0eb683] text-white rounded-md px-16 py-3 self-center transition-all active:scale-90"
                  >
                    Done
                  </button>
                </motion.div>
              </motion.div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {failed && (
          <div>
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="confirmation_modal bg-[#13111173] backdrop-blur-sm fixed w-full top-0 left-0 justify-center md:justify-end h-full items-center z-50"
            >
              <motion.div
                initial={{
                  x: 600,
                  scale: 0,
                }}
                animate={{
                  x: 0,
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 600,
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                className="flex h-screen items-center justify-center md:justify-end"
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="desktop-card md:flex hidden justify-center flex-col gap-6 bg-white  w-full max-w-md h-[450px] md:h-full rounded-l-3xl p-5 "
                >
                  <div className="icon self-center">
                    <FailedIcon width={"200px"} height={"200px"} />
                  </div>
                  <h1 className="text-2xl font-bold text-[#071827] self-center">
                    Failed
                  </h1>

                  <p className="text-sm text-[#5C6F7F] self-center text-center">
                    Sorry we could not process your request
                  </p>

                  <button
                    onClick={() => {
                      setShowVerification(false);
                      setFailed(false);
                    }}
                    className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                  >
                    Retry
                  </button>
                </motion.div>
                {/* Mobile */}

                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="mobile-card flex md:hidden justify-center flex-col gap-8 bg-white  w-full max-w-sm h-[450px] rounded-3xl p-5 "
                >
                  <div className="icon self-center">
                    <FailedIcon width={"100px"} height={"100px"} />
                  </div>
                  <h1 className="text-2xl font-bold text-[#071827] self-center">
                    Failed
                  </h1>

                  <p className="text-sm text-[#5C6F7F] self-center text-center">
                    Sorry we could not process your request!
                  </p>

                  <button
                    onClick={() => {
                      setShowVerification(false);
                      setFailed(false);
                    }}
                    className="bg-[#0eb683] text-white rounded-md px-16 py-3 self-center transition-all active:scale-90"
                  >
                    Retry
                  </button>
                </motion.div>
              </motion.div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </AnimatePresence>
  );
};

export default NgnBeneficiaryModal;
