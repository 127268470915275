import React, { useEffect, useState } from "react";
import brandLogo from "../../assets/misc/design-top.svg";
import brandLogo2 from "../../assets/misc/design-bottom.svg";

import logo from "../../assets/misc/logo-on-dark.svg";
import PasswordInput from "../../components/base-components/PasswordInput";
import { useResetPassword } from "../../sevices/hooks/users";
import { useLocation, useNavigate } from "react-router-dom";
import { FcCancel, FcCheckmark } from "react-icons/fc";
import { FaSpinner } from "react-icons/fa";

const NewPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordValid, setpasswordValid] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [code, setCode] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [showModal, setShowModal] = useState(false);

  const resetPassword = useResetPassword();

  const onSubmitPassword = (e: any) => {
    //  e.preventDefault()
    setShowModal(true);
    resetPassword
      .mutateAsync({
        newPassword: newPassword.password,
        email: email,
        verificationCode: code?.toString(),
      })
      .then((res) => {
        setResponse(true);
        // console.log(res, "response");
        if (res.status >= 200 && res.status <= 299) {
          setSuccess(true);
        } else {
          setSuccess(false);
        }
        setResponseMsg(res.data?.data);
      })
      .catch((e) => {
        setResponse(true);
        setSuccess(false);
        setResponseMsg(e.response.data);
      });
  };

  useEffect(() => {
    let token = new URLSearchParams(location.search).get("code") as string;
    let email = new URLSearchParams(location.search).get("email") as string;
    setCode(token);
    setEmail(email);

    // Check for password validity
    if (
      newPassword.password === newPassword.confirmPassword &&
      newPassword.password.length > 5
    ) {
      setPasswordMatch(true);
    } else {
      let regularExpression =
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;
      setpasswordValid(regularExpression.test(newPassword.password));

      setPasswordMatch(false);
    }
  }, [newPassword.password, newPassword.confirmPassword, location.search]);

  return (
    <div>
      <img src={brandLogo} alt="" className="absolute w-28  md:w-44 " />
      <img
        src={brandLogo2}
        alt=""
        className="absolute w-28 md:w-44 right-0 bottom-0"
      />
      <div className=" flex items-center flex-col gap-5 bg-[#0C0D1F] h-screen justify-center ">
        <img
          onClick={() => navigate("/")}
          src={logo}
          alt=""
          className=" w-60 inline"
        />

        <h1 className="text-white font-bold text-3xl pt-10">
          Create new password
        </h1>
        <div className="input-wrapper flex flex-col gap-3 bg-[#0C0D1F] p-7 rounded-md mt-8 w-full max-w-lg">
          <PasswordInput
            label="Enter password"
            value={newPassword.password}
            onChange={(e: any) => {
              setNewPassword({ ...newPassword, password: e.target.value });
            }}
            name={"password"}
          />
          {!passwordValid && (
            <p className="text-xs text-[#0eb683]">
              Password must be 6 characters or greater, and must contain a
              special character, a number and an uppercase character
            </p>
          )}
          <PasswordInput
            label="Confirm password"
            value={newPassword.confirmPassword}
            onChange={(e: any) => {
              setNewPassword({
                ...newPassword,
                confirmPassword: e.target.value,
              });
              // checkPasswordValidity(e.target.value);
            }}
            name={"confirmPassword"}
          />
          {passwordValid && !passwordMatch && (
            <p className="text-xs text-red-500">Passwords do not match!</p>
          )}
        </div>
        <button
          className="bg-[#0eb683] px-28 md:px-46 py-4 rounded-md text-white cursor-pointer disabled:bg-[#73ad9c] disabled:cursor-not-allowed"
          disabled={!passwordMatch}
          onClick={onSubmitPassword}
        >
          Reset password
        </button>
      </div>
      {showModal && (
        <div className="modal bg-[#fffc] fixed w-full h-full top-0 left-0 flex justify-center items-center">
          {response ? (
            <div className="card bg-white shadow-lg shrink-0 w-full max-w-sm h-60 rounded-xl p-8 flex flex-col gap-3">
              <h2 className="text-center text-xl font-semibold">
                {success
                  ? "Password Reset Successful"
                  : "Password reset failed"}
              </h2>
              <div
                className={`msg p-5 flex items-center gap-3 rounded-md ${
                  success ? "bg-green-100" : "bg-red-100"
                }`}
              >
                {success ? <FcCheckmark size={24} /> : <FcCancel size={24} />}
                <p className={success ? "text-green-500" : "text-red-500"}>
                  {responseMsg}
                </p>
              </div>
              <button
                className="bg-[#0eb683] px-10 py-3 rounded-md text-white cursor-pointer mt-5 self-center transition-all active:scale-90"
                onClick={() => {
                  success && navigate("/login");
                  setShowModal(false);
                  setResponse(false);
                }}
              >
                {success ? "Proceed to Login" : "Retry"}
              </button>
            </div>
          ) : (
            <FaSpinner size={24} color="#0eb683" className="animate-spin" />
          )}
        </div>
      )}
    </div>
  );
};

export default NewPassword;
