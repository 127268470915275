import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useCheckEmail,
  useInvitationVerify,
} from "../../../sevices/hooks/users";
import { IPotentialUser } from "../../../types/Users";
import { useUserContext } from "../../../context/UserContext";
import LoadingScreen from "../../Notifications/LoadingScreen2";

const Refresh = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { user } = useUserContext();
  const checkEmail = useCheckEmail();

  useEffect(() => {
    setLoading(true)
    if (user) {
      checkEmail
        .mutateAsync({ email: user.email })
        .then((res) => {
   
          if (res.data === true) {
            navigate(
              `/refresh-initiated?user=${user?.id}&code=${user?.invitationCode}&at=${user?.accountType}`
            );
          }
        })

        .catch((err) => {
          navigate("/login");
        });
    }
  }, []);
  return (
    <div className="invite-screen">
      {loading && (
        <LoadingScreen msg="Collecting users info..." bg="#f2faf799" />
      )}
    </div>
  );
};

export default Refresh;
