import React, { useState } from "react";
import Logo from "../../../assets/misc/logo-on-light.svg";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Stepper from "./Stepper";
import { useLocation } from "react-router-dom";
import BrandImg from "../../../assets/misc/design.svg";

interface IStepperSideBarProps {
  currentStep: number;
  step: Array<{ id: number; description: string; step: string }>;
  at: string | null;
}

const StepperSideBar = ({ currentStep, step, at }: IStepperSideBarProps) => {
  const navigate = useNavigate();

  return (
    <div className="transition-all md:w-72 lg:w-[420px] overflow-x-hidden flex flex-col ">
      <div className="lg:pl-7">
        <div className="logo  py-10 hidden md:block">
          <img src={Logo} alt="" className=" w-32" />
        </div>
        <header className="flex md:hidden justify-between items-center p-3">
          <button
            className="w-10 h-10 flex justify-center items-center bg-[#ECF6F3] rounded-full"
            onClick={() => navigate(-1)}
          >
            <BsChevronLeft size={18} />
          </button>
          <h1 className="mx-auto font-semibold text-lg">Onboarding</h1>
          <div />
        </header>
        <div className="mt-7 ">
          <Stepper step={step} userType={at} currentStep={currentStep} />
        </div>
      </div>
      <div className="brand hidden md:block mt-auto ">
        <img src={BrandImg} className="w-36" alt="" />
      </div>
    </div>
  );
};

export default StepperSideBar;
