import { motion } from "framer-motion";
import { useRef } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import SuccessMobile from "../../../assets/iconsComponents/SuccessMobile";
import AppSelectInput from "../../../components/base-components/AppSelectInput";
import AppCheckBox from "../../../components/widgets/AppCheckBox";
import AppNumericInput from "../../../components/widgets/AppNumericInput";
import { IWithdrawal } from "../../../types/Withdrawal";
import { numberWithCommas } from "../Withdrawals";

const WithdrawalModal: React.FC<IWithdrawal> = ({
  banksOptions,
  userDetails,
  resolveName,
  bank,
  accountNumber,
  accountName,
  balance,
  refValue,
  error,
  valueFormatted,
  value,
  valueFloat,
  failed,
  confirmation,
  success,
  loading,
  firstName,
  lastName,
  accNumber,
  accName,
  withdrawalModal,
  showErr,
  setLoading,
  setSuccess,
  setShowErr,
  setFailed,
  beneficiary,
  setVerify,
  //       // otp={OTP}
  setBank,
  verify,
  setOtp,
  withdraw,
  setError,
  setAccount,
  setRefValue,
  setValue,
  bankName,
  setAccNumber,
  setModal,
  setAccName,
  setValues,
  setConfirmation,
}) => {
  const overLayRef = useRef(null);
  const closeModal = () => {
    setModal(false);
    setAccName("");
    setBank({});
    setAccount("");
    setValue("");
    setRefValue("");
    setConfirmation(false);
    setFailed("");
    setShowErr(false);
  };
  let regEx =
    /\b([1-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9]|[1-9][0-9][0-9][0-9][0-9]|[1-9][0-9][0-9][0-9][0-9][0-9]|[1-9][0-9][0-9][0-9][0-0][0-0][0-0])\b/;

  const handleOverLayClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.target === overLayRef.current && closeModal();
  };
  return (
    <div>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            // delay: 0.3,
            duration: 0.1,
          },
        }}
        onClick={handleOverLayClick}
        className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
      >
        <motion.div
          initial={{
            x: 600,
            scale: 0,
          }}
          animate={{
            x: 0,
            scale: 1,
            transition: {
              duration: 0.3,
            },
          }}
          exit={{
            x: 600,
            scale: 0,
            transition: {
              delay: 0.3,
            },
          }}
          ref={overLayRef}
          className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173] "
        >
          <motion.div
            initial={{
              x: 600,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.3,
              },
            }}
            exit={{
              x: 100,
              opacity: 1,
              transition: {
                delay: 3,
                duration: 3,
              },
            }}
            className="flex flex-col bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative xxs:rounded-md md:rounded-l-md  p-5 md:px-7 overflow-scroll"
          >
            {!success ? (
              <div>
                <div className="flex items-end justify-end ">
                  <div
                    onClick={closeModal}
                    className="close  flex items-center justify-center h-10 w-10  rounded-full
                    hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center self-end"
                  >
                    <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                      <IoMdClose size={29} className="" />
                    </span>
                  </div>
                </div>
                {!confirmation ? (
                  <>
                    <div>
                      <h1 className="font-bold text-xl text-[#071827]  absolute top-6">
                        Withdraw
                      </h1>
                      <p className="text-[#5C6F7F] font-medium pt-10 xxs:pt-5 text-left">
                        Provide the following details for your withdrawal.
                        Withdrawals are limited to{" "}
                        <span className="text-[#0eb683]">N50,000,000.00</span>{" "}
                        per transaction
                      </p>
                    </div>
                    <div className="flex flex-col gap-2 mt-7">
                      {error ? (
                        <div className="text-[#ee0000] truncate w-38">
                          {error}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="relative">
                        <AppSelectInput
                          options={banksOptions || []}
                          label=""
                          placeholder="Bank"
                          handleChange={(bank: any) => {
                            setBank(bank);
                          }}
                        />
                        <div className="text-[#ee0000] font-bold absolute top-3 left-3 ">
                          *
                        </div>
                      </div>
                      <div className="relative">
                        <input
                          type="number"
                          name="accNumber"
                          id="accNumber"
                          maxLength={10}
                          placeholder="Account Number"
                          className="p-4 rounded-md bg-[#F2FAF7] border w-full outline-[#0eb683] "
                          required
                          value={accountNumber}
                          onChange={(evt) => {
                             const inputValue = evt.target.value;
                            // setAccNumber(inputValue);
                            // setError("");
                            if (inputValue.length <= 15) {
                              setAccNumber(inputValue);
                              setError("");
                            } else {
                              // Trim the input value to 15 characters
                              setAccNumber(inputValue.slice(0, 15));
                              setError("");
                            }
                          }}
                        />

                        <div className="font-bold text-lg  ">
                          {resolveName
                            ? "Validating details.."
                            : accountName && accountNumber.length === 10
                            ? accountName
                            : error
                            ? ""
                            : ""}
                        </div>
                        <div className="text-[#ee0000] font-bold absolute top-3 left-2 ">
                          *
                        </div>
                      </div>

                      <div className="relative">
                        <AppNumericInput
                          className="bg-[#F2FAF7]"
                          value={valueFormatted}
                          onValueChange={(value: any) => {
                            setValues(value);
                          }}
                          thousandSeparator={true}
                          prefix="₦ "
                          style={{
                            backgroundColor: "#F2FAF7",
                          }}
                          labelStyle={{ color: "#000", fontWeight: "bold" }}
                          info={`Available Balance: N${numberWithCommas(
                            balance
                          )}`}
                          infoStyle={{
                            textAlign: "end",
                            color: "#0EB683",
                            fontWeight: "bold",
                          }}
                          // rightLabel={`NGN100 transfer service fee applies`}
                          // rightLabelStyle={{
                          //   fontStyle: "bold",
                          //   fontSize: "15px",
                          //   color: "#ED0000",
                          // }}
                          placeholder="Amount"
                          // isAllowed={(nValue: any) => nValue.value.length <= 7}
                          isAllowed={(nValue: any) =>
                            nValue.value.length <= 7 &&
                            nValue.value.indexOf("-") === -1
                          }
                          isInsufficient={
                            parseFloat(balance) <
                            //@ts-ignore
                            parseFloat(value)
                          }
                          insufficientLabel="Insufficient balance"
                        />
                        <div className="text-[#ee0000] font-bold absolute top-3 left-[9px] ">
                          *
                        </div>
                      </div>
                    </div>
                    <div className=" relative">
                      <input
                        type="text"
                        name="input"
                        id="referenceInput"
                        value={refValue}
                        placeholder="Narration"
                        className="p-4 rounded-md bg-[#F2FAF7] border w-full outline-[#0eb683]"
                        required
                        onChange={(e) => {
                          setRefValue(e.target.value);
                        }}
                      />
                      <div className="text-[#ee0000] font-bold absolute top-3 left-[9px] ">
                        *
                      </div>
                    </div>

                    <div className="xxs:mt-20  md:mt-12 text-center">
                      <p className="text-base text-[#5C6F7F]">
                        Withdrawal Service Fee:{" "}
                        <span className="font-bold    text-[#0eb683]">
                          NGN50
                        </span>
                      </p>
                      {/* <p className="text-base text-[#5C6F7F]">
                        Your{" "}
                        <span className="font-bold   text-[#0eb683]">
                          C.O.T
                        </span>{" "}
                        is :{" "}
                        <span className="font-bold">
                          NGN{" "}
                          {Number.isNaN((Number(value) * 1) / 1000)
                            ? 0.0
                            : (Number(value) * 1) / 1000}
                        </span>
                      </p> */}
                      <p className="text-base text-[#5C6F7F]">
                        Your{" "}
                        <span className="font-bold   text-[#0eb683]">
                          C.O.T
                        </span>{" "}
                        is :{" "}
                        <span className="font-bold">
                          NGN{" "}
                          {Number.isNaN((0.5 / 1000) * Number(value))
                            ? 0.0
                            : (0.5 / 1000) * Number(value)}
                        </span>
                      </p>
                    <span className="text-xs text-[#5c6f7f] text-center">
                      (C.O.T is charged at 50 Kobo per NGN1,000)
                    </span>
                    </div>
                    <button
                      //@ts-ignore
                      disabled={
                        !bankName ||
                        refValue?.trim() === "" ||
                        !accountNumber ||
                        error ||
                        !parseFloat(value) ||
                        parseFloat(balance) - 50 < parseFloat(value) ||
                        !regEx.test(valueFloat) ||
                        parseFloat(balance) - (0.5 / 1000) * Number(value) <
                          parseFloat(value)
                      }
                      onClick={() => {
                        setConfirmation(true);
                      }}
                      className="bg-[#0eb683] rounded-md px-10 p-3 xxs:mt-20 md:mt-12 transition-all active:scale-90 w-full  text-white  disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90 select-none tracking-wider"
                    >
                      Continue
                    </button>
                  </>
                ) : (
                  <>
                    {/* <button
                        onClick={() => {
                          setConfirmationModal(false);
                          setAccountName("");
                          setBank({});
                          setAccountNumber("");
                          setAmountValue("");
                          setReferenceValue("");
                        }}
                        className="hover:bg-[#f2faf7] rounded-full p-2 self-end"
                      >
                        <AiOutlineClose size={28} />
                      </button> */}

                    <div>
                      <div className="flex items-center gap-4 absolute top-6 ">
                        <button
                          className=" rounded-full bg-[#ECF6F3] p-2 flex transition-all active:scale-90 cursor-pointer overflow-hidden h-10 w-10 justify-center items-center"
                          onClick={() => {
                            setConfirmation(false);
                            setFailed("");
                            setShowErr(false);
                          }}
                        >
                          <BsChevronLeft />
                        </button>
                        <h1 className="text-lg font-bold text-[#071827]">
                          Confirmation
                        </h1>
                      </div>
                      <p className="text-[#5C6F7F] pt-3 select-none text-base tracking-tighter">
                        Confirm the following details for your withdrawal
                      </p>
                    </div>
                    <div className="flex justify-between xxs:mt-8 md:mt-5">
                      <p className="text-[#5C6F7F] text-sm font-semibold">
                        You are Withdrawing:
                      </p>
                      <h1 className="text-[#0eb683] text-base font-semibold">
                        NGN {numberWithCommas(value)}
                      </h1>
                    </div>
                    <hr />

                    <div className="flex justify-between mt-2">
                      <p className="text-[#5C6F7F] text-sm font-semibold">
                        C.O.T:
                      </p>
                      <h1 className="text-[#0eb683] text-base font-semibold">
                        NGN{" "}
                        {Number.isNaN((0.5 / 1000) * Number(value))
                          ? 0.0
                          : (0.5 / 1000) * Number(value)}
                      </h1>
                    </div>
                    <hr />
                    <div className="flex justify-between mt-2">
                      <p className="text-[#5C6F7F] text-sm font-semibold">
                        Withdrawal Service Fee:
                      </p>
                      <h1 className="text-[#0eb683] text-base font-semibold">
                        NGN {numberWithCommas(50)}
                      </h1>
                    </div>
                    <hr />
                    <div className=" mt-4">
                      <h1 className="text-[#071827] font-extrabold">To:</h1>
                      <div className="flex flex-col gap-2 mt-2">
                        <div className="flex items-center justify-between">
                          <h1 className="text-[#5C6F7F]">Bank</h1>
                          <h2 className="text-[#071827] font-medium">
                            {bank?.label }
                          </h2>
                        </div>
                        <div className="flex items-center justify-between">
                          <h1 className="text-[#5C6F7F]">Account Number</h1>
                          <h2 className="text-[#071827] font-medium">
                            {accountNumber}
                          </h2>
                        </div>
                        <div className="flex items-center justify-between">
                          <h1 className="text-[#5C6F7F]">Beneficiary</h1>
                          <h2 className="text-[#071827] font-medium">
                            {accountName}
                          </h2>
                          {/*  */}
                        </div>
                        <div className="flex items-center justify-between">
                          <h1 className="text-[#5C6F7F]">
                            Total Amount Debited{" "}
                          </h1>
                          <h2 className="text-[#0eb683] text-base font-bold">
                            NGN{" "}
                            {numberWithCommas(
                              value * 1 +
                                50 +
                                (Number.isNaN((0.5 / 1000) * Number(value))
                                  ? 0.0
                                  : (0.5 / 1000) * Number(value))
                            )}
                          </h2>
                        </div>

                        <div className="flex items-center justify-between">
                          <h1 className="text-[#5C6F7F]">
                            Save Beneficiary Details
                          </h1>
                          <AppCheckBox
                            getChecked={(value: boolean) => {
                              beneficiary(value);
                            }}
                          />
                        </div>
                      </div>
                      {showErr ? (
                        <p className="text-[#ee0000]  xxs:mb-8 text-center pt-10 select-none tracking-tight">
                          {failed}
                        </p>
                      ) : (
                        <p className=" text-center pt-10 select-none tracking-tight">
                          By clicking the button below, I agree my NGN balance
                          would be debited of the amount above immediately
                        </p>
                      )}
                    </div>

                    <button
                      disabled={showErr}
                      className=" bg-[#0eb683] rounded-md px-10 p-3 transition-all active:scale-90  text-white tracking-wider select-none w-full  disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none xxs:mt-28 md:mt-16"
                      onClick={verify}
                    >
                      Continue
                    </button>
                  </>
                )}
              </div>
            ) : (
              <>
                <div className=" flex items-center flex-col justify-center h-full gap-3">
                  <div className="icon self-center">
                    <SuccessMobile width={"100px"} height={"100px"} />
                  </div>
                  <h1 className="text-2xl font-bold text-[#071827] self-center">
                    Withdrawal successful
                  </h1>
                  <p className="text-sm text-[#5C6F7F] self-center text-center">
                    Your recipient will receive value shortly
                  </p>

                  <button
                    onClick={() => {
                      setSuccess(false);
                      setConfirmation(false);
                      setModal(false);
                      setShowErr(false);
                    }}
                    className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                  >
                    Done
                  </button>
                </div>
              </>
            )}
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default WithdrawalModal;
