import React, { useEffect, useState } from "react";
import { IOnboardingUser } from "./UserDetails";
import {
  useBusinessCorporate,
  useBusinessIndividual,
} from "../../../sevices/hooks/users";
import { TabPanel } from "../../../components/widgets/TabWidget";
import { AiFillPlusCircle, AiOutlineClose } from "react-icons/ai";
import { useGetCountry, useGetState } from "../../../sevices/hooks/tags";
import InputField from "../comp/InputField";
import CustomSelect from "../../../components/base-components/CustomSelect";
import { GenderData } from "../IndividualUser";
import DragDropComponent from "../../../components/base-components/DragDropComponent";
import { IFormData, useForm } from "../../../context/OnboardingFormContext";
import Controller from "./Controller";
import { TabSelector } from "../../../components/widgets/TabSelector";
import { useNavigate } from "react-router-dom";

export const IDTypeOptions = [
  {
    id: 1,
    label: "International Passport",
    slug: "international-passport",
  },
  {
    id: 2,
    label: "National Identity Slip",
    slug: "NIN_SLIP",
  },
  {
    id: 3,
    label: "National Identification Card",
    slug: "NIN_V2",
  },
  {
    id: 4,
    label: "Driver's License",
    slug: "DRIVERS_LICENSE",
  },
  {
    id: 5,
    label: "Voter's Card",
    slug: "VOTER_ID",
  },
];
const BusinessOwners = ({
  handleClick,
  currentStep,
  steps,
  setErrMsg,
}: IOnboardingUser) => {
  const [formInstances, setFormInstances] = useState<number>(1);
  const createBusinessIndividual = useBusinessIndividual();
  const [formData, setFormData] = useState<any>();
  const createBusinessCorporate = useBusinessCorporate();

  const [selectedType, setSelectedType] = useState<string[]>(["Individual"]);
  const handleMember = (action: string, index: number) => {
    if (action === "add") {
      setFormInstances((prevInstances) => prevInstances + 1);
      setSelectedType((prevCurrencies) => [...prevCurrencies, "Individual"]);
    } else if (action === "remove" && formInstances > 1) {
      setFormInstances((prevInstances) => prevInstances - 1);
      setSelectedType((prevCurrencies) => {
        const newCurrencies = [...prevCurrencies];
        newCurrencies.splice(index, 1);
        return newCurrencies;
      });
    }
  };

  const handleInputChange = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    //   dispatch({ type: "UPDATE_FIELD", field: name as keyof IFormData, value });
  };

  const handleFormSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    // Handle form submission using formData

  };

  const updateFormData = (index: number, data: any) => {
    // setFormData((prevData: any) => {
    //   const newData = [...prevData];
    //   newData[index] = data;
    //   return newData;
    // });
    // createBusinessIndividual
    //   .mutateAsync(formData)
    //   .then((res) => {
    //     console.log({ res });
    //   })
    //   .catch((err) => {});
  };

  const handleRemoveMember = (index: number) => {
    handleMember("remove", index);
  };
  return (
    <div>
      <div className=" pb-4 pt-10">
        <h1 className="text-[24px] leading-[36px] font-bold pb-1 text-[#071827] ">
          Ultimate Business Owners
        </h1>
        <p className=" text-[#5C6F7F] text-[16px] font-normal leading-[24px]">
          Complete basic details about owners of this business
        </p>
      </div>
      <div className="md:w-full md:max-w-lg">
        {/* <form
          onSubmit={handleFormSubmit}
          className="bg-none md:bg-white flex flex-col gap-3 p-0 md:p-5 "
        ></form> */}

        <div className=" md:pl-5">
          {/* {!showSummary ? ( */}
          {Array.from({ length: formInstances }).map((_, index) => (
            <div className={` ${formInstances > 1 ? "mb-8" : ""}`}>
              <div className=" px-5 bg-[#F2FAF7]  md:max-w-lg md:bg-white md:mt-10">
                <div className=" py-7 flex xxs:flex-col md:flex-row md:justify-between ">
                  <h1 className="text-[#071827] text-[16px] leading-[22px] font-medium  ">
                    Business Owner 1
                  </h1>
                  {index > 0 && (
                    <div
                      onClick={() => handleRemoveMember(index)}
                      className="cursor-pointer"
                    >
                      <AiOutlineClose />
                    </div>
                  )}
                </div>

                <nav className=" flex items-center  justify-between  pb-5  ">
                  <TabSelector
                    className={` flex items-center md:gap-2 cursor-pointer relative bg-transparent mr-4 md:mr-0 text-[] ${
                      selectedType[index] === "Individual"
                        ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                        : ""
                    }`}
                    isActive={selectedType[index] === "Individual"}
                    onClick={() =>
                      setSelectedType((prevCurrencies) => {
                        const newCurrencies = [...prevCurrencies];
                        newCurrencies[index] = "Individual";
                        return newCurrencies;
                      })
                    }
                  >
                    <div className="flex items-center gap-2">
                      <div className=" bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>

                      <h1 className="text-[#5C6F7F] md:font-bold md:hidden">
                        Individual
                      </h1>
                    </div>
                    <h1 className="text-[#5C6F7F] md:font-bold hidden md:block">
                      Individual
                    </h1>
                  </TabSelector>

                  <TabSelector
                    className={` flex items-center md:gap-2 cursor-pointer relative bg-transparent mr-4 md:mr-0 ${
                      selectedType[index] === "Corporate"
                        ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                        : ""
                    }`}
                    isActive={selectedType[index] === "Corporate"}
                    onClick={() =>
                      setSelectedType((prevCurrencies) => {
                        const newCurrencies = [...prevCurrencies];
                        newCurrencies[index] = "Corporate";
                        return newCurrencies;
                      })
                    }
                  >
                    <div className="flex items-center gap-2">
                      <div className=" bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>

                      <h1 className="text-[#5C6F7F] md:hidden">Corporate</h1>
                    </div>
                    <h1 className="text-[#5C6F7F] md:font-bold  hidden md:block">
                      Corporate
                    </h1>
                  </TabSelector>
                </nav>
                <main className="panel p-0 flex flex-col">
                  <TabPanel
                    hidden={selectedType[index] !== "Individual"}
                    className=" "
                  >
                    <div className="  px-0 md:pb-5 ">
                      <IndividualForm
                        handleMember={handleMember}
                        index={index}
                        updateFormData={updateFormData}
                        handleClick={handleClick}
                        currentStep={currentStep}
                        steps={steps}
                        setErrMsg={setErrMsg}
                      />{" "}
                    </div>
                  </TabPanel>
                  <TabPanel hidden={selectedType[index] !== "Corporate"}>
                    <div className=" px-0 md:pb-5 ">
                      <div className="card">
                        <CorporateForm
                          handleMember={handleMember}
                          index={index}
                          updateFormData={updateFormData}
                          handleClick={handleClick}
                          currentStep={currentStep}
                          steps={steps}
                          setErrMsg={setErrMsg}
                        />
                      </div>
                    </div>
                  </TabPanel>
                </main>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BusinessOwners;

type IndividualFormProps = {
  handleMember: (action: string, index: number) => void;
  index: number;
  updateFormData: (index: number, data: any) => void;
  handleClick: (direction: any) => void;
  currentStep: number;
  steps: {
    id: number;
    description: string;
    step: string;
  }[];
  setErrMsg: React.Dispatch<React.SetStateAction<string>>;
};

const IndividualForm: React.FC<IndividualFormProps> = ({
  handleMember,
  index,
  updateFormData,
  handleClick,
  currentStep,
  steps,
  setErrMsg,
}) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { state, dispatch } = useForm();
  const createBusinessIndividual = useBusinessIndividual();
  const [loading, setLoading] = useState(false);
  const handleAddMember = () => {
    handleMember("add", index);
  };
const navigate = useNavigate()
  const Icountry = useGetCountry();
  const states = useGetState();

  const countryOptions =
    Icountry?.data?.data.data.map(
      (
        {
          countryCode,
          name,
          index,
        }: { countryCode: string; name: string; index: number },
        id: number
      ) => ({
        label: name,
        slug: countryCode,
        id,
      })
    ) || [];

  const stateOptions =
    states?.data?.data.data.map(
      ({ name, index }: { name: string; index: number }, id: number) => ({
        label: name,
        slug: name,
        id,
      })
    ) || [];

  const [formDatas, setFormData] = useState({
    fullName: "",
    businessOwnerEmail: "",
    businessOwnerPhoneNumber: "",
    businessOwnerCountry: null,
    businessIDType: null,
    businessOwnerState: null,
    businessOwnerGender: null,
    businessOwnerPostCode: "",
    businessOwnerAdrress1: "",
    businessOwnerAdrress2: "",
    businessOwnerPercentage: "",
    businessOwnerIdFile: "",
  });

  const handleRemoveMember = () => {
    handleMember("remove", index);
  };

  const handleInputChange = (name: string, value: string) => {
    setErrMsg("");
    dispatch({ type: "UPDATE_FIELD", field: name as keyof IFormData, value });
    setFormData({
      ...formDatas,
      [name]: value,
    });
  };

  const handleSubmit = async (index: number) => {
    try {
      const formData = new FormData();
      formData.append("businessId", state.businessId);
      if (state.businessIDType) {
        formData.append("idType", state.businessIDType);
      }
      if (state.businessOwnerGender) {
        formData.append("gender", state.businessOwnerGender);
      }
      formData.append("fullName", state.fullName);
      formData.append("addressLine1", state.businessOwnerAdrress1);
      formData.append("addressLine2", state.businessOwnerAdrress2);
      formData.append("email", state.businessOwnerEmail);
      formData.append("postCode", state.businessOwnerPostCode);
      formData.append("shareholding", `${state.businessOwnerPercentage}%`);
      formData.append("phoneNumber", state.businessOwnerPhoneNumber);
      if (state.businessOwnerIdFile) {
        formData.append("idDoc", state.businessOwnerIdFile);
      }
      if (state.businessOwnerCountry) {
        formData.append("country", state.businessOwnerCountry);
      }
      if (state.businessOwnerState) {
        formData.append("state", state.businessOwnerState);
      }

      const res = await createBusinessIndividual.mutateAsync(formData);

      setLoading(false);

      navigate("/success");
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Function to handle window resize and update mobile state
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className={` px-0 `}>
      <div className="bg-none md:bg-white flex flex-col gap-3 p-0  md:w-full md:max-w-lg">
        <div className="relative ">
          <InputField
            label="Full Name"
            name="fullName"
            type="text"
            required={true}
            value={state.fullName}
            onChange={(value: string) => handleInputChange("fullName", value)}
          />
        </div>

        <div className="relative w-full">
          <small className="text-[#0B0C0E] text-sm font-normal ">
            <span className="text-[#E01A00]">*</span>
            Gender{" "}
          </small>
          <CustomSelect
            //@ts-ignore
            selectedOption={state.businessOwnerGender}
            setSelectOption={(opt) =>
              handleInputChange("businessOwnerGender", opt.slug)
            }
            placeholder={"Choose gender"}
            options={GenderData || []}
          />
          {/* <span className="text-[#E01A00] absolute left-2 top-9">*</span> */}
        </div>

        <div className="relative ">
          <InputField
            label="Email"
            name="businessOwnerEmail"
            type="businessOwnerEmail"
            required={true}
            value={state.businessOwnerEmail}
            onChange={(value: string) =>
              handleInputChange("businessOwnerEmail", value)
            }
          />
        </div>

        <div>
          <InputField
            label="Phone Number"
            name="businessOwnerPhoneNumber"
            type="tel"
            required={true}
            value={state.businessOwnerPhoneNumber}
            onChange={(value: string) =>
              handleInputChange("businessOwnerPhoneNumber", value)
            }
          />
          <small className="text-[11px] text-[#7991a4] ">
            You will be required to confirm this via OTP
          </small>
        </div>

        <div className="relative ">
          <InputField
            label="Address Line 1"
            name="businessOwnerAdrress1"
            type="text"
            required={true}
            value={state.businessOwnerAdrress1}
            onChange={(value: string) =>
              handleInputChange("businessOwnerAdrress1", value)
            }
          />
        </div>
        <div className="relative ">
          <InputField
            label="Address Line 2"
            name="businessOwnerAdrress2"
            type="text"
            // required={true}
            value={state.businessOwnerAdrress2}
            onChange={(value: string) =>
              handleInputChange("businessOwnerAdrress2", value)
            }
          />
        </div>
        <div className="flex items-center justify-between gap-4">
          <div className="mb-3 w-[50%] relative">
            <label
              className=" text-[#0B0C0E] text-[14px] block leading-[16px] font-normal  pl-2"
              htmlFor="country"
            >
              Select Country
            </label>

            <CustomSelect
              selectedOption={state.businessOwnerCountry}
              setSelectOption={(country) =>
                handleInputChange("businessOwnerCountry", country.slug)
              }
              placeholder={"select country"}
              options={countryOptions || []}
              // errors={errorMessages}
              name={"businessOwnerCountry"}
            />
            <span className="text-[#E01A00] absolute top-[0px]">*</span>
          </div>
          <div className="mb-3 w-[50%] relative">
            <label
              className=" text-[#0B0C0E] text-[14px] block leading-[21px] font-normal  pl-2"
              htmlFor="state"
            >
              State
            </label>

            <CustomSelect
              selectedOption={state.businessOwnerState}
              setSelectOption={(state) =>
                handleInputChange("businessOwnerState", state.slug)
              }
              placeholder={"select state"}
              options={stateOptions || []}
              // errors={errorMessages}
              name={"businessOwnerState"}
            />
            <span className="text-[#E01A00] absolute top-[0px]">*</span>
          </div>
        </div>
        <div className="flex items-center justify-between gap-4">
          <div className="relative w-full">
            <InputField
              label="Post Code"
              name="businessOwnerPostCode"
              type="text"
              required={true}
              value={state.businessOwnerPostCode}
              onChange={(value: string) =>
                handleInputChange("businessOwnerPostCode", value)
              }
            />
          </div>
          <div className="relative w-full">
            <InputField
              label="Percentage Shareholder (%)"
              name="businessOwnerPercentage"
              type="number"
              required={true}
              value={state.businessOwnerPercentage}
              onChange={(value: string) =>
                handleInputChange("businessOwnerPercentage", value)
              }
            />
          </div>
        </div>
        <div className="relative w-full">
          <small className="text-[#0B0C0E] text-sm font-normal ">
            <span className="text-red-600">*</span>
            Select means of identification{" "}
          </small>
          <CustomSelect
            //@ts-ignore
            selectedOption={state.businessIDType}
            setSelectOption={(IDType) =>
              handleInputChange("businessIDType", IDType.slug)
            }
            placeholder={"ID Type"}
            name={"businessIDType"}
            // errors={errorMessages}
            options={IDTypeOptions || []}
          />
          <span className="text-red-600 absolute left-2 top-9">*</span>
        </div>
        <div>
          <small className="text-[#0B0C0E] text-sm font-normal ">
            <span className="text-red-600">*</span>
            Upload <span className="font-bold text-[#0eb683] ">
              selected
            </span>{" "}
            means of identification{" "}
          </small>
          <DragDropComponent
            inputId="cherry++"
            getFiles={(file) => handleInputChange("idFile", file)}
          />
          <small className="text-[11px] leading-[18px] text-[#7991a4] tracking-tight ">
            These include International Passport, NIN ID, or Driver’s License.
            Supported file types .pdf, .docx, .jpeg, .jpg, .png, .txt
          </small>
        </div>
        {index === 0 && (
          <div
            onClick={handleAddMember}
            className="text-[#0eb683] flex items-center gap-2 text-[16px] leading-[24px] cursor-pointer"
          >
            <AiFillPlusCircle />
            <span>Add another owner</span>
          </div>
        )}
        {/* <OnboardingFooter
          backFn={
            backFn
            // updateStep("choose", false);
            // setCurrentFlow("individual");
            // setSelectedTab("choose");
          }
          nextFn={() => handleSubmit(index)}
          // nextDisabled={!isValid}
        /> */}
        <Controller
          handleClick={handleClick}
          currentStep={currentStep}
          steps={steps}
          color="#fff"
          setErrMsg={setErrMsg}
        />
      </div>
    </div>
  );
};

const CorporateForm: React.FC<IndividualFormProps> = ({
  handleMember,
  index,
  updateFormData,
  handleClick,
  currentStep,
  steps,
  setErrMsg,
}) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { state, dispatch } = useForm();
  const [loading, setLoading] = useState(false);
  const createBusinessCorporate = useBusinessCorporate();
  const navigate = useNavigate();

  const [formDatas, setFormData] = useState({
    corporateBusinessName: "",
    corporateIncorNum: "",
    corporateBusinessPhone: "",
    corporateCountry: null,
    corporateBusinessEmail: "",
    corporateBusinessState: null,
    corporatePostCode: "",
    corporateBusinessAddress: "",
    corporateBusinessPercentage: "",
    corporateIdFile: "",
  });

  const Icountry = useGetCountry();
  const states = useGetState();

  const countryOptions =
    Icountry?.data?.data.data.map(
      (
        {
          countryCode,
          name,
          index,
        }: { countryCode: string; name: string; index: number },
        id: number
      ) => ({
        label: name,
        slug: countryCode,
        id,
      })
    ) || [];

  const handleAddMember = () => {
    handleMember("add", index);
  };

  const handleInputChange = (name: string, value: string) => {
    setErrMsg("");
    dispatch({ type: "UPDATE_FIELD", field: name as keyof IFormData, value });

    setFormData({
      ...formDatas,
      [name]: value,
    });
  };

  const handleSubmit = async (index: number) => {
    try {
      const formData = new FormData();

      formData.append("businessId", state.businessId);

      formData.append("businessName", state.corporateBusinessName);
      formData.append("registrationNumber", state.corporateIncorNum);
      formData.append("businessAddress", state.corporateBusinessAddress);
      formData.append("businessEmail", state.corporateBusinessEmail);
      formData.append("businessPhoneNumber", state.corporateBusinessPhone);
      formData.append("shareholding", `${state.corporateBusinessPercentage}%`);

      if (state.corporateIdFile) {
        formData.append("registrationDocument", state.corporateIdFile);
      }
      if (state.corporateCountry) {
        formData.append("countryOfRegistration", state.corporateCountry);
      }
      const res = await createBusinessCorporate.mutateAsync(formData);

      setLoading(false);
    
      navigate("/success");
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Function to handle window resize and update mobile state
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className=" px-0 ">
      <div className="bg-none md:bg-white flex flex-col gap-3 p-0  md:w-full md:max-w-lg">
        <div className="relative ">
          <InputField
            label="Business Name"
            name="corporateBusinessName"
            type="text"
            required={true}
            value={state.corporateBusinessName}
            onChange={(value: string) =>
              handleInputChange("corporateBusinessName", value)
            }
          />
        </div>

        <div className="relative ">
          <InputField
            label="Registration Number"
            name="corporateIncorNum"
            type="number"
            required={true}
            value={state.corporateIncorNum}
            onChange={(value: string) =>
              handleInputChange("corporateIncorNum", value)
            }
          />
        </div>
        <div className="relative ">
          <CustomSelect
            //@ts-ignore
            selectedOption={state.corporateCountry}
            setSelectOption={(country) =>
              handleInputChange("country", country.slug)
            }
            placeholder={"select country"}
            options={countryOptions || []}
            // errors={errorMessages}
            name={"corporateCountry"}
          />
          {/* <span className="text-[#E01A00] absolute top-[0px]">*</span> */}
        </div>
        <div>
          <small className="text-[#0B0C0E] text-[14px] leading-[21px] font-normal ">
            Upload incorporation documents{" "}
            <span className="text-red-600">*</span>
          </small>
          <DragDropComponent
            inputId="dktimere"
            getFiles={(file) => handleInputChange("idFile", file)}
          />
          <small className="text-[11px] text-[#7991a4] ">
            These include CAC incorporation certificates, etc.
          </small>
        </div>

        <div className="relative ">
          <InputField
            label="Business Address"
            name="corporateBusinessAddress"
            type="text"
            required={true}
            value={state.corporateBusinessAddress}
            onChange={(value: string) =>
              handleInputChange("corporateBusinessAddress", value)
            }
          />
        </div>
        <div className="relative ">
          <InputField
            label="Business Email"
            name="corporateBusinessEmail"
            type="corporateBusinessEmail"
            required={true}
            value={state.corporateBusinessEmail}
            onChange={(value: string) =>
              handleInputChange("corporateBusinessEmail", value)
            }
          />
        </div>

        <div>
          <InputField
            label="Phone Number"
            name="corporateBusinessPhone"
            type="tel"
            required={true}
            value={state.corporateBusinessPhone}
            onChange={(value: string) =>
              handleInputChange("corporateBusinessPhone", value)
            }
          />

          <small className="text-[11px] text-[#7991a4] ">
            You will be required to confirm this via OTP
          </small>
        </div>

        <div className="relative ">
          <InputField
            label="% Shareholding"
            name="corporateBusinessPercentage"
            type="text"
            required={true}
            value={state.corporateBusinessPercentage}
            onChange={(value: string) =>
              handleInputChange("corporateBusinessPercentage", value)
            }
          />
        </div>
      </div>
      {index === 0 && (
        <div
          onClick={handleAddMember}
          className="text-[#0eb683] flex items-center gap-2 text-[16px] leading-[24px] cursor-pointer"
        >
          <AiFillPlusCircle />
          <span>Add another owner</span>
        </div>
      )}
      <div className="mt-4">
        <Controller
          handleClick={handleClick}
          currentStep={currentStep}
          steps={steps}
          color="#fff"
          setErrMsg={setErrMsg}
        />
      </div>
    </div>
  );
};
