import React from 'react'
import { FC } from 'react';
import SvgIconConstituentValues from './types';

export interface SvgIcon extends FC<SvgIconConstituentValues>{}

const FailedIcon = ({
    height= "24px",
    width="24px",
    ...props
}:React.SVGProps<SVGAElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width= {width} height= {height} viewBox="0 0 90 90" fill="none">
<path d="M45 82.5C65.7107 82.5 82.5 65.7107 82.5 45C82.5 24.2893 65.7107 7.5 45 7.5C24.2893 7.5 7.5 24.2893 7.5 45C7.5 65.7107 24.2893 82.5 45 82.5Z" stroke="#E01A00" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.75 71.25L71.25 18.75" stroke="#E01A00" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default FailedIcon