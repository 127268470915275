import React from "react";
import { IoWarning } from "react-icons/io5";

const Warning = ({currency}:{currency: any}) => {
  return (
    <div className="flex items-center justify-center  gap-3 py-2 px-3 bg-[#FFC3BD] rounded-xl h-full ">
      <div className="h-full w-10 flex justify-center items-center">
        <div className="rounded-full bg-[#FFF] h-10 w-10 flex items-center justify-center shadow-md ">
          <div className="flex justify-center mb-1">
            <IoWarning fill="#E01A00"  size={29} />
          </div>
        </div>
      </div>

      <div>
        <h1 className="text-[#E01A00] text-base font-bold">Warning</h1>
        <p className="xxs:text-xs  text-[#071827]font-semibold text-justify pr-2">
          Funds transferred to the wrong address would be lost permanently.
          Sending any token other than {currency.toUpperCase()} to  this address over the {currency === "usdc" ? "Polygon (MATIC)" : currency === "usdt" ? "Ethereum Chain (ERC-20)": null}  will result in funds being lost forever. Only send
          amounts using two decimal places, because after that you won’t be
          credited{" "}
          <span className="font-bold text-xs ">
            (e.g. sending 1.0999 results in you getting 1.00)
          </span>{" "}
          .
        </p>
      </div>
   
    </div>
  );

};

export default Warning;
