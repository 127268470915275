import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import usFlag from "../../assets/flags/usd.png";
import ukFlag from "../../assets/flags/pound.png";
import euFlag from "../../assets/flags/euro.png";
import ngFlag from "../../assets/flags/ng.png";
import hamburger from "../../assets/misc/hamburger-button.svg";
import { AiOutlineClose } from "react-icons/ai";
import { useRateStore, useSidebarState } from "../../store";
import {
  useAccActivity,
  useAccountNgn,
  useAccountUsd,
  useBuyCurrency,
  useSwap,
  useWalletBalance,
} from "../../sevices/hooks/wallets";
import logo from "../../assets/misc/FaviconAsset_ttfcx.svg";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";
import { motion, AnimatePresence } from "framer-motion";
import Notification from "../../components/dashboard-components/Notification";
import AppNumericInput from "../../components/widgets/AppNumericInput";
import { numberWithCommas } from "./Withdrawals";
import { useGbpRates, useRates, useRatesEur } from "../../sevices/hooks/tags";

import DashBoardEmptyState from "../../components/dashboard-components/DashBoardEmptyState";
import LoadingScreen from "../Notifications/LoadingScreen2";
import Table from "../../components/transaction-component/Table";
import TransactionRecieptModal from "./transaction/TransactionRecieptModal";
import { useRecieptState } from "../../store/useReciept";
import moment from "moment";
import { IoIosSend } from "react-icons/io";
import WalletWidget from "../../components/dashboard-components/WalletWidget";
import ReactLoading from "react-loading";
import SwapModal from "../../components/dashboard-components/SwapModal";
import FundModal from "../../components/dashboard-components/FundModal";
import TabContext from "../../store/context/TabProvider";
import WalletAccountTable from "../../components/wallet-components/WalletAccountTable";
import DashboardTable from "../../components/dashboard-components/DashboardTable";

interface IPayload {
  benDetails: {
    email: string;
    phone: string;
    bank: string;
    account_name: string;
    account_number: string;
  };
  trxDetails: {
    from_currency: string;
    to_currency: string;
    from_amount: number;
    transaction_type: string;
  };
}

const DashboardPage = () => {
  const toggleSideBarOpen = useSidebarState((state) => state.toggleSidebar);
  const [userDetails, refetch] = useOutletContext<any>();

  const navigate = useNavigate();

  const accountNgn = useWalletBalance("NGN");
  const accountUsd = useWalletBalance("usd");

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [currentTab, setCurrentTab] = useState("buy");
  const [showBuyCurrency, setShowBuyCurrency] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fundedSuccess, setfundedSuccess] = useState(false);

  //BuyUsd
  const [usdAmountValue, setUsdAmountValue] = useState<any>();
  const [NGNAmountValue, setNGNAmountValue] = useState<any>();
  // const [appUSDRate, setappUSDRate] = useState(usdRate);

  //hooks for Gbp operations
  const [showBuyGbp, setShowBuyGbp] = useState(false);

  const [gbpBuySuccess, setGbpBuySuccess] = useState(false);
  const [confirmSellGbp, setConfirmSellGbp] = useState(false);

  // buyGBP
  const [gbpAmountValue, setGdpAmountValue] = useState<any>();
  const [NGNGBPAmountValue, setNGNGBPAmountValue] = useState<any>();

  //buyEUR
  const [eurAmountValue, setEurAmountValue] = useState<any>();

  //hooks for EUR operations
  const [showBuyEur, setShowBuyEur] = useState(false);

  const [confirmSellEur, setConfirmSellEur] = useState(false);
  const [eurSellSuccess, setEurSellSuccess] = useState(false);

  //fund
  const [fundModal, setFundModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [countdown, setCountdown] = useState(60);

  const currentRate = useRates();
  const eurRates = useRatesEur();
  const gbpRates = useGbpRates();

  const userActivities = useAccActivity();

  const { refetch: refetchRate } = currentRate;

  const transactions = useMemo(
    () => userActivities?.data?.data,
    [userActivities?.data?.data]
  );

  const showAllTransactionReciept = useRecieptState(
    (state) => state.showTransactionReciept
  );

  const [amountValue, setAmountValue] = useState<any>();
  //@ts-ignore
  const { setTab, tab } = useContext(TabContext);

  function commas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const amountErr =
    tab === "buy"
      ? `Minimum amount is NGN ${commas(
          Number(currentRate?.data?.data?.buyRate) * Number(500)
        )} `
      : `Minimum amount is USD ${commas(
          (Number(currentRate?.data?.data?.sellRate) * Number(10)) /
            Number(currentRate?.data?.data?.sellRate)
        )} `;
  const GbpErr = `Minimum amount is NGN ${commas(
    Number(gbpRates?.data?.data?.buyRate * 100).toFixed()
  )}`;
  const EurErr = `Minimum amount is NGN ${commas(
    Number(eurRates?.data?.data?.buyRate * 100).toFixed()
  )}`;

  // useEffect(() => {}, [accountUsd?.data?.data]);

  const fundNgn = () => setFundModal((prevState) => !prevState);
  const initiateBuy = () => setShowBuyCurrency(true);
  const initiateBuyEur = () => setShowBuyEur(true);
  const initiateBuyGBP = () => setShowBuyGbp((prevState) => !prevState);

  return (
    <LoadingScreen
      showLoading={
        currentRate.status === "loading" ||
        eurRates.status === "loading" ||
        gbpRates.status === "loading"
      }
      msg="Fetching Current Rates.."
      // bg="#ffc"
    >
      <div className="bg-[#f7fbfb] h-full w-full p-5  md:pl-8 md:pr-9">
        <div className="w-16 ml-auto block md:hidden ">
          <img src={logo} alt="" />
        </div>

        <div className="flex flex-wrap items-center justify-between -z-0 ">
          <div className="flex">
            <div className="flex flex-col">
              <div className="top flex flex-col items-start gap-2">
                <button
                  className="py-1 md:hidden w-16"
                  onClick={() => toggleSideBarOpen()}
                >
                  <img src={hamburger} alt="" className="w-10" />
                </button>
                <div className="info ">
                  {userDetails?.accountType !== "business" ? (
                    <h1 className="text-2xl  text-[#071827] pb-2 ">
                      Hello{" "}
                      <span className=" text-2xl font-bold text-[#071827]">
                        {userDetails.firstName.charAt(0).toUpperCase() +
                          userDetails.firstName.slice(1)}
                        ,
                      </span>
                    </h1>
                  ) : (
                    <h1 className="text-2xl  text-[#071827] pb-2 ">
                      Welcome{" "}
                      <span className=" text-2xl font-bold text-[#071827]">
                        {userDetails?.businessName?.charAt(0).toUpperCase() +
                          userDetails?.businessName?.slice(1)}
                        ,
                      </span>
                    </h1>
                  )}

                  <p className=" xxs:pb-0 md:pb-5 text-[#5C6F7F] text-lg">
                    {` Here’s an overview of your ${
                      transactions?.length > 0 ? "activities" : "activity"
                    }`}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className=" hidden items-center justify-center gap-2 md:gap-5 py-5 md:flex ">
            <div className=" flex  items-center border-[#0eb683] border-r-2 gap-3 pr-2 md:pr-4  ">
              <figure className="w-5 md:w-10 ">
                <img src={usFlag} alt="" className="" />
              </figure>
              <p className="text-xs md:text-lg font-semibold">{`$1/N${
                currentRate?.data?.data?.buyRate || "0.00"
              }`}</p>
            </div>
            <div className=" flex  items-center  border-[#0eb683] border-r-2 gap-3 pr-2 md:pr-4">
              <figure className="w-5 md:w-10 ">
                <img src={ukFlag} alt="" className="" />
              </figure>
              <p className="text-xs md:text-lg font-semibold">{`£1/N${
                gbpRates?.data?.data?.buyRate || "0.00"
              }`}</p>
            </div>
            <div className=" flex  items-center gap-3">
              <figure className="w-5 md:w-10 ">
                <img src={euFlag} alt="" className="" />
              </figure>
              <p className="text-xs md:text-lg font-semibold">{`€1/N${
                eurRates?.data?.data?.buyRate || "0.00"
              }`}</p>
            </div>
          </div>

          <button
            onClick={() => {
              navigate("/dashboard/beneficiaries", { replace: true });
            }}
            className="hidden  md:flex items-center text-base text-white font-medium bg-[#0eb683] p-3 rounded-md gap-2 px-8 transition-all active:scale-90 cursor-pointer md:mb-3.5 "
          >
            <IoIosSend size={25} />
            <span className="text-base pt-1">Initiate Transfer</span>
          </button>
        </div>

        {/*Mobile Rate */}

        <div className=" md:hidden items-center justify-center gap-2 md:gap-5 py-5 flex overflow-x-auto px-5">
          <div className=" flex  items-center border-[#0eb683] border-r-2 gap-1 pr-2 md:pr-4  ">
            <figure className="w-5 md:w-10 ">
              <img src={usFlag} alt="" className="" />
            </figure>
            <p className="text-base md:text-lg font-semibold text-[#333333]">{`$1/N${
              currentRate?.data?.data?.buyRate || "0.00"
            }`}</p>
          </div>
          <div className=" flex  items-center  border-[#0eb683] border-r-2 gap-1 pr-2 md:pr-4">
            <figure className="w-5 md:w-10 ">
              <img src={ukFlag} alt="" className="" />
            </figure>
            <p className="text-base md:text-lg font-semibold text-[#333333]">{`£1/N${
              gbpRates?.data?.data?.buyRate || "0.00"
            }`}</p>
          </div>
          <div className=" flex  items-center gap-1">
            <figure className="w-5 md:w-10 ">
              <img src={euFlag} alt="" className="" />
            </figure>
            <p className="text-base md:text-lg font-semibold text-[#333333]">{`€1/N${
              eurRates?.data?.data?.buyRate === "undefined"
                ? "0.00"
                : eurRates?.data?.data?.buyRate
            }`}</p>
          </div>
        </div>
        <div className="w-full  flex xxs:gap-5 md:gap-7 flex-1  whitespace-no wrap max-w-full  overflow-x-scroll xxs:mt-0 md:mt-4 -z-50 ">
          <WalletWidget
            color="#FFF"
            currency="NGN Balance"
            symbol="₦‎"
            buttonColor={"#0eb683"}
            amount={
              numberWithCommas(userDetails?.ngnBalance) === "NaN"
                ? "0.00"
                : ` ${numberWithCommas(userDetails?.ngnBalance)}`
            }
            border={"1px solid #0eb683"}
            action={fundNgn}
            button="Fund"
            textColor={"#fff"}
            flag={ngFlag}
            navigate={""}
            disable={false}
          />
          <WalletWidget
            color="#C4FFF4"
            currency="USD Balance"
            symbol="$"
            buttonColor={"#fff"}
            textColor={"#0eb683"}
            amount={
              numberWithCommas(userDetails?.usdBalance) === "NaN"
                ? "0.00"
                : ` ${numberWithCommas(userDetails?.usdBalance)}`
            }
            border={"1px solid #C4FFF4"}
            action={initiateBuy}
            button="Buy | Sell"
            flag={usFlag}
            navigate={""}
            disable={false}
          />
          <WalletWidget
            color="#FFDECF"
            currency="GBP Balance"
            symbol="£"
            buttonColor={"#fff"}
            textColor={"#0eb683"}
            amount={
              numberWithCommas(userDetails?.gbpBalance) === "NaN"
                ? "0.00"
                : ` ${numberWithCommas(userDetails?.gbpBalance)}`
            }
            border={"1px solid #FFDECF"}
            action={initiateBuyGBP}
            button="Buy | Sell"
            flag={ukFlag}
            navigate={""}
            disable={true}
          />
          <WalletWidget
            color="#E8EAFF"
            currency="EUR Balance"
            symbol="€"
            buttonColor={"#fff"}
            textColor={"#0eb683"}
            amount={
              numberWithCommas(userDetails?.eurBalance) === "NaN"
                ? "0.00"
                : ` ${numberWithCommas(userDetails?.eurBalance)}`
            }
            border={"1px solid #E8EAFF"}
            action={initiateBuyEur}
            button="Buy | Sell"
            flag={euFlag}
            navigate={""}
            disable={true}
          />
        </div>

        <div>
          <div className="">
            {showBuyGbp && (
              <SwapModal
                baseCurrency={"gbp"}
                baseCurrencySymbol={"£ "}
                refetchRates={refetch}
                refetch={refetchRate}
                currency={"ngn"}
                currencySymbol={"₦ "}
                sellRate={gbpRates?.data?.data?.sellRate}
                rate={gbpRates?.data?.data?.buyRate}
                confirmation={confirmSellGbp}
                setConfirmation={setConfirmSellGbp}
                inputValue={
                  tab === "buy"
                    ? NGNGBPAmountValue?.value
                    : gbpAmountValue?.value
                }
                inputValueFormatted={NGNGBPAmountValue?.formattedValue}
                inputValueFloat={NGNGBPAmountValue?.floatValue}
                setInputValue={setNGNGBPAmountValue}
                openModal={showBuyGbp}
                loading={loading}
                setLoading={setLoading}
                setOpenModal={setShowBuyGbp}
                balance={userDetails?.ngnBalance}
                baseBalance={userDetails?.gbpBalance}
                computedValue={
                  tab === "buy"
                    ? gbpAmountValue?.value
                    : NGNGBPAmountValue?.value
                }
                computedValueFloat={gbpAmountValue?.floatValue}
                computedValueFormatted={gbpAmountValue?.formattedValue}
                setComputedValue={setGdpAmountValue}
                errorMessage={GbpErr}
                success={gbpBuySuccess}
                setSuccess={setGbpBuySuccess}
                // initiateBuy={purchaseUsd}
                setFundModal={fundNgn}
              />
            )}
          </div>
          <div className="xxs:mb-10 md:mb-0">
            {/* <DashBoardEmptyState/> */}
            {userActivities?.data?.data.length > 0 ? (
              // <WalletAccountTable itemsPerPage={10} data={transactions} />
              <DashboardTable tableData={userActivities?.data?.data} />
            ) : (
              <>
                <DashBoardEmptyState />
              </>
            )}
          </div>
        </div>

        {showBuyEur && (
          <SwapModal
            baseCurrency={"eur"}
            baseCurrencySymbol={"€ "}
            currency={"ngn"}
            currencySymbol={"₦ "}
            sellRate={eurRates?.data?.data?.sellRate}
            rate={eurRates?.data?.data?.buyRate}
            confirmation={confirmSellEur}
            setConfirmation={setConfirmSellEur}
            inputValue={NGNAmountValue?.value}
            inputValueFormatted={NGNAmountValue?.formattedValue}
            inputValueFloat={NGNAmountValue?.floatValue}
            setInputValue={setNGNAmountValue}
            openModal={showBuyEur}
            loading={loading}
            setLoading={setLoading}
            setOpenModal={setShowBuyEur}
            balance={userDetails?.ngnBalance}
            baseBalance={userDetails?.eurBalance}
            computedValue={eurAmountValue?.value}
            computedValueFloat={eurAmountValue?.floatValue}
            computedValueFormatted={eurAmountValue?.formattedValue}
            setComputedValue={setEurAmountValue}
            errorMessage={EurErr}
            success={eurSellSuccess}
            setSuccess={setEurSellSuccess}
            refetchRates={refetch}
            refetch={refetchRate}
            // initiateBuy={purchaseUsd}
            setFundModal={fundNgn}
          />
        )}

        <AnimatePresence>
          {showSuccessModal && (
            <div>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    // delay: 0.3,
                    duration: 0.1,
                  },
                }}
                className="confirmation_modal backdrop-blur-sm
                  bg-[#13111137] fixed w-full top-0 left-0 justify-center h-full items-center z-50"
              >
                <motion.div
                  initial={{
                    x: 600,
                    scale: 0,
                  }}
                  animate={{
                    x: 0,
                    scale: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 600,
                    scale: 0,
                    transition: {
                      delay: 0.3,
                    },
                  }}
                  className="flex h-screen items-center justify-center md:justify-end "
                >
                  <motion.div
                    initial={{
                      x: 600,
                      opacity: 0,
                    }}
                    animate={{
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 100,
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    className="desktop-card md:flex hidden justify-center flex-col gap-8 bg-white h-full w-full md:w-[448px] rounded-l-3xl p-5 "
                  >
                    <div className="icon self-center">
                      <SuccessMobile width={"100px"} height={"100px"} />
                    </div>
                    <h1 className="text-2xl font-bold text-[#071827] self-center">
                      Order placed
                    </h1>

                    <p className="text-sm text-[#5C6F7F] self-center text-center">
                      Your purchase is in progress <br /> Please check your FCY
                      wallet
                    </p>

                    <button
                      onClick={() => {
                        setShowSuccessModal(false);
                        setUsdAmountValue("");
                        setNGNAmountValue("");
                      }}
                      className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                    >
                      Done
                    </button>
                  </motion.div>
                  {/* Mobile */}

                  <motion.div
                    initial={{
                      x: 600,
                      opacity: 0,
                    }}
                    animate={{
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 100,
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    className="mobile-card flex md:hidden justify-center flex-col gap-8 bg-white shadow-2xl w-full max-w-sm h-[450px] <rounded-3></rounded-3>xl p-5 "
                  >
                    <div className="icon self-center">
                      <SuccessMobile width={"100px"} height={"100px"} />
                    </div>
                    <h1 className="text-2xl font-bold text-[#071827] self-center">
                      Order placed
                    </h1>

                    <p className="text-sm text-[#5C6F7F] self-center text-center">
                      Your purchase is in progress <br /> Please check your FCY
                      wallet
                    </p>

                    <button
                      onClick={() => {
                        setShowSuccessModal(false);
                      }}
                      className="bg-[#0eb683] text-white rounded-md px-16 py-3 self-center transition-all active:scale-90"
                    >
                      Done
                    </button>
                  </motion.div>
                </motion.div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>

        {/* fund modal NgN */}

        {fundModal && (
          <FundModal
            fundModal={fundModal}
            confirm={showConfirmationModal}
            setConfirm={setShowConfirmationModal}
            loading={loading}
            success={showSuccess}
            setValue={setAmountValue}
            setSuccess={setShowSuccess}
            value={amountValue?.value}
            currency={"ngn"}
            valueFormatted={amountValue?.formattedValue}
            setFund={fundNgn}
          />
        )}

        <div
          onClick={(e) => {
            e.preventDefault();
          }}
        ></div>
        <div>
          {/*BUY USD MODAL */}
          <AnimatePresence>
            {showBuyCurrency && (
              <SwapModal
                baseCurrency={"usd"}
                baseCurrencySymbol={"$ "}
                currency={"ngn"}
                currencySymbol={"₦ "}
                rate={currentRate?.data?.data?.buyRate}
                confirmation={showConfirmation}
                setConfirmation={setShowConfirmation}
                inputValue={
                  tab === "buy" ? NGNAmountValue?.value : usdAmountValue?.value
                }
                inputValueFormatted={NGNAmountValue?.formattedValue}
                inputValueFloat={NGNAmountValue?.floatValue}
                setInputValue={setNGNAmountValue}
                openModal={showBuyCurrency}
                loading={loading}
                setLoading={setLoading}
                setOpenModal={setShowBuyCurrency}
                balance={userDetails?.ngnBalance}
                baseBalance={userDetails?.usdBalance}
                computedValue={
                  tab === "buy" ? usdAmountValue?.value : NGNAmountValue?.value
                }
                sellRate={currentRate?.data?.data?.sellRate}
                computedValueFloat={usdAmountValue?.floatValue}
                computedValueFormatted={usdAmountValue?.formattedValue}
                setComputedValue={setUsdAmountValue}
                errorMessage={amountErr}
                success={showSuccessModal}
                setSuccess={setShowSuccessModal}
                refetchRates={refetch}
                refetch={refetchRate}
                // initiateBuy={purchaseUsd}
                setFundModal={fundNgn}
              />
            )}
          </AnimatePresence>
        </div>
        {showAllTransactionReciept && <TransactionRecieptModal />}
      </div>
    </LoadingScreen>
  );
};

export default DashboardPage;
