import React, { useState } from "react";
import { RiCloseLine } from "react-icons/ri";

export interface IFile {
  fieldname: string;
  originalname: string;
  encoding: string;
  mimetype: string;
  size: number;
  buffer: Buffer;
}

const DragDropComponent = ({
  getFiles,
  inputId,
}: {
  getFiles: (files: any) => void;
  inputId: string;
}) => {
  const [componentFiles, setComponentFiles] = useState<IFile[]>([]);
  const [filenames, setFilenames] = useState<string[]>([]);
  const [fileArr, setFileArr] = useState<any[]>([]);
  // Cancel Default DnD Behavior using events preventDefault & stopPropagation
  const cancelDefaultBehavior = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  // Function to convert files to dataUrl && Blob
  const handleFileProcessing = (
    files: any[],
    doneFn: (files: IFile[]) => void
  ) => {
    const processedFiles: IFile[] = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const reader = new FileReader();
      reader.onload = (evt) => {
        const dataUrl = evt?.target?.result as string;

        const processedFile: any = {
          dataUrl,
          name: file?.name,
          type: file?.type,
          size: file?.size,
        };
        processedFiles.push(processedFile);
        if (processedFiles.length === files?.length) {
          doneFn(processedFiles);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const processFiles = (files: any[]) => {
    // getFiles([...files]);
    setFilenames(
      [...filenames].concat(Array.from(files).map((file: any) => file.name))
    );

    // handleFileProcessing(files, (processedFiles) => {
    //   getFiles(processedFiles);
    // });
  };

  // Function to listen for and handle input change
  const handleInputChange = (evt: any) => {
    processFiles(evt.target.files);
    getFiles(evt.target.files[0]);
  };

  // Function to handle drag enter
  const handleDragEnter = (evt: any) => {
    cancelDefaultBehavior(evt);
    evt.target.classList.add("border-dashed-[#0eb683]");

    return false;
  };

  // Function to handle drag leave
  const handleDragLeave = (evt: any) => {
    cancelDefaultBehavior(evt);
    evt.target.classList.remove("border-dashed");

    return false;
  };

  // Function to handle drag over
  const handleDragOver = (evt: any) => {
    cancelDefaultBehavior(evt);

    return false;
  };

  // Function to handle drop
  const handleDrop = (evt: any) => {
    cancelDefaultBehavior(evt);

    const acceptedFileTypes = [
      "application/pdf",
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "application/msword",
      " application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      " application/vnd.ms-powerpoint",
      " application/vnd.openxmlformats-officedocument.presentationml.slideshow",
      " application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];

    if (acceptedFileTypes.includes(evt.dataTransfer.files[0].type)) {
      processFiles(evt.dataTransfer.files);
    } else {
      throw new Error("Invalid File Type. This file cannot be accepted.");
    }

    return false;
  };

  const removeFile = (index: number) => {
    let newFiles = componentFiles.filter((_, i) => i !== index);
    let newFilenames = filenames.filter((_, i) => i !== index);

    setFilenames(newFilenames);
    setComponentFiles(newFiles);
    getFiles(newFiles);
  };

  return (
    <div className="flex flex-col">
      <div
        className="dnd bg-white md:bg-[#F2FAF7] h-[48px] flex items-center justify-center border rounded-sm"
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <label htmlFor={inputId}>
          {filenames.length > 0 ? (
            <div className="uploaded flex flex-wrap gap-1 text-sm ">
              {filenames.map((filename: string, index: number) => (
                <div key={index} className="flex">
                  <span className="text-xs shrink-0 text-[#0eb683] px-2 rounded-md flex items-center">
                    {filename}
                  </span>
                  <button
                    className="p-2 text-[#E01A00]"
                    onClick={() => removeFile(index)}
                  >
                    <RiCloseLine />
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-[14px] leading-[16px] text-[#5C6F7F] font-normal ">
              Drag and drop document here or{" "}
              <span className="text-[#0eb683] hover:underline active:scale-90 duration-500 ease-in-out cursor-pointer">
                Browse
              </span>{" "}
            </div >
          )}
        </label>
        <input
          onChange={handleInputChange}
          className="hidden"
          accept="image/*,.pdf,.docx,.doc,.txt"
          type="file"
          name={inputId}
          id={inputId}
        />
      </div>
    </div>
  );
};

export default DragDropComponent;
