import React, { TextareaHTMLAttributes, useState } from "react";

export interface TextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  dvalue?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  label,
  name,
  required,
  disabled,
  dvalue,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(!!rest.value || !!rest.placeholder);
  };

  return (
    <div className="relative z-0 w-full">
      <textarea
        {...rest}
        id={name}
        name={name}
        value={dvalue}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="border bg-white md:bg-[#F2FAF7] placeholder:text-sm placeholder:text-[#6F798B] p-3 appearance-none focus:outline-none outline-[#0eb683] focus:ring-0 focus:border-[#0eb683] w-full"
      />
      <label
        htmlFor={name}
        className={`absolute duration-300 top-3 left-0 -z-1 origin-0 text-[#6F798B] text-[14px] leading-[21px] font-normal pl-3
        ${
          focused || rest.value || rest.placeholder
            ? "transform scale-75 -translate-y-3"
            : ""
        }`}
      >
        {required && <span className="text-[#E01A00]">*</span>}
        {label}
      </label>
    </div>
  );
};

export default TextArea;
